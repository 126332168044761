import { Directive, Input } from '@angular/core';
import { AbstractControl, UntypedFormArray, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export function minimumCommodityCountValidator(minCount): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control) {
      return null;
    }

    const commodityCount = (control as UntypedFormArray).controls.reduce((totalCommodities, commodity) => (commodity && commodity.valid ? totalCommodities + 1 : totalCommodities), 0);
    return commodityCount < minCount && control.touched ? { minimumCommodityCount: true } : null;
  };
}

@Directive({
  selector: '[minimumCommodityCountValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinimumCommodityCountValidatorDirective, multi: true }],
})
export class MinimumCommodityCountValidatorDirective implements Validator {
  @Input() minCount: number;

  validate(control: AbstractControl): { [key: string]: any } {
    return minimumCommodityCountValidator(this.minCount)(control);
  }
}
