import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EmployeeName } from '@xpo-ltl/sdk-humanresource';
import * as _ from 'lodash';
import { Observable, BehaviorSubject } from 'rxjs';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { map, debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ClaimHeaderMaxLengths } from 'projects/internal/src/app/enums/FormMaxLengths/claim-header-max-lengths.enum';
import { EmployeeNamePipe } from '../../pipes/employee-name.pipe';

export interface RegistrationReassignClaimResult {
  reassign: boolean;
  employee: EmployeeName;
  reason: string;
}

export enum RegistrationReassignClaimFormNames {
  Employee = 'examinerReassignControl',
  Reason = 'reasonReassignControl',
}

@Component({
  selector: 'app-registration-reassign-claim',
  templateUrl: './registration-reassign-claim.component.html',
  styleUrls: ['./registration-reassign-claim.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationReassignClaimComponent implements OnInit {
  public ClaimHeaderMaxLengths = ClaimHeaderMaxLengths;

  public formGroup: UntypedFormGroup;

  public employeeNames: EmployeeName[];
  public employeeFiltered$: Observable<EmployeeName[]>;

  private reassignDisabledSubject = new BehaviorSubject<boolean>(true);
  public reassignDisabled$ = this.reassignDisabledSubject.asObservable();

  private unsubscriber = new Unsubscriber();

  public readonly formNames = RegistrationReassignClaimFormNames;

  constructor(private formBuilder: UntypedFormBuilder, private namePipe: EmployeeNamePipe, @Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<RegistrationReassignClaimComponent>) {
    this.employeeNames = _.get(data, 'employeeNames', <EmployeeName[]>[]);
  }

  // On angular init lifecycle
  public ngOnInit(): void {
    this.initializeFormGroup();
    // Listen to the employee field and update the filter as they type
    this.employeeFiltered$ = this.formGroup.get(this.formNames.Employee).valueChanges.pipe(
      debounceTime(250),
      startWith(''),
      map((v: string | EmployeeName): string => (typeof v === 'string' ? v : this.namePipe.transform(v, true))),
      map((name: string): EmployeeName[] => this.filterEmployees(name))
    );

    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe(() => this.reassignDisabledSubject.next(this.formGroup.invalid));
  }

  /**
   * Initialized the form with default values and setup validators.
   * Ran in ngOnInit
   */
  private initializeFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      [this.formNames.Employee]: [undefined, [Validators.required]],
      [this.formNames.Reason]: ['', [Validators.required, Validators.maxLength(ClaimHeaderMaxLengths.ReassignReasonNumber)]],
    });
  }

  /**
   * Turns an employee object into a string to display for the autocomplete
   *
   * @param employee Employee to transform
   */
  public displayEmployee(employee?: EmployeeName): string | undefined {
    return !!employee ? this.namePipe.transform(employee, true) : '';
  }

  /**
   * Given input from the user return all the employee objects it matches to exactly
   *
   * @param value typed input from the control
   */
  private filterEmployees(value: string): EmployeeName[] {
    const isMatch = (v: string): boolean => v.toLowerCase().indexOf(value.toLowerCase()) > -1;
    return this.employeeNames.filter((employee: EmployeeName): boolean => isMatch(this.namePipe.transform(employee, true)) || isMatch(this.namePipe.transform(employee, false)));
  }

  /**
   * Exit out of the dialog
   */
  public onCancelClicked(): void {
    this.dialogRef.close(<RegistrationReassignClaimResult>{
      reassign: false,
    });
  }

  /**
   * Confirm then pass back the form data
   */
  public onReassignClicked(): void {
    this.formGroup.markAsTouched();
    if (this.formGroup.valid) {
      this.dialogRef.close(<RegistrationReassignClaimResult>{
        reassign: true,
        employee: this.formGroup.get(this.formNames.Employee).value,
        reason: this.formGroup.get(this.formNames.Reason).value,
      });
    }
  }
}
