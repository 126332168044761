import { NavigationBarLabelType } from '../enums';

export class NavigatorPanel {
  label: NavigationBarLabelType;
  isValid: boolean;

  constructor(label?: NavigationBarLabelType, isValid?: boolean) {
    this.label = label;
    this.isValid = isValid;
  }
}
