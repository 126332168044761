import { DatePipe } from '@angular/common';
import { Directive, Input } from '@angular/core';
import { AbstractControl, NgModel, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import dayjs from 'dayjs';

export function xpoNotGreaterThanDateValidator(xpoNotGreaterThanDate: NgModel, datePipe: DatePipe): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (xpoNotGreaterThanDate && xpoNotGreaterThanDate.value && control.value) {
      const otherDate = dayjs(xpoNotGreaterThanDate.value)
        .endOf('day')
        .toDate();
      const validator = control.validator;
      control.clearValidators();
      xpoNotGreaterThanDate.control.updateValueAndValidity({
        onlySelf: true,
      });
      control.validator = validator;
      if (
        dayjs(control.value)
          .endOf('day')
          .toDate() > otherDate
      ) {
        return {
          greaterThanOther: `Greater than ${datePipe.transform(otherDate, 'MM/dd/yyyy')}`,
        };
      }
    }
    return undefined;
  };
}

@Directive({
  selector: '[xpoNotGreaterThanDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: XpoNotGreaterThanDateValidatorDirective,
      multi: true,
    },
  ],
})
export class XpoNotGreaterThanDateValidatorDirective implements Validator {
  // tslint:disable-next-line:no-input-rename
  @Input('xpoNotGreaterThanDate')
  xpoNotGreaterThanDate: NgModel;

  constructor(private datePipe: DatePipe) {}

  validate(control: AbstractControl): { [key: string]: any } {
    return xpoNotGreaterThanDateValidator(this.xpoNotGreaterThanDate, this.datePipe)(control);
  }
}
