import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { XpoLtlDynamicValidator } from '@xpo-ltl/ngx-ltl';
import { Payment, PaymentDetail, UpsertClaimRqst } from '@xpo-ltl/sdk-claims';
import { ActionCd, ClaimCurrencyCd, PaymentStatusInternalCd, User } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { FormUtils } from '../../../../classes/form-utils.class';
import {
  ClaimEditState,
  ClaimsRegistrationFormNames,
  ErrorWarningValidationType,
  PaymentEntryFormNames,
  PaymentState,
  CommodityLineItemsFormNames,
} from '../../../../enums';
import { ClaimsRegistrationService } from '../../../../services/claims-registration/claims-registration.service';
import { lambdaValidator } from '../../../../validators/lambda-validator.directive';
import { RegistrationClaimPaymentComponent } from './registration-claim-payment.component';
import { ClaimPaymentConstants } from 'projects/internal/src/app/enums/FormMaxLengths/claim-payment-constants.enum';

export class RegistrationClaimPaymentFormBuilder {
  private static defaultValues;

  static create(
    formBuilder: UntypedFormBuilder,
    claimsRegistrationService: ClaimsRegistrationService,
    component: RegistrationClaimPaymentComponent
  ): Observable<AbstractControl> {
    return new Observable((observer) => {
      const countryCd = claimsRegistrationService?.claim?.payee?.countryCd;
      const formGroup = formBuilder.group({
        [PaymentEntryFormNames.ClaimPayoutAmount]: [0, [Validators.min(0.01)]],
        [PaymentEntryFormNames.ClaimPayoutAmountDisplay]: ['$0.00'],
        [PaymentEntryFormNames.ReturnToCDC]: [countryCd ? ['US', 'CA'].every(cd => cd !== countryCd) : false],
        [PaymentEntryFormNames.DecisionReasonCode]: [undefined, [Validators.required]],
        [PaymentEntryFormNames.DecisionSubReasonCode]: [undefined, [Validators.required]],
        [PaymentEntryFormNames.ReasonForAdditionalPayment]: [undefined],
        [PaymentEntryFormNames.DraftNotes]: [''],
        [PaymentEntryFormNames.EmailNotes]: ['', [Validators.maxLength(ClaimPaymentConstants.MaxEmailNotesCharacters)]],
        [PaymentEntryFormNames.ClaimTotalPayoutAmount]: [0],
        [PaymentEntryFormNames.ClaimCurrencyCd]: [ClaimCurrencyCd.US_DOLLARS],
      });

      RegistrationClaimPaymentFormBuilder.defaultValues = formGroup.getRawValue();

      formGroup.get(PaymentEntryFormNames.ClaimPayoutAmount).setValidators([
        XpoLtlDynamicValidator(() => {
          return claimsRegistrationService.claimEditState === ClaimEditState.Payment;
        }, Validators.min(0.01)),
        lambdaValidator(
          () => {
            if (
              claimsRegistrationService.claimEditState === ClaimEditState.Payment ||
              claimsRegistrationService.claimEditState === ClaimEditState.Rebuttal
            ) {
              return (
               Math.round(component.newTotalPayoutAmount * 100) > Math.round(component.totalLineItemClaimAmount * 100)
              );
            } else {
              return false;
            }
          },
          'claimValue',
          ErrorWarningValidationType.Error
        ),
        lambdaValidator(
          () => {
            return component.newTotalPayoutAmount > component.totalLiabilityAndFreightCharges;
          },
          'payoutTooHigh',
          ErrorWarningValidationType.Warning
        ),
      ]);

      formGroup.get(PaymentEntryFormNames.DraftNotes).setValidators([
        Validators.maxLength(ClaimPaymentConstants.MaxDraftNotesCharacters),
        XpoLtlDynamicValidator(() => {
          const payoutAmount: number = FormUtils.getNestedValue(formGroup, PaymentEntryFormNames.ClaimPayoutAmount) ;
          const existingPaymentTotal: number = FormUtils.getNestedValue(formGroup, PaymentEntryFormNames.ClaimTotalPayoutAmount);
          const totalClaimedAmount: number = FormUtils.getNestedValue(
            claimsRegistrationService.claimsRegistrationFormGroup,
            ClaimsRegistrationFormNames.Commodities,
            CommodityLineItemsFormNames.LineItemClaimAmount
          );
          const requireDraftNote = (payoutAmount + existingPaymentTotal) < _.defaultTo(totalClaimedAmount, 0);
          return requireDraftNote;
        }, Validators.required),
      ]);

      formGroup.get(PaymentEntryFormNames.ReasonForAdditionalPayment).setValidators([
        XpoLtlDynamicValidator(() => {
          return component.showAdditionalPaymentReason;
        }, Validators.required),
      ]);

      observer.next(formGroup);
      observer.complete();
    });
  }

  static setDefaultValues(formGroup: UntypedFormGroup): void {
    if (formGroup) {
      formGroup.setValue(RegistrationClaimPaymentFormBuilder.defaultValues);
    }
  }

  static transform(
    upsertClaimRqst: UpsertClaimRqst,
    rawFormGroup: any,
    approver: User,
    claimsRegistrationService: ClaimsRegistrationService
  ): UpsertClaimRqst {
    if (!claimsRegistrationService.claim) {
      return upsertClaimRqst;
    }

    const getValue = (field: string, defaultValue: any = undefined) =>
      _.get(rawFormGroup, `${ClaimsRegistrationFormNames.Payments}.${field}`, defaultValue);
    let approvedAmount = +`${getValue(PaymentEntryFormNames.ClaimTotalPayoutAmount, 0)}`;

    upsertClaimRqst.payments = _.map(
      claimsRegistrationService.claim.payments,
      (payment: PaymentDetail) => payment.payment
    );

    const requestedPayment = _.find(
      upsertClaimRqst.payments,
      (payment: Payment) => payment.statusInternalCd === PaymentStatusInternalCd.PAYMENT_REQUESTED
    );

    // adding a new payment, approving an existing payment, or declining an existing payment during rebuttal declination
    const paymentState: PaymentState = claimsRegistrationService.paymentEditState;
    if (
      paymentState === PaymentState.Approving ||
      paymentState === PaymentState.EnteringNewPayment ||
      paymentState === PaymentState.EnteringNewRebuttalPayment ||
      paymentState === PaymentState.ApprovingAfterEscalation ||
      ((paymentState === PaymentState.Declining || paymentState === PaymentState.DecliningAfterEscalation) &&
        requestedPayment)
    ) {
      const payment = requestedPayment || new Payment();

      payment.amount = +getValue(PaymentEntryFormNames.ClaimPayoutAmount);
      payment.currencyCd = getValue(PaymentEntryFormNames.ClaimCurrencyCd, ClaimCurrencyCd.US_DOLLARS);
      payment.draftNote = getValue(PaymentEntryFormNames.DraftNotes);
      payment.emailNote = getValue(PaymentEntryFormNames.EmailNotes);
      payment.retainCheckInd = getValue(PaymentEntryFormNames.ReturnToCDC, false);
      payment.separateDraftInd = true;
      payment.additionalPaymentReasonCd = getValue(PaymentEntryFormNames.ReasonForAdditionalPayment);
      payment.listActionCd = requestedPayment ? ActionCd.UPDATE : ActionCd.ADD;

      if (
        paymentState === PaymentState.Approving ||
        paymentState === PaymentState.EnteringNewPayment ||
        paymentState === PaymentState.EnteringNewRebuttalPayment ||
        paymentState === PaymentState.ApprovingAfterEscalation
      ) {
        payment.statusInternalCd = PaymentStatusInternalCd.PAYMENT_REQUESTED;
        payment.approvedByEmployeeId = approver.employeeId;
      } else if (
        (paymentState === PaymentState.Declining || paymentState === PaymentState.DecliningAfterEscalation) &&
        requestedPayment
      ) {
        payment.statusInternalCd = PaymentStatusInternalCd.DECLINED;
      }

      if (
        !requestedPayment &&
        (paymentState === PaymentState.Approving ||
          paymentState === PaymentState.EnteringNewPayment ||
          paymentState === PaymentState.EnteringNewRebuttalPayment)
      ) {
        upsertClaimRqst.payments.push(payment);
      }

      // adding a new payment, so need to add it to total approved amount for the claim
      if (payment.listActionCd === ActionCd.ADD) {
        approvedAmount += payment.amount;
      }
    }

    // store the current decision reasons
    upsertClaimRqst.claim.decisionCategoryCd = getValue(
      PaymentEntryFormNames.DecisionReasonCode,
      upsertClaimRqst.claim.decisionCategoryCd
    );
    upsertClaimRqst.claim.decisionSubCategoryCd = getValue(
      PaymentEntryFormNames.DecisionSubReasonCode,
      upsertClaimRqst.claim.decisionSubCategoryCd
    );

    // get the total approved payment amount for claim
    upsertClaimRqst.claim.approvedAmount = approvedAmount;

    return upsertClaimRqst;
  }
}
