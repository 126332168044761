<div class="claim-history-list">

  <div class="claim-history-list__header">
    <h2 md-dialog-title class="claim-history-list__header-title">Claim History - #{{dialogData.claimId}}
      <xpo-spinner [showSpinner]="showSpinner$ | async"></xpo-spinner>
    </h2>

    <div class="claim-history-list__header-close">
      <small *ngIf="!!events">Showing {{events.length}} event{{ events.length > 1 ? 's' : ''}}</small>
      <button mat-icon-button [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <ng-container *ngIf="(dialogData.isMigrated && eventsLoaded && noEvents) then migratedClaim else claimHistoryDetail"></ng-container>

  <ng-template #claimHistoryDetail>
    <div class="claim-history-list__container">
      <div class="claim-history-list__container-content">
        <div class="migrated-info" *ngIf="eventsLoaded && dialogData.isMigrated">
          This is a migrated claim. Only the events occurred after the migration are shown.
        </div>
        <div class="claim-history-list__table-container">
          <table mat-table [dataSource]="events$ | async" class="claim-history-list__table" matSort>
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date &amp; Time</th>
              <td mat-cell *matCellDef="let element">
                <a [href]="getElementUrl(element)" target="_blank" rel="noopener"> {{element.date | date:'MM/dd/yyyy, HH:mm'}} </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="typCd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Event</th>
              <td mat-cell *matCellDef="let element">{{element.typCd }}</td>
            </ng-container>

            <ng-container matColumnDef="employeeName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated By</th>
              <td mat-cell *matCellDef="let element">
                {{element.employeeName}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>


        <app-timeline [claimId]="claimId" [allowZoom]="false" (showSpinner)="showSpinnerTimeline($event)"></app-timeline>
      </div>
    </div>
  </ng-template>
  <ng-template #migratedClaim>
    <mat-dialog-content class="claim-history-list-content">
      <div>
        <p>This migrated claim has no events to show.</p>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="claim-history-list-actionbar">
      <button mat-button color="primary" [mat-dialog-close]="true">Close</button>
    </mat-dialog-actions>
  </ng-template>
</div>
