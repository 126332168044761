export enum FooterButtonGroup {
  ClaimPendingApproval = 'ClaimPendingApproval',
  ManagerDirectorPendingApproval = 'ManagerDirectorPendingApproval',
  ClaimCompleteApproval = 'ClaimCompleteApproval',
  ClaimDecline = 'ClaimDecline',
  ClaimDeclineAfterEscalation = 'ClaimDeclineAfterEscalation',
  ClaimSave = 'ClaimSave',
  ClaimEdit = 'ClaimEdit',
  ClaimReadOnly = 'ClaimReadOnly',
  ClaimSubmitPayment = 'ClaimSubmitPayment',
  RebuttalPending = 'RebuttalPending',
  RebuttalApprovedNoPreviousPayments = 'RebuttalApprovedNoPreviousPayments',
  RebuttalApprovedPreviousPayments = 'RebuttalApprovedPreviousPayments',
  RebuttalDeclined = 'RebuttalDeclined',
  RegisterClaimReview = 'RegisterClaimReview',
  RegisterClaimSubmit = 'RegisterClaimSubmit',
  Default = 'Default',
}
