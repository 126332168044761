export enum ClaimsRegistrationFormNames {
  ClaimNumber = 'ClaimNumber',
  ClaimDate = 'ClaimDate',
  Header = 'Header',
  AsEnteredClaimant = 'AsEnteredClaimant',
  AsEnteredPayee = 'AsEnteredPayee',
  Commodities = 'Commodities',
  Indicators = 'Indicators',
  Notes = 'Notes',
  Rebuttals = 'Rebuttals',
  RelatedPros = 'RelatedPros',
  Payments = 'Payments',
}
