import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { XpoBadgeConfig } from '../interfaces/xpo-badge-config';
import { Observable, isObservable } from 'rxjs';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

@Directive({
  selector: '[xpoBadge]',
})
export class XpoBadgeDirective implements OnDestroy {
  private badgeElement: HTMLElement;
  private _xpoBadge: string;
  private _xpoBadgeColor: string;
  private _xpoBadgeBackgroundColor: string;
  private _xpoBadgeSize: string;
  private _xpoBadgeFontSize: string;
  private _xpoBadgeClass: string;
  private _xpoBadgeDescription: string;
  private _xpoBadgeHidden: boolean;
  private _unsubscriber: Unsubscriber = new Unsubscriber();

  constructor(private renderer: Renderer2, private elementRef: ElementRef<HTMLElement>) {}

  ngOnDestroy(): void {
    if (this._unsubscriber) {
      this._unsubscriber.complete();
      this._unsubscriber = null;
    }
  }

  private getConfigValue(config: XpoBadgeConfig, propertyKey: string) {
    if (config[propertyKey]) {
      if (isObservable(config[propertyKey])) {
        (config[propertyKey] as Observable<string | boolean>).pipe(takeUntil(this._unsubscriber.done)).subscribe((propertyValue: string | boolean) => {
          this[propertyKey] = propertyValue;
        });
      } else {
        this[propertyKey] = config[propertyKey];
      }
    }
  }

  @Input('xpoBadge')
  public set xpoBadge(value: string | XpoBadgeConfig) {
    if (typeof value === 'string') {
      this._xpoBadge = value;
      this.updateTextContent();
    } else {
      value = _.defaultTo(value, {} as XpoBadgeConfig);
      this.getConfigValue(value, 'xpoBadge');
      this.getConfigValue(value, 'xpoBadgeColor');
      this.getConfigValue(value, 'xpoBadgeBackgroundColor');
      this.getConfigValue(value, 'xpoBadgeSize');
      this.getConfigValue(value, 'xpoBadgeFontSize');
      this.getConfigValue(value, 'xpoBadgeClass');
      this.getConfigValue(value, 'xpoBadgeDescription');
      this.getConfigValue(value, 'xpoBadgeHidden');
    }
  }

  @Input('xpoBadgeColor')
  public set xpoBadgeColor(value: string) {
    this._xpoBadgeColor = value;
    this.updateTextContent();
  }

  @Input('xpoBadgeBackgroundColor')
  public set xpoBadgeBackgroundColor(value: string) {
    this._xpoBadgeBackgroundColor = value;
    this.updateTextContent();
  }

  @Input('xpoBadgeSize')
  public set xpoBadgeSize(value: string) {
    this._xpoBadgeSize = value;
    this.updateTextContent();
  }

  @Input('xpoBadgeFontSize')
  public set xpoBadgeFontSize(value: string) {
    this._xpoBadgeFontSize = value;
    this.updateTextContent();
  }

  @Input('xpoBadgeClass')
  public set xpoBadgeClass(value: string) {
    this._xpoBadgeClass = value;
    this.updateTextContent();
  }

  @Input('xpoBadgeDescription')
  public set xpoBadgeDescription(value: string) {
    this._xpoBadgeDescription = value;
    this.updateTextContent();
  }

  @Input('xpoBadgeHidden')
  public set xpoBadgeHidden(value: boolean) {
    this._xpoBadgeHidden = value;
    this.hideBadge(value);
  }

  private hideBadge(hide: boolean) {
    if (hide) {
      if (!!this.badgeElement) {
        this.renderer.removeChild(this.renderer.parentNode, this.badgeElement);
        this.badgeElement = undefined;
      }
    } else {
      if (!this.badgeElement) {
        this.badgeElement = this.createBadgeElement();
      }
    }
  }

  private updateTextContent(): HTMLSpanElement {
    if (this._xpoBadgeHidden) {
      this.hideBadge(this._xpoBadgeHidden);
    } else {
      if (!this.badgeElement) {
        this.badgeElement = this.createBadgeElement();
      }
      this.updateBadgeAttributes(this.badgeElement);
      return this.badgeElement;
    }
  }

  private updateBadgeAttributes(element: HTMLElement) {
    element.textContent = this._xpoBadge;
    element.style.color = this._xpoBadgeColor || 'white';
    element.style.backgroundColor = this._xpoBadgeBackgroundColor || 'black';
    if (this._xpoBadgeFontSize) {
      element.style.fontSize = this._xpoBadgeFontSize;
    }
    if (this._xpoBadgeSize) {
      element.style.width = this._xpoBadgeSize;
      element.style.height = this._xpoBadgeSize;
    }
    if (this._xpoBadgeClass) {
      element.classList.remove('xpo-badge');
      element.classList.add(this._xpoBadgeClass);
    }
    if (this._xpoBadgeDescription) {
      element.setAttribute('aria-label', this._xpoBadgeDescription);
    }
  }

  private createBadgeElement(): HTMLElement {
    const rootNode = this.renderer;
    const badgeElement = rootNode.createElement('span');
    badgeElement.classList.add('xpo-badge');
    this.updateBadgeAttributes(badgeElement);
    this.elementRef.nativeElement.parentElement.appendChild(badgeElement);
    return badgeElement;
  }
}
