import { DatePipe } from '@angular/common';
import { Directive, Input } from '@angular/core';
import { AbstractControl, NgModel, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import dayjs from 'dayjs';

export function xpoNotLessThanDateValidator(xpoNotLessThanDate: NgModel, datePipe: DatePipe): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (xpoNotLessThanDate && xpoNotLessThanDate.value && control.value) {
      const otherDate = dayjs(xpoNotLessThanDate.value)
        .startOf('day')
        .toDate();
      const validator = control.validator;
      control.clearValidators();
      xpoNotLessThanDate.control.updateValueAndValidity({ onlySelf: true });
      control.validator = validator;
      if (
        dayjs(control.value)
          .startOf('day')
          .toDate() < otherDate
      ) {
        return {
          lessThanOther: `Less than ${datePipe.transform(otherDate, 'MM/dd/yyyy')}`,
        };
      }
    }
    return undefined;
  };
}

@Directive({
  selector: '[xpoNotLessThanDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: XpoNotLessThanDateValidatorDirective,
      multi: true,
    },
  ],
})
export class XpoNotLessThanDateValidatorDirective implements Validator {
  // tslint:disable-next-line:no-input-rename
  @Input('xpoNotLessThanDate')
  xpoNotLessThanDate: NgModel;

  constructor(private datePipe: DatePipe) {}

  validate(control: AbstractControl): { [key: string]: any } {
    return xpoNotLessThanDateValidator(this.xpoNotLessThanDate, this.datePipe)(control);
  }
}
