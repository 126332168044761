import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toDate'
})
export class ToDatePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    const date = new Date(+value);
    return date;
  }

}
