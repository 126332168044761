import { Directive, ElementRef, Input, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[xpoFocus]',
})
export class XpoFocusDirective implements AfterViewInit {
  // Id references for next and previous targets
  @Input() private xpoFocusNext: string;
  @Input() private xpoFocusPrev: string;

  constructor(private host: ElementRef, private renderer: Renderer2) {}

  /**
   * Register the next (Tab) & prev (Shift+Tab) listeners
   */
  public ngAfterViewInit(): void {
    if (!!this.xpoFocusNext) {
      this.renderer.listen(this.host.nativeElement, 'keydown', this.handleNext.bind(this));
    }
    if (!!this.xpoFocusPrev) {
      this.renderer.listen(this.host.nativeElement, 'keydown', this.handlePrevious.bind(this));
    }
  }

  /**
   * Focuses the target of this directive if Tab is pressed
   * If no target can be found use default html implementation
   *
   * @param event the keyboard event triggering this method
   */
  private handleNext(event: KeyboardEvent): void {
    const target = document.getElementById(this.xpoFocusNext);
    if (!!target && !event.shiftKey && event.key.toLowerCase() === 'tab') {
      event.preventDefault();
      target.focus();
    }
  }

  /**
   * Focuses the host of this directive if Shift+Tab is pressed
   * If no target can be found use default html implementation
   *
   * @param event the keyboard event triggering this method
   */
  private handlePrevious(event: KeyboardEvent): void {
    const target = document.getElementById(this.xpoFocusPrev);
    if (!!target && !!event.shiftKey && event.key.toLowerCase() === 'tab') {
      event.preventDefault();
      target.focus();
    }
  }
}
