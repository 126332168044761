import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ClaimsLookupFilters } from '../classes/claims-lookup-filters';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ConditioningService } from '@xpo-ltl/common-services';

@Injectable({ providedIn: 'root' })
export class LookupService {
  private filtersSubject = new BehaviorSubject<ClaimsLookupFilters>(undefined);
  public filters$ = this.filtersSubject.asObservable();

  public get filters(): ClaimsLookupFilters {
    return this.filtersSubject.value;
  }

  constructor(private conditioningService: ConditioningService) {}

  public parseURI(route: ActivatedRouteSnapshot): ClaimsLookupFilters {
    const filters = new ClaimsLookupFilters();
    _.assign(filters, route.queryParams);

    // Convert to dates
    filters.filedDateFrom = !!filters.filedDateFrom ? new Date(filters.filedDateFrom) : undefined;
    filters.filedDateTo = !!filters.filedDateTo ? new Date(filters.filedDateTo) : undefined;
    filters.pickupDateFrom = !!filters.pickupDateFrom ? new Date(filters.pickupDateFrom) : undefined;
    filters.pickupDateTo = !!filters.pickupDateTo ? new Date(filters.pickupDateTo) : undefined;

    // Convert to array
    const claimStatusList = _.get(route, 'queryParams.claimStatusList'); // Must use this format because filters.claimStatusList is an string[]
    filters.claimStatusList = !!claimStatusList ? claimStatusList.split(',') : undefined;

    // Condition the pro number
    filters.proNbr = !!filters.proNbr ? this.conditioningService.conditionProNumber(filters.proNbr, 11) : undefined;

    // Set in BehaviorSubject for subscriptions
    this.filtersSubject.next(filters);
    return filters;
  }

  public refresh() {
    this.filtersSubject.next(undefined);
  }
}
