import * as _ from 'lodash';
import { PaymentState, ClaimEditState } from '../enums';
import { FooterButtonGroup } from '../enums/footer-button-group.enum';
import { ClaimStatePropertyType } from '../enums/claim-state-property-type.enum';
import { ClaimStateType } from '../enums/claim-state-type.enum';

interface ClaimStatePropertyTypeMap {
  [ClaimStatePropertyType.PaymentState]: PaymentState;
  [ClaimStatePropertyType.FooterButtonState]: FooterButtonGroup;
  [ClaimStatePropertyType.ClaimEditState]: ClaimEditState;
}

interface ClaimStateMap {
  [key: string]: ClaimStatePropertyTypeMap;
}

export class DefaultClaimStates {
  private static defaultClaimStates: ClaimStateMap = {
    [ClaimStateType.Registration]: {
      [ClaimStatePropertyType.PaymentState]: PaymentState.ReadOnly,
      [ClaimStatePropertyType.ClaimEditState]: ClaimEditState.Header,
      [ClaimStatePropertyType.FooterButtonState]: FooterButtonGroup.RegisterClaimReview,
    },
    [ClaimStateType.ClaimDetails]: {
      [ClaimStatePropertyType.PaymentState]: PaymentState.ReadOnly,
      [ClaimStatePropertyType.ClaimEditState]: ClaimEditState.ReadOnly,
      [ClaimStatePropertyType.FooterButtonState]: FooterButtonGroup.ClaimReadOnly,
    },
    [ClaimStateType.ClaimDetailsNoButtons]: {
      [ClaimStatePropertyType.PaymentState]: PaymentState.ReadOnly,
      [ClaimStatePropertyType.ClaimEditState]: ClaimEditState.ReadOnly,
      [ClaimStatePropertyType.FooterButtonState]: FooterButtonGroup.Default,
    },
  };

  /**
   * Returns default claims state for the given claim state type
   */
  public static defaultStateForClaimStateType(claimState: ClaimStateType): ClaimStatePropertyTypeMap {
    return this.defaultClaimStates[claimState];
  }
}
