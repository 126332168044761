export enum NavigationBarLabelType {
  ClaimRebuttals = 'Claim Rebuttals',
  ClaimPayment = 'Claim Payment',
  RelatedProsIndicators = 'Related PROs and Indicators',
  RelatedPros = 'Related PROs',
  CommodityLineItems = 'Commodity Line Items',
  ClaimantPayee = 'Claimant & Payee',
  Indicators = 'Indicators',
  ClaimHeader = 'Claim Header',
  Notes = 'Notes',
  Undefined = 'UNDEFINED',
}
