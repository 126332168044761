export enum ServiceCenterChargeabilityFormNames {
  OriginSic = 'originSic',
  CurrencyCode = 'currencyCode',
  ClaimId = 'claimId',
  ChargeAmount = 'chargeAmount',
  OriginPctAloc = 'originPctAloc',
  OriginAmount = 'originAmount',
  DestSic = 'destSic',
  DestPctAloc = 'destPctAloc',
  DestAmount = 'destAmount',
  Notes = 'notes',
  OtherSics = 'otherSics',
  LoadAtSics = 'loadAtSics',
  ReasonCode = 'reasonCode',
}

export enum ServiceCenterChargeabilityOtherSicsFormNames {
  Sic = 'sic',
  PctAloc = 'pctAloc',
  Amount = 'amount',
}

export enum ServiceCenterChargeabilityLoadAtSicsFormNames {
  Sic = 'sic',
  PctAloc = 'pctAloc',
  Amount = 'amount',
}
