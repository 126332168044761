import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { CommodityLineItemFormNames } from '../enums';

export function claimTypeRequiredFieldsValidator(claimTypes: string[], testFn: Function = () => true): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control || !control.parent) {
      return null;
    }

    const controlClaimType = control.parent.get(CommodityLineItemFormNames.ClaimTypeCd).value;
    return _.some(claimTypes, claimType => claimType === controlClaimType) && !control.value && testFn() ? { required: true } : null;
  };
}

@Directive({
  selector: '[claimTypeRequiredFieldsValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ClaimTypeRequiredFieldsValidatorDirective, multi: true }],
})
export class ClaimTypeRequiredFieldsValidatorDirective implements Validator {
  @Input() claimTypes: string[];

  validate(control: AbstractControl): { [key: string]: any } {
    return claimTypeRequiredFieldsValidator(this.claimTypes)(control);
  }
}
