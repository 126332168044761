import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

/**
 * Returns string split into words at Capital letter boundaries.
 *
 * Ex:
 *  'ThisIsATest' => 'This Is A Test'
 */
@Pipe({
  name: 'asWords',
})
export class AsWordsPipe implements PipeTransform {
  transform(value: string, overrides?: { [key: string]: string }): any {
    return _.get(overrides, value, _.words(_.trim(value)).join(' '));
  }
}
