import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function xpoMaxTimeValidator(time: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    if (control && control.value.length === 5 ) {
      return control.value > time ?  {'xpoMaxTime': { 'value': time, 'actualValue': control.value }} : null;
    }

    return null;
  };
}

export function xpoMaxTimeAsyncValidator(time$: Observable<any>): AsyncValidatorFn {
  return (control: AbstractControl): Promise<{[key: string]: any}> | Observable<{[key: string]: any}> => {
    return time$.pipe(map((value: string) => {
      if (value && value.length === 5) {
        return xpoMaxTimeValidator(value)(control);
      }
    }));
  };
}

@Directive({
  selector: '[xpoMaxTime]',
  providers: [{provide: NG_VALIDATORS, useExisting: XpoMaxTimeValidatorDirective, multi: true}]
})
export class XpoMaxTimeValidatorDirective implements Validator {
  @Input() time: string;

  validate(control: AbstractControl): {[key: string]: any} {
    return xpoMaxTimeValidator(this.time)(control);
  }
}
