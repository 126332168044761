import { Directive, Input } from '@angular/core';
import { AbstractControl, NgModel, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export function xpoNotGreaterThanValueValidator(xpoNotGreaterThanValue: NgModel): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (xpoNotGreaterThanValue && xpoNotGreaterThanValue.value && control.value) {
      const otherValue = (<string>xpoNotGreaterThanValue.value).replace('$', '') || '0';
      const thisValue = (<string>control.value).replace('$', '') || '0';
      const validator = control.validator;
      control.clearValidators();
      xpoNotGreaterThanValue.control.updateValueAndValidity({
        onlySelf: true,
      });
      control.validator = validator;
      if (+thisValue > +otherValue) {
        return {
          greaterThanOther: `Greater than ${otherValue}`,
        };
      }
    }
    return undefined;
  };
}

@Directive({
  selector: '[xpoNotGreaterThanValue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: XpoNotGreaterThanValueValidatorDirective,
      multi: true,
    },
  ],
})
export class XpoNotGreaterThanValueValidatorDirective implements Validator {
  // tslint:disable-next-line:no-input-rename
  @Input('xpoNotGreaterThanValue')
  xpoNotGreaterThanValue: NgModel;

  validate(control: AbstractControl): { [key: string]: any } {
    return xpoNotGreaterThanValueValidator(this.xpoNotGreaterThanValue)(control);
  }
}
