import { Pipe, PipeTransform } from '@angular/core';
import { ClaimChargeabilityReasonCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'serviceCenterChargeabilityReasonCdPipe',
})
export class ServiceCenterChargeabilityReasonCdPipe implements PipeTransform {
  transform(value: ClaimChargeabilityReasonCd): string {
    switch (value) {
      case ClaimChargeabilityReasonCd.BOL_NOT_SIGNED_CORRECTLY:
        return 'BOL Not Signed Correctly';

      case ClaimChargeabilityReasonCd.CONSIGNEE_UNLOAD_WITHOUT_DOCS:
        return 'Consignee Unload Without Docs';

      case ClaimChargeabilityReasonCd.DEFINITE_EVIDENCE_OF_DAMAGE_OR_LOSS:
        return 'Definite Evidence Of Damage Or Loss';

      case ClaimChargeabilityReasonCd.FREIGHT_DISPOSED_WITHOUT_PROCESS:
        return 'Freight Disposed Without Process';

      case ClaimChargeabilityReasonCd.INSPECTION_NOT_PERFORMED:
        return 'Inspection Not Performed';

      case ClaimChargeabilityReasonCd.INTERNAL_HISTORY_SHARED:
        return 'Internal History Shared';

      case ClaimChargeabilityReasonCd.NO_DMG_DXFO_PHOTOS:
        return 'No Damage DXFO Photos';

      case ClaimChargeabilityReasonCd.NO_DR_IN_IMAGE:
        return 'No DR In Image';

      case ClaimChargeabilityReasonCd.SHORTAGE_RESEARCH_INCOMPLETE:
        return 'Shortage Research Not Complete And Signed';

      case ClaimChargeabilityReasonCd.SLC_NOT_FOLLOWED:
        return 'SLC Not Followed';

      case ClaimChargeabilityReasonCd.VAGUE_EXCEPTION_ON_DR:
        return 'Vague Exception On DR';

      default:
        return value || 'UNKNOWN';
    }
  }
}
