import { ArtificialIntelligenceStatus } from './../enums/artificial-intelligence-status';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'artificialIntelligencePipe',
})
export class ArtificialIntelligencePipe implements PipeTransform {
  transform(value: ArtificialIntelligenceStatus): string {
    switch (value) {
      case ArtificialIntelligenceStatus.Approve:
        return `To ${ArtificialIntelligenceStatus.Approve}`;
      case ArtificialIntelligenceStatus.Decline:
        return `To ${ArtificialIntelligenceStatus.Decline}`;
      default:
        return value || 'Unknown';
    }
  }
}
