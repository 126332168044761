import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'base64'
})
export class Base64Pipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(base64Data: string, ...args: any[]): any {
    let transformedData: string;
    if (base64Data) {
      transformedData = `data:image/png;base64, ${base64Data}`;
    }
    return this.sanitizer.bypassSecurityTrustUrl(transformedData);
  }
}
