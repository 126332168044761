import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FormUtils } from '../classes/form-utils.class';
import { ClaimantContactFormNames } from '../enums';

/** make contact name required when there's atleast one in contact info*/
export function contactPhonePhoneExtValidator(): ValidatorFn {
  return (form: AbstractControl): { [key: string]: any } => {
    // If form is instantiated and no fields are set
    if (!form || !form.value) {
      return null;
    }

    // If phone is instantiated and doesn't have a value
    // and there is a phone extension value
    // return error
    if (!!FormUtils.getNestedValue(form, ClaimantContactFormNames.ContactPhoneExt) && !FormUtils.getNestedValue(form, ClaimantContactFormNames.ContactPhone)) {
      return { phoneRequired: true };
    }

    // Otherwise, Phone and Ext don't have values, return
    return null;
  };
}
