import { ElementRef } from '@angular/core';

export class ScrollHelper {

  public static scrollToElement(element: ElementRef) {
    if (element && element.nativeElement) {
      (element.nativeElement as HTMLElement).scrollIntoView();
    }
  }

  public static scrollToElementById(id: string) {
    const relatedPRoEl = document.getElementById(id);
    if (relatedPRoEl) {
      relatedPRoEl.scrollIntoView();
    }else{
      console.error(`element not found by id: ${id}`);
    }
  }
}
