import { ActivatedRoute, Router } from '@angular/router';
import { AppConstantsService } from '../../services/app-constants.service';
import { ClaimEventTypeCd } from '@xpo-ltl/sdk-common';
import { ClaimHistoryListEvent as ListEvent, ClaimHistoryService } from '../../services/claim-history.service';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Input,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DataSet, Timeline } from 'vis';
import { RouterUriComponents } from '../../enums';
import { XpoLtlWindowService } from '@xpo-ltl/ngx-ltl';
import { finalize, take, map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

export interface TimelineEventCategory {
  color: string;
  events: ClaimEventTypeCd[];
}

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TimelineComponent implements OnInit {
  @Input() claimId: string;
  @Input() allowZoom: boolean;
  @Output() showSpinner: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('visjsTimeline', { static: true }) timelineContainer: ElementRef;

  // Is key help box displayed
  displayHelp = false;

  // Needs to be a class variable because it may not be fetched from api
  events: ListEvent[] = [];
  openWindow = false; // Timeline opens events in new window/tab

  private currentEventId: string;
  private timeline: Timeline;
  // Mappings from events to colors in the form categories
  private categories: {
    claimFieldUpdatedEvents: TimelineEventCategory;
    claimStatusEvents: TimelineEventCategory;
    contactClaimantPayeeEvents: TimelineEventCategory;
    dmsEvents: TimelineEventCategory;
    noteEmailEvents: TimelineEventCategory;
    paymentEvents: TimelineEventCategory;
    rebuttalEvents: TimelineEventCategory;
  };
  // Used to construct the full path of applicaiton url for new tab events to avoid side effects
  private appUrl = this.document.location.origin + (!this.constants.isLocalBuild ? '/appjs/claims' : '');

  constructor(
    private historyService: ClaimHistoryService,
    private router: Router,
    private route: ActivatedRoute,
    private windowService: XpoLtlWindowService,
    public constants: AppConstantsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Setting up the categories with their colors
    // Class variables stated previously are technically set in the constructor, so putting the definition here
    this.categories = {
      contactClaimantPayeeEvents: {
        color: 'orange',
        events: [
          ClaimEventTypeCd.CLAIMANT_ADDED,
          ClaimEventTypeCd.CLAIMANT_CHANGED,
          ClaimEventTypeCd.CONTACT_CHANGED,
          ClaimEventTypeCd.PAYEE_ADDED,
          ClaimEventTypeCd.PAYEE_CHANGED,
        ],
      },
      claimStatusEvents: {
        color: 'gray',
        events: [
          ClaimEventTypeCd.CLAIM_APPROVED,
          ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_CEO,
          ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_DIRECTOR_OF_CLAIMS,
          ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_EXAMINER,
          ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_CLAIMS_MANAGER,
          ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_PRESIDENT,
          ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_RVP,
          ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_SVP_OPS,
          ClaimEventTypeCd.CLAIM_CANCELLED_BY_CUSTOMER,
          ClaimEventTypeCd.CLAIM_DECLINED,
          ClaimEventTypeCd.CLAIM_REASSIGNED,
          ClaimEventTypeCd.CLAIM_SAVED_AS_DRAFT,
          ClaimEventTypeCd.CLAIM_SUBMITTED,
          ClaimEventTypeCd.CLAIM_UNDER_REVIEW,
        ],
      },
      claimFieldUpdatedEvents: {
        color: 'purple',
        events: [ClaimEventTypeCd.CLAIM_FIELDS_UPDATED_ONLY],
      },
      dmsEvents: {
        color: 'black',
        events: [ClaimEventTypeCd.DMS_DOCUMENT_ADDED],
      },
      paymentEvents: {
        color: 'green',
        events: [
          ClaimEventTypeCd.PAYMENT_CHECK_CASHED,
          ClaimEventTypeCd.PAYMENT_CHECK_CANCELLED,
          ClaimEventTypeCd.PAYMENT_CHECK_ISSUED,
          ClaimEventTypeCd.PAYMENT_DECLINED,
          ClaimEventTypeCd.PAYMENT_ON_HOLD,
          ClaimEventTypeCd.PAYMENT_PENDING_TRANSMISSIONTO_AP,
          ClaimEventTypeCd.PAYMENT_SENT_TO_AP,
          ClaimEventTypeCd.PAYMENT_VOIDED,
        ],
      },
      rebuttalEvents: {
        color: 'red',
        events: [
          ClaimEventTypeCd.REBUTTAL_APPROVED,
          ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_CEO,
          ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_DIRECTOR_OF_CLAIMS,
          ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_EXAMINER,
          ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_CLAIMS_MANAGER,
          ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_PRESIDENT,
          ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_RVP,
          ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_SVP_OPS,
          ClaimEventTypeCd.REBUTTAL_CANCELLED_BY_CUSTOMER,
          ClaimEventTypeCd.REBUTTAL_DECLINED,
          ClaimEventTypeCd.REBUTTAL_RECEIVED,
          ClaimEventTypeCd.REBUTTAL_UNDER_REVIEW,
        ],
      },
      noteEmailEvents: {
        color: 'lightblue',
        events: [ClaimEventTypeCd.NOTE_ADDED, ClaimEventTypeCd.INCOMING_EMAIL],
      },
    };
  }

  // On angular init lifecycle
  ngOnInit(): void {
    this.claimId = this.claimId || (this.route.snapshot.params['claim-id'] as string);
    this.currentEventId = this.route.snapshot.params['event-id'];
    this.openWindow = !this.currentEventId;
    const isMigratedClaim = _.startsWith(this.claimId, 'CC1');
    const migrationDate = this.historyService.getMigrationDate();

    // Tell the claim history list spinner to spin while we call api
    this.showSpinner.emit(true);
    // Calls the history service, have claim history list spinner stop when finished
    this.historyService
      .getEvents(this.claimId)
      .pipe(
        finalize(() => this.showSpinner.emit(false)),
        take(1),
        // if isMigrated claim, keep only events occurred after migration
        map((listEvent) =>
          !isMigratedClaim ? listEvent : listEvent.filter((event) => moment(event.date).isAfter(migrationDate))
        )
      )
      .subscribe(
        // Set the events from the service response, they are fomratted
        // Then create the timeline with the events
        (events: ListEvent[]): void => {
          this.events = events;
          this.createTimeline();
        },
        (err) => console.log(err)
      );
  }

  /**
   * Sets up the timeline plugin with the data.
   * Ran after events is set from api.
   */
  private createTimeline(): void {
    // Pass undefined if there are no events such as in API fail
    // First and last event needed to know the extents of timeline
    const firstEvent = moment(this.events[0].date || undefined)
      .subtract(1, 'd')
      .toISOString();
    const lastEvent = moment(_.last(this.events).date || undefined)
      .add(1, 'd')
      .toISOString();

    // Create the data set, consumed by plugin
    const data = new DataSet();
    _.each(this.events, (event) => {
      // Set the color based off the first event
      const eventColor = this.getEventColor(event.events[0].typCd);
      const eventDate = event.date.toLocaleString();
      // Create the object for the plugin to consume
      data.add({
        id: event.eventId,
        start: eventDate,
        title: `<div class="item-hint"><div>${eventDate}</div><div>${event.typCd}</div><div>${
          event.employeeName
        }</div></div>`,
        style: `background-color: ${eventColor} ;border-radius: 10px; cursor: pointer; border-color: #6d6c6c00; :hover{ background-color: yellow}`,
      });
    });

    // Set options to the timeline plugin
    const options = {
      height: '400px',
      start: firstEvent,
      end: lastEvent,
      min: firstEvent,
      max: lastEvent,
      zoomMin: 1000 * 60 * 60 * 24,
      groupOrder: 'id',
      zoomable: this.allowZoom,
    };

    // Init timeline
    this.timeline = new Timeline(this.timelineContainer.nativeElement, data, options);

    // Bind click events
    this.timeline.on('click', (properties) => {
      // If the timeline is within the dialog, we want to open the event in a new tab
      if (properties.item && this.openWindow) {
        this.windowService.openWindow(
          `${this.appUrl}/${RouterUriComponents.CLAIM_HISTORY}/${this.claimId}/${properties.item}`,
          '_blank'
        );
        // Otherwise, open the event in this window
      } else if (properties.item) {
        this.timeline.setSelection(properties.item);
        this.router.navigate([`${RouterUriComponents.CLAIM_HISTORY}/${this.claimId}/${properties.item}`]);
      }
    });

    // Set Selection if we have an event id in the route
    this.timeline.setSelection(this.currentEventId);
  }

  /**
   * Given a claim event type code, looks through categories for a match and returns the associated color
   *
   * @param currentEventTypCd type code to look up in categories
   * @returns color consumed by css
   */
  private getEventColor(currentEventTypCd: ClaimEventTypeCd): string {
    return _.findLast(this.categories, ['events', [currentEventTypCd]]).color;
  }

  /**
   * Helper method which moves the timeline by a percentage of its width
   *
   * @param percentage a positive or negative value to move the window by, positive is left, negative right
   */
  private moveTimelineWindow(percentage: number): void {
    // Map the start and end fields from getWindow to timestamps, then set them as local variables
    const { start, end } = _.mapValues(this.timeline.getWindow(), (v: Date): number => v.valueOf());
    const offset = (end - start) * percentage;
    this.timeline.setWindow(start - offset, end - offset);
  }

  /**
   * Click handler to toggle the color key help pop up, color the button to be selected
   */
  onHelpClick(): void {
    this.displayHelp = !this.displayHelp;
  }

  /**
   * Left button click handler, moves timeline
   */
  onMoveLeftClicked(): void {
    this.moveTimelineWindow(0.2);
  }

  /**
   * Right button click handler, moves timeline
   */
  onMoveRightClicked(): void {
    this.moveTimelineWindow(-0.2);
  }

  /**
   * Left jump button click handler, jumps timeline to first event
   */
  onMoveToTheFirstEventClicked(): void {
    this.moveTimelineWindow(1000);
  }

  /**
   * Right jump button click handler, jumps timeline to last event
   */
  onMoveToTheLastEventClicked(): void {
    this.moveTimelineWindow(-1000);
  }

  /**
   * Zoom in click handler
   */
  onZoomInClicked(): void {
    this.timeline.zoomIn(0.8);
  }

  /**
   * Zome out click handler
   */
  onZoomOutClicked(): void {
    this.timeline.zoomOut(0.8);
  }
}
