export enum ClaimantContactFormNames {
  ContactFirstName = 'firstName',
  ContactLastName = 'lastName',
  ContactEmail = 'email',
  ContactPhone = 'phoneNbr',
  ContactPhoneExt = 'phoneExt',
  ContactFax = 'faxNbr',
  ContactId = 'contactId',
  ListActionCd = 'listActionCd',
}
