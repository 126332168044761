import { Injectable } from '@angular/core';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { UserRole, LocalStorageKeys } from '../enums';
import * as moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';

interface LocalStorageActiveUser {
  userId: string;
  role: UserRole;
}

@Injectable({ providedIn: 'root' })
export class AppConstantsService {
  private isProduction = false;
  private roleNames;
  private roleIds;

  private userSubject = new BehaviorSubject<User>(undefined);
  user$ = this.userSubject.asObservable();
  displayMultipleRole = false;

  private activeUserRoleSubject = new BehaviorSubject<UserRole>(undefined);
  activeUserRole$ = this.activeUserRoleSubject.asObservable();
  get activeUserRole(): UserRole {
    return this.activeUserRoleSubject.value;
  }
  set activeUserRole(role: UserRole) {
    this.activeUserRoleSubject.next(role);
  }

  activeUserRoles: string[] = [];
  isUpdatingRole: boolean;

  private readonly removeDomainRegExp = /.*\//gm;

  get isLocalBuild() {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion) === 'local-version';
  }

  get claimsDeptMaxTotalPayout(): number {
    return 5000;
  }

  constructor(private configManagerService: ConfigManagerService) {
    this.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);

    this.roleNames = {
      [UserRole.Csr]: this.isProduction ? 'LTL_CLAIMS_CSR' : ['TST_LTL_CLAIMS_CSR'],
      [UserRole.Examiner]: this.isProduction ? 'LTL_CLAIMS_EXAMINER' : ['TST_LTL_CLAIMS_EXAMINER'],
      [UserRole.Manager]: this.isProduction ? 'LTL_CLAIMS_MANAGER' : ['TST_LTL_CLAIMS_MANAGER'],
      [UserRole.Director]: this.isProduction ? 'LTL_CLAIMS_DIRECTOR' : ['TST_LTL_CLAIMS_DIRECTOR'],
      [UserRole.RVPWest]: this.isProduction ? 'LTL_CLAIMS_RVPWEST' : ['TST_LTL_CLAIMS_RVPWEST'],
      [UserRole.RVPEast]: this.isProduction ? 'LTL_CLAIMS_RVPEAST' : ['TST_LTL_CLAIMS_RVPEAST'],
      [UserRole.RVPMidwest]: this.isProduction ? 'LTL_CLAIMS_RVPMIDWEST' : ['TST_LTL_CLAIMS_RVPMIDWEST'],
      [UserRole.SVP]: this.isProduction ? 'LTL_CLAIMS_SVPOPS' : ['TST_LTL_CLAIMS_SVPOPS'],
      [UserRole.RVPSalesWest]: this.isProduction ? 'LTL_CLAIMS_RVPSALES_WEST' : ['TST_LTL_CLAIMS_RVPSALES_WEST'],
      [UserRole.RVPSalesEast]: this.isProduction ? 'LTL_CLAIMS_RVPSALES_EAST' : ['TST_LTL_CLAIMS_RVPSALES_EAST'],
      [UserRole.RVPSalesMidwest]: this.isProduction
        ? 'LTL_CLAIMS_RVPSALES_MIDWEST'
        : ['TST_LTL_CLAIMS_RVPSALES_MIDWEST'],
      [UserRole.SVPSales]: this.isProduction ? 'LTL_CLAIMS_SVPSALES' : ['TST_LTL_CLAIMS_SVPSALES'],
      [UserRole.President]: this.isProduction ? 'LTL_CLAIMS_PRESIDENT' : ['TST_LTL_CLAIMS_PRESIDENT'],
      [UserRole.CEO]: this.isProduction ? 'LTL_CLAIMS_CEO' : ['TST_LTL_CLAIMS_CEO'],
      [UserRole.ReadOnly]: this.isProduction ? 'LTL_CLAIMS_READ_ONLY' : ['TST_LTL_CLAIMS_READ_ONLY'],
    };
    this.roleIds = {
      [UserRole.Csr]: this.isProduction ? 315 : 319,
      [UserRole.Examiner]: this.isProduction ? 316 : 320,
      [UserRole.Manager]: this.isProduction ? 317 : 321,
      [UserRole.Director]: this.isProduction ? 318 : 322,
      [UserRole.SVP]: this.isProduction ? 324 : 327,
      [UserRole.President]: this.isProduction ? 325 : 328,
      [UserRole.CEO]: this.isProduction ? 326 : 329,
      [UserRole.ReadOnly]: this.isProduction ? 331 : 332,
      [UserRole.RVPWest]: this.isProduction ? 394 : 398,
      [UserRole.RVPEast]: this.isProduction ? 395 : 397,
      [UserRole.RVPMidwest]: this.isProduction ? 396 : 399,
      [UserRole.RVPSalesWest]: this.isProduction ? 431 : 434,
      [UserRole.RVPSalesEast]: this.isProduction ? 432 : 435,
      [UserRole.RVPSalesMidwest]: this.isProduction ? 433 : 436,
      [UserRole.SVPSales]: this.isProduction ? 437 : 438,
    };
  }

  get csrRoleId(): number {
    return this.roleIds[UserRole.Csr];
  }

  get examinerRoleId(): number {
    return this.roleIds[UserRole.Examiner];
  }

  get managerRoleId(): number {
    return this.roleIds[UserRole.Manager];
  }

  get directorRoleId(): number {
    return this.roleIds[UserRole.Director];
  }

  get svpOpsRoleId(): number {
    return this.roleIds[UserRole.SVP];
  }

  get presidentRoleId(): number {
    return this.roleIds[UserRole.President];
  }

  get ceoRoleId(): number {
    return this.roleIds[UserRole.CEO];
  }

  get rvpWestRoleId(): number {
    return this.roleIds[UserRole.RVPWest];
  }

  get rvpEastRoleId(): number {
    return this.roleIds[UserRole.RVPEast];
  }

  get rvpMidwestRoleId(): number {
    return this.roleIds[UserRole.RVPMidwest];
  }

  get isCSR() {
    return this.userRole === UserRole.Csr;
  }

  get isExaminer() {
    return this.userRole === UserRole.Examiner;
  }

  get isManager() {
    return this.userRole === UserRole.Manager;
  }

  get isDirector() {
    return this.userRole === UserRole.Director;
  }

  get isSVP() {
    return this.userRole === UserRole.SVP;
  }

  get isPresident() {
    return this.userRole === UserRole.President;
  }

  get isCEO() {
    return this.userRole === UserRole.CEO;
  }

  get isReadOnly() {
    return this.userRole === UserRole.ReadOnly;
  }

  get isRvpWest() {
    return this.userRole === UserRole.RVPWest;
  }

  get isRvpEast() {
    return this.userRole === UserRole.RVPEast;
  }

  get isRvpMidwest() {
    return this.userRole === UserRole.RVPMidwest;
  }

  get isRvpSalesWest() {
    return this.userRole === UserRole.RVPSalesWest;
  }

  get isRvpSalesEast() {
    return this.userRole === UserRole.RVPSalesEast;
  }

  get isRvpSalesMidwest() {
    return this.userRole === UserRole.RVPSalesMidwest;
  }

  get isSvpSales() {
    return this.userRole === UserRole.SVPSales;
  }

  get userRole(): UserRole {
    return this.activeUserRole;
  }

  getActiveClaimsRolesFromUser(roles: string[], transformToUserRole?: boolean): string[] {
    // Strips off the domain
    const mappedRoles = roles.map((role) => role.replace(this.removeDomainRegExp, ''));
    // Convert each roleName to a string (test region roles are arrays)
    const roleNamesToCompare = _.map(this.roleNames, (roleName) =>
      _.isArray(roleName) ? roleName.join('') : roleName
    );
    // Convert each roleName to a string (test region roles are arrays)
    return mappedRoles.reduce((activeClaimsUserRoles, currentUserRole) => {
      return _.some(roleNamesToCompare, (roleNameToCompare) => {
        return roleNameToCompare === currentUserRole;
      })
        ? [...activeClaimsUserRoles, currentUserRole]
        : activeClaimsUserRoles;
    }, []);
  }

  getUserRoleFromRoleName(roleName: string): UserRole {
    return _.invert(this.roleNames)[roleName] as UserRole;
  }

  getRoleNameFromUserRole(userRole: UserRole): string {
    const roleName = this.roleNames[userRole];
    return _.isArray(roleName) ? roleName[0] : roleName;
  }

  getRoleIdFromUserRole(userRole: UserRole): string {
    return this.roleIds[userRole];
  }

  set user(user: User) {
    this.userSubject.next(user);
  }

  get user(): User {
    return this.userSubject.value;
  }

  setActiveUserRole(role: string | UserRole): void {
    if (!role) {
      throw new Error('Role required');
    }

    this.activeUserRole = _.defaultTo(this.getUserRoleFromRoleName(role), role as UserRole);
    localStorage.setItem(
      LocalStorageKeys.ActiveUserRole,
      JSON.stringify({
        userId: this.user.userId,
        role: this.activeUserRole,
      } as LocalStorageActiveUser)
    );
  }

  getActiveUserRoleFromLocalStorage(): UserRole {
    const localStorageActiveUser: LocalStorageActiveUser = JSON.parse(
      localStorage.getItem(LocalStorageKeys.ActiveUserRole)
    );
    return localStorageActiveUser ? localStorageActiveUser.role : undefined;
  }

  isAuthorizedUser(user: User) {
    const roles = _.concat(
      [],
      this.roleNames[UserRole.Csr],
      this.roleNames[UserRole.Examiner],
      this.roleNames[UserRole.Manager],
      this.roleNames[UserRole.Director],
      this.roleNames[UserRole.ReadOnly]
    );
    return !!user && (this.hasRole(user.roles, roles) || this.isExecutiveRole());
  }

  isUserRole(role: UserRole | UserRole[]): boolean {
    return _.some(_.castArray(role), (userRole) => this.userRole === userRole);
  }

  isExecutiveRole(): boolean {
    return (
      this.isSVP ||
      this.isPresident ||
      this.isCEO ||
      this.isRvpEast ||
      this.isRvpWest ||
      this.isRvpMidwest ||
      this.isSvpSales ||
      this.isRvpSalesEast ||
      this.isRvpSalesWest ||
      this.isRvpSalesMidwest
    );
  }

  private hasRole(userRoles: string[], roleOf: any): boolean {
    const roleOfArray = _.castArray(roleOf);
    const splitUserRolesArr = _.map(userRoles, (role: string) => {
      return role.replace(this.removeDomainRegExp, '');
    });
    const results = _.intersectionBy(splitUserRolesArr, roleOfArray, (value) => value.toUpperCase());
    return !!results && !!results.length;
  }

  getDaysFromNowAsUTC(utcDate: Date): number {
    const now = moment.utc(new Date()).endOf('day');
    return utcDate ? now.diff(moment.utc(utcDate).endOf('day'), 'days') : 0;
  }

  getDateAsUTC(date: Date = new Date(), format?: string) {
    return moment.utc(date).format(format);
  }
}
