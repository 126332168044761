export class ClaimsLookupFilters {
  proNbr: string;
  claimNbr: string;
  claimantClaimNbr: string;
  checkNbr: string;
  claimantName: string;
  examinerName: string;
  claimStatusList: string[];
  currentApprover: string;
  filedDateFrom: Date;
  filedDateTo: Date;
  pickupDateFrom: Date;
  pickupDateTo: Date;
  claimAmountFrom: string;
  claimAmountTo: string;
  payoutAmountFrom: string;
  payoutAmountTo: string;
  filedDate: string;
}
