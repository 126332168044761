import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EmployeeName, HumanResourceApiService, ListEmployeesByRoleIdPath, ListEmployeesByRoleIdResp } from '@xpo-ltl/sdk-humanresource';
import * as _ from 'lodash';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { AppConstantsService } from '../../services/app-constants.service';

@Component({
  selector: 'app-reassign-claims',
  templateUrl: './reassign-claims.component.html',
  styleUrls: ['./reassign-claims.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReassignClaimsComponent implements OnInit {
  @ViewChild('employeeNameInput', {static: true})
  public employeeNameInput: NgModel;

  @ViewChild('auto', {static: true})
  public auto: MatAutocompleteTrigger;

  public employeeName;

  private originalEmployeeList = new Array<EmployeeName>();

  private filteredEmployeeListSubject = new BehaviorSubject<string[]>(undefined);
  public filteredEmployeeList$ = this.filteredEmployeeListSubject.asObservable();

  private isMatchedSubject = new BehaviorSubject<boolean>(false);
  public isMatched$ = this.isMatchedSubject.asObservable();

  constructor(private hrService: HumanResourceApiService, private constants: AppConstantsService, private matDialogRef: MatDialogRef<ReassignClaimsComponent>) {}

  ngOnInit() {
    this.employeeNameInput.valueChanges
      .pipe(
        startWith(''),
        tap(value => {
          this.filteredEmployeeListSubject.next(this.filter(value));
          this.isMatchedSubject.next(this.originalEmployeeList.findIndex(employee => employee.fullName.toLowerCase() === value.toLowerCase()) >= 0);
        })
      )
      .subscribe();

    const managersFunc = (): Observable<ListEmployeesByRoleIdResp> => {
      const request = new ListEmployeesByRoleIdPath();
      request.roleId = `${this.constants.managerRoleId}`;
      const flagActiveOnlyInd = { activeOnlyInd: 'true' };

      return this.hrService.listEmployeesByRoleId(request);
    };

    const examinersFunc = (): Observable<ListEmployeesByRoleIdResp> => {
      const request = new ListEmployeesByRoleIdPath();
      request.roleId = `${this.constants.examinerRoleId}`;
      const flagActiveOnlyInd = { activeOnlyInd: 'true' };

      return this.hrService.listEmployeesByRoleId(request);
    };

    merge(managersFunc(), examinersFunc()).subscribe(response => {
      this.originalEmployeeList.push(..._.get(response, 'employeeNames', []));
      this.originalEmployeeList = _.sortBy(_.uniqBy(this.originalEmployeeList, item => item.fullName), item => item.fullName);
      this.filteredEmployeeListSubject.next(this.originalEmployeeList.map(employee => employee.fullName));
    });
  }

  private filter(value: string): string[] {
    return this.originalEmployeeList.filter(employee => employee.fullName.toLowerCase().includes(value.toLowerCase())).map(employee => employee.fullName);
  }

  public cancelClicked(): void {
    this.matDialogRef.close(undefined);
  }

  public reassignClicked(): void {
    const selectedEmployee = _.first(this.originalEmployeeList.filter(f => f.fullName.toLowerCase() === this.employeeName.toLowerCase()));
    this.matDialogRef.close(selectedEmployee);
  }
}
