import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Payment, PaymentDetail } from '@xpo-ltl/sdk-claims';
import _ from 'lodash';
import { CurrencyCdPipe } from '../../pipes/currency-cd.pipe';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentHistoryComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: PaymentDetail[],
    private matDialogRef: MatDialogRef<PaymentHistoryComponent>,
    private currencyPipe: CurrencyPipe,
    private currencyCdPipe: CurrencyCdPipe
  ) {}

  public payments = this.data;
  public hideApprovals = false;

  public paidAmount(payment: Payment): string {
    if (_.has(payment, 'amount')) {
      return `${this.currencyPipe.transform(payment.amount)} ${this.currencyCdPipe.transform(payment.currencyCd)}`;
    } else {
      return null;
    }
  }

  ngOnInit() {}

  hideApproval() {
    this.hideApprovals = !this.hideApprovals;
  }
}
