import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from './paginator/paginator.component';
import { XpoMaxDateValidatorDirective } from './validators/max-date.validators';
import { AlertWrapperComponent } from './components/alert-wrapper/alert-wrapper.component';
import { XpoEmailValidatorDirective } from './validators/email-validator';
import { XpoBadCharactersValidatorDirective } from './validators/non-use-characters.validators';
import { XpoMinDateValidatorDirective } from './validators/min-date.validators';
import { XpoMaxTimeValidatorDirective } from './validators/max-time.validators';
import { XpoPhoneFormatValidatorDirective } from './validators/phone.validators';
import { DialogTemplateInjectorDirective } from './directives/dialog-template-injector.directive';
import { XpoErrorDirective } from './directives/xpo-error.directive';
import { XpoErrorsDirective } from './directives/xpo-errors.directive';
import { DocTypePipe } from './pipes/doc-type.pipe';
import { Base64Pipe } from './pipes/base64.pipe';
import { ToDatePipe } from './pipes/to-date.pipe';
import { XpoWarningDirective } from './directives/xpo-warning.directive';
import { XpoWarningsDirective } from './directives/xpo-warnings.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { XpoWarningsComponent } from './components/xpo-warnings/xpo-warnings.component';
import { XpoSpinnerComponent } from './components/spinner/xpo-spinner.component';
import { XpoNotGreaterThanDateValidatorDirective } from './validators/not-greater-than-date.validators';
import { XpoNotGreaterThanValueValidatorDirective } from './validators/not-greater-than-value.validators';
import { XpoNotLessThanDateValidatorDirective } from './validators/not-less-than-date.validators';
import { XpoNotLessThanValueValidatorDirective } from './validators/not-less-than-value.validators';
import { XpoCurrencyInputMaskDirective } from './directives/xpo-currency-input-mask.directive';
import { XpoSmartSelectDirective } from './directives/xpo-smart-select.directive';
import { DocumentSearchPipe } from './pipes/document-search.pipe';
import { CurrencyInputDirective } from './directives/currency-input.directive';
import { MaterialModule } from '../material.module';
const directives = [
  XpoMaxDateValidatorDirective,
  XpoEmailValidatorDirective,
  XpoBadCharactersValidatorDirective,
  XpoMinDateValidatorDirective,
  XpoMaxTimeValidatorDirective,
  XpoPhoneFormatValidatorDirective,
  DialogTemplateInjectorDirective,
  XpoErrorDirective,
  XpoErrorsDirective,
  XpoWarningDirective,
  XpoWarningsDirective,
  XpoNotGreaterThanDateValidatorDirective,
  XpoNotGreaterThanValueValidatorDirective,
  XpoNotLessThanDateValidatorDirective,
  XpoNotLessThanValueValidatorDirective,
  XpoCurrencyInputMaskDirective,
  XpoSmartSelectDirective,
  CurrencyInputDirective
];
const components = [PaginatorComponent, AlertWrapperComponent, XpoWarningsComponent, XpoSpinnerComponent];

const pipes = [DocTypePipe, Base64Pipe, ToDatePipe, DocumentSearchPipe];

@NgModule({
  declarations: [components, directives, pipes],
  exports: [components, directives, pipes],
  imports: [CommonModule, MaterialModule, PdfViewerModule],
  providers: [pipes],
})
export class CoreModule {}
