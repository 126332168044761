import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { LookupService } from '../services/lookup.service';
import { RouterUriComponents } from '../enums';
import { ClaimsService } from '../services/claims/claims.service';
import * as _ from 'lodash';

@Injectable()
export class LookupGuard implements CanActivate {
  constructor(private router: Router, private lookupService: LookupService, private claimService: ClaimsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    // If there is no query paramters, return true so we redirec to the dashboard
    if (!_.size(_.keys(route.queryParams))) {
      this.router.navigate([`${RouterUriComponents.DASHBOARD}`]);
      return true;
    }
    // There is query parameters so lets make a filter
    const filters = this.lookupService.parseURI(route);
    // If there is a claim number, redirect to the details page of the claim
    if (!!filters.claimNbr) {
      this.router.navigate([`${RouterUriComponents.CLAIMS_DETAILS_PAGE}/${filters.claimNbr}`]);
      return true; // Not going to dash so false
    }
    // If there is only 1 result, redirect to claim
    // otherwise, redirect to dash with filters set in lookup service
    return this.claimService.searchXrtClaims(filters, 1, 2, undefined).pipe(
      take(1),
      map(response => {
        if (!!response && response.result.length === 1) {
          this.router.navigate([`${RouterUriComponents.CLAIMS_DETAILS_PAGE}/${response.result[0].claimId}`]);
        } else {
          this.router.navigate([`${RouterUriComponents.DASHBOARD}`]);
        }
        return true;
      }),
      catchError(() => {
        // Clear the stored filters
        this.lookupService.refresh();
        return of(true);
      })
    );
  }
}
