<div class="match-dialog">
  <div class="match-dialog__header">
    <div><h2 mat-dialog-title style="margin: 0px">Claimant / Payee Matching</h2></div>
  </div>
  <div class="match-dialog__container">
    <div class="match-dialog__container-content">
      <!----------------  search ------------------->
      <form [formGroup]="formGroup" (ngSubmit)="handleSearchClicked()">
        <div class="match-dialog__search-container">
          <div class="match-dialog__search-content">
            <mat-form-field class="match-dialog__search-name" >
              <input matInput type="text" autofocus placeholder="Name" autocomplete="off"
                    id="match-dialog-search__name-input"
                    [formControlName]="AddressEntryFormNames.Name"/>
              <mat-error>
                <div [xpoErrors]="AddressEntryFormNames.Name" #nameError="xpoErrors">
                  <div xpoError="required" id="match-dialog-search__name-input-error-required">Required</div>
                  <div xpoError="maxlength" id="match-dialog-search__name-input-error-maxlen">Input too long</div>
                  <div xpoError="badchars" id="match-dialog-search__name-input-error-badchars">Invalid Input: {{ nameError?.errors?.badchars?.badChars }}</div>
                </div>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="match-dialog__search-attention" >
              <input matInput type="text" autofocus placeholder="Attention" autocomplete="off"
                    id="match-dialog-search__attention-input"
                    [formControlName]="AddressEntryFormNames.Attention"/>
            </mat-form-field>
            <mat-form-field class="match-dialog__search-address" >
              <input matInput type="text" autofocus placeholder="Address" autocomplete="off"
                    id="match-dialog-search__address-input"
                    [formControlName]="AddressEntryFormNames.Address1"/>
              <mat-error>
                <div [xpoErrors]="AddressEntryFormNames.Address1" #address1Error="xpoErrors">
                  <div xpoError="required" id="match-dialog__search__address-1-input-error-required">Required</div>
                  <div xpoError="maxlength" id="match-dialog__search__address-1-input-error-maxlen">Input too long</div>
                  <div xpoError="badchars" id="match-dialog__search__address-1-input-error-badchars">Invalid Input: {{ address1Error?.errors?.badchars?.badChars }}</div>
                </div>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="match-dialog__search-address2" >
              <input matInput type="text" autofocus placeholder="Address 2" autocomplete="off"
                    id="match-dialog-search__address-input"
                    [formControlName]="AddressEntryFormNames.Address2"/>
              <mat-error>
                <div [xpoErrors]="AddressEntryFormNames.Address2" #address2Error="xpoErrors">
                  <div xpoError="maxlength" id="match-dialog__search__address-2-input-error-maxlen">Input too long</div>
                  <div xpoError="badchars" id="match-dialog__search__address-2-input-error-badchars">Invalid Input: {{ address1Error?.errors?.badchars?.badChars }}</div>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="match-dialog__search-content">
            <mat-form-field class="match-dialog__search-city" >
              <input matInput type="text" autofocus placeholder="City" autocomplete="off"
                    id="match-dialog-search__city-input"
                    [formControlName]="AddressEntryFormNames.City"/>
              <mat-error>
                <div [xpoErrors]="AddressEntryFormNames.City" #cityError="xpoErrors">
                  <div xpoError="maxlength" id="match-dialog__search__city-input-error-maxlen">Input too long</div>
                  <div xpoError="required" id="match-dialog__search__city-input-error-required">Required</div>
                  <div xpoError="badchars" id="match-dialog__search__city-input-error-badchars">Invalid Input: {{ cityError?.errors?.badchars?.badChars }}</div>
                </div>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="match-dialog__search-state" >
              <input matInput type="text" autofocus placeholder="State" autocomplete="off"
                    id="match-dialog-search__state-input"
                    [formControlName]="AddressEntryFormNames.State"/>
              <mat-error>
                <div [xpoErrors]="AddressEntryFormNames.State" #stateError="xpoErrors">
                  <div xpoError="required" id="match-dialog__search__state-input-error-required">Required</div>
                  <div xpoError="maxlength" id="match-dialog__search__state-input-error-maxlen">Input too long</div>
                  <div xpoError="badchars" id="match-dialog__search__state-input-error-badchars">Invalid Input: {{ stateError?.errors?.badchars?.badChars }}</div>
                </div>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="match-dialog__search-zip" >
              <input matInput type="text" autofocus placeholder="Zip" autocomplete="off"
                    id="match-dialog-search__zip-input"
                    [formControlName]="AddressEntryFormNames.Zip"/>
              <mat-error>
                <div [xpoErrors]="AddressEntryFormNames.Zip" #zipError="xpoErrors">
                  <div xpoError="invalidzippostal" id="match-dialog__search__zip-input-error-invalid">{{ zipError?.errors?.invalidzippostal?.message }}</div>
                  <div xpoError="required" id="match-dialog__search__zip-input-error-required">Required</div>
                  <div xpoError="maxlength" id="match-dialog__search__zip-input-error-maxlen">Input too long</div>
                  <div xpoError="badchars" id="match-dialog__search__zip-input-error-badchars">Invalid Input: {{ zipError?.errors?.badchars?.badChars }}</div>
                </div>
              </mat-error>
            </mat-form-field>
            <button *ngIf="!(searching$ | async)" mat-button color="primary" [disabled]="formGroup.pristine" (click)="handleSearchClicked()">SEARCH</button>
            <span *ngIf="(searching$ | async)" class="match-dialog__spinner"></span>
          </div>
        </div>
      </form>


      <mat-dialog-content>
        <mat-table class="match-dialog__grid" #table [dataSource]="dataSource$ | async">
          <!------select radio button------>
          <ng-container matColumnDef="select">
            <th class="match-dialog__match-select-radio"><mat-header-cell *matHeaderCellDef >  </mat-header-cell></th>
            <td><mat-cell *matCellDef="let element"> <mat-radio-button (change)="selectedListItem(element)" ></mat-radio-button>
            </mat-cell></td>
          </ng-container>

          <!----Name 1------->
          <ng-container matColumnDef="name1">
            <th><mat-header-cell *matHeaderCellDef> Name 1 </mat-header-cell></th>
            <td><mat-cell *matCellDef="let element"> {{element?.name1}}
            </mat-cell></td>
          </ng-container>

          <!----Attention------->
          <ng-container matColumnDef="name2">
            <th><mat-header-cell *matHeaderCellDef> Attention </mat-header-cell></th>
            <td><mat-cell *matCellDef="let element"> {{element?.name2}}
            </mat-cell></td>
          </ng-container>

          <!----Address------->
          <ng-container matColumnDef="fullAddress">
            <th><mat-header-cell *matHeaderCellDef class="match-dialog__grid__address"> Address </mat-header-cell></th>
            <td><mat-cell *matCellDef="let element" class="match-dialog__grid__address"> {{element?.fullAddress}}
            </mat-cell></td>
          </ng-container>

          <!----% Relevance------->
          <ng-container matColumnDef="pctRelevance">
            <th><mat-header-cell *matHeaderCellDef class="match-dialog__grid__pct-relevance"> % Relevance </mat-header-cell></th>
            <td><mat-cell *matCellDef="let element" class="match-dialog__grid__pct-relevance"> {{element?.pctRelevance}}
            </mat-cell></td>
          </ng-container>

          <!----Column Headers------->
          <tr><mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row></tr>
          <tr><mat-row *matRowDef="let row; columns: columnsToDisplay; let i = index;" (click)="selectRow(row, i)" class="match-dialog__grid-row"></mat-row></tr>
        </mat-table>
        <div *ngIf="!(dataSource$ | async) && !(searching$ | async)"
            class="match-dialog__grid__no-data">
          No matches found
        </div>
      </mat-dialog-content>
    </div>
  </div>


  <mat-dialog-actions class="match-dialog__action-buttons-container">
    <button mat-button color="primary" (click)="cancelClicked()">NO MATCH</button>
    <button mat-raised-button color="primary" [disabled]="!selectedParty" (click)="handleMatchClicked()">MATCH</button>
  </mat-dialog-actions>
</div>
