<div class="escalation-email">

  <div class="escalation-email__header">
    <div class="escalation-email__header-title">Escalation Required</div>
  </div>

  <mat-dialog-content class="escalation-email__content">
    <app-emails
      [config]="emailsConfig"
      [claimsRegistrationFormGroup]="claimsRegistrationFormGroup"
      [claim]="claim$ | async"
      [dmsDocList$]="dmsDocList$ | async"
      [isEscalatingOrDeclining]="true"
      [downloadDmsAttachmentFn]="downloadEscalationEmailAttachmentsFn"
      ></app-emails>
  </mat-dialog-content>

  <mat-dialog-actions class="escalation-email__actionbar">
    <button
      mat-button
      color="primary"
      [mat-dialog-close]="EscalationEmailResults.Canceled"
      [disabled]="(isSendingEmail$ | async)">CANCEL</button>
    <button
      mat-raised-button
      color="primary"
      (click)="handleSendForReviewClicked()"
      [disabled]="!(emailReady$ | async) || (isSendingEmail$ | async)">
        Send For Review
    </button>
  </mat-dialog-actions>
</div>
