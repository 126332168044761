import { ErrorWarningValidationType } from '../enums/error-warning-validation-type.enum';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

export const ClaimValueKey = 'claimValue';
export function claimValueValidator(validationType: ErrorWarningValidationType, valueLimit: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control || !control.value) {
      return null;
    }
    const inputValue = +control.value;
    if (Number.isNaN(inputValue)) {
      return null;
    }

    if (validationType === ErrorWarningValidationType.Warning) {
      _.unset(control, `warnings[claimValue]`);
      if (inputValue > valueLimit) {
        _.set(control, `warnings`, { ...control['warnings'], [ClaimValueKey]: true });
      }
    } else if (validationType === ErrorWarningValidationType.Error && inputValue > valueLimit) {
      return { [ClaimValueKey]: true };
    }

    return null;
  };
}
