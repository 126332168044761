import { ConfirmCancelDataInterface } from '../interfaces/confirm-cancel-data';


export class ConfirmCancelData implements ConfirmCancelDataInterface {
    heading: string;
    body: string;
    leftButtonTitle: string;
    rightButtonTitle: string;

    constructor(heading: string,
                body: string,
                leftButtonTitle: string,
                rightButtonTitle: string) {
        this.heading = heading;
        this.body = body;
        this.leftButtonTitle = leftButtonTitle;
        this.rightButtonTitle = rightButtonTitle;
    }
}
