<div class="xpo-LtlDocumentSelector">
  <button mat-icon-button class="xpo-LtlDocumentSelector__trigger" disableRipple="true" [matTooltip]="tooltip"
    [disabled]="!(documentList$ | async)?.length" (click)="documentSelect.toggle()">
    <mat-icon [xpoBadge]="badgeConfig">{{ matIcon }}</mat-icon>
  </button>
  <mat-select #documentSelect disableOptionCentering panelClass="xpo-LtlDocumentSelector__panel"
    (selectionChange)="selectionChanged($event)">
    <mat-option *ngIf="(documentList$ | async)?.length > 1" [value]="defaultAll">
      <div class="xpo-LtlDocumentSelector__option">
        <div>All Documents</div>
      </div>
    </mat-option>
    <mat-option *ngFor="let doc of documentList$ | async" [value]="doc">
      <div class="xpo-LtlDocumentSelector__option">
        <div>{{ doc.cdt.docClass | docType }}</div>
        <div>{{ doc.timestampISO | date: 'MM/dd/yyyy HH:mm' }}</div>
      </div>
    </mat-option>
  </mat-select>
</div>