import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StatusBadgeComponent } from './status-badge.component';
import { MaterialModule } from '../../material.module';


const components = [StatusBadgeComponent];

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: components,
  exports: components,
})
export class StatusBadgeModule {}
