import { AppConstantsService } from './../../../../services/app-constants.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { EmailInteraction } from '@xpo-ltl/sdk-claims';
import { EmailDirectionTypeCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ClaimsRegistrationService } from '../../../../services/claims-registration/claims-registration.service';
import { EmailActionTypes } from '../../email-action-types.enum';
import { EmailActionInterface } from '../../email-action.interface';
import { NotificationService } from '@xpo-ltl/data-api';
import { take, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-emails-detail',
  templateUrl: './emails-detail.component.html',
  styleUrls: ['./emails-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'emails-detail',
  },
})
export class EmailsDetailComponent implements OnInit {
  private badgeHiddenBehaviorSubject = new BehaviorSubject<boolean>(true);
  private badgeBehaviorSubject = new BehaviorSubject<string>(undefined);

  badgeConfig = {
    xpoBadgeHidden: this.badgeHiddenBehaviorSubject,
    xpoBadge: this.badgeBehaviorSubject,
    xpoBadgeFontSize: new BehaviorSubject<string>('12px'),
    xpoBadgeBackgroundColor: 'black',
    xpoBadgeColor: '#ffffff',
  };

  @Input()
  pro: string;

  @Input()
  email: EmailInteraction;

  @Input()
  draftInd: boolean;

  @Input()
  downloadDmsAttachmentFn: Function;

  @Output()
  emailActionClicked = new EventEmitter<EmailActionInterface>();

  private isExpandedSubject = new BehaviorSubject<boolean>(true);
  isExpanded$ = this.isExpandedSubject.asObservable();

  private isDownloadingAttachmentsSubject = new BehaviorSubject<boolean>(false);
  isDownloadingAttachments$ = this.isDownloadingAttachmentsSubject.asObservable();

  private emailReadSubject = new BehaviorSubject<boolean>(false);
  emailRead$ = this.emailReadSubject.asObservable();

  EmailDirectionTypeCd = EmailDirectionTypeCd;

  constructor(private claimsRegistrationService: ClaimsRegistrationService, public constants: AppConstantsService) {}

  ngOnInit() {
    const attachments = _.get(this.email, 'emailAttachment', []);

    this.badgeHiddenBehaviorSubject.next(attachments.length === 0);
    this.badgeBehaviorSubject.next(`${attachments.length}`);
    this.emailReadSubject.next(this.email.readInd);
  }

  replyClicked(): void {
    this.emailActionClicked.next({ action: EmailActionTypes.Reply, email: this.email });
  }

  replyAllClicked(): void {
    this.emailActionClicked.next({ action: EmailActionTypes.ReplyAll, email: this.email });
  }

  forwardClicked(): void {
    this.emailActionClicked.next({ action: EmailActionTypes.Forward, email: this.email });
  }

  markAsReadClicked(): void {
    this.claimsRegistrationService.markEmailAsRead(this.email.emailInstId).subscribe(() => {
      this.email.readInd = true;
      this.emailReadSubject.next(true);

      this.claimsRegistrationService.updateNotesEmailsBadgeCounts();
    });
  }

  attachmentsClicked(): void {
    this.isDownloadingAttachmentsSubject.next(true);

    const attachments = _.get(this.email, 'emailAttachment', []);

    if (!!_.size(attachments)) {
      this.downloadDmsAttachmentFn(attachments)
        .pipe(
          take(1),
          finalize(() => {
            this.isDownloadingAttachmentsSubject.next(false);
          })
        )
        .subscribe(() => {});
    } else {
      // Ensure to turn off the spinner if we aren't going to actually download anything
      this.isDownloadingAttachmentsSubject.next(false);
    }
  }

  expandCollapseClicked(): void {
    this.isExpandedSubject.next(!this.isExpandedSubject.value);
  }

  getAssignedClaimExaminer(): boolean {
    return this.claimsRegistrationService.isAssignedClaimExaminer();
  }
}
