import { ChangeDetectionStrategy, Component, Input, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ArtificialIntelligenceStatus } from '../../enums/artificial-intelligence-status';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusBadgeComponent {
  @Input() status: string;
  @Input() isInternal: boolean;
  @Input() isHeaderStatusBadge: boolean;
  @Input() pipe: PipeTransform = null;
  @Input() artificialIntelligence: boolean;
  readonly ArtificialIntelligenceStatus = ArtificialIntelligenceStatus;
  get statusAsClass() {
    return _.kebabCase(this.status);
  }

  get statusAsString() {
    if (this.pipe) {
      return this.pipe.transform(this.status);
    } else {
      return this.status;
    }
  }

  get isInternalStatus() {
    if (this.isInternal && !this.artificialIntelligence) {
      return 'Internal Status';
    } else if (!this.isInternal && !this.artificialIntelligence) {
      return 'External status';
    } else if (this.artificialIntelligence) {
      return 'AI Prediction';
    }
  }
}
