import { Injectable, OnDestroy } from '@angular/core';
import { AsyncValidatorFn, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';

import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, concat, forkJoin, Observable, of, Subject, timer } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';

import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { ConditioningService } from '@xpo-ltl/common-services';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ApiRequest, DataOptions, NotificationService } from '@xpo-ltl/data-api';
import {
  ChargeableServiceCenter,
  Claim,
  ClaimParty,
  ClaimsApiService,
  Contact,
  EmailAttachment,
  EmailInteraction,
  GetClaimResp,
  ListContactsForPartyResp,
  ListContactsForPartyRqst,
  MatchClaimPartyResp,
  MatchClaimPartyRqst,
  Payment,
  PaymentDetail,
  Rebuttal,
  UpdateClaimEmailReadIndRqst,
  UpsertClaimResp,
  UpsertClaimRqst,
  GetClaimFraudPredictionResp,
  GetAIPredictionForClaimPath,
} from '@xpo-ltl/sdk-claims';
import {
  ActionCd,
  BillClassCd,
  ClaimCurrencyCd,
  ClaimEmailTemplateTypeCd,
  ClaimInternalStatusCd,
  CurrencyCd,
  DmsDocTypeCd,
  MatchedPartyTypeCd,
  PaymentStatusInternalCd,
  ProStatusCd,
  RebuttalInternalStatusCd,
} from '@xpo-ltl/sdk-common';
import {
  AsMatchedParty,
  GetOdsShipmentQuery,
  GetOdsShipmentResp,
  ListOdsShipmentsResp,
  ListOdsShipmentsRqst,
  Shipment,
  ShipmentOdsApiService,
  ListLoadAtSicsForOdsShipmentQuery,
  ListLoadAtSicsForOdsShipmentResp,
} from '@xpo-ltl/sdk-shipmentods';

import { DefaultClaimStates } from '../../classes/default-claim-states.class';
import { FormUtils } from '../../classes/form-utils.class';
import { RegistrationClaimPaymentFormBuilder } from '../../components/registration/components/registration-claim-payment/registration-claim-payment.form-builder';
import { RegistrationClaimantFormBuilder } from '../../components/registration/components/registration-claimant/registration-claimant.form-builder';
import { RegistrationCommoditiesFormBuilder } from '../../components/registration/components/registration-commodities/registration-commodities.form-builder';
import { RegistrationHeaderFormBuilder } from '../../components/registration/components/registration-header/registration-header.form-builder';
import { RegistrationIndicatorsFormBuilder } from '../../components/registration/components/registration-indicators/registration-indicators.form-builder';
import { RegistrationNotesFormBuilder } from '../../components/registration/components/registration-notes/registration-notes.form-builder';
import { RegistrationPayeeFormBuilder } from '../../components/registration/components/registration-payee/registration-payee.form-builder';
import { RegistrationRebuttalsFormBuilder } from '../../components/registration/components/registration-rebuttals/registration-rebuttals.form-builder';
import { RegistrationRelatedProsFormBuilder } from '../../components/registration/components/registration-related-pros/registration-related-pros.form-builder';
import { RegistrationSuccessComponent } from '../../dialogs/registration-success/registration-success.component';
import {
  ClaimantCopyFrom,
  ClaimEditState,
  ClaimRebuttalFormNames,
  ClaimsRegistrationFormNames,
  ConfigManagerProperties,
  NavigationBarLabelType,
  PaymentState,
  RegistrationHeaderFormNames,
  RouterUriComponents,
  UserRole,
} from '../../enums';
import { ClaimReviewRequiredUpdateType } from '../../enums/claim-review-required-update-type.enum';
import { ClaimReviewUpdateMessageType } from '../../enums/claim-review-update-message-type.enum';
import { ClaimStatePropertyType } from '../../enums/claim-state-property-type.enum';
import { ClaimStateType } from '../../enums/claim-state-type.enum';
import { FooterButtonGroup } from '../../enums/footer-button-group.enum';
import { FormControlState } from '../../interfaces/form-control-state';
import { PanelInterface } from '../../interfaces/panel.interface';
import { AppConstantsService } from '../app-constants.service';
import { ClaimsDialogService } from '../claims-dialog/claims-dialog.service';
import { ClaimsForShipmentCacheService } from '../claims-for-shipments-cache/claims-for-shipment-cache.service';
import { ClaimsService } from '../claims/claims.service';
import { NavigationBarService } from '../navigation-bar/navigation-bar.service';
import { AppFooterButtonStateService } from './app-footer-button-state.service';
import { EnumHelper } from '../../enums/enum-helper';
import { LatitudeLongitude } from '../../interfaces/latitude-longitude.interface';
import { DocumentCdt, DocumentSearch, GetDocumentResp } from '@xpo-ltl/sdk-documentmanagement';
import { UnitConversion } from '../../classes';
import { FooterButtonTypeCd } from '../../enums/footer-button-type-cd.enum';
import { decode } from 'typescript-base64-arraybuffer';
import { ClaimStatusCds } from '../../interfaces/claim-status-cds.interface';
import { NavigatorPanel } from '../../classes/navigatorPanel';
import { ServiceCenterChargeabilityService } from '../service-center-chargeability/service-center-chargeability.service';
import { ArtificialIntelligenceStatus } from '../../enums/artificial-intelligence-status';
import { Unsubscriber, XpoLtlWindowService } from '@xpo-ltl/ngx-ltl';
import { XpoDialogManagerService } from '../../core/services/xpo-dialog-manager.service';
import { DocViewService } from '../../core/services/doc-view.service';

@Injectable({ providedIn: 'root' })
export class ClaimsRegistrationService implements OnDestroy {
  public printClaimDialogOpen = false;
  private unsubscriber = new Unsubscriber();
  private readonly claimReviewUpdateMessageTypeHelper = new EnumHelper(ClaimReviewUpdateMessageType);
  private readonly claimReviewRequiredUpdateTypeHelper = new EnumHelper(ClaimReviewRequiredUpdateType);

  public hasEnteredRebuttalEditMode = false;
  public latitudeLongitude: LatitudeLongitude;
  public isUpdatingClaim = false;
  public isUpsertingClaimAfterSavingChargeabilityDialog = false;

  private showSpinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showSpinner$ = this.showSpinnerSubject.asObservable();

  private artificialIntelligenceStatusSubject: BehaviorSubject<string> = new BehaviorSubject<string>(ArtificialIntelligenceStatus.Unknown);
  public artificialIntelligenceStatus$ = this.artificialIntelligenceStatusSubject.asObservable();

  private showNotesEmailsSubject = new BehaviorSubject<void>(null);
  public showNotesEmails$ = this.showNotesEmailsSubject.asObservable();

  private showRebuttalsSubject = new BehaviorSubject<boolean>(false);
  public showRebuttals$ = this.showRebuttalsSubject.asObservable();
  public set showRebuttals(showRebuttal: boolean) {
    this.showRebuttalsSubject.next(showRebuttal);
  }

  private updateNotesEmailsBadgeCountsSubject = new BehaviorSubject<void>(null);
  public updateNotesEmailsBadgeCounts$ = this.updateNotesEmailsBadgeCountsSubject.asObservable();

  public claimsRegistrationFormGroup: UntypedFormGroup;
  private registrationPanels: Array<PanelInterface> = [];
  private reviewRequiredMsgMap = {};

  private shipmentSubject: BehaviorSubject<GetOdsShipmentResp> = new BehaviorSubject<GetOdsShipmentResp>(undefined);
  public shipment$ = this.shipmentSubject.asObservable();

  public get shipment(): GetOdsShipmentResp {
    return this.shipmentSubject.value;
  }
  public set shipment(shipment: GetOdsShipmentResp) {
    this.shipmentSubject.next(shipment);
    if (_.get(this.claim, 'claim')) {
      // update the claim's shipmentInstId with the new shipment
      _.set(this.claim, 'claim.shipmentInstId', _.get(shipment, 'shipment.shipmentInstId') as number);
    }
  }

  private loadAtSicsSubject = new BehaviorSubject<string[]>([]);
  public loadAtSics$ = this.loadAtSicsSubject.asObservable();
  public get loadAtSics(): string[] {
    return this.loadAtSicsSubject.value;
  }
  public set loadAtSics(loadAtSics: string[]) {
    this.loadAtSicsSubject.next(loadAtSics);
  }

  private escalationReasonListSubject: BehaviorSubject<ClaimReviewUpdateMessageType[]> = new BehaviorSubject<ClaimReviewUpdateMessageType[]>([]);
  public escalationReasonList$ = this.escalationReasonListSubject.asObservable();
  public get escalationReasonList(): ClaimReviewUpdateMessageType[] {
    return this.escalationReasonListSubject.value;
  }
  public set escalationReasonList(escalationReasons: ClaimReviewUpdateMessageType[]) {
    this.escalationReasonListSubject.next(escalationReasons);
  }

  private claimStatusCdsSubject: BehaviorSubject<ClaimStatusCds> = new BehaviorSubject<ClaimStatusCds>({} as ClaimStatusCds);
  public claimStatusCds$ = this.claimStatusCdsSubject.asObservable();
  public get claimStatusCds(): ClaimStatusCds {
    return this.claimStatusCdsSubject.value;
  }
  public set claimStatusCds(claimStatus: ClaimStatusCds) {
    this.claimStatusCdsSubject.next(claimStatus);
  }

  private _pristineClaim: GetClaimResp;
  public get pristineClaim(): GetClaimResp {
    return this._pristineClaim;
  }

  public proSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public pro$ = this.proSubject.asObservable();
  public claimSubject: BehaviorSubject<GetClaimResp> = new BehaviorSubject<GetClaimResp>(undefined);
  public claim$ = this.claimSubject.asObservable();
  public get claim(): GetClaimResp {
    return this.claimSubject.value;
  }

  // Some subscriptions don't want to be called when the claim is resetting so this flag allows them to filter off any events fired during this action
  public isClaimResetting = false;
  public set claim(claim: GetClaimResp) {
    this.isClaimResetting = true;
    this.reset();
    this.claimSubject.next(claim);

    // force change detection of new states
    // (new claim after global search navigation may have same edit/payment state,
    // which prevents panels from being updated properly)
    this.claimEditStateSubject.next(this.claimEditStateSubject.value);
    this.paymentEditStateSubject.next(this.paymentEditStateSubject.value);

    this.claimStatusCds = {
      internalStatusCd: claim.claim.internalStatusCd,
      externalStatusCd: claim.claim.externalStatusCd,
    } as ClaimStatusCds;

    // save pristine original copy for reference
    this._pristineClaim = _.cloneDeep(claim);
    // update the loaded shipment to match the claim
    this.getShipmentFromId(_.get(claim, 'claim.shipmentInstId'), _.get(claim, 'claim.proNbr'), { loadingOverlayEnabled: false } as DataOptions).subscribe();

    // update the loadAtSic list for this claim
    this.getLoadAtSics();

    if (claim && this.appConstantsService.userRole !== UserRole.ReadOnly) {
      this.getAiPrediction(_.get(claim, 'claim.claimId'));
    }

    this.isClaimResetting = false;
  }

  private _initialSvcCenterChargeabilityState;
  public get initialSvcCenterChargeabilityState(): Object | undefined {
    return this._initialSvcCenterChargeabilityState;
  }
  public set initialSvcCenterChargeabilityState(value) {
    this._initialSvcCenterChargeabilityState = value;
  }

  public get claimStatus(): ClaimInternalStatusCd {
    return _.get(this.claim, 'claim.internalStatusCd', undefined);
  }
  public set claimStatus(status: ClaimInternalStatusCd) {
    _.set(this.claim, 'claim.internalStatusCd', status);
  }

  // Mode the entire Claim view is in
  private claimEditStateSubject = new BehaviorSubject<ClaimEditState>(ClaimEditState.ReadOnly);
  public claimEditState$ = this.claimEditStateSubject.asObservable();
  public get claimEditState() {
    return this.claimEditStateSubject.value;
  }
  public set claimEditState(newState: ClaimEditState) {
    if (this.claimEditState !== newState) {
      this.previousClaimEditStateValue = this.claimEditState;
      this.claimEditStateSubject.next(newState);
    }
  }

  private previousClaimEditStateValue: ClaimEditState;
  public get previousClaimEditState() {
    return this.previousClaimEditStateValue;
  }

  // Mode the Payment process is in
  private paymentEditStateSubject = new BehaviorSubject<PaymentState>(PaymentState.ReadOnly);
  public paymentEditState$ = this.paymentEditStateSubject.asObservable();

  private previousPaymentEditStates: PaymentState[] = [];

  public get paymentEditState(): PaymentState {
    return this.paymentEditStateSubject.value;
  }
  public set paymentEditState(newState: PaymentState) {
    if (newState !== this.paymentEditState) {
      this.previousPaymentEditStates.unshift(this.paymentEditState);
      this.paymentEditStateSubject.next(newState);
    }
  }

  public get previousPaymentEditState() {
    return _.first(this.previousPaymentEditStates);
  }

  // true when the Claimant is matched
  private isClaimantValidSubject = new BehaviorSubject<boolean>(false);
  public isClaimantValid$ = this.isClaimantValidSubject.asObservable();
  public get isClaimantValid() {
    return this.isClaimantValidSubject.value;
  }
  public set isClaimantValid(value: boolean) {
    this.isClaimantValidSubject.next(value);
  }

  private dmsDocListSubject = new BehaviorSubject<DocumentSearch[]>([]);
  public dmsDocList$ = this.dmsDocListSubject.asObservable();
  public get dmsDocList() {
    return this.dmsDocListSubject.value;
  }
  public set dmsDocList(value: DocumentSearch[]) {
    this.dmsDocListSubject.next(value);
  }

  // true when the Payee is matched
  private isPayeeValidSubject = new BehaviorSubject<boolean>(false);
  public isPayeeValid$ = this.isPayeeValidSubject.asObservable();
  public get isPayeeValid() {
    return this.isPayeeValidSubject.value;
  }
  public set isPayeeValid(value: boolean) {
    this.isPayeeValidSubject.next(value);
  }

  private resetFormSubject: Subject<boolean> = new Subject<boolean>();
  public resetForm$ = this.resetFormSubject.asObservable();

  private checkDuplicateClaimsSubject = new Subject();
  public checkDuplicateClaims$ = this.checkDuplicateClaimsSubject.asObservable();
  public triggerCheckDuplicateClaims() {
    this.checkDuplicateClaimsSubject.next();
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private shipmentOdsApiService: ShipmentOdsApiService,
    private conditioningService: ConditioningService,
    private dialogManager: XpoDialogManagerService,
    private claimsApiService: ClaimsApiService,
    private claimsDialogService: ClaimsDialogService,
    private claimsService: ClaimsService,
    private navbarService: NavigationBarService,
    private router: Router,
    private configManagerService: ConfigManagerService,
    private claimsForShipmentCacheService: ClaimsForShipmentCacheService,
    private appConstantsService: AppConstantsService,
    private appFooterButtonStateService: AppFooterButtonStateService,
    private notificationService: NotificationService,
    private loggingApiService: LoggingApiService,
    private windowService: XpoLtlWindowService,
    private docViewService: DocViewService
  ) {
    this.claimsRegistrationFormGroup = this.formBuilder.group({});

    // any time the shipment, claim, claimant, or payee changes, update the state
    combineLatest(this.shipment$, this.claim$, this.isClaimantValid$, this.isPayeeValid$)
      .pipe(
        distinctUntilChanged((p, q) => _.isEqual(p, q)),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(() => {
        this.updateClaimEditState();
      });

    this.claim$
      .pipe(
        takeUntil(this.unsubscriber.done),
        distinctUntilChanged()
      )
      .subscribe(claim => {
        // if we get a new claim, then set claimant/payee as valid
        this.isClaimantValid = !!claim;
        this.isPayeeValid = !!claim;

        this.setEscalationReviewRequiredStatusList();
      });
  }

  public ngOnDestroy() {
    this.unsubscriber.complete();
  }

  public updateButtonStateValidity(): void {
    // disable any upsert button except for Save
    const isFormValid = _.isEqual(_.get(this.claimsRegistrationFormGroup, 'status', '').toLowerCase(), 'valid');
    for (const btnCd of [
      FooterButtonTypeCd.SubmitClaim,
      FooterButtonTypeCd.CompleteApproval,
      FooterButtonTypeCd.ProceedToDeclinationLetter,
      FooterButtonTypeCd.ApproveRebuttal,
      FooterButtonTypeCd.DeclineRebuttal,
      FooterButtonTypeCd.SubmitPayment,
      FooterButtonTypeCd.Approve,
    ]) {
      this.appFooterButtonStateService.setButtonDisabled(btnCd, !isFormValid);
    }

    // Set registration review state based on panel state reviewability
    this.appFooterButtonStateService.setButtonDisabled(FooterButtonTypeCd.ReviewClaim, !this.isFormReviewable());
  }

  public updateClaimEditState() {
    let newState: ClaimEditState = ClaimEditState.ReadOnly;

    if (!this.claim) {
      // No claim, so must be Registering one
      if (!this.shipment) {
        // No shipment, so only allow edit of Header
        newState = ClaimEditState.Header;
      } else {
        // we have a shipment.  Do we have a claimant?
        if (!this.isClaimantValid) {
          // need a valid Claimant
          newState = ClaimEditState.Claimant;
        } else {
          if (!this.isPayeeValid) {
            // need a valid Payee
            newState = ClaimEditState.Payee;
          } else {
            // we have a shipment, a claimant, and a payee.
            newState = ClaimEditState.Additional;
          }
        }
      }
    } else {
      // If we are a readOnly role force claimEditState to readOnly
      if (this.appConstantsService.isReadOnly) {
        this.claimEditState = ClaimEditState.ReadOnly;
        return;
      }

      // viewing Claim Details
      if (!this.hasActiveRebuttal()) {
        if (this.claimEditState === ClaimEditState.Rebuttal) {
          // the user added a rebuttal and canceled (clicked on Cancel button or unchecked RebuttalInd) without saving
          newState = this.previousClaimEditState;
          if (this.appFooterButtonStateService.ButtonGroup === FooterButtonGroup.RebuttalPending) {
            // same as canApproveRebuttal
            this.paymentEditState = this.previousPaymentEditState;
            this.appFooterButtonStateService.ButtonGroup = this.appFooterButtonStateService.PreviousButtonGroup;
          }
        } else if (this.isCurrentAssignedClaimReviewer()) {
          if (this.isClaimStatus([ClaimInternalStatusCd.SUBMITTED, ClaimInternalStatusCd.UNDER_REVIEW])) {
            newState = ClaimEditState.Edit;
          } else {
            // If current claimEditState is Payment, keep it
            if (this.claimEditState === ClaimEditState.Payment) {
              newState = ClaimEditState.Payment;
            }
          }
        } else if (this.appConstantsService.isManager) {
          if (this.isClaimStatus([ClaimInternalStatusCd.MANAGER_PENDING_APPROVAL])) {
            newState = ClaimEditState.Edit;
          }
        } else if (this.appConstantsService.isDirector) {
          if (this.isClaimStatus([ClaimInternalStatusCd.DIRECTOR_PENDING_APPROVAL, ClaimInternalStatusCd.RVP_PENDING_APPROVAL])) {
            newState = ClaimEditState.Edit;
          }
        }
      } else {
        if (!this.hasEnteredRebuttalEditMode) {
          if (!this.isRebuttalInEscalatedApprovalState()) {
            if (this.canApproveRebuttal() || (this.canEditRebuttal() && this.isAddingNewClaimRebuttal())) {
              newState = ClaimEditState.Rebuttal;
            } else {
              newState = ClaimEditState.ReadOnly;
            }
          }
        } else {
          if (!this.isRebuttalInEscalatedApprovalState() && this.canEditRebuttal()) {
            newState = ClaimEditState.Rebuttal;
          } else if (this.isRebuttalInEscalatedApprovalState() && this.canApproveRebuttal()) {
            newState = ClaimEditState.ReadOnly;
          }
        }
      }

      // If current claimEditState is Edit, keep it
      if (newState === ClaimEditState.ReadOnly && this.claimEditState === ClaimEditState.Edit) {
        newState = ClaimEditState.Edit;
      }
    }
    this.claimEditState = newState;
  }

  public reset() {
    this.claimsRegistrationFormGroup.reset();
    this.clearShipment();
    this.clearClaim();
    this.resetFormSubject.next(true);
    this.resetClaimState();
    this.loadAtSics = undefined;
  }

  public clearShipment() {
    this.shipment = undefined;
  }

  public clearClaim() {
    this.claimSubject.next(undefined);
  }

  public clearClaimStates() {
    this.claimEditState = ClaimEditState.ReadOnly;
    this.paymentEditState = PaymentState.ReadOnly;
    this.appFooterButtonStateService.ButtonGroup = FooterButtonGroup.Default;
    this.previousPaymentEditStates = [];
  }

  public resetClaimState(): void {
    let defaultClaimStateType;

    if (this.router.url.startsWith(`/${RouterUriComponents.CLAIMS_REGISTRATION_PAGE}`)) {
      defaultClaimStateType = ClaimStateType.Registration;
    } else {
      defaultClaimStateType = ClaimStateType.ClaimDetails;
    }

    this.setDefaultClaimState(defaultClaimStateType);
    this.reviewRequiredMsgMap = {};
    this.previousPaymentEditStates = [];
    this.claimsForShipmentCacheService.reset();
  }

  public setDefaultClaimState(claimStateType: ClaimStateType): void {
    const defaultClaimState = DefaultClaimStates.defaultStateForClaimStateType(claimStateType);

    if (!defaultClaimState) {
      return;
    }
    this.appFooterButtonStateService.ButtonGroup = defaultClaimState[ClaimStatePropertyType.FooterButtonState];
    this.paymentEditState = defaultClaimState[ClaimStatePropertyType.PaymentState];
    this.claimEditState = defaultClaimState[ClaimStatePropertyType.ClaimEditState];
  }

  public addPanel(component: PanelInterface): void {
    this.registrationPanels.push(component);
  }

  public removePanel(component: PanelInterface): void {
    this.registrationPanels = this.registrationPanels.filter(panel => panel !== component);
  }

  public clearPanels(): void {
    this.registrationPanels = [];
  }

  /**
   * true if no errors in form and all required data has been filled in
   */
  public isFormSubmittable(): boolean {
    switch (this.claimEditState) {
      case ClaimEditState.Additional:
      case ClaimEditState.Payment:
        return this.registrationPanels.every(panel => !!panel.isPanelValid);
      default:
        return false;
    }
  }
  public splitPanels(claimRequestAmountVisible?: boolean): NavigatorPanel[] {
    const splittedPanels: NavigatorPanel[] = [];
    _.each(this.registrationPanels, panel => {
      const registrationPanel: NavigatorPanel = new NavigatorPanel();
      // this Panel Interface is not a panel, is the component itself. I needed to access properties not defined in the interface
      panel['formGroup'].updateValueAndValidity();
      const formGroupInvalid = FormUtils.checkIfFormInvalid(panel['formGroup']);
      // To work with the method showPanel from navigator service , I need the NavigationBarLabelType so I created a function to map them
      registrationPanel.label = this.navbarService.mapFormNameToNavigationBarLabel(panel['formGroupName'], !!this.claim);
      if (!panel.isPanelValid && formGroupInvalid) {
        registrationPanel.isValid = false;
      } else {
        registrationPanel.isValid = true;
      }
      splittedPanels.push(registrationPanel);
    });
    // This scenario occurs in the detail section where the claimRequestAmount control is not visible but behind the scenes
    // its invalid because it has no min value, so in order to fix that...
    if (!claimRequestAmountVisible) {
      // This means we are not registrating a claim, we are in the detail section
      const headerControls = this.claimsRegistrationFormGroup.get(ClaimsRegistrationFormNames.Header) as UntypedFormGroup;
      const ClaimRequestAmount = headerControls.get(RegistrationHeaderFormNames.ClaimRequestAmount);
      const errors = FormUtils.getFormValidationErrors(headerControls);
      // I check that the hidden request ammount control has min error and is the only error one in the formgroup
      if (_.size(errors) === 1 && ClaimRequestAmount.hasError('min')) {
        _.each(splittedPanels, panel => {
          panel.isValid = panel.label === NavigationBarLabelType.ClaimHeader ? true : panel.isValid;
        });
      }
    }
    return splittedPanels;
  }
  public isFormSavable(): boolean {
    switch (this.claimEditState) {
      case ClaimEditState.Edit:
        return this.registrationPanels.every(panel => !!panel.isPanelValid);
      default:
        return false;
    }
  }

  /**
   * true when all panels have been enabled
   */
  public isFormReviewable(): boolean {
    return !!this.shipment && this.claimEditState === ClaimEditState.Additional;
  }

  public addControl(formState: FormControlState, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, formGroupName?: string): UntypedFormControl {
    let formGroup = this.claimsRegistrationFormGroup;
    if (formGroupName) {
      formGroup = this.claimsRegistrationFormGroup.get(formGroupName) as UntypedFormGroup;
    }
    const control = this.formBuilder.control({ value: formState.value, disabled: formState.disabled }, validator, asyncValidator);
    formGroup.addControl(formState.name, control);
    return control;
  }

  public addFormGroup(name: string, formGroup: UntypedFormGroup) {
    if (this.claimsRegistrationFormGroup.get(name)) {
      this.claimsRegistrationFormGroup.get(name).patchValue(formGroup.value);
    } else {
      this.claimsRegistrationFormGroup.addControl(name, formGroup);
    }
  }

  public getShipmentsForPros(proNumbers: string | string[]): Observable<ListOdsShipmentsResp> {
    if (_.size(proNumbers) === 0) {
      throw new Error('Pro Number Required');
    }

    const request = new ListOdsShipmentsRqst();
    proNumbers = _.isArray(proNumbers) ? proNumbers : [proNumbers];
    request.proNbrs = _.map(proNumbers, pro => this.conditioningService.conditionProNumber(pro, 11));
    request.filterByPickupDateAgeInMonths = -this.configManagerService.getSetting<number>(ConfigManagerProperties.filterByPickupDateAgeInMonths);
    return this.shipmentOdsApiService.listOdsShipments(request, { loadingOverlayEnabled: true });
  }

  public resetClaimsRegistrationFormGroup(): void {
    this.claimsRegistrationFormGroup = this.formBuilder.group({});
  }

  /**
   * An RxJS Pipe, given a ListOdsShipmentsResp, return a single shipmentInstId.  If more then one shipment,
   * asks the user which one to return.
   */
  private pickOneShipmentInstId() {
    return (source: Observable<ListOdsShipmentsResp>) => {
      return Observable.create(subscriber => {
        const subscription = source.subscribe(
          (value: ListOdsShipmentsResp) => {
            try {
              const shipments = value.odsShipments;
              if (_.size(shipments) > 1) {
                // Pro has multiple shipments.  Ask user which one to use
                this.claimsDialogService.showRecycledProsDialog(shipments).subscribe(shipmentInstId => {
                  subscriber.next(shipmentInstId);
                  subscriber.complete();
                });
              } else if (_.size(shipments) === 1) {
                // only one shipment for this pro, so use it
                const shipmentInstId = _.get(_.first(shipments), 'shipment.shipmentInstId');
                subscriber.next(shipmentInstId);
                subscriber.complete();
              } else {
                subscriber.next(false);
                subscriber.complete();
              }
            } catch (err) {
              subscriber.error(err);
              subscriber.complete();
            }
          },
          err => {
            subscriber.error(err);
            subscriber.complete();
          }
        );

        return subscription;
      });
    };
  }

  public getShipmentFromPro(proNumber: string): Observable<GetOdsShipmentResp> {
    if (!proNumber) {
      throw new Error('Pro Number Required');
    }

    return this.getShipmentsForPros(proNumber).pipe(
      take(1),
      this.pickOneShipmentInstId(),
      switchMap((shipmentInstId: number) => {
        return this.getShipmentFromId(shipmentInstId, proNumber);
      }),
      catchError(err => {
        throw new Error(`ERROR! ${err}`);
      })
    );
  }

  public getShipmentFromId(shipmentInstId: number, proNbr?: string, dataOptions?: DataOptions): Observable<GetOdsShipmentResp> {
    if (shipmentInstId) {
      const query = new GetOdsShipmentQuery();
      query.shipmentInstId = shipmentInstId;
      return this.shipmentOdsApiService.getOdsShipment(query, { ...ApiRequest.defaultDataOptions, ...dataOptions }).pipe(
        tap((response: GetOdsShipmentResp) => {
          this.shipment = response;
        })
      );
    } else {
      // no shipment id, so clear any existing shipme
      this.clearShipment();
      this.shipment = this.getNewShipment(shipmentInstId, proNbr);
      return of(undefined);
    }
  }

  public getShipmentFromRelatedPro(proNumber: string): Observable<GetOdsShipmentResp> {
    if (!proNumber) {
      throw new Error('Pro Number Required');
    }

    return this.getShipmentsForPros(proNumber).pipe(
      take(1),
      this.pickOneShipmentInstId(),
      switchMap((shipmentInstId: number) => {
        if (shipmentInstId) {
          // Shipment was found
          const query = new GetOdsShipmentQuery();
          query.shipmentInstId = shipmentInstId;
          return this.shipmentOdsApiService.getOdsShipment(query, { loadingOverlayEnabled: false }).pipe(
            tap((response: GetOdsShipmentResp) => {
              if (!!response) {
                this.claimsForShipmentCacheService.getClaimsForShipment(`${shipmentInstId}`);
              }
            })
          );
        } else {
          return of(undefined);
        }
      }),
      catchError(err => {
        throw new Error(`ERROR! ${err}`);
      })
    );
  }

  private getLoadAtSics() {
    const proNbr = _.get(this.claim, 'claim.proNbr');
    const pickupDate = _.get(this.claim, 'claim.pickupDate');
    const request: ListLoadAtSicsForOdsShipmentQuery = {
      ...new ListLoadAtSicsForOdsShipmentQuery(),
      proNbr,
      pickupDate,
    };

    this.shipmentOdsApiService.listLoadAtSicsForOdsShipment(request, { loadingOverlayEnabled: false }).subscribe((resp: ListLoadAtSicsForOdsShipmentResp) => {
      this.loadAtSics = resp.loadAtSics; // if loadAtSics is undefined, consider it as failed (leaving this.loadAtSics = undefined)
      // also consider it as failed if the API returns an empty list
      if (this.loadAtSics && _.isEmpty(this.loadAtSics)) {
        this.loadAtSics = undefined;
      }
    });
  }

  // Return the AsMatchedParty from the Shipment that matches the requested data, or undefined if not found
  public getPartyFromShipment(copyFromCd: ClaimantCopyFrom): AsMatchedParty {
    if (!!this.shipment && !!this.shipment.asMatchedParty) {
      switch (copyFromCd) {
        case ClaimantCopyFrom.CONSIGNEE:
          return this.shipment.asMatchedParty.find(asMatchedParty => asMatchedParty.typeCd === MatchedPartyTypeCd.CONS);
        case ClaimantCopyFrom.SHIPPER:
          return this.shipment.asMatchedParty.find(asMatchedParty => asMatchedParty.typeCd === MatchedPartyTypeCd.SHPR);
        case ClaimantCopyFrom.BILL_TO:
          return this.shipment.asMatchedParty.find(asMatchedParty => asMatchedParty.typeCd === MatchedPartyTypeCd.BILL_TO_OTB || asMatchedParty.typeCd === MatchedPartyTypeCd.BILL_TO_INB);
      }
    }
    return undefined;
  }

  public getContacts(partyId: number): Observable<Contact[]> {
    if (!partyId) {
      return of([]);
    }
    return Observable.create(observer => {
      const request: ListContactsForPartyRqst = new ListContactsForPartyRqst();
      request.partyId = partyId;
      this.claimsApiService
        .listContactsForParty(request, ApiRequest.concealedCall)
        .pipe(take(1))
        .subscribe(
          (response: ListContactsForPartyResp) => {
            observer.next(response.contacts);
          },
          error => {
            console.error(error);
          },
          () => {
            observer.complete();
          }
        );
    });
  }

  /**
   * Run validation on the form and return true if it is submittable
   */
  public reviewClaim(): boolean {
    FormUtils.touchAllControls(this.claimsRegistrationFormGroup, true);
    if (this.isFormSubmittable()) {
      const splittedPanels: NavigatorPanel[] = this.splitPanels();
      this.navbarService.clearAllPanelsStyles(splittedPanels);
      return true;
    }
    return false;
  }

  /**
   * Handle when one of the claim API calls fails and we want to display a better error toast
   */
  protected handleApiError(error: any) {
    const errorCode = +_.get(error, 'code', -1);
    switch (errorCode) {
      case 500:
        const moreInfo = _.get(error, 'error.moreInfo[0]');
        const message = `${_.get(moreInfo, 'errorCode', error.errorCode)} - ${_.get(moreInfo, 'message', error.message)}`;
        this.notificationService.showSnackBarMessage(`Error: ${message}`, { status: 'error', durationInMillis: 5000 });
        break;

      case 404:
        this.notificationService.showSnackBarMessage(`Error: Unable to Contact Server.  Please Try Again`, { status: 'error', durationInMillis: 5000 });
        break;

      default:
        this.notificationService.showSnackBarMessage(`Unknown API Error: ${error}`, { status: 'error', durationInMillis: 5000 });
        break;
    }
  }

  /** to find if the user has access to edit an active rebuttal*/
  public canEditRebuttal(): boolean {
    // check if both claim and current form has not have rebuttal added
    if (!this.hasActiveRebuttal() && !this.claim.claim.rebuttalInd) {
      return false;
    }

    let activeClaimRebuttalStatusCd;
    if (this.hasActiveRebuttal()) {
      activeClaimRebuttalStatusCd =
        _.get(this.getActiveRebuttalFromClaim(), 'internalStatusCd') ||
        FormUtils.getNestedValue(this.claimsRegistrationFormGroup, ClaimsRegistrationFormNames.Rebuttals, ClaimRebuttalFormNames.InternalStatusCd);
    }

    switch (this.appConstantsService.userRole) {
      case UserRole.Csr: {
        return activeClaimRebuttalStatusCd === RebuttalInternalStatusCd.REBUTTAL_RECEIVED;
      }
      case UserRole.Examiner: {
        return _.some([RebuttalInternalStatusCd.REBUTTAL_RECEIVED, RebuttalInternalStatusCd.UNDER_REVIEW], status => activeClaimRebuttalStatusCd === status);
      }
      case UserRole.Manager: {
        return this.isAssignedClaimExaminer()
          ? _.some([RebuttalInternalStatusCd.REBUTTAL_RECEIVED, RebuttalInternalStatusCd.UNDER_REVIEW], status => activeClaimRebuttalStatusCd === status)
          : activeClaimRebuttalStatusCd === RebuttalInternalStatusCd.REBUTTAL_RECEIVED;
      }
      case UserRole.Director: {
        return activeClaimRebuttalStatusCd === RebuttalInternalStatusCd.REBUTTAL_RECEIVED;
      }
      default: {
        return false;
      }
    }
  }

  public shouldPreventManagerApprovalOfEscalatedClaim(): boolean {
    const isOverMaxApprovalLimit = _.get(this.claim, 'claim.approvedAmount', 0) > this.appConstantsService.claimsDeptMaxTotalPayout;
    return isOverMaxApprovalLimit && this.isClaimStatus(ClaimInternalStatusCd.DIRECTOR_PENDING_APPROVAL);
  }

  /** who can approve/decline a rebuttal and when can he*/
  public canApproveRebuttal(): boolean {
    let statusCd;
    const activeClaimRebuttal = this.getActiveRebuttalFromClaim();

    statusCd = activeClaimRebuttal
      ? activeClaimRebuttal.internalStatusCd
      : FormUtils.getNestedValue(this.claimsRegistrationFormGroup, ClaimsRegistrationFormNames.Rebuttals, ClaimRebuttalFormNames.InternalStatusCd);

    switch (this.appConstantsService.userRole) {
      case UserRole.Examiner: {
        return this.isAssignedClaimExaminer() && _.some([RebuttalInternalStatusCd.REBUTTAL_RECEIVED, RebuttalInternalStatusCd.UNDER_REVIEW], status => statusCd === status);
      }
      case UserRole.Manager: {
        return this.isAssignedClaimExaminer()
          ? _.some([RebuttalInternalStatusCd.REBUTTAL_RECEIVED, RebuttalInternalStatusCd.UNDER_REVIEW], status => statusCd === status)
          : _.some([RebuttalInternalStatusCd.MANAGER_PENDING_APPROVAL, RebuttalInternalStatusCd.DIRECTOR_PENDING_APPROVAL], status => statusCd === status);
      }
      case UserRole.Director: {
        return _.some(
          [RebuttalInternalStatusCd.MANAGER_PENDING_APPROVAL, RebuttalInternalStatusCd.DIRECTOR_PENDING_APPROVAL, RebuttalInternalStatusCd.RVP_PENDING_APPROVAL],
          status => statusCd === status
        );
      }
      /* These roles can only approve rebuttals from CLAIMS_APPROVAL_PAGE
      case UserRole.President: {
        return statusCd === RebuttalInternalStatusCd.PRESIDENT_PENDING_APPROVAL;
      }
      case UserRole.CEO: {
        return statusCd === RebuttalInternalStatusCd.CEO_PENDING_APPROVAL;
      }
      case UserRole.RVPEast:
      case UserRole.RVPWest:
      case UserRole.RVPMidwest: {
        return statusCd === RebuttalInternalStatusCd.RVP_PENDING_APPROVAL;
      }*/
      default: {
        return false;
      }
    }
  }

  /**
   * Register a new Claim
   */
  public registerClaim(): Observable<boolean> {
    return Observable.create(observer => {
      const claimRequest: UpsertClaimRqst = this.transformFormToClaimsRequest();
      const pickupDate = _.get(this.shipment, 'shipment.pickupDate');
      if (pickupDate) {
        claimRequest.claim.pickupDate = this.appConstantsService.getDateAsUTC(new Date(pickupDate));
      }

      // upsert the claim
      this.claimsApiService
        .upsertClaim(claimRequest, { toastOnError: false })
        .pipe(take(1))
        .subscribe(
          (results: UpsertClaimResp) => {
            this.dialogManager
              .open(RegistrationSuccessComponent, { data: { claimId: results.claimId } })
              .afterClosed()
              .pipe(take(1))
              .subscribe(value => {
                if (value) {
                  // user wants to register another claim
                  this.setDefaultClaimState(ClaimStateType.Registration);
                  observer.next(true);
                  observer.complete();
                } else {
                  // user wants to return to dashboard
                  this.router.navigate([RouterUriComponents.DASHBOARD]);
                  observer.next(undefined);
                  observer.complete();
                }
              });
            this.loggingApiService.setContext('proNumber', _.get(claimRequest, 'claim.proNbr', undefined));
            this.loggingApiService.setContext('claimId', _.get(results, 'claimId', undefined));
            this.loggingApiService.info('Create claim', 'Create', 'Create');
          },
          error => {
            // error registering the claim
            this.handleApiError(error);
            observer.next(false);
            observer.complete();
            this.appFooterButtonStateService.setButtonDisabled(FooterButtonTypeCd.SubmitClaim, false);
          }
        );
    });
  }

  /**
   * Return true if the claimStatus matches one of the passed statuses
   */

  public isClaimStatus(status: ClaimInternalStatusCd | ClaimInternalStatusCd[]) {
    return _.indexOf(_.castArray(status), this.claimStatus) > -1;
  }

  public isRoleWithClaimStatus(role: UserRole | UserRole[], claimStatus: ClaimInternalStatusCd | ClaimInternalStatusCd[]): boolean {
    return Array.isArray(role)
      ? _.some(role, userRole => userRole === this.appConstantsService.userRole && this.isClaimStatus(claimStatus))
      : role === this.appConstantsService.userRole && this.isClaimStatus(claimStatus);
  }

  /**
   * Returns true if the current user is able to approve a claim based on their user role and the current claim status
   */

  public canApproveClaim(): boolean {
    switch (this.appConstantsService.userRole) {
      case UserRole.Csr: {
        return false;
      }
      case UserRole.Examiner: {
        return this.isClaimStatus([ClaimInternalStatusCd.SUBMITTED, ClaimInternalStatusCd.UNDER_REVIEW]) && this.isAssignedClaimExaminer();
      }
      case UserRole.Manager: {
        if (this.isAssignedClaimExaminer()) {
          return this.isClaimStatus([ClaimInternalStatusCd.SUBMITTED, ClaimInternalStatusCd.UNDER_REVIEW]);
        } else {
          return (
            this.isClaimStatus(ClaimInternalStatusCd.MANAGER_PENDING_APPROVAL) ||
            (this.isClaimStatus(ClaimInternalStatusCd.DIRECTOR_PENDING_APPROVAL) && !this.shouldPreventManagerApprovalOfEscalatedClaim())
          );
        }
      }
      case UserRole.Director: {
        return this.isClaimStatus([ClaimInternalStatusCd.MANAGER_PENDING_APPROVAL, ClaimInternalStatusCd.DIRECTOR_PENDING_APPROVAL, ClaimInternalStatusCd.RVP_PENDING_APPROVAL]);
      }
      case UserRole.SVP: {
        return this.isClaimStatus(ClaimInternalStatusCd.SVP_OF_OPS_PENDING_APPROVAL);
      }
      case UserRole.President: {
        return this.isClaimStatus(ClaimInternalStatusCd.PRESIDENT_PENDING_APPROVAL);
      }
      case UserRole.CEO: {
        return this.isClaimStatus(ClaimInternalStatusCd.CEO_PENDING_APPROVAL);
      }
      case UserRole.RVPWest:
      case UserRole.RVPEast:
      case UserRole.RVPMidwest: {
        return this.isClaimStatus(ClaimInternalStatusCd.RVP_PENDING_APPROVAL);
      }
      case UserRole.RVPSalesWest:
      case UserRole.RVPSalesEast:
      case UserRole.RVPSalesMidwest: {
        return this.isClaimStatus(ClaimInternalStatusCd.RVP_SALES_PENDING_APPROVAL);
      }
      case UserRole.SVPSales: {
        return this.isClaimStatus(ClaimInternalStatusCd.SVP_SALES_PENDING_APPROVAL);
      }
      default: {
        return false;
      }
    }
  }

  /**
   * Returns true if the current user is currently assigned to Review this claim (manager, director, etc.)
   */
  public isCurrentAssignedClaimReviewer(): boolean {
    return _.get(this.claim, 'claim.currentAssigneeEmployeeId', undefined) === this.appConstantsService.user.employeeId;
  }

  /**
   * Returns true if the current user is the currently assigned Examiner for this claim
   */
  public isAssignedClaimExaminer(): boolean {
    return _.get(this.claim, 'claim.examinedByEmployeeId', undefined) === _.get(this.appConstantsService.user, 'employeeId');
  }

  public canSetClaimFieldModified(): boolean {
    return (
      !!this.claim &&
      (this.isRoleWithClaimStatus(UserRole.Examiner, [ClaimInternalStatusCd.SUBMITTED, ClaimInternalStatusCd.UNDER_REVIEW, ClaimInternalStatusCd.APPROVED, ClaimInternalStatusCd.DECLINED]) ||
        this.isRoleWithClaimStatus(UserRole.Csr, [ClaimInternalStatusCd.SUBMITTED]) ||
        (this.isRoleWithClaimStatus(UserRole.Manager, [ClaimInternalStatusCd.SUBMITTED, ClaimInternalStatusCd.UNDER_REVIEW, ClaimInternalStatusCd.APPROVED, ClaimInternalStatusCd.DECLINED]) &&
          this.isAssignedClaimExaminer()))
    );
  }

  public setClaimFieldModified(updateType: ClaimReviewRequiredUpdateType): void {
    if (!this.reviewRequiredMsgMap[updateType]) {
      switch (updateType) {
        case ClaimReviewRequiredUpdateType.ClaimRequestAmount: {
          this.reviewRequiredMsgMap[updateType] = ClaimReviewUpdateMessageType.ClaimRequestAmount;
          break;
        }
        case ClaimReviewRequiredUpdateType.Payee: {
          this.reviewRequiredMsgMap[updateType] = ClaimReviewUpdateMessageType.Payee;
          break;
        }
        case ClaimReviewRequiredUpdateType.ProNumber: {
          this.reviewRequiredMsgMap[updateType] = ClaimReviewUpdateMessageType.ProNumber;
          break;
        }
        case ClaimReviewRequiredUpdateType.PaymentGreaterThanLiability: {
          this.reviewRequiredMsgMap[updateType] = ClaimReviewUpdateMessageType.PaymentGreaterThanLiability;
          break;
        }
      }
    }
    this.setClaimReviewRequiredStatus();
  }

  public unsetClaimFieldModified(keyToRemove: ClaimReviewRequiredUpdateType, forceUnsetField = false): void {
    if (_.get(this.pristineClaim, 'claim.reviewRequiredInd', false) && !forceUnsetField) {
      // check if review message was on incoming claim, if so, do not unset message

      const pristineClaimReviewMessages = _.get(this.pristineClaim, 'claim.reviewRequiredDescription', '')
        .split(',')
        .map(val => val.trim());

      const hasMessageInPristineClaim = _.find(pristineClaimReviewMessages, reviewMessage => {
        const enumKey = this.claimReviewUpdateMessageTypeHelper.getKey(reviewMessage);
        const compareEnumKey = this.claimReviewRequiredUpdateTypeHelper.getKey(keyToRemove);
        return enumKey === compareEnumKey;
      });

      if (hasMessageInPristineClaim) {
        return;
      }
    }

    this.reviewRequiredMsgMap = _.pickBy(this.reviewRequiredMsgMap, (val, key) => key !== keyToRemove);
    this.setClaimReviewRequiredStatus();
  }

  private setClaimReviewRequiredStatus() {
    this.claim.claim.reviewRequiredDescription = _.values(this.reviewRequiredMsgMap).join(', ') || undefined;
    this.claim.claim.reviewRequiredInd = !!_.keys(this.reviewRequiredMsgMap).length;
  }

  private setEscalationReviewRequiredStatusList() {
    if (!this.claim || !_.get(this.claim, 'claim.reviewRequiredInd', false)) {
      this.escalationReasonListSubject.next([]);
      return;
    }

    const escalationReasonsList = this.claim.claim.reviewRequiredDescription.split(',').map(val => val.trim()) as ClaimReviewUpdateMessageType[];

    _.forEach(escalationReasonsList, (escalationReason: ClaimReviewUpdateMessageType) => {
      const escalationReasonUpdateTypeEnum = this.claimReviewUpdateMessageTypeHelper.getKey(escalationReason);
      this.reviewRequiredMsgMap[ClaimReviewRequiredUpdateType[escalationReasonUpdateTypeEnum]] = escalationReason;
    });

    this.escalationReasonListSubject.next(escalationReasonsList);
  }

  public hasClaimReviewRequiredStatus(statusKey: ClaimReviewRequiredUpdateType, ignoreUserRole: boolean = false): boolean {
    if (!_.get(this.claim, 'claim.reviewRequiredInd', false) || (!ignoreUserRole && !this.canViewClaimEscalationReasons())) {
      return false;
    }

    let statusCompareMsg = '';

    switch (statusKey) {
      case ClaimReviewRequiredUpdateType.ClaimRequestAmount: {
        statusCompareMsg = ClaimReviewUpdateMessageType.ClaimRequestAmount;
        break;
      }
      case ClaimReviewRequiredUpdateType.Payee: {
        statusCompareMsg = ClaimReviewUpdateMessageType.Payee;
        break;
      }
      case ClaimReviewRequiredUpdateType.PaymentGreaterThanLiability: {
        statusCompareMsg = ClaimReviewUpdateMessageType.PaymentGreaterThanLiability;
        break;
      }
      case ClaimReviewRequiredUpdateType.PayoutAmount: {
        statusCompareMsg = ClaimReviewUpdateMessageType.PayoutAmount;
        break;
      }
      case ClaimReviewRequiredUpdateType.ProNumber: {
        statusCompareMsg = ClaimReviewUpdateMessageType.ProNumber;
        break;
      }
      case ClaimReviewRequiredUpdateType.SvcCenterChargeabilityAllocation: {
        statusCompareMsg = ClaimReviewUpdateMessageType.SvcCenterChargeabilityAllocation;
        break;
      }
      default:
        break;
    }

    return _.some(this.escalationReasonList, (message: string) => message.toLowerCase() === statusCompareMsg.toLowerCase());
  }

  private canViewClaimEscalationReasons(): boolean {
    switch (this.appConstantsService.userRole) {
      case UserRole.Csr:
      case UserRole.Examiner:
        return false;
      case UserRole.Manager:
        return this.isClaimStatus([ClaimInternalStatusCd.MANAGER_PENDING_APPROVAL, ClaimInternalStatusCd.DIRECTOR_PENDING_APPROVAL]);
      case UserRole.Director:
        return this.isClaimStatus([ClaimInternalStatusCd.MANAGER_PENDING_APPROVAL, ClaimInternalStatusCd.DIRECTOR_PENDING_APPROVAL, ClaimInternalStatusCd.RVP_PENDING_APPROVAL]);
      default:
        return false;
    }
  }

  private getAiPrediction(claimId: string): void {
    this.claimsApiService
      .getAIPredictionForClaim({ claimId } as GetAIPredictionForClaimPath)
      .pipe(catchError(() => of(undefined)))
      .subscribe((claimFraudPrediction: GetClaimFraudPredictionResp) => {
        this.artificialIntelligenceStatusSubject.next(
          !claimFraudPrediction ? ArtificialIntelligenceStatus.Unknown : _.startCase(_.get(claimFraudPrediction, 'claimFraudPrediction.predictedClass', '').toLowerCase())
        );
      });
  }

  public hasActiveRebuttal(): boolean {
    return FormUtils.getNestedValue(this.claimsRegistrationFormGroup, ClaimsRegistrationFormNames.Header, RegistrationHeaderFormNames.Rebuttal) || _.get(this.claim, 'claim.rebuttalInd', false);
  }

  public getActiveRebuttalFromClaim(): Rebuttal {
    if (!this.claim.claim.rebuttalInd) {
      return;
    }

    return _.chain(_.get(this.claim, 'rebuttals', []))
      .find((rebuttal: Rebuttal) => {
        return (
          rebuttal.internalStatusCd !== RebuttalInternalStatusCd.APPROVED &&
          rebuttal.internalStatusCd !== RebuttalInternalStatusCd.DECLINED &&
          rebuttal.internalStatusCd !== RebuttalInternalStatusCd.CUSTOMER_CANCELLED
        );
      })
      .value();
  }

  public getExistingDraftEscalationEmailFromClaim(): EmailInteraction {
    return _.chain(_.get(this.claim, 'emails', []))
      .sortBy(email => email.auditInfo.createdTimestamp)
      .findLast(email => {
        return email.draftEmailInd && (email.templateTypeCd === ClaimEmailTemplateTypeCd.ESCALATION_APPROVAL || email.templateTypeCd === ClaimEmailTemplateTypeCd.POLICY_PAY);
      })
      .value();
  }

  private isAddingNewClaimRebuttal(): boolean {
    return !_.get(this.claim, 'claim.rebuttalInd', false) && this.hasActiveRebuttal();
  }

  public setRebuttalButtonState(): void {
    this.appFooterButtonStateService.ButtonGroup = this.findRebuttalButtonState();
  }

  public findActiveButtonGroup(claimResp?: GetClaimResp): FooterButtonGroup {
    const claim = claimResp || this.claim;

    if (this.appConstantsService.isReadOnly) {
      return FooterButtonGroup.ClaimReadOnly;
    }

    let activeButtonGroup = FooterButtonGroup.ClaimReadOnly;

    // if claim has Rebuttal indicator set, then need to begin rebuttal process
    if (!this.hasActiveRebuttal()) {
      // be default, keep form as Read-Only
      activeButtonGroup = FooterButtonGroup.ClaimReadOnly;
      if (this.appConstantsService.isCSR) {
        if (!this.isClaimInEscalatedApprovalState()) {
          activeButtonGroup = FooterButtonGroup.ClaimEdit;
        }
      } else {
        switch (claim.claim.internalStatusCd) {
          case ClaimInternalStatusCd.APPROVED:
          case ClaimInternalStatusCd.DECLINED:
            activeButtonGroup = FooterButtonGroup.ClaimEdit;
            break;

          case ClaimInternalStatusCd.UNDER_REVIEW:
          case ClaimInternalStatusCd.SUBMITTED:
            // if we are the assigned reviewer for this claim, then we can approve/decline it
            // else, we can only edit a few items
            if (this.isCurrentAssignedClaimReviewer()) {
              activeButtonGroup = FooterButtonGroup.ClaimPendingApproval;
            } else {
              activeButtonGroup = FooterButtonGroup.ClaimEdit;
            }
            break;

          case ClaimInternalStatusCd.MANAGER_PENDING_APPROVAL:
            if ((this.appConstantsService.isManager && !this.isAssignedClaimExaminer()) || this.appConstantsService.isDirector) {
              activeButtonGroup = FooterButtonGroup.ManagerDirectorPendingApproval;
            }
            break;

          case ClaimInternalStatusCd.DIRECTOR_PENDING_APPROVAL:
            if (this.appConstantsService.isDirector || (this.appConstantsService.isManager && !this.isAssignedClaimExaminer() && !this.shouldPreventManagerApprovalOfEscalatedClaim())) {
              activeButtonGroup = FooterButtonGroup.ManagerDirectorPendingApproval;
            }
            break;

          case ClaimInternalStatusCd.RVP_PENDING_APPROVAL:
            if (this.appConstantsService.isDirector) {
              activeButtonGroup = FooterButtonGroup.ManagerDirectorPendingApproval;
            } else {
              activeButtonGroup = FooterButtonGroup.ClaimReadOnly;
            }
            break;

          case ClaimInternalStatusCd.SVP_OF_OPS_PENDING_APPROVAL:
          case ClaimInternalStatusCd.PRESIDENT_PENDING_APPROVAL:
          case ClaimInternalStatusCd.CEO_PENDING_APPROVAL:
          case ClaimInternalStatusCd.RVP_PENDING_APPROVAL:
            activeButtonGroup = FooterButtonGroup.ClaimReadOnly;
        }
      }
    } else {
      // set button state when there is an active rebuttal present
      return this.findRebuttalButtonState();
    }

    return activeButtonGroup;
  }

  public findRebuttalButtonState(): FooterButtonGroup {
    let activeRebuttalButtonState = FooterButtonGroup.ClaimReadOnly;

    if (!this.hasEnteredRebuttalEditMode) {
      if (!this.isRebuttalInEscalatedApprovalState()) {
        if (this.canEditRebuttal() && !this.canApproveRebuttal()) {
          if (this.isAddingNewClaimRebuttal()) {
            activeRebuttalButtonState = FooterButtonGroup.ClaimSave;
          } else {
            activeRebuttalButtonState = FooterButtonGroup.ClaimEdit;
          }
        } else if (this.canApproveRebuttal()) {
          activeRebuttalButtonState = FooterButtonGroup.RebuttalPending;
        } else {
          activeRebuttalButtonState = FooterButtonGroup.ClaimReadOnly;
        }
      } else {
        if (this.canApproveRebuttal()) {
          activeRebuttalButtonState = FooterButtonGroup.RebuttalPending;
        } else {
          activeRebuttalButtonState = FooterButtonGroup.ClaimReadOnly;
        }
      }
    } else {
      if (this.canApproveRebuttal()) {
        activeRebuttalButtonState = FooterButtonGroup.RebuttalPending;
      } else if (this.canEditRebuttal()) {
        activeRebuttalButtonState = FooterButtonGroup.ClaimSave;
      }
    }
    return activeRebuttalButtonState;
  }

  public getActivePaymentFromClaim(): PaymentDetail {
    return _.find(_.get(this.claim, 'payments', []), (paymentDetail: PaymentDetail) => paymentDetail.payment.statusInternalCd === PaymentStatusInternalCd.PAYMENT_REQUESTED);
  }

  /**
   *
   */
  public upsertClaim(): Observable<UpsertClaimResp> {
    return Observable.create(observer => {
      const claimRequest: UpsertClaimRqst = this.transformFormToClaimsRequest();
      this.claimsApiService
        .upsertClaim(claimRequest, { loadingOverlayEnabled: true, toastOnError: false })
        .pipe(take(1))
        .subscribe(
          (results: UpsertClaimResp) => {
            this.loggingApiService.setContext('proNumber', _.get(claimRequest, 'claim.proNbr', undefined));
            this.loggingApiService.setContext('claimId', _.get(results, 'claimId', undefined));
            this.loggingApiService.info('Update claim', 'Update', 'Update');
            this.setDefaultClaimState(ClaimStateType.ClaimDetailsNoButtons);
            observer.next(results);
            observer.complete();
          },
          error => {
            // what kind of error is it?  Display correct dialog
            // TODO - for now, API only returns 500 for all errors, instead of a 409.  We need to test if we have a ConflictException until this changes.
            if (_.get(error, 'error.moreInfo[0].message', '').indexOf('ConflictException') >= 0) {
              this.claimEditState = ClaimEditState.ReadOnly;
              this.appFooterButtonStateService.ButtonGroup = FooterButtonGroup.ClaimReadOnly;
              this.claimsDialogService
                .showConfirmCancelDialog('Error Updating Claim', 'This Claim has been updated by another user since it was last opened. Please refresh in order to update it.', null, 'Ok')
                .subscribe(() => {
                  observer.next(undefined);
                  observer.complete();
                });
            } else {
              // Non-conflic error
              this.handleApiError(error);
              observer.next(undefined);
              observer.complete();
              for (const btnCd of [FooterButtonTypeCd.Save, FooterButtonTypeCd.SaveFlat]) {
                this.appFooterButtonStateService.setButtonDisabled(btnCd, false);
              }
            }
          }
        );
    });
  }

  /**
   * Transforms claim object to upsertClaimRqst object when approving from claim approval page and no form group has been
   * built out
   */
  public upsertClaimFromClaimsApproval(): Observable<UpsertClaimResp> {
    return Observable.create(observer => {
      const claimRequest = this.transformClaimToClaimsRequest();
      this.claimsApiService
        .upsertClaim(claimRequest, { toastOnError: false })
        .pipe(take(1))
        .subscribe(
          (results: UpsertClaimResp) => {
            observer.next(results);
            observer.complete();
          },
          error => {
            this.handleApiError(error);
            observer.next(undefined);
            observer.complete();
          }
        );
    });
  }

  /**
   * Create a default Claim request
   */
  private createDefaultRequest(): UpsertClaimRqst {
    const upsertClaimRqst = new UpsertClaimRqst();

    upsertClaimRqst.asEnteredParty = [..._.get(this.claim, 'asEnteredParty', [undefined, undefined])];

    upsertClaimRqst.claim = new Claim();
    upsertClaimRqst.claim.shipmentInstId = _.get(this.shipment, 'shipment.shipmentInstId');
    upsertClaimRqst.claim.reviewRequiredInd = false;
    upsertClaimRqst.claim.inLitigationInd = false;
    upsertClaimRqst.claim.migratedInd = false;
    upsertClaimRqst.claim.liabilityLimitWaivedInd = false;
    upsertClaimRqst.claim.possibleDuplicateClaimInd = false;
    upsertClaimRqst.claim.declinedAfterEscalationInd = false;
    upsertClaimRqst.currentAssigneeRoleId = this.appConstantsService.getRoleIdFromUserRole(this.appConstantsService.activeUserRole);

    return upsertClaimRqst;
  }

  private transformServiceCenterChargeability(upsertClaimRqst: UpsertClaimRqst): UpsertClaimRqst {
    upsertClaimRqst.chargeableServiceCenters = this.calculateServiceCenterChargeability(upsertClaimRqst);
    return upsertClaimRqst;
  }

  private calculateServiceCenterChargeability(upsertClaimRqst?: UpsertClaimRqst): ChargeableServiceCenter[] {
    const chargeableServiceCenters: ChargeableServiceCenter[] = _.cloneDeep(_.get(this.claim, 'chargeableServiceCenters', []));

    const originSvcCenterSic = _.get(this.shipment, 'shipment.originTerminalSicCd');
    const destSvcCenterSic = _.get(this.shipment, 'shipment.destinationTerminalSicCd');
    const approvedAmount = _.get(upsertClaimRqst, 'claim.approvedAmount', 0);

    const hasOriginDestSic: boolean = !!originSvcCenterSic && !!destSvcCenterSic;
    const loadAtSicsAPICallFailed = !this.loadAtSics;
    let loadAtSics = _.clone(this.loadAtSics);

    if (!hasOriginDestSic || loadAtSicsAPICallFailed) {
      loadAtSics = [];
    }

    // initialize serviceCenterChargeabilityService
    const serviceCenterChargeabilityService = new ServiceCenterChargeabilityService(originSvcCenterSic, destSvcCenterSic, this.claim, chargeableServiceCenters, loadAtSics);
    loadAtSics = serviceCenterChargeabilityService.getFilteredLoadAtSics();

    if (!_.size(chargeableServiceCenters)) {
      // chargeableServiceCenters isEmpty
      // we are NOT saving from the chargeability dialog and we have NO chargability previously saved
      // -> create chargeability with default values

      if (hasOriginDestSic && !loadAtSicsAPICallFailed) {
        if (originSvcCenterSic === destSvcCenterSic) {
          const originDestChargeability = this.buildServiceCenterChargeability(originSvcCenterSic, 100, ActionCd.ADD, approvedAmount);
          chargeableServiceCenters.push(originDestChargeability);
        } else {
          this.applyEquallyDistributedChargeability(originSvcCenterSic, destSvcCenterSic, approvedAmount, chargeableServiceCenters, serviceCenterChargeabilityService, loadAtSics);
        }
      } else {
        // keep chargeableServiceCenters array empty
      }
    } else {
      // we are saving from the dialog or we had an already saved chargeability
      const savingFromChargeabilityDialog = this.isUpsertingClaimAfterSavingChargeabilityDialog;
      if (!savingFromChargeabilityDialog && !serviceCenterChargeabilityService.shouldKeepExistingChargeability()) {
        if (hasOriginDestSic && !loadAtSicsAPICallFailed) {
          this.applyEquallyDistributedChargeability(originSvcCenterSic, destSvcCenterSic, approvedAmount, chargeableServiceCenters, serviceCenterChargeabilityService, loadAtSics);
        } else {
          // delete previous chargeability (leave chargeableServiceCenters empty)
          chargeableServiceCenters.forEach(chargeableServiceCenter => (chargeableServiceCenter.listActionCd = ActionCd.DELETE));
        }
      } else {
        // saving from dialog data or new chargeability non applicable
        _.forEach(chargeableServiceCenters, (svcCenter: ChargeableServiceCenter) => {
          const originalChargeAmount = svcCenter.chargeAmount;
          svcCenter.chargeAmount = UnitConversion.round(approvedAmount * (svcCenter.chargePercentage * 0.01));
          if (svcCenter.listActionCd === ActionCd.NO_ACTION && originalChargeAmount !== svcCenter.chargeAmount) {
            svcCenter.listActionCd = ActionCd.UPDATE;
          }
        });
      }
    }

    return chargeableServiceCenters;
  }

  private applyEquallyDistributedChargeability(
    originSvcCenterSic: string,
    destSvcCenterSic: string,
    approvedAmount: number,
    chargeableServiceCenters: ChargeableServiceCenter[],
    serviceCenterChargeabilityService: ServiceCenterChargeabilityService,
    loadAtSics: string[]
  ) {
    // helper functions
    const findSvcCenter = (sicCd: string) => {
      return chargeableServiceCenters.find(x => x.sicCd.toUpperCase() === sicCd.toUpperCase());
    };
    const addOrUpdateSvcCenter = (sicCd: string, newPctAloc: number) => {
      const existingSvcCenter = findSvcCenter(sicCd);
      if (existingSvcCenter) {
        // update (if changed)
        if (_.toNumber(existingSvcCenter.chargePercentage) !== newPctAloc || existingSvcCenter.chargeAmount !== approvedAmount) {
          existingSvcCenter.chargePercentage = newPctAloc;
          existingSvcCenter.currencyCd = this.claim?.claim?.currencyCd;
          existingSvcCenter.claimId = _.get(this.claim, 'claim.claimId');
          existingSvcCenter.chargeAmount = UnitConversion.round(approvedAmount * (existingSvcCenter.chargePercentage * 0.01));
          existingSvcCenter.listActionCd = ActionCd.UPDATE;
        }
      } else {
        // add
        const newChargeabilitySvc = this.buildServiceCenterChargeability(sicCd, newPctAloc, ActionCd.ADD, approvedAmount);
        chargeableServiceCenters.push(newChargeabilitySvc);
      }
    };

    // mark other chargeableServiceCenters for deletion
    const keepSicCds = [originSvcCenterSic, destSvcCenterSic, ...loadAtSics].map(sicCd => sicCd.toUpperCase());
    _.forEach(chargeableServiceCenters, (svcCenter: ChargeableServiceCenter) => {
      if (!keepSicCds.includes(svcCenter.sicCd.toUpperCase())) {
        svcCenter.listActionCd = ActionCd.DELETE;
      }
    });

    const [originPctAloc, destPctAloc, loadAtSicsPctAloc] = serviceCenterChargeabilityService.getEquallyDistributedChargeability();

    addOrUpdateSvcCenter(originSvcCenterSic, originPctAloc);
    addOrUpdateSvcCenter(destSvcCenterSic, destPctAloc);
    loadAtSics.forEach((loadAtSicCd, i) => {
      const loadAtPctAloc = loadAtSicsPctAloc[i];
      addOrUpdateSvcCenter(loadAtSicCd, loadAtPctAloc);
    });
  }

  private buildServiceCenterChargeability(sicCd: string, chargePercentage: number, listActionCd: ActionCd, approvedAmount: number): ChargeableServiceCenter {
    const serviceCenterChargeability = new ChargeableServiceCenter();
    serviceCenterChargeability.sicCd = sicCd;
    serviceCenterChargeability.chargePercentage = chargePercentage;
    serviceCenterChargeability.currencyCd =  this.claim?.claim?.currencyCd || ClaimCurrencyCd.US_DOLLARS;
    serviceCenterChargeability.claimId = _.get(this.claim, 'claim.claimId');
    serviceCenterChargeability.listActionCd = listActionCd;

    serviceCenterChargeability.chargeAmount = UnitConversion.round(approvedAmount * (chargePercentage * 0.01));

    return serviceCenterChargeability;
  }

  private transformClaimData(upsertClaimRqst: UpsertClaimRqst): UpsertClaimRqst {
    if (this.claim) {
      // claim, set upsert request to global claim object properties
      _.assign(upsertClaimRqst.claim, this.claim.claim);
    } else {
      // no claim, set registration claim properties
      upsertClaimRqst.claim.filedByEmployeeId = this.appConstantsService.user.employeeId;
    }

    const getClaimsForShipmentRqstId = upsertClaimRqst.claim.shipmentInstId ? upsertClaimRqst.claim.shipmentInstId : upsertClaimRqst.claim.proNbr;

    // see if there are any other claims with this shipment
    this.claimsForShipmentCacheService.getClaimsForShipment(`${getClaimsForShipmentRqstId}`).subscribe(claims => {
      // Remove self
      claims = _.filter(claims, claimId => claimId !== _.get(this.claim, 'claim.claimId'));
      if (_.size(claims) > 0) {
        upsertClaimRqst.claim.possibleDuplicateClaimInd = true;
      }
    });

    return upsertClaimRqst;
  }

  public setRebuttalStatus(status: RebuttalInternalStatusCd): void {
    const rebuttalFG = FormUtils.getNestedControl(this.claimsRegistrationFormGroup, ClaimsRegistrationFormNames.Rebuttals);
    const rebuttalStatusCtrl = FormUtils.getNestedControl(rebuttalFG, ClaimRebuttalFormNames.InternalStatusCd);
    rebuttalStatusCtrl.setValue(status);
  }

  public performClaimApproval() {
    const rebuttalInd = FormUtils.getNestedValue(this.claimsRegistrationFormGroup, ClaimsRegistrationFormNames.Header, RegistrationHeaderFormNames.Rebuttal);
    if (!!rebuttalInd) {
      this.setRebuttalStatus(RebuttalInternalStatusCd.APPROVED);
    }

    // reset review required indicator and description when manager or director approves a claim
    if ((this.appConstantsService.isManager && !this.isAssignedClaimExaminer()) || this.appConstantsService.isDirector) {
      this.claim.claim.reviewRequiredInd = false;
      this.claim.claim.reviewRequiredDescription = undefined;
    }

    this.claim.claim.approvedByEmployeeId = this.appConstantsService.user.employeeId;
    this.claim.claim.internalStatusCd = ClaimInternalStatusCd.APPROVED;

    // upsert the new Claim and then reload it.
    this.upsertClaim().subscribe((results: UpsertClaimResp) => {
      if (results) {
        // reload the claim
        this.notificationService.showSnackBarMessage('Claim Approved', { durationInMillis: 5000, status: 'SUCCESS' });
        this.loadClaim(results.claimId);
      }
    });
  }

  public performSubmitPayment() {
    const rebuttalInd = FormUtils.getNestedValue(this.claimsRegistrationFormGroup, ClaimsRegistrationFormNames.Header, RegistrationHeaderFormNames.Rebuttal);
    if (!!rebuttalInd) {
      this.setRebuttalStatus(RebuttalInternalStatusCd.APPROVED);
    }

    // reset review required indicator and description when manager or director approves a claim
    if ((this.appConstantsService.isManager && !this.isAssignedClaimExaminer()) || this.appConstantsService.isDirector) {
      this.claim.claim.reviewRequiredInd = false;
      this.claim.claim.reviewRequiredDescription = undefined;
    }

    this.claim.claim.approvedByEmployeeId = this.appConstantsService.user.employeeId;
    this.claim.claim.internalStatusCd = ClaimInternalStatusCd.APPROVED;

    this.upsertClaim().subscribe((results: UpsertClaimResp) => {
      if (results) {
        // reload the claim
        this.notificationService.showSnackBarMessage('Payment Submitted', { durationInMillis: 5000, status: 'SUCCESS' });
        this.loadClaim(results.claimId);
      }
    });
  }

  public performDeclination() {
    const rebuttalInd = FormUtils.getNestedValue(this.claimsRegistrationFormGroup, ClaimsRegistrationFormNames.Header, RegistrationHeaderFormNames.Rebuttal);

    if (!!rebuttalInd) {
      this.setRebuttalStatus(RebuttalInternalStatusCd.DECLINED);
    }

    // set the claim state to Declined or Under Review depending on pending approval status
    // reset review required indicator and description, and set declined after escalation indicator when manager declines a claim
    if (this.isClaimInEscalatedApprovalState()) {
      this.claim.claim.declinedAfterEscalationInd = true;

      // mark the last payment in the list as DECLINED
      const declinedPayment = _.find(this.claim.payments, (detail: PaymentDetail) => detail.payment.statusInternalCd === PaymentStatusInternalCd.PAYMENT_REQUESTED);
      if (declinedPayment) {
        _.set(declinedPayment, 'payment.statusInternalCd', PaymentStatusInternalCd.DECLINED);
        _.set(declinedPayment, 'payment.listActionCd', ActionCd.UPDATE);
        this.claim.claim.approvedAmount -= declinedPayment.payment.amount;
      }
    }

    this.claim.claim.internalStatusCd = ClaimInternalStatusCd.DECLINED;

    this.upsertClaim().subscribe((results: UpsertClaimResp) => {
      if (results) {
        // reload the claim
        this.notificationService.showSnackBarMessage('Declination Submitted.', { durationInMillis: 5000, status: 'SUCCESS' });
        this.loadClaim(results.claimId);
      }
    });
  }

  public setToPreviousPaymentEditState(skipPendingStates?: boolean): void {
    const pendingStates = [PaymentState.PendingDeclinationFormReview, PaymentState.PendingApprovalFormReview, PaymentState.PendingRebuttalApprovalFormReview];
    let previousState = this.previousPaymentEditStates.shift();

    if (skipPendingStates) {
      while (_.some(pendingStates, state => state === previousState)) {
        previousState = this.previousPaymentEditStates.shift();
      }
    }

    this.paymentEditStateSubject.next(previousState);
  }

  /**
   * create an UpsertClaimRqst from the current state of the Form
   */
  private transformFormToClaimsRequest(): UpsertClaimRqst {
    let upsertClaimRqst: UpsertClaimRqst = this.createDefaultRequest();
    const rawForm = this.claimsRegistrationFormGroup.getRawValue();
    upsertClaimRqst = this.transformClaimData(upsertClaimRqst);
    upsertClaimRqst = this.transformReviewRequired(upsertClaimRqst);

    upsertClaimRqst = RegistrationHeaderFormBuilder.transform(upsertClaimRqst, rawForm, this.claim, this.appConstantsService);
    upsertClaimRqst = RegistrationClaimantFormBuilder.transform(upsertClaimRqst, rawForm);
    upsertClaimRqst = RegistrationPayeeFormBuilder.transform(upsertClaimRqst, rawForm);
    upsertClaimRqst = RegistrationCommoditiesFormBuilder.transform(upsertClaimRqst, rawForm);
    upsertClaimRqst = RegistrationIndicatorsFormBuilder.transform(upsertClaimRqst, rawForm);
    upsertClaimRqst = RegistrationNotesFormBuilder.transform(upsertClaimRqst, rawForm, this.appConstantsService);
    upsertClaimRqst = RegistrationRelatedProsFormBuilder.transform(upsertClaimRqst, rawForm, this, this.conditioningService);
    if (this.hasActiveRebuttal()) {
      upsertClaimRqst = RegistrationRebuttalsFormBuilder.transform(upsertClaimRqst, rawForm, this.pristineClaim, this.appConstantsService, this);
    }
    upsertClaimRqst = RegistrationClaimPaymentFormBuilder.transform(upsertClaimRqst, rawForm, this.appConstantsService.user, this);
    upsertClaimRqst = this.transformNotes(this.claim, upsertClaimRqst);
    upsertClaimRqst = this.transformServiceCenterChargeability(upsertClaimRqst);

    // do any claim-level changes that aren't handled in components
    if (_.some(upsertClaimRqst.payments, (payment: Payment) => payment.listActionCd === ActionCd.ADD && payment.statusInternalCd === PaymentStatusInternalCd.PAYMENT_REQUESTED)) {
      // TODO - Is adding a Payment the only way we can APPROVE?
      upsertClaimRqst.claim.internalStatusCd = ClaimInternalStatusCd.APPROVED;
    }
    if (!!this.latitudeLongitude) {
      this.setlatitudeLongitude(upsertClaimRqst);
    }

    return upsertClaimRqst;
  }

  private transformReviewRequired(upsertClaimRqst: UpsertClaimRqst): UpsertClaimRqst {
    if (this.payeeIsSameAsClaimantAndChanged(upsertClaimRqst)) {
      upsertClaimRqst.claim.reviewRequiredInd = true;
      upsertClaimRqst.claim.reviewRequiredDescription = ClaimReviewUpdateMessageType.Payee;
    }
    return upsertClaimRqst;
  }

  private payeeIsSameAsClaimantAndChanged(upsertClaimRqst: UpsertClaimRqst): boolean {
    // Is new claim or Payee is not same as claimant
    if (!upsertClaimRqst || !this.claim || !upsertClaimRqst.claim.payeeSameAsClaimantInd) {
      return false;
    }

    // Payee is same as claimant
    if (upsertClaimRqst.claimant) {
      for (const key in upsertClaimRqst.claimant) {
        if (upsertClaimRqst.claimant.hasOwnProperty(key)) {
          const payeePropValue = this.claim.payee[key];
          const claimantPropValue = upsertClaimRqst.claimant[key];

          if (payeePropValue && claimantPropValue && claimantPropValue !== payeePropValue) {
            // Payee has changed
            return true;
          }
        }
      }
    }

    // Payee has not changed
    return false;
  }

  /**
   * create an UpsertClaimRqst from the current state of the claim
   * occurs when no form has been created (ie. escalated claim approvals from senior leadership)
   */
  private transformClaimToClaimsRequest(): UpsertClaimRqst {
    let upsertClaimRqst: UpsertClaimRqst = this.createDefaultRequest();
    upsertClaimRqst = _.assignWith(
      upsertClaimRqst,
      _.pick(this.claim, _.keys(upsertClaimRqst)),
      (claimForUpsertVal: any, srcClaimVal: any, key: string, claimForUpsert: UpsertClaimResp, srcClaim: GetClaimResp) => {
        switch (key.toLowerCase()) {
          case ClaimsRegistrationFormNames.Payments.toLowerCase():
            return _.map(srcClaim.payments, (paymentDetail: PaymentDetail) => paymentDetail.payment);
          default:
            return srcClaimVal;
        }
      }
    );
    return upsertClaimRqst;
  }

  private transformNotes(claim: GetClaimResp, upsertClaimRqst: UpsertClaimRqst): UpsertClaimRqst {
    if (claim) {
      upsertClaimRqst.notes = claim.notes;
    }
    return upsertClaimRqst;
  }

  /** For non-revenue - NOT NORM, ACCO, PTLT */
  public isNonRevenueBillClass(billClassCode: BillClassCd): boolean {
    let nonRevenueBill = false;

    if (billClassCode) {
      nonRevenueBill = billClassCode !== BillClassCd.NORMAL_MVMT && billClassCode !== BillClassCd.ACCESSORIAL_ONLY && billClassCode !== BillClassCd.SPLIT_SEGMENT;
    }

    return nonRevenueBill;
  }

  public conditionNmfcCode(nmfc: string): string {
    if (nmfc && nmfc.trim().length > 0 && nmfc.match(/-0[0-9]$/)) {
      nmfc = nmfc.substring(0, nmfc.length - 2) + nmfc.substring(nmfc.length - 1);
    }
    if (nmfc && nmfc[0] === '0') {
      nmfc = nmfc.substring(1);
    }
    return nmfc;
  }

  public isClaimInEscalatedApprovalState(): boolean {
    if (!this.claim) {
      return false;
    }

    return this.isClaimStatus([
      ClaimInternalStatusCd.MANAGER_PENDING_APPROVAL,
      ClaimInternalStatusCd.DIRECTOR_PENDING_APPROVAL,
      ClaimInternalStatusCd.SVP_OF_OPS_PENDING_APPROVAL,
      ClaimInternalStatusCd.PRESIDENT_PENDING_APPROVAL,
      ClaimInternalStatusCd.CEO_PENDING_APPROVAL,
      ClaimInternalStatusCd.RVP_PENDING_APPROVAL,
    ]);
  }

  public isClaimInExecutiveApprovalState(): boolean {
    if (!this.claim) {
      return false;
    }

    return this.isClaimStatus([
      ClaimInternalStatusCd.DIRECTOR_PENDING_APPROVAL,
      ClaimInternalStatusCd.SVP_OF_OPS_PENDING_APPROVAL,
      ClaimInternalStatusCd.PRESIDENT_PENDING_APPROVAL,
      ClaimInternalStatusCd.CEO_PENDING_APPROVAL,
      ClaimInternalStatusCd.RVP_PENDING_APPROVAL,
      ClaimInternalStatusCd.RVP_SALES_PENDING_APPROVAL,
      ClaimInternalStatusCd.SVP_SALES_PENDING_APPROVAL,
    ]);
  }

  public isRebuttalInEscalatedApprovalState(): boolean {
    if (!this.claim) {
      return false;
    }

    const activeRebuttal = this.getActiveRebuttalFromClaim();
    return _.some(
      [
        RebuttalInternalStatusCd.MANAGER_PENDING_APPROVAL,
        RebuttalInternalStatusCd.DIRECTOR_PENDING_APPROVAL,
        RebuttalInternalStatusCd.SVP_OF_OPS_PENDING_APPROVAL,
        RebuttalInternalStatusCd.PRESIDENT_PENDING_APPROVAL,
        RebuttalInternalStatusCd.CEO_PENDING_APPROVAL,
        RebuttalInternalStatusCd.RVP_PENDING_APPROVAL,
      ],
      state => !!activeRebuttal && state === activeRebuttal.internalStatusCd
    );
  }

  public matchParty(party: ClaimParty): Observable<MatchClaimPartyResp> {
    const request = new MatchClaimPartyRqst();
    request.claimParty = party;

    return this.claimsApiService.matchClaimParty(request);
  }

  private getNewShipment(shipmentInstId: number, proNbr: string): GetOdsShipmentResp {
    const newShipment = new GetOdsShipmentResp();

    newShipment.shipment = new Shipment();
    newShipment.shipment.shipmentInstId = shipmentInstId || undefined;
    newShipment.shipment.proNbr = proNbr;
    newShipment.asMatchedParty = [];
    newShipment.commodity = [];
    newShipment.accessorialService = [];
    newShipment.suppRefNbr = [];
    newShipment.remark = [];
    newShipment.advanceBeyondCarrier = [];
    newShipment.customsBond = [];
    newShipment.miscLineItem = [];
    newShipment.childShipmentIds = [];
    newShipment.siblingShipmentIds = [];
    newShipment.proFrtBillIndexStatCd = ProStatusCd.AVAILABLE;

    return newShipment;
  }

  public loadClaim(claimId: string) {
    this.claimsService.getClaim(claimId).subscribe((claim: GetClaimResp) => {
      this.claim = claim;
      this.navbarService.showPanelWithName(NavigationBarLabelType.ClaimHeader);
      this.updateClaimEditState();
    });
  }

  /*
    After sending a note or email, claim status/record version number can change. If so, need to update only
    status and version number on claim and not reload/potentially overwrite other claim information.
  */

  public checkClaimRecordVersionNbrUpdate(): void {
    this.claimsService.getClaim(_.get(this.claim, 'claim.claimId')).subscribe((claim: GetClaimResp) => {
      const currentClaimRecordVersionNbr = _.get(this.claim, 'claim.recordVersionNbr');
      const newClaimRecordVersionNbr = claim.claim.recordVersionNbr;
      if (currentClaimRecordVersionNbr !== newClaimRecordVersionNbr) {
        const claimToUpdate = this.claim;
        claimToUpdate.claim.recordVersionNbr = claim.claim.recordVersionNbr;
        claimToUpdate.claim.internalStatusCd = claim.claim.internalStatusCd;
        claimToUpdate.claim.externalStatusCd = claim.claim.externalStatusCd;

        const pristineClaimToUpdate = this.pristineClaim;
        pristineClaimToUpdate.claim.recordVersionNbr = claim.claim.recordVersionNbr;
        pristineClaimToUpdate.claim.internalStatusCd = claim.claim.internalStatusCd;
        pristineClaimToUpdate.claim.externalStatusCd = claim.claim.externalStatusCd;

        this._pristineClaim = pristineClaimToUpdate;

        this.claimStatusCds = {
          internalStatusCd: claim.claim.internalStatusCd,
          externalStatusCd: claim.claim.externalStatusCd,
        } as ClaimStatusCds;
      }
    });
  }

  public showNotesEmails(): void {
    this.showNotesEmailsSubject.next(null);
  }

  public markEmailAsRead(emailInstId: number): Observable<void> {
    const request = new UpdateClaimEmailReadIndRqst();
    request.claimId = this.claim.claim.claimId;
    request.emailInstId = emailInstId;
    request.readInd = true;
    return this.claimsApiService.updateClaimEmailReadInd(request);
  }

  public updateNotesEmailsBadgeCounts(): void {
    this.updateNotesEmailsBadgeCountsSubject.next(null);
  }

  public setlatitudeLongitude(upsertClaimRqst: UpsertClaimRqst) {
    upsertClaimRqst.claimant.latitudeNbr = this.latitudeLongitude.latitudeNbr;
    upsertClaimRqst.claimant.longitudeNbr = this.latitudeLongitude.longitudeNbr;
    if (!!upsertClaimRqst.payee) {
      upsertClaimRqst.payee.latitudeNbr = this.latitudeLongitude.latitudeNbr;
      upsertClaimRqst.payee.longitudeNbr = this.latitudeLongitude.longitudeNbr;
    }
  }

  public downloadDMSAttachment(attachments: EmailAttachment | EmailAttachment[]): Observable<void> {
    const attachmentListObservers: Observable<any>[] = [];
    const attachmentsList = _.isArray(attachments) ? attachments : [attachments];

    _.forEach(attachmentsList, (attachment: EmailAttachment) => {
      let uploadedDmsImage = _.find(this.dmsDocList, (dmsDoc: DocumentSearch) => attachment.dmsUrl === _.get(dmsDoc, 'cdt.timestamp'));

      if (!uploadedDmsImage) {
        uploadedDmsImage = new DocumentSearch();
        uploadedDmsImage.cdt = new DocumentCdt();
        uploadedDmsImage.cdt.timestamp = _.get(attachment, 'dmsUrl');
        uploadedDmsImage.cdt.docClass = _.get(attachment, 'dmsDocTypeCd');
      }

      attachmentListObservers.push(this.docViewService.getDocument(_.get(uploadedDmsImage, 'cdt.timestamp'), attachment.dmsDocTypeCd, 'pdf'));
    });
    return new Observable(observer => {
      // If there are no observers, ensure this observerable finishes
      if (!_.size(attachmentListObservers)) {
        observer.next();
        observer.complete();
        return;
      }
      forkJoin(attachmentListObservers)
        .pipe(
          map((getDocumentRespList: GetDocumentResp[]) =>
            _.map(getDocumentRespList, getDocumentResp => {
              return of(getDocumentResp).pipe(switchMap((document: GetDocumentResp) => this.generateDownloadFile(document.contentType, decode(document.documentData), document.fileName)));
            })
          )
        )
        .subscribe(resp => {
          concat(...resp).subscribe(() => {
            observer.next();
            observer.complete();
          });
        });
    });
  }

  public generateDownloadFile(mimeType: string, data: string | Uint8Array, fileName: string): Observable<void> {
    return new Observable(observer => {
      const blob = new Blob([data], { type: mimeType });
      saveAs(blob, fileName);

      timer(100).subscribe(() => {
        observer.next();
        observer.complete();
      });
    });
  }
  public checkPanelErrors(claimRequestAmountVisible?: boolean) {
    const splittedPanels: NavigatorPanel[] = this.splitPanels(claimRequestAmountVisible);
    splittedPanels
      .filter(panel => panel.label !== NavigationBarLabelType.Undefined)
      .map(panel => {
        panel.isValid ? this.navbarService.clearPanelLabelStyle(panel.label) : this.navbarService.setPanelLabelError(panel.label);
      });
  }

  /**
   * Return true if chargeability would be empty on upserting
   */
  public chargeabilityIsEmpty(): boolean {
    const nextChargeability = this.calculateServiceCenterChargeability();
    const nextEffectiveChargeability = nextChargeability.filter(chargeableServiceCenter => chargeableServiceCenter.listActionCd !== ActionCd.DELETE);
    return _.isEmpty(nextEffectiveChargeability);
  }
}
