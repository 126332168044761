import { BREAKPOINT } from '@angular/flex-layout';

const CUSTOM_BREAKPOINTS = [
  {
    alias: 'mobile',
    suffix: 'Mobile',
    mediaQuery: 'screen and (max-width: 800px)',
    overlapping: false,
  },
  {
    alias: 'non-mobile',
    suffix: 'NonMobile',
    mediaQuery: 'screen and (min-width: 801px)',
    overlapping: false,
  },
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: CUSTOM_BREAKPOINTS,
  multi: true,
};
