export enum ClaimRebuttalFormNames {
  FilingDate = 'filingDate',
  ReasonCd = 'reasonCd',
  FinalApprovedDate = 'finalApprovedDate',
  FinalDeclinedDate = 'finalDeclinedDate',
  DecisionDate = 'decisionDate',
  Amount = 'amount',
  AmountDisplayOnly = 'amountDisplayOnly',
  NoteTxt = 'noteTxt',
  InternalStatusCd = 'internalStatusCd',
  ExternalStatusCd = 'externalStatusCd',
  ClaimEventId = 'claimEventId',
  ClaimId = 'claimId',
  ClosedDate = 'closedDate',
  ListActionCd = 'listActionCd',
  SequenceNbr = 'sequenceNbr',
  ReceivedDateTime = 'receivedDateTime',
  RecordVersionNbr = 'recordVersionNbr',
  CorrelationId = 'correlationId',
  AuditInfo = 'auditInfo',
}
