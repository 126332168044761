import { Pipe, PipeTransform } from '@angular/core';
import { ICountry } from 'ngx-country-picker';

@Pipe({
  name: 'iCountry',
})
export class ICountryPipe implements PipeTransform {
  transform(value: ICountry, args?: any): any {
    if (value && value.name) {
      const returnValue = value.name.common;
      return returnValue;
    }
    return value;
  }
}
