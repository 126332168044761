import { Injectable } from '@angular/core';
import { ClaimEmailTemplateTypeCd } from '@xpo-ltl/sdk-common';
import { XpoLtlBaseCacheService } from '@xpo-ltl/ngx-ltl';
import { ListClaimEmailTemplatesResp, ClaimsApiService, ListClaimEmailTemplatesRqst } from '@xpo-ltl/sdk-claims';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

export interface EmailTemplateCacheParams {
  templateTypeCd: ClaimEmailTemplateTypeCd;
}

@Injectable()
export class EmailTemplatesCacheService extends XpoLtlBaseCacheService<EmailTemplateCacheParams, ListClaimEmailTemplatesResp> {
  constructor(private claimsService: ClaimsApiService) {
    super();
  }

  protected getKeyFromParams(params: EmailTemplateCacheParams): string {
    return `${_.defaultTo(params.templateTypeCd, '')}`;
  }

  protected requestData(params: EmailTemplateCacheParams): Observable<ListClaimEmailTemplatesResp> {
    const request = new ListClaimEmailTemplatesRqst();
    request.emailTemplateTypes = [params.templateTypeCd];
    return this.claimsService.listClaimEmailTemplates(request, { toastOnError: false });
  }
}
