<mat-dialog-content id="print-section">
  <div class="print-claim">
    <!-- Header -->
    <div class="print-claim-general-details-block">
      <div fxLayoutAlign="space-between center">
        <div class="print-claim-general-details-block-title">
          <h3 class="details-title">General details - {{claim?.claim?.claimId}}</h3>
        </div>
        <div class="details-external-status">
          <p class="field-label">External status: </p>
          <p class="field-value">{{claimExternalStatus}}</p>
        </div>
        <button  mat-icon-button aria-label="Print" matTooltip="Print" printSectionId="print-section" ngxPrint
          id="print-button" [useExistingCss]="true">
          <mat-icon matSuffix>print</mat-icon>
        </button>
      </div>
      <div class="print-claim-general-details-block-subitems">
        <div class="subitem">
          <p class="field-label">Received Date</p>
          <p class="field-value">{{claim?.claim?.receivedDate ? claim?.claim?.receivedDate : '-'}}</p>
        </div>
        <div class="subitem">
          <p class="field-label">Pickup Date</p>
          <p class="field-value">{{claim?.claim?.pickupDate ? claim?.claim?.pickupDate : '-'}}</p>
        </div>
        <div class="subitem">
          <p class="field-label">Filling Method</p>
          <p class="field-value">{{claim?.claim?.filingMethodCd ? claim?.claim?.filingMethodCd : '-'}}</p>
        </div>
        <div class="subitem">
          <p class="field-label">PRO #</p>
          <p class="field-value">{{claim?.claim?.proNbr ? claim?.claim?.proNbr : '-'}}</p>
        </div>
        <div class="subitem">
          <p class="field-label">Customer Reference #</p>
          <p class="field-value">{{claim?.claim?.claimantRefNbr ? claim?.claim?.claimantRefNbr : '-'}}</p>
        </div>
        <div class="subitem">
          <p class="field-label">Filled By</p>
          <p class="field-value">
            {{claim?.claim?.filedByEmployeeDtl ? (claim?.claim?.filedByEmployeeDtl | employeeName) : '-'}}</p>
        </div>
        <div class="subitem">
          <p class="field-label">Current Approver</p>
          <p class="field-value">
            {{claim?.claim?.currAssigneeEmployeeDtl ? (claim?.claim?.currAssigneeEmployeeDtl | employeeName)  : '-'}}
          </p>
        </div>
        <div class="subitem">
          <p class="field-label">Rebuttal Status</p>
          <p class="field-value">{{getLatestRebuttalStatus() ? getLatestRebuttalStatus() : '-'}}</p>
        </div>
        <div class="subitem">
          <p class="field-label">Claim Type</p>
          <p class="field-value">{{claim?.claim?.typeCd ? claim?.claim?.typeCd : '-'}}</p>
        </div>
        <div class="subitem">
          <p class="field-label">Examiner</p>
          <p class="field-value">
            {{claim?.claim?.examinedByEmployeeDtl ? (claim?.claim?.examinedByEmployeeDtl | employeeName) : '-'}}</p>
        </div>
        <div class="subitem">
          <p class="field-label">Reason to Reassign Examiner</p>
          <p class="field-value">{{claim?.claim?.reassignmentReasonTxt ? claim?.claim?.reassignmentReasonTxt : '-'}}</p>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <!-- Claimant -->
    <div class="print-claim-claimant-block">
      <div class="print-claim-claimant-block-title">
        <h3 class="details-title">Claimant details</h3>
      </div>
      <div class="print-claim-claimant-block-subitems" id="print-section">
        <div class="print-claim-claimant-block-subitems-first-row-subitem-1">
          <p class="field-label">Name</p>
          <p class="field-value">{{claim?.claimant?.name1 ? claim?.claimant?.name1 : '-'}}</p>
        </div>
        <div class="print-claim-claimant-block-subitems-first-row-subitem-2">
          <p class="field-label">Attention</p>
          <p class="field-value">{{claim?.claimant?.name2 ? claim?.claimant?.name2 : '-'}}</p>
        </div>
        <div class="print-claim-claimant-block-subitems-second-row-subitem-1">
          <p class="field-label">Mailing Adress 1</p>
          <p class="field-value">{{claim?.claimant?.addr1 ? claim?.claimant?.addr1 : '-'}}</p>
        </div>
        <div class="print-claim-claimant-block-subitems-second-row-subitem-2">
          <p class="field-label">Mailing Adress 2</p>
          <p class="field-value">{{claim?.claimant?.addr2 ? claim?.claimant?.addr2 : '-'}}</p>
        </div>
        <div class="print-claim-claimant-block-subitems-third-row-subitem-1">
          <p class="field-label">City</p>
          <p class="field-value">{{claim?.claimant?.cityName ? claim?.claimant?.cityName : '-'}}</p>
        </div>
        <div class="print-claim-claimant-block-subitems-third-row-subitem-2">
          <p class="field-label">Province/State</p>
          <p class="field-value">
            {{stateOrCountrySubDivisionCd(claim?.claimant?.stateCd,claim?.claimant?.countrySubdivisionNm ) 
              ? stateOrCountrySubDivisionCd(claim?.claimant?.stateCd,claim?.claimant?.countrySubdivisionNm ) : '-'}}
          </p>
        </div>
        <div class="print-claim-claimant-block-subitems-third-row-subitem-3">
          <p class="field-label">Zip Code By</p>
          <p class="field-value">
            {{getFullZip(claim?.claimant?.postalCd,claim?.claimant?.postalCdExt, xpoUtils) ?
               getFullZip(claim?.claimant?.postalCd,claim?.claimant?.postalCdExt, xpoUtils) : '-'}}
          </p>
        </div>
        <div class="print-claim-claimant-block-subitems-third-row-subitem-4">
          <p class="field-label">Country</p>
          <p class="field-value">{{claim?.claimant?.countryCd ? claim?.claimant?.countryCd : '-'}}</p>
        </div>
        <div class="print-claim-claimant-block-subitems-fourth-row-subitem-1">
          <p class="field-label">Acting on behalf of</p>
          <p class="field-value">{{onBehalf(claim?.asEnteredParty) ? onBehalf(claim?.asEnteredParty) : '-'}}</p>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <!-- Payee -->
    <div class="print-claim-payee-block">
      <div class="print-claim-payee-block-title">
        <h3 class="details-title">Payee details</h3>
      </div>
      <div class="print-claim-payee-block-subitems">
        <div class="print-claim-payee-block-subitems-first-row-subitem-1">
          <p class="field-label">Name</p>
          <p class="field-value">{{claim?.payee?.name1 ? claim?.payee?.name1 : '-'}}</p>
        </div>
        <div class="print-claim-payee-block-subitems-first-row-subitem-2">
          <p class="field-label">Attention</p>
          <p class="field-value">{{claim?.payee?.name2 ? claim?.payee?.name2 : '-'}}</p>
        </div>
        <div class="print-claim-payee-block-subitems-second-row-subitem-1">
          <p class="field-label">Mailing Adress 1</p>
          <p class="field-value">{{claim?.payee?.addr1 ? claim?.payee?.addr1 : '-'}}</p>
        </div>
        <div class="print-claim-payee-block-subitems-second-row-subitem-2">
          <p class="field-label">Mailing Adress 2</p>
          <p class="field-value">{{claim?.payee?.addr2 ? claim?.payee?.addr2 : '-'}}</p>
        </div>
        <div class="print-claim-payee-block-subitems-third-row-subitem-1">
          <p class="field-label">City</p>
          <p class="field-value">{{claim?.payee?.cityName ? claim?.payee?.cityName : '-'}}</p>
        </div>
        <div class="print-claim-payee-block-subitems-third-row-subitem-2">
          <p class="field-label">Province/State</p>
          {{stateOrCountrySubDivisionCd(claim?.payee?.stateCd,claim?.payee?.countrySubdivisionNm ) ?
            stateOrCountrySubDivisionCd(claim?.payee?.stateCd,claim?.payee?.countrySubdivisionNm ) : '-'}}
        </div>
        <div class="print-claim-payee-block-subitems-third-row-subitem-3">
          <p class="field-label">Zip Code By</p>
          {{getFullZip(claim?.payee?.postalCd,claim?.payee?.postalCdExt, xpoUtils) ?
            getFullZip(claim?.payee?.postalCd,claim?.payee?.postalCdExt, xpoUtils) : '-'}}
        </div>
        <div class="print-claim-payee-block-subitems-third-row-subitem-4">
          <p class="field-label">Country</p>
          <p class="field-value">{{claim?.payee?.countryCd ? claim?.payee?.countryCd : '-'}}</p>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="break-page"></div>
    <!-- Commodities -->
    <div class="print-claim-commodities-block" *ngFor="let commodity of claim?.commodity; let i = index">
      <div class="print-claim-commodities-block-title">
        <h3 class="details-title">Commodity #{{i+1}} details</h3>
      </div>
      <div class="print-claim-commodities-block-subitems">
        <div class="print-claim-commodities-block-subitems-first-row-subitem-1">
          <p class="field-label">Type</p>
          <p class="field-value">{{commodity?.claimTypeCd ? commodity?.claimTypeCd : '-'}}</p>
        </div>
        <div class="print-claim-commodities-block-subitems-first-row-subitem-1">
          <p class="field-label">Condition</p>
          <p class="field-value">{{commodity?.conditionTypeCd ? commodity?.conditionTypeCd : '-'}}</p>
        </div>
        <div class="print-claim-commodities-block-subitems-first-row-subitem-1">
          <p class="field-label">Pieces</p>
          <p class="field-value">{{commodity?.piecesCount ? commodity?.piecesCount : '-'}}</p>
        </div>
        <div class="print-claim-commodities-block-subitems-first-row-subitem-1">
          <p class="field-label">Description</p>
          <p class="field-value">{{commodity?.descriptionTxt ? commodity?.descriptionTxt : '-'}}</p>
        </div>
        <div class="print-claim-commodities-block-subitems-first-row-subitem-1">
          <p class="field-label">Model #</p>
          <p class="field-value">{{commodity?.modelOrPartNbr ? commodity?.modelOrPartNbr : '-'}}</p>
        </div>
        <div class="print-claim-commodities-block-subitems-first-row-subitem-1">
          <p class="field-label">Claimed Weight</p>
          <p class="field-value">{{commodity?.commodityWeight ? commodity?.commodityWeight : '-'}}
            {{commodity?.commodityWeightUnitOfMeasure | uppercase}}</p>
        </div>
        <div class="print-claim-commodities-block-subitems-second-row-subitem-1">
          <p class="field-label">Class</p>
          <p class="field-value">{{commodity?.classCd ? (commodity?.classCd | commodityClassCd) : '-'}}</p>
        </div>
        <div class="print-claim-commodities-block-subitems-second-row-subitem-1">
          <p class="field-label">Fak Class</p>
          <p class="field-value"></p>
        </div>
        <div class="print-claim-commodities-block-subitems-second-row-subitem-1">
          <p class="field-label">NMFC</p>
          <p class="field-value"></p>
        </div>
        <div class="print-claim-commodities-block-subitems-second-row-subitem-1">
          <p class="field-label">Claimed Amount</p>
          <p class="field-value">{{commodity?.claimedAmount ? (commodity?.claimedAmount | currency) : '-'}}</p>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="break-page" *ngIf="((i+1)%4)==0"></div>
    </div>
    <mat-divider></mat-divider>
    <div class="break-page" *ngIf="claim?.commodity?.length < 4"></div>
    <!-- Payments -->
    <div class="print-claim-payment-block">
      <div class="print-claim-payment-block-title">
        <h3 class="details-title">Payment details</h3>
      </div>
      <ng-container *ngIf="lastPayment else noPayment">
        <div class="print-claim-payment-block-subitems">
          <div class="print-claim-payment-block-subitems-first-row-subitem-1">
            <p class="field-label">Payee</p>
            <p class="field-value">{{claim?.payee?.name1 ? claim?.payee?.name1 : '-'}}</p>
          </div>
          <div class="print-claim-payment-block-subitems-first-row-subitem-1">
            <p class="field-label">Current Payment Status</p>
            <p class="field-value">
              {{lastPayment?.payment?.statusInternalCd ? (lastPayment.payment.statusInternalCd | paymentStatusInternal) : '-'}}
            </p>
          </div>
          <div class="print-claim-payment-block-subitems-first-row-subitem-1">
            <p class="field-label">Claim Request Amount</p>
            <p class="field-value">{{claim?.claim?.claimedAmount ? (claim?.claim?.claimedAmount | currency) : '-'}}</p>
          </div>
          <div class="print-claim-payment-block-subitems-second-row-subitem-1">
            <p class="field-label">Claim Payout Total</p>
            <p class="field-value">{{claim?.claim?.approvedAmount ? (claim?.claim?.approvedAmount | currency) : '-'}}
            </p>
          </div>
          <div class="print-claim-payment-block-subitems-second-row-subitem-1">
            <p class="field-label">Previous Amounts Paid Total</p>
            <p class="field-value">
              {{setPreviousPayoutAmount(claim) ? (setPreviousPayoutAmount(claim) |currency ) : '-'}}
            </p>
          </div>
          <div class="print-claim-payment-block-subitems-second-row-subitem-1">
            <p class="field-label">New Total Payout Amount</p>
            <p class="field-value">{{updateTotalPayout(claim) ? (updateTotalPayout(claim) | currency) : '-'}}</p>
          </div>
          <div class="print-claim-payment-block-subitems-third-row-subitem-1">
            <p class="field-label">Draft Notes</p>
            <p class="field-value">{{lastPayment?.payment?.draftNote ? lastPayment.payment.draftNote : '-'}}</p>
          </div>
          <div class="print-claim-payment-block-subitems-fourth-row-subitem-1">
            <p class="field-label">Email Notes</p>
            <p class="field-value">{{lastPayment?.payment?.emailNote ? lastPayment.payment.emailNote : '-'}}</p>
          </div>
        </div>
      </ng-container>
      <ng-template #noPayment>
        <div>No Payments For This Claim</div>
      </ng-template>
    </div>
    <mat-divider></mat-divider>
    <!-- Payment history -->
    <div class="print-claim-payment-history-history-block">
      <div class="print-claim-payment-history-block-title">
        <h3 class="details-title">Payment History details</h3>
      </div>
      <ng-container *ngIf="claim?.payments?.length !==0 else noPayments">
        <div *ngFor="let payment of claim?.payments; let i = index">
          <h4>Payment #{{i+1}}</h4>
          <div class="print-claim-payment-history-block-subitems">
            <div class="print-claim-payment-history-block-subitems-first-row-subitem-1">
              <p class="field-label">Payment Request</p>
              <p class="field-value">
                {{payment?.payment?.sentToOfspDateTimeUtc ? (payment?.payment?.sentToOfspDateTimeUtc | date) : '-' }}
              </p>
            </div>
            <div class="print-claim-payment-history-block-subitems-first-row-subitem-1">
              <p class="field-label">Payment Reason</p>
              <p class="field-value">
                {{payment?.payment?.additionalPaymentReasonCd ? (payment?.payment?.additionalPaymentReasonCd | asWords) : '-'}}
              </p>
            </div>
            <div class="print-claim-payment-history-block-subitems-first-row-subitem-1">
              <p class="field-label">Payment Status</p>
              <p class="field-value">
                {{payment?.payment?.statusInternalCd ? (payment?.payment?.statusInternalCd | paymentStatusInternal) : '-'}}
              </p>
            </div>
            <div class="print-claim-payment-history-block-subitems-first-row-subitem-1">
              <p class="field-label">Payment Date</p>
              <p class="field-value">
                {{payment?.payment?.checkDate ? payment?.payment?.checkDate : '-'}}
              </p>
            </div>
            <div class="print-claim-payment-history-block-subitems-first-row-subitem-1">
              <p class="field-label">Paid Amount</p>
              <p class="field-value">
                {{paidAmount(payment?.payment) ? paidAmount(payment?.payment) : '-'}}
              </p>
            </div>
            <div class="print-claim-payment-history-block-subitems-second-row-subitem-1">
              <p class="field-label">Awaiting Approval From</p>
              <p class="field-value">
                {{payment?.payment?.approvalPendingEmployeeDtl ?
                   (payment?.payment?.approvalPendingEmployeeDtl | employeeName) : '-'}}
              </p>
            </div>
            <div class="print-claim-payment-history-block-subitems-second-row-subitem-1">
              <p class="field-label">Final Approval From</p>
              <p class="field-value">
                {{payment?.payment?.approvedByEmployeeDtl ?
                  (payment?.payment?.approvedByEmployeeDtl | employeeName) : '-'}}
              </p>
            </div>
            <div class="print-claim-payment-history-block-subitems-second-row-subitem-1">
              <p class="field-label">Check #</p>
              <p class="field-value">
                {{payment?.payment?.checkNbr ? payment?.payment?.checkNbr : '-'}}
              </p>
            </div>
            <div class="print-claim-payment-history-block-subitems-second-row-subitem-1"
              *ngIf="payment?.payment?.checkCancelDate">
              <p class="field-label">Check Voided</p>
              <p class="field-value">
                {{ payment?.payment?.checkCancelDate ? payment?.payment?.checkCancelDate : '-' }}
              </p>
            </div>
            <div class="print-claim-payment-history-block-subitems-second-row-subitem-1"
              *ngIf="!payment?.payment?.checkCancelDate">
              <p class="field-label">Check Cashed</p>
              <p class="field-value">
                {{payment?.payment?.checkCancelDate ? (payment?.payment?.checkCancelDate | date) : '-'}}
              </p>
            </div>
            <div class="print-claim-payment-history-block-subitems-second-row-subitem-1">
              <p class="field-label">OFS Invoice #</p>
              <p class="field-value">
                {{payment?.payment?.ofspInvoiceNbr ? payment?.payment?.ofspInvoiceNbr : '-'}}
              </p>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div class="break-page" *ngIf="i+1==2 || i+1%4==0"></div>
        </div>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<ng-template #noPayments>
  <div>No Payments For This Claim</div>
</ng-template>