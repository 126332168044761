import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { retry, publishReplay, refCount } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class XpoAngularUtilsService {

  constructor() { }

  isShowingErrorsForFormControl(controlRef: AbstractControl, controlChildKey?: string, errorKey?: string): boolean {
    if (controlChildKey && errorKey) {
      const formField = controlRef.get(controlChildKey);
      return (formField.dirty || formField.touched) && formField.hasError(errorKey);
    } else {
      return controlRef.invalid && (controlRef.dirty || controlRef.touched);
    }
  }

  isShowingWarningsForFormControl(controlRef: AbstractControl, controlChildKey?: string, warningKey?: string): boolean {
    if (controlChildKey && warningKey) {
      const formField = controlRef.get(controlChildKey);
      return (formField.dirty || formField.touched) && formField['warnings'] && formField['warnings'][warningKey];
    } else {
      return controlRef.invalid && (controlRef.dirty || controlRef.touched);
    }
  }

  controlHasValue(controlRef: AbstractControl, controlChildKey?: string) {
    if (controlChildKey) {
      const formField = controlRef.get(controlChildKey);
      return formField && formField.value && formField.value.length > 0;
    } else {
      return false;
    }
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    _.values(formGroup.controls).forEach((control: AbstractControl) => {
      control.markAsTouched();

      const castedControl = control as UntypedFormGroup;
      if (castedControl.controls) {
        _.values(castedControl.controls).forEach((c: UntypedFormGroup) => this.markFormGroupTouched(c));
      }
    });
  }

  getCachedObservable(sourceObservable: Observable<any>): Observable<any> {
    return sourceObservable.pipe(
      retry(3),
      publishReplay(),
      refCount()
    );
  }

  matchesFilterString(filterQuery: string | Array<string>, objectToQuery: Object, tokenDelimiter: RegExp = /\s+/g,
    comparatorFunction: Function = this.matchesFunctionStringDefaultComparator): boolean {
    if (!filterQuery) {
      return true;
    }

    let tokens: Array<string>;

    if (Array.isArray(filterQuery)) {
      tokens = filterQuery;
    } else {
      tokens = filterQuery.split(tokenDelimiter);  // Split the search input string into tokens
    }

    const searchableValues = _.values(objectToQuery).filter(value => typeof value === 'string');

    // If every search token matches *at least* one of the searchable values, then the result is true
    return _.every(tokens, token => _.some(searchableValues, (item) => comparatorFunction(item, token)));
  }

  concatenateZips(zip6: string, zip4: string) {
    if (zip6 && zip4) {
      return `${zip6}-${zip4}`;
    }
    return zip6;
  }

  private matchesFunctionStringDefaultComparator(data: string, token: string) {
    return data.toLowerCase().includes(token);
  }
}
