import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { CreateClaimNoteRqst, Note, ClaimsApiService, CreateClaimNoteResp, GetClaimResp } from '@xpo-ltl/sdk-claims';
import { ActionCd, AuditInfo, ClaimNoteTypeCd, ClaimNoteVisibilityCd } from '@xpo-ltl/sdk-common';
import { SplitComponent } from 'angular-split';
import * as _ from 'lodash';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { FormUtils } from '../../../classes/form-utils.class';
import { NotesFormNames } from '../../../enums/FormControlNames/notes-form-names.enum';
import { AppConstantsService } from '../../../services/app-constants.service';
import { ClaimsRegistrationService } from '../../../services/claims-registration/claims-registration.service';
import { AgeTypes } from '../age-types.enum';
import { NoteTypes } from '../note-types.enum';
import { NotificationService } from '@xpo-ltl/data-api';
import { EmployeeDetailsCacheService } from '../../../services/employee-details-cache.service';
import { GetEmployeeDetailsByEmpIdResp } from '@xpo-ltl/sdk-humanresource';
import { ClaimNoteMaxLengths } from '../../../enums/FormMaxLengths/claim-note-max-lengths.enum';
import { NoteVisibilityCdPipe } from '../../../pipes/note-visibility-cd.pipe';
import { InterfaceEmployeePipe } from '../../../pipes/interface-employee.pipe';
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  ClaimNoteMaxLengths = ClaimNoteMaxLengths;

  noteTypes = [NoteTypes.ClaimsOnly, NoteTypes.XpoLtl];
  visibilityTypes: Array<String | NoteTypes>;
  ageTypes = [AgeTypes.NewestToOldest, AgeTypes.OldestToNewest];

  private notesSubject = new BehaviorSubject<Note[]>([]);
  notes$ = this.notesSubject.asObservable();

  private editorFocusedSubject = new BehaviorSubject<boolean>(false);
  editorFocused$ = this.editorFocusedSubject.asObservable();
  set editorFocused(focused: boolean) {
    this.editorFocusedSubject.next(focused);
  }

  NoteFormNames = NotesFormNames;
  formGroup: UntypedFormGroup;

  private unsubscriber = new Unsubscriber();

  @ViewChild('splitPanel', { static: true })
  splitPanel: SplitComponent;

  @ViewChild('visibility', { static: true })
  visibility: MatSelect;

  @ViewChild('age', { static: true })
  age: MatSelect;

  @Input()
  set tabChanged(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.splitPanel.setVisibleAreaSizes([50, 50]);
      });
    }
  }

  private _notes:Array<Note>

  @Input()
  set notes(value:Array<Note>){
    this._notes = value;
  }

  @Input()
  isWebNotes: boolean;

  @Input() claim: GetClaimResp;
  prevClaim: GetClaimResp;

  ckConfig = {
    allowedContent: true,
    removeEmpty: false,
    removeFormatAttributes: '',
    removeFormatTags: '',
    extraAllowedContent: '*(*);*{*}',
    extraPlugins: 'colorbutton',
    removePlugins : 'elementspath,contextmenu,tabletools,tableselection',
    enterMode : 2,
    disableNativeSpellChecker: false,
    toolbarGroups: [
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
      { name: 'forms', groups: ['forms'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] },
      { name: 'about', groups: ['about'] },
    ],

    removeButtons:
      'Source,Save,Templates,Cut,Undo,Find,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,ImageButton,HiddenField,Button,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Table,Flash,Image,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,Format,Font,FontSize,BGColor,ShowBlocks,Maximize,About,NewPage,Preview,Print,Copy,Paste,PasteText,PasteFromWord,Redo,Replace',
  };


  constructor(
    public constants: AppConstantsService,
    private claimsRegistrationService: ClaimsRegistrationService,
    private formBuilder: UntypedFormBuilder,
    private noteVisibilityCd: NoteVisibilityCdPipe,
    private claimsApiService: ClaimsApiService,
    private notificationService: NotificationService,
    private employeeCacheService: EmployeeDetailsCacheService,
    private employeePipe: InterfaceEmployeePipe,
    private cdt:ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.constants.isReadOnly && !this.isWebNotes) {
      this.visibilityTypes = ['All', NoteTypes.XpoLtl];
    } else if (this.isWebNotes) {
      this.visibilityTypes = [NoteTypes.External];
    } else {
      this.visibilityTypes = ['All', NoteTypes.ClaimsOnly, NoteTypes.XpoLtl];
    }

    this.notesSubject.next(this._notes);
    const typeCd = this.isWebNotes ? NoteTypes.External : NoteTypes.ClaimsOnly;

    this.formGroup = this.formBuilder.group({
      [NotesFormNames.SubjectTxt]: ['', Validators.required],
      [NotesFormNames.TypeCd]: [typeCd, Validators.required],
      [NotesFormNames.NoteTxt]: ['', [Validators.required, Validators.maxLength(ClaimNoteMaxLengths.BodyCharacters)]],
    });

    if (this.constants.isReadOnly) {
      this.formGroup.disable({ emitEvent: false });
    }

    if (this.isWebNotes) {
      this.formGroup.get(NotesFormNames.TypeCd).disable();
    }
  }

  detectChanges(){
    this.cdt.markForCheck();
  }

  ngAfterViewInit(): void {
    this.setWatchers();
    this.loadNotes();
  }

  ngOnChanges() {
    if (this.claim !== this.prevClaim) {
      const sameClaimId = _.get(this.claim, 'claim.claimId') === _.get(this.prevClaim, 'claim.claimId');
      if (!!this.prevClaim && !sameClaimId) {
        // Reset
        this.clearFormFields();
        this.resetFilters();
        this.loadNotes();
      }
      this.prevClaim = this.claim;
    }
  }

  private resetFilters() {
    if (!this.isWebNotes && this.visibility) {
      this.visibility.value = null;
    }
    this.age.value = null;
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  private setWatchers(): void {
    if (this.visibility) {
      this.visibility.valueChange.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
        this.loadNotes();
      });
    }

    this.age.valueChange.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      this.loadNotes();
    });
  }

  addNoteClicked(): void {
    if (this.constants.isReadOnly) {
      return;
    }
    const newNote = new Note();
    newNote.auditInfo = new AuditInfo();
    newNote.auditInfo.createdById = this.constants.user.employeeId;
    newNote.auditInfo.createdTimestamp = new Date();
    newNote.listActionCd = ActionCd.ADD;
    newNote.noteTxt = this.formGroup.get(NotesFormNames.NoteTxt).value;
    newNote.subjectTxt = this.formGroup.get(NotesFormNames.SubjectTxt).value;
    newNote.typeCd = ClaimNoteTypeCd.EXAMINER_USE;
    newNote.visibilityCd = this.noteVisibilityCd.transformText(this.formGroup.get(NotesFormNames.TypeCd).value);
    newNote.authorName = _.get(this.constants.user, 'userId', undefined);

    const request = new CreateClaimNoteRqst();
    request.claimId = this.claimsRegistrationService.claim.claim.claimId;
    request.note = newNote;

    // If auditInfo is set, our request is HR GetEmployeeDetails and CreateClaimNote, else just CreateClaimNote
    const observableRequest: Observable<
      Array<GetEmployeeDetailsByEmpIdResp | CreateClaimNoteResp> | CreateClaimNoteResp
    > = newNote.auditInfo.createdById
      ? forkJoin(
          this.employeeCacheService.request({ employeeId: newNote.auditInfo.createdById }),
          this.claimsApiService.createClaimNote(request)
        ).pipe(take(1))
      : this.claimsApiService.createClaimNote(request).pipe(take(1));
    // Handle the request, typing hell
    observableRequest.subscribe(
      (data: Array<GetEmployeeDetailsByEmpIdResp | CreateClaimNoteResp> | CreateClaimNoteResp) => {
        // Grab the response from createClaimNote, always will be there
        const response = _.isArray(data) ? <CreateClaimNoteResp>data[1] : data;

        // If falsey, we're done$
        if (response) {
          this.claimsRegistrationService.checkClaimRecordVersionNbrUpdate();

          // Grab the employee data if set
          const employee = _.isArray(data) ? (<GetEmployeeDetailsByEmpIdResp>data[0]).employee : undefined;

          // Set noteId
          newNote.noteId = response['noteId'];

          // Set employeeDtl after transform
          newNote.createByEmployeeDtl = this.employeePipe.transform(employee);

          // Set no action otherwise will get duplicates
          newNote.listActionCd = ActionCd.NO_ACTION;

          // Make available to frontend
          if (!this._notes) {
            this._notes = [newNote];
          } else {
            this._notes.push(newNote);
          }

          // Let them know we're done$
          this.notificationService.showSnackBarMessage('Note Added', { durationInMillis: 3000, status: 'SUCCESS' });

          // Reset
          this.clearFormFields();
          this.loadNotes();
        }
      }
    );
  }

  private clearFormFields(): void {
    FormUtils.untouchAllControls(this.formGroup.get(NotesFormNames.SubjectTxt));
    FormUtils.untouchAllControls(this.formGroup.get(NotesFormNames.TypeCd));
    FormUtils.untouchAllControls(this.formGroup.get(NotesFormNames.NoteTxt));

    this.formGroup.get(NotesFormNames.SubjectTxt).setValue('');
    this.formGroup.get(NotesFormNames.TypeCd).setValue(this.isWebNotes ? NoteTypes.External : NoteTypes.ClaimsOnly);
    this.formGroup.get(NotesFormNames.NoteTxt).setValue('');
  }

  private loadNotes(): void {
    const filterFunc = (note: Note) => {
      const noteSearchKeyMap = {
        [ClaimNoteVisibilityCd.EXTERNAL]: NoteTypes.External,
        [ClaimNoteVisibilityCd.CLAIMS_DEPT]: NoteTypes.ClaimsOnly,
        [ClaimNoteVisibilityCd.XPO]: NoteTypes.XpoLtl,
      };

      return (
        !_.get(this.visibility, 'value') ||
        this.visibility.value === 'All' ||
        noteSearchKeyMap[note.visibilityCd] === this.visibility.value
      );
    };

    if (!this.age.value || this.age.value === AgeTypes.NewestToOldest) {
      this.notesSubject.next(
        _.sortBy(_.filter(this._notes, filterFunc), (note: Note) => note.auditInfo.createdTimestamp).reverse()
      );
    } else {
      this.notesSubject.next(
        _.sortBy(_.filter(this._notes, filterFunc), (note: Note) => note.auditInfo.createdTimestamp)
      );
    }
  }
}
