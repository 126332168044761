<div class="declination-email">

  <div class="declination-email__header">
    <div class="declination-email__header-title">Declination Letter</div>
  </div>

  <mat-dialog-content class="declination-email__content">
    <app-emails
      [config]="emailsConfig"
      [claimsRegistrationFormGroup]="claimsRegistrationFormGroup"
      [claim]="claim$ | async"
      [dmsDocList$]="dmsDocList$ | async"
      [isEscalatingOrDeclining]="true"
      [downloadDmsAttachmentFn]="downloadDeclinationEmailAttachmentsFn"
      ></app-emails>
  </mat-dialog-content>

  <mat-error>
    <div *ngIf="!(validRecipient$ | async)" class="declination-email__print-warning">
        There is no email address for this Claimant. Declination Letter must be printed and mailed. Document must be scanned and stored in DMS.
    </div>
  </mat-error>

  <mat-dialog-actions class="declination-email__actionbar">
    <button mat-button color="primary" [mat-dialog-close]="DeclinationEmailResults.Canceled" [disabled]="(isSendingEmail$ | async)">CANCEL</button>
    <button mat-button color="primary" [mat-dialog-close]="DeclinationEmailResults.DeclineWithoutEmail" [disabled]="(isSendingEmail$ | async)">DECLINE WITHOUT EMAIL</button>
    <button mat-raised-button color="primary" (click)="handleSend()" [disabled]="!(emailReady$ | async) || (isSendingEmail$ | async)">SEND</button>
  </mat-dialog-actions>
</div>
