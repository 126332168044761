import { Directive, Input } from '@angular/core';
import { AbstractControl, NgModel, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { DatePipe } from '@angular/common';

export function xpoNotLessThanValueValidator(xpoNotLessThanValue: NgModel): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (xpoNotLessThanValue && xpoNotLessThanValue.value && control.value) {
      const otherValue = (<string>xpoNotLessThanValue.value).replace('$', '') || '0';
      const thisValue = (<string>control.value).replace('$', '') || '0';
      const validator = control.validator;
      control.clearValidators();
      xpoNotLessThanValue.control.updateValueAndValidity({
        onlySelf: true,
      });
      control.validator = validator;
      if (+thisValue < +otherValue) {
        return {
          lessThanOther: `Less than ${otherValue}`,
        };
      }
    }
    return undefined;
  };
}

@Directive({
  selector: '[xpoNotLessThanValue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: XpoNotLessThanValueValidatorDirective,
      multi: true,
    },
  ],
})
export class XpoNotLessThanValueValidatorDirective implements Validator {
  // tslint:disable-next-line:no-input-rename
  @Input('xpoNotLessThanValue')
  xpoNotLessThanValue: NgModel;

  validate(control: AbstractControl): { [key: string]: any } {
    return xpoNotLessThanValueValidator(this.xpoNotLessThanValue)(control);
  }
}
