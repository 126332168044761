import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ScrollHelper } from '../../core/classes/scroll-helper';
import * as _ from 'lodash';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { NavigationBarLabelType, ClaimsRegistrationFormNames, WindowKeyListener } from '../../enums';
// @ts-ignore
import { NavBarButton } from '../../interfaces/nav-bar-button';
// import { NavBarPanelConfig } from '../../interfaces/nav-bar-panel-config';
import { takeUntil, map } from 'rxjs/operators';
import { XpoBadgeConfig } from '../../interfaces/xpo-badge-config';
import { NavigatorPanel } from '../../classes/navigatorPanel';

/**
 * Configurtation information for a Navigation Panel
 */
export interface NavBarPanelConfig {
  label: NavigationBarLabelType;
  altKey?: string;
  insertAtIndex?: number;
}

export class NavBarPanel implements NavBarPanelConfig {
  label;
  style;
  keyShortcut;
  macKeyShortcut;
  panelName;
  insertAtIndex;

  constructor(public elementRef: ElementRef, label: NavigationBarLabelType, altKey: string) {
    this.keyShortcut = altKey ? altKey.toUpperCase() : undefined;

    const underline = (t) => `<span class="navigation-bar__container-underline">${t}</span>`;

    this.panelName = label;
    // if there is an altKey, then update the label to show the first
    // instance of that character as underlined
    if (this.keyShortcut) {
      const index = label.toUpperCase().indexOf(this.keyShortcut);
      if (index >= 0) {
        if (index === 0) {
          this.label = `${underline(label[0])}${label.slice(index + 1)}`;
        } else if (index === label.length - 1) {
          this.label = `${label.slice(0, index)}${underline(label[index])}`;
        } else {
          this.label = `${label.slice(0, index)}${underline(label[index])}${label.slice(index + 1)}`;
        }
      }
    }
  }
}

export interface NavBarButton {
  tooltip: string;
  icon: string;
  onClick: Function;
  disabled: boolean;
}

export interface NavMenuButton {
  menuItem: string;
  onClick: Function;
}

export interface MenuButtonsConfig {
  menuButtons: NavMenuButton[];
  moreButtonConfig?: XpoBadgeConfig;
}

/**
 * Manage NavigationBar menu and icons
 */
@Injectable({ providedIn: 'root' })
export class NavigationBarService implements OnDestroy {
  private scrolledSubject = new Subject<Event>();
  public scrolled$ = this.scrolledSubject.asObservable();

  private panelsSubject = new BehaviorSubject<NavBarPanel[]>([]);
  public panels$ = this.panelsSubject.asObservable();
  public activePanel: NavBarPanel;

  private buttonsSubject = new BehaviorSubject<NavBarButton[]>([]);
  public buttons$ = this.buttonsSubject.asObservable();

  private menuButtonsSubject = new BehaviorSubject<MenuButtonsConfig>({} as MenuButtonsConfig);
  public menuButtons$ = this.menuButtonsSubject.asObservable();

  private isShownSubject = new BehaviorSubject<boolean>(false);
  public isShown$ = this.isShownSubject.asObservable();

  private unsubscriber: Unsubscriber = new Unsubscriber();

  constructor() {
    combineLatest([this.panels$, this.buttons$, this.menuButtons$])
      .pipe(
        takeUntil(this.unsubscriber.done$),
        map(([panels, buttons, menuButtons]) => [panels, buttons, menuButtons.menuButtons])
      )
      .subscribe(([panels, buttons, menuButtons]) => {
        const isAllEmpty = _.isEmpty(panels) && _.isEmpty(buttons) && _.isEmpty(menuButtons);
        if (!isAllEmpty) {
          this.showNavBar();
        } else {
          this.hideNavBar();
        }
      });
  }

  ngOnDestroy() {
    this.clear();
    this.unsubscriber.complete();

    // stop emitting scroll events
    this.scrolledSubject.complete();
  }

  /**
   * show the navigation bar
   */
  public showNavBar() {
    this.isShownSubject.next(true);
  }

  /**
   * hide the navigation bar
   */
  public hideNavBar() {
    this.isShownSubject.next(false);
  }

  /**
   * Clear the panels
   */
  public clear() {
    this.activePanel = undefined;
    this.panelsSubject.next([]);
    this.buttonsSubject.next([]);
    this.menuButtonsSubject.next({ menuButtons: [] });
  }

  public setButtons(buttons: NavBarButton[]) {
    this.buttonsSubject.next(buttons);
  }

  public setMenuButtons(menuButtons: NavMenuButton[], moreButtonConfig?: XpoBadgeConfig) {
    this.menuButtonsSubject.next({ menuButtons, moreButtonConfig } as MenuButtonsConfig);
  }

  public addPanel(panel: ElementRef, config: NavBarPanelConfig) {
    let panels;

    const panelExists = _.some(this.panelsSubject.value, (currPanel) => currPanel.panelName === config.label);
    if (!panelExists) {
      panels = config.insertAtIndex
        ? [
            ...this.panelsSubject.value.slice(0, config.insertAtIndex),
            new NavBarPanel(panel, config.label, config.altKey),
            ...this.panelsSubject.value.slice(config.insertAtIndex),
          ]
        : [...this.panelsSubject.value, new NavBarPanel(panel, config.label, config.altKey)];

      this.panelsSubject.next(panels);

      if (panels.length === 1) {
        // auto-select first panel added
        this.activePanel = panels[0];
      }
    }
  }

  public removePanel(panelName: NavigationBarLabelType): void {
    const panels = this.panelsSubject.value.filter((navPanel) => navPanel.panelName !== panelName);
    this.panelsSubject.next(panels);
    if (panels.length === 1) {
      // auto-select first panel added
      this.activePanel = panels[0];
    }
  }

  public getPanel(panelName: NavigationBarLabelType): NavBarPanel {
    return _.find(this.panelsSubject.value, (panel) => panel.panelName === panelName);
  }

  public showPanel(panel: NavBarPanel) {
    if (panel) {
      this.activePanel = panel;
      ScrollHelper.scrollToElement(panel.elementRef);
    }
  }

  public showPanelWithName(panelName: NavigationBarLabelType) {
    const panel = this.getPanel(panelName);
    if (panel) {
      this.showPanel(panel);
    }
  }
  public setPanelLabelError(panelName: NavigationBarLabelType) {
    const panel = this.getPanel(panelName);
    if (panel) {
      panel.style = '2px solid red';
    }
  }
  public clearPanelLabelStyle(panelName: NavigationBarLabelType) {
    const panel = this.getPanel(panelName);
    if (panel) {
      panel.style = '';
    }
  }
  public clearAllPanelsStyles(panelNames: NavigatorPanel[]) {
    panelNames
      .filter((p) => p.label !== NavigationBarLabelType.Undefined)
      .map((panel) => {
        const validPanel = this.getPanel(panel.label);
        validPanel.style = '';
      });
  }

  public handleScrollEvent(event) {
    this.scrolledSubject.next(event);

    // The padding at the bottom of the container is enough to have the top of the last pannel
    // stop at the start of the nav bar panel.
    // This is needed so the last panel can scroll up close to the top and get marked active on scroll

    // select the panel closest to the top of the screen.
    const scrollPos = _.get(event.currentTarget, 'scrollTop') + 100; // TODO - offset from top to get some breathing room.
    _.forEach(this.panelsSubject.value, (panel: NavBarPanel) => {
      const panelTop = panel.elementRef.nativeElement.offsetTop;
      const panelBottom = panelTop + panel.elementRef.nativeElement.offsetHeight;
      if (scrollPos >= panelTop && scrollPos <= panelBottom) {
        this.activePanel = panel;
      }
    });
  }

  public handleKeyDownEvent(event: KeyboardEvent) {
    if (event.altKey) {
      this.panelsSubject.value.forEach((panel: NavBarPanel) => {
        // if the key equals one of the panel hotkeys, then select that panel
        if (panel.keyShortcut === event.key.toUpperCase()) {
          this.showPanel(panel);
          return;
        }
      });
    }
  }

  public handleMenuButtonKeyShorcut(event: KeyboardEvent, buttons: ElementRef[], moreButton: ElementRef): boolean {
    if (event.altKey) {
      if (WindowKeyListener.KeyO === event.code) {
        moreButton.nativeElement.click();
        return true;
      } else {
        for (let i = 0; i < this.buttonsSubject.value.length; i++) {
          const button = this.buttonsSubject.value[i];
          if (!button.disabled && button.altKey === event.key.toUpperCase()) {
            buttons[i].nativeElement.click();
            event.preventDefault();
            event.stopPropagation();
            return true;
          }
        }
      }
    }
    return false;
  }

  public mapFormNameToNavigationBarLabel(panelFormName, hasClaim: boolean) {
    switch (panelFormName) {
      case ClaimsRegistrationFormNames.Header:
        return NavigationBarLabelType.ClaimHeader;
      case ClaimsRegistrationFormNames.AsEnteredClaimant:
      case ClaimsRegistrationFormNames.AsEnteredPayee:
        return NavigationBarLabelType.ClaimantPayee;
      case ClaimsRegistrationFormNames.Commodities:
        return NavigationBarLabelType.CommodityLineItems;
      case ClaimsRegistrationFormNames.RelatedPros:
        return hasClaim ? NavigationBarLabelType.RelatedPros : NavigationBarLabelType.RelatedProsIndicators;
      case ClaimsRegistrationFormNames.Notes:
        return NavigationBarLabelType.Notes;
      default:
        return NavigationBarLabelType.Undefined;
    }
  }
}
