export enum PaymentState {
  ReadOnly = 'readOnly',
  WaitingForApproval = 'waitingForApproval',
  Approving = 'approving',
  ApprovingAfterEscalation = 'approvingAfterEscalation',
  Declining = 'declining',
  DecliningAfterEscalation = 'decliningAfterEscalation',
  WaitingForNewPayment = 'waitingForNewPayment',
  PendingApprovalFormReview = 'pendingApprovalFormReview',
  PendingDeclinationFormReview = 'pendingDeclinationFormReview',
  EnteringNewPayment = 'enteringNewPayment',
  EnteringNewRebuttalPayment = 'enteringNewRebuttalPayment',
  PendingRebuttalApprovalFormReview = 'pendingRebuttalApprovalFormReview',
  WaitingForRebuttalApproval = 'waitingForRebuttalApproval',
}
