import { Directive, HostListener, Injector, OnInit } from '@angular/core';
import { MatLegacyDialogContainer as MatDialogContainer, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[matDialogDraggableTitle]',
})
export class DialogDraggableTitleDirective implements OnInit {
  private _subscription: Subscription;
  private mouseStart: Position;
  private mouseDelta: Position;
  private offset: Position;
  private matDialogRef: MatDialogRef<any>;
  private container: MatDialogContainer;

  constructor(private injector: Injector) {
    try {
      this.matDialogRef = injector.get(MatDialogRef);
      this.container = injector.get(MatDialogContainer);
    } catch {
      // ignore
    }
  }

  ngOnInit() {
    this.offset = this._getOffset();
  }

  private hasDialogData(): boolean {
    return !!this.matDialogRef && !!this.container;
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (!this.hasDialogData()) {
      return;
    }
    this.mouseStart = { x: event.pageX, y: event.pageY };
    const mouseup$ = fromEvent(document, 'mouseup');
    this._subscription = mouseup$.subscribe(() => this.onMouseup());
    const mousemove$ = fromEvent(document, 'mousemove')
      .pipe(takeUntil(mouseup$))
      .subscribe((e: MouseEvent) => this.onMouseMove(e));
    this._subscription.add(mousemove$);
  }

  onMouseMove(event: MouseEvent) {
    if (!this.hasDialogData()) {
      return;
    }
    this.mouseDelta = { x: event.pageX - this.mouseStart.x, y: event.pageY - this.mouseStart.y };
    this._updatePosition(this.offset.y + this.mouseDelta.y, this.offset.x + this.mouseDelta.x);
  }

  onMouseup() {
    if (!this.hasDialogData()) {
      return;
    }
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = undefined;
    }
    if (this.mouseDelta) {
      this.offset.x += this.mouseDelta.x;
      this.offset.y += this.mouseDelta.y;
    }
  }

  private _updatePosition(top: number, left: number) {
    this.matDialogRef.updatePosition({
      top: `${top}px`,
      left: `${left}px`,
    });
  }

  private _getOffset(): Position {
    if (!!this.container['_elementRef'] && !!this.container['_elementRef'].nativeElement) {
      const box = this.container['_elementRef'].nativeElement.getBoundingClientRect();
      return {
        x: box.left + pageXOffset,
        y: box.top + pageYOffset,
      };
    }
  }
}

export interface Position {
  x: number;
  y: number;
}
