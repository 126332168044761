import { AbstractControl, ValidatorFn, UntypedFormArray } from '@angular/forms';
import * as _ from 'lodash';
import { ServiceCenterChargeabilityFormNames, ServiceCenterChargeabilityOtherSicsFormNames } from '../enums/FormControlNames/service-center-chargeability-form-names.enum';

export function serviceCentersTotalPercentageValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control || !control.value) {
      return null;
    }

    const totalAloc = serviceCentersTotalPctAllocation(control);
    if (_.round(totalAloc, 2) > 100) {
      return { max: true };
    } else if (_.round(totalAloc, 2) !== 100) {
      return { not100: true };
    } else {
      return null;
    }
  };
}
export function serviceCentersTotalPctAllocation(formGroup: AbstractControl): number {
  let totalPctAloc = 0;

  totalPctAloc += +_.get(formGroup.get(ServiceCenterChargeabilityFormNames.OriginPctAloc), 'value', 0);
  totalPctAloc += +_.get(formGroup.get(ServiceCenterChargeabilityFormNames.DestPctAloc), 'value', 0);

  const loadAtLocs = formGroup.get(ServiceCenterChargeabilityFormNames.LoadAtSics) as UntypedFormArray;
  if (loadAtLocs && loadAtLocs.controls && loadAtLocs.controls.length > 0) {
    loadAtLocs.controls.forEach(loadAtLoc => {
      if (loadAtLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.PctAloc).value) {
        totalPctAloc += +loadAtLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.PctAloc).value;
      }
    });
  }

  const otherLocs = formGroup.get(ServiceCenterChargeabilityFormNames.OtherSics) as UntypedFormArray;
  if (otherLocs && otherLocs.controls && otherLocs.controls.length > 0) {
    otherLocs.controls.forEach(otherLoc => {
      if (otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.PctAloc).value) {
        totalPctAloc += +otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.PctAloc).value;
      }
    });
  }

  return totalPctAloc;
}
