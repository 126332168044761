import { GetClaimResp, ChargeableServiceCenter } from '@xpo-ltl/sdk-claims';
import * as _ from 'lodash';
import { UnitConversion } from '../../classes';
import { PaymentStatusInternalCd, ClaimInternalStatusCd, ClaimNoteTypeCd } from '@xpo-ltl/sdk-common';

// This is a non-injectable service
export class ServiceCenterChargeabilityService {
  private loadAtSicsUnfiltered: string[];

  constructor(
    private originSvcCenterSic: string,
    private destSvcCenterSic: string,
    private claim: GetClaimResp,
    private chargeableServiceCenters: ChargeableServiceCenter[],
    private loadAtSics: string[]
  ) {
    this.loadAtSicsUnfiltered = this.loadAtSics;
    this.loadAtSics = this.getFilteredLoadAtSics();
  }

  public getCurrentlySavedOriginDestAlocationPct(): [number, number] {
    const originPctAloc = this.getAlocPct(this.originSvcCenterSic);
    const destPctAloc = this.getAlocPct(this.destSvcCenterSic);
    return [originPctAloc, destPctAloc];
  }

  /**
   * Returns the chargePercentage if it exists.
   * If sic param is undefined or sic is not found in chargeableServiceCenters, then undefined is returned
   *
   * @param sic
   */
  private getAlocPct(sic: string): number {
    const item = !!sic ? this.chargeableServiceCenters.find(x => x.sicCd.toUpperCase() === sic.toUpperCase()) : undefined;
    return item ? item.chargePercentage : undefined;
  }

  private isSameOriginDestSic() {
    const hasOriginDestSic: boolean = !!this.originSvcCenterSic && !!this.destSvcCenterSic;
    const isSameOriginDestSic = hasOriginDestSic && this.originSvcCenterSic === this.destSvcCenterSic;
    return isSameOriginDestSic;
  }

  public getCurrentlySavedLoadAtAlocationPct(): number[] {
    const loadAtSicsPctAloc = [];
    // get current aloc. percentages for 'Load At SICs'
    this.loadAtSics.forEach((loadAtSic, i) => {
      const loadAtSicPctAloc = this.getAlocPct(loadAtSic);
      loadAtSicsPctAloc.push(loadAtSicPctAloc);
    });
    return loadAtSicsPctAloc;
  }

  public getEquallyDistributedChargeability(): [number, number, number[]] {
    const loadAtSicsCount = _.size(this.loadAtSicsUnfiltered);
    const roundValue = value => UnitConversion.round(_.toNumber(value), 2);

    // handle case when we have no shipment info
    if (!this.originSvcCenterSic || !this.destSvcCenterSic) {
      return [0, 0, _.fill(Array(loadAtSicsCount), 0)];
    }

    const splitEquallyPct = roundValue(100 / loadAtSicsCount);
    const originPctAloc = this.loadAtSicsUnfiltered.some(scc => scc === this.originSvcCenterSic) ? splitEquallyPct : 0;
    const destPctAloc = this.loadAtSicsUnfiltered.some(scc => scc === this.destSvcCenterSic) ? splitEquallyPct : 0;
    // fill the array repeating splitEquallyPct value
    const loadAtSicsPctAloc = _.fill(Array(loadAtSicsCount), splitEquallyPct);

    // since we use two decimal positions, some minor decimal differences need to be taken care of
    // for example: 100/3 = 33.33*3 = 99.99       -> strategy: originPctAloc gets +0.01
    //              100/6 = 16.67*6 = 100.02      -> strategy: destPctAloc gets -0.01, last loadAtSic gets -0.01
    //              100/12 = 8.33*12 = 99.96      -> strategy: originPctAloc gets +0.01, first/second/third loadAtSICs get +0.01 each
    let differencePoints = _.round((100 - splitEquallyPct * loadAtSicsCount) * 100);
    if (differencePoints > 0) {
      for (let i = 0; i < differencePoints; i++) {
        loadAtSicsPctAloc[i] = roundValue(loadAtSicsPctAloc[i] + 0.01);
      }
    } else if (differencePoints < 0) {
      differencePoints = differencePoints * -1; // make positive

      loadAtSicsPctAloc.reverse();
      for (let i = 0; i < differencePoints; i++) {
        loadAtSicsPctAloc[i] = roundValue(loadAtSicsPctAloc[i] - 0.01);
      }
      loadAtSicsPctAloc.reverse();
    }

    return [originPctAloc, destPctAloc, loadAtSicsPctAloc];
  }

  /**
   * Returns filtered loadAt SICs (without origin and destination svc)
   */
  public getFilteredLoadAtSics() {
    const originSvcUpperCase = this.originSvcCenterSic ? this.originSvcCenterSic.toUpperCase() : undefined;
    const destSvcUpperCase = this.destSvcCenterSic ? this.destSvcCenterSic.toUpperCase() : undefined;

    return this.loadAtSics.filter(loadAtSic => loadAtSic.toUpperCase() !== originSvcUpperCase && loadAtSic.toUpperCase() !== destSvcUpperCase);
  }

  public shouldKeepExistingChargeability(): boolean {
    return this.isClaimDecisionMade() || this.hasChargeabilityReason() || this.newChargeabilityPreviouslySaved();
  }

  /**
   * Returns true if the claim has been already Declined/Approved
   * (with the escalation process finished for the latter case).
   */
  private isClaimDecisionMade() {
    const payments = _.get(this.claim, 'payments', []);
    const approvedOrDeclinedPayments = _.filter(payments, p => p.payment.statusInternalCd !== PaymentStatusInternalCd.PAYMENT_REQUESTED);
    const isClaimStatusDeclined = _.get(this.claim, 'claim.internalStatusCd') === ClaimInternalStatusCd.DECLINED;
    const claimHasRebuttal = _.get(this.claim, 'claim.rebuttalInd', false);
    return _.size(approvedOrDeclinedPayments) > 0 || isClaimStatusDeclined || claimHasRebuttal;
  }

  /**
   * Return true if the claim has a reason code.
   * If it has one, it means that the default chargeability was changed explicitly at some point.
   */
  private hasChargeabilityReason(): boolean {
    return this.chargeableServiceCenters.some(x => !!x.reasonCd);
  }

  /**
   * Detect case in which new chargeability was previously saved, but loadAtSics/shipment info failed to load
   */
  private newChargeabilityPreviouslySaved(): boolean {
    const serviceCenters = this.chargeableServiceCenters.filter(x => x.chargePercentage > 0);
    return _.isEmpty(this.loadAtSics) && !this.hasChargeabilityReason() && _.size(serviceCenters) > 2;
  }
}
