<div *ngIf="show" class="notification">
  <div class="notification__content"
       fxLayout="column"
       fxLayoutAlign="center center">
    <mat-progress-spinner *ngIf="showProgressSpinner"
                          mode="indeterminate"
                          [strokeWidth]="5"
                          [diameter]="60"
                          class="notification__spinner">
    </mat-progress-spinner>
    <div class="notification__message">{{message}}
    </div>
  </div>
</div>
