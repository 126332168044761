import { Pipe, PipeTransform } from '@angular/core';
import { ClaimEventTypeCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'claimEventTypeCd',
})
export class ClaimEventTypeCdPipe implements PipeTransform {
  transform(value: ClaimEventTypeCd): string {
    switch (value) {
      case ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_CEO:
      case ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_CLAIMS_MANAGER:
      case ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_DIRECTOR_OF_CLAIMS:
      case ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_EXAMINER:
      case ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_PRESIDENT:
      case ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_RVP:
      case ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_RVP_SALES:
      case ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_SVP_SALES:
      case ClaimEventTypeCd.CLAIM_APPROVAL_PENDING_SVP_OPS:
        return 'Claim status updated';

      case ClaimEventTypeCd.CLAIM_APPROVED:
        return 'Claim is approved';

      case ClaimEventTypeCd.CLAIM_CANCELLED_BY_CUSTOMER:
        return 'Claim is Cancelled by Customer';

      case ClaimEventTypeCd.CLAIM_DECLINED:
        return 'Claim is declined';

      case ClaimEventTypeCd.CLAIM_FIELDS_UPDATED_ONLY:
        return 'Claim fields updated';

      case ClaimEventTypeCd.CLAIM_REASSIGNED:
        return 'Claim reassigned';

      case ClaimEventTypeCd.CLAIM_SAVED_AS_DRAFT:
        return 'Claim saved as draft';

      case ClaimEventTypeCd.CLAIM_SUBMITTED:
        return 'Claim is submitted';

      case ClaimEventTypeCd.CLAIM_UNDER_REVIEW:
        return 'Claim updated to "Under Review" status';

      case ClaimEventTypeCd.CLAIMANT_ADDED:
        return 'Claimant added';

      case ClaimEventTypeCd.CLAIMANT_CHANGED:
        return 'Claimant Changed';

      case ClaimEventTypeCd.CONTACT_CHANGED:
        return 'Contact changed';

      case ClaimEventTypeCd.DMS_DOCUMENT_ADDED:
        return 'Document Added in DMS';

      case ClaimEventTypeCd.INCOMING_EMAIL:
        return 'New email received';

      case ClaimEventTypeCd.NOTE_ADDED:
        return 'New note added';

      case ClaimEventTypeCd.PAYEE_ADDED:
        return 'Payee Added';

      case ClaimEventTypeCd.PAYEE_CHANGED:
        return 'Payee changed';

      case ClaimEventTypeCd.PAYMENT_CHECK_CANCELLED:
        return 'Check is cancelled';

      case ClaimEventTypeCd.PAYMENT_CHECK_CASHED:
        return 'Check is cashed';

      case ClaimEventTypeCd.PAYMENT_CHECK_ISSUED:
        return 'Check is issued';

      case ClaimEventTypeCd.PAYMENT_ON_HOLD:
        return 'Payment is on hold';

      case ClaimEventTypeCd.PAYMENT_PENDING_TRANSMISSIONTO_AP:
        return 'Payment status "Pending Transmission"';

      case ClaimEventTypeCd.PAYMENT_SENT_TO_AP:
        return 'Payment is sent to AP';

      case ClaimEventTypeCd.PAYMENT_VOIDED:
        return 'Payment is voided';

      case ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_CEO:
      case ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_CLAIMS_MANAGER:
      case ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_DIRECTOR_OF_CLAIMS:
      case ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_EXAMINER:
      case ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_PRESIDENT:
      case ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_RVP:
      case ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_RVP_SALES:
      case ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_SVP_SALES:
      case ClaimEventTypeCd.REBUTTAL_APPROVAL_PENDING_SVP_OPS:
        return 'Rebuttal status updated';

      case ClaimEventTypeCd.REBUTTAL_APPROVED:
        return 'Rebuttal is approved';

      case ClaimEventTypeCd.REBUTTAL_CANCELLED_BY_CUSTOMER:
        return 'Rebuttal cancelled by customer';

      case ClaimEventTypeCd.REBUTTAL_DECLINED:
        return 'Rebuttal is declined';

      case ClaimEventTypeCd.REBUTTAL_RECEIVED:
        return 'Rebuttal is received';

      case ClaimEventTypeCd.REBUTTAL_UNDER_REVIEW:
        return 'Rebuttal Status updated to "Under Review"';

      case ClaimEventTypeCd.SUBROGATION_PAYMENT_APPROVED:
        return 'Subrogation payment approved';

      case ClaimEventTypeCd.PAYMENT_DECLINED:
        return 'Payment declined';

      case ClaimEventTypeCd.SUBROGATION_PAYMENT_DECLINED:
        return 'Subrogation payment declined';

      case ClaimEventTypeCd.SUBROGATION_PAYMENT_RECEIVED:
        return 'Subrogation payment received';

      case ClaimEventTypeCd.SUBROGATION_SUBMITTED_TO_CARRIER:
        return 'Subrogation submitted to carrier';

      case ClaimEventTypeCd.CLAIM_VIEWED:
        return 'Claim viewed';

      default:
        return value || 'Unknown';
    }
  }
}
