import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { InterfaceEmployee } from '@xpo-ltl/sdk-claims';
import { Employee } from '@xpo-ltl/sdk-humanresource';

@Pipe({
  name: 'employeeIf',
})
export class InterfaceEmployeePipe implements PipeTransform {
  protected readonly employeeMap = {
    employeeId: 'basicInfo.employeeId',
    firstName: 'basicInfo.firstName',
    middleName: 'basicInfo.middleInitial',
    lastName: 'basicInfo.lastName',
    jobDescription: 'employments[0].jobPositions[0].jobDescription',
    domicileSicCd: 'basicInfo.deptSic',
    supervisorEmployeeId: 'supervisorEmployeeId',
    businessPhoneTxt: 'phones[0].phoneNbr',
    auditInfo: 'auditInfo',
  };

  public transform(i: Employee): InterfaceEmployee {
    if (!!i) {
      const e = new InterfaceEmployee();
      _.each(this.employeeMap, (v: string, k: string) => _.set(e, k, _.get(i, v, undefined)));
      return e;
    }
    return undefined;
  }
}
