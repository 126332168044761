import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export function disallowNumericsOnlyValidatorDirective(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!!control && !!control.value) {
      return isNaN(control.value) ? null : { numericOnly: true };
    }
    return null;
  };
}

@Directive({
  selector: '[disallowNumericsOnlyValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DisallowNumericsOnlyValidatorDirective, multi: true }],
})
export class DisallowNumericsOnlyValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } {
    return disallowNumericsOnlyValidatorDirective()(control);
  }
}
