export enum RegistrationHeaderFormNames {
  ProNumber = 'ProNumber',
  ClaimReceivedDate = 'ClaimReceivedDate',
  ClaimType = 'ClaimType',
  CustomerReferenceNumber = 'CustomerReferenceNumber',
  FilingMethod = 'FilingMethod',
  ClaimRequestAmount = 'ClaimRequestAmount',
  Currency = 'Currency',
  FilingDate = 'FilingDate',
  ClaimRequestAmountDisplayOnly = 'ClaimRequestAmountDisplayOnly',
  ShipmentInstId = 'ShipmentInstId',
  Examiner = 'Examiner',
  ReassignToExaminer = 'ReassignToExaminer',
  ExaminerReassignReason = 'ExaminerReassignReason',
  PendingCustomerResponse = 'PendingCustomerResponse',
  PendingServiceCenterResponse = 'PendingServiceCenterResponse',
  PendingInspection = 'PendingInspection',
  InLitigation = 'InLitigation',
  Rebuttal = 'Rebuttal',
  DeclinedDate = 'DeclineDate',
  Subrogation = 'Subrogation',
  PaymentReconciled = 'PaymentReconciled',
}
