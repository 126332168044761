import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { XpoLtlBaseCacheService } from '@xpo-ltl/ngx-ltl';
import { ClaimsApiService, ListClaimsPendingMgrApprovalQuery, ListClaimsPendingMgrApprovalResp } from '@xpo-ltl/sdk-claims';
import * as _ from 'lodash';
import { AssignedClaimsCacheParams } from '../../interfaces/assigned-claims-cache-params.interface';
import { ApiRequest } from '@xpo-ltl/data-api';

@Injectable()
export class ListClaimsPendingApprovalCacheService extends XpoLtlBaseCacheService<AssignedClaimsCacheParams, ListClaimsPendingMgrApprovalResp> {
  constructor(private claimsApiService: ClaimsApiService) {
    super();
  }

  protected getKeyFromParams(params: AssignedClaimsCacheParams): string {
    return `${params.index}`;
  }

  protected requestData(params: AssignedClaimsCacheParams): Observable<ListClaimsPendingMgrApprovalResp> {
    const request = new ListClaimsPendingMgrApprovalQuery();
    _.assign(request, _.pick(params, _.keys(request)));
    request.managerEmployeeId = params.employeeId;

    return this.claimsApiService.listClaimsPendingMgrApproval(request, { ...ApiRequest.defaultDataOptions, toastOnError: false });
  }
}
