export enum PaymentEntryFormNames {
  ClaimPayoutAmount = 'claimPayoutAmount',
  ClaimPayoutAmountDisplay = 'claimPayoutAmountDisplay',
  ClaimCurrencyCd = 'currencyCd',
  PaymentEntry = 'paymentEntry',
  DecisionReasonCode = 'decisionReasonCode',
  DecisionSubReasonCode = 'decisionSubReasonCode',
  ReasonForAdditionalPayment = 'reasonForAdditionalPayment',
  ReturnToCDC = 'returnToCDC',
  DraftNotes = 'draftNotes',
  EmailNotes = 'emailNotes',

  ClaimTotalPayoutAmount = 'claimTotalPayoutAmount',
}
