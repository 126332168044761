import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { GetClaimResp } from '@xpo-ltl/sdk-claims';
import _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { EmailsConfig } from '../../components/notes-emails/emails/emails-config';
import { EmailsComponent } from '../../components/notes-emails/emails/emails.component';
import { EscalationEmailResults } from '../../enums/escalation-email-results.enum';
import { FormStatus } from '../../enums/FormControlNames/form-status.enum';
import { UntypedFormGroup } from '@angular/forms';
import { DocumentSearch } from '@xpo-ltl/sdk-documentmanagement';

@Component({
  selector: 'app-escalation-email',
  templateUrl: './escalation-email.component.html',
  styleUrls: ['./escalation-email.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EscalationEmailComponent implements AfterViewInit, OnDestroy {
  @ViewChild(EmailsComponent, {static: true}) emailsComponent;

  public readonly EscalationEmailResults = EscalationEmailResults;

  public claimsRegistrationFormGroup: UntypedFormGroup;
  public claim$: Observable<GetClaimResp>;
  public dmsDocList$: Observable<DocumentSearch[]>;
  public emailsConfig: EmailsConfig;
  public downloadEscalationEmailAttachmentsFn: Function;

  private emailReadySubject = new BehaviorSubject<boolean>(false);
  public emailReady$ = this.emailReadySubject.asObservable();

  private isSendingEmailSubject = new BehaviorSubject<boolean>(false);
  public isSendingEmail$ = this.isSendingEmailSubject.asObservable();

  private unsubscriber = new Unsubscriber();

  constructor(private dialogRef: MatDialogRef<EscalationEmailComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.emailsConfig = _.cloneDeep(_.get(data, 'emailsConfig', {}));
    this.claim$ = of(_.get(data, 'claim'));
    this.dmsDocList$ = of(_.get(data, 'dmsDocListObs'));
    this.claimsRegistrationFormGroup = _.get(data, 'claimsRegistrationFormGroup');
    this.downloadEscalationEmailAttachmentsFn = _.get(data, 'downloadDMSAttachmentFn', () => {});
  }

  ngAfterViewInit() {
    this.emailsComponent.formGroup.statusChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe(status => {
      this.emailReadySubject.next(status === FormStatus.Valid);
    });
    this.emailReadySubject.next(this.emailsComponent.formGroup.valid);
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  public handleSendForReviewClicked(): void {
    this.isSendingEmailSubject.next(true);
    this.emailsComponent
      .sendEmail()
      .pipe(
        take(1),
        finalize(() => this.isSendingEmailSubject.next(false))
      )
      .subscribe(() => {
        this.dialogRef.close(EscalationEmailResults.SaveAsDraft);
      });
  }
}
