import { NgModule } from '@angular/core';
import { ClaimTypeRequiredFieldsValidatorDirective } from './claim-type-required-fields-validator.directive';
import { MinimumCommodityCountValidatorDirective } from './minimum-commodity-count-validator.directive';
import { SicValidatorDirective } from './sic-validator.directive';
import { ClaimTypeGreaterThanZeroFieldValidatorDirective } from './claim-type-greater-than-zero-field-validator.directive';

const validators = [MinimumCommodityCountValidatorDirective, ClaimTypeRequiredFieldsValidatorDirective, ClaimTypeGreaterThanZeroFieldValidatorDirective, SicValidatorDirective];

@NgModule({
  imports: [],
  exports: validators,
  declarations: validators,
})
export class ClaimsRegistrationValidatorsModule {}
