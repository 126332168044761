import { Pipe, PipeTransform } from '@angular/core';
import { RebuttalReasonCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'rebuttalReasonInternal',
})
export class RebuttalReasonInternalPipe implements PipeTransform {
  transform(value: RebuttalReasonCd): any {
    switch (value) {
      case RebuttalReasonCd.ADD_DOCUMENTS:
        return 'Add Documents';
      case RebuttalReasonCd.AMENDING_CLAIM_AMOUNT:
        return 'Amending Claim Amount';
      case RebuttalReasonCd.CONCEALED_DAMAGE:
        return 'Concealed Damage';
      case RebuttalReasonCd.CONTRACT_DISCREPANCY:
        return 'Contract Discrepancy';
      case RebuttalReasonCd.FREIGHT_CHARGES:
        return 'Freight Charges';
      case RebuttalReasonCd.OTHER:
        return 'Other';
      case RebuttalReasonCd.POLICY_PAY:
        return 'Policy Pay';
      default:
        return 'UNKNOWN';
    }
  }
}
