import { NgModule } from '@angular/core';

import { ClaimEventTypeCdPipe } from './claim-event-type-cd.pipe';
import { ClaimNumberTransformerPipe } from './claim-number-transformer.pipe';
import { UnescapeXmlPipe } from './unescape-xml.pipe';
import { ArtificialIntelligencePipe } from './artificial-intelligence.pipe';
import { ServiceCenterChargeabilityReasonCdPipe } from './service-center-chargeability-reason-cd.pipe';
import { AsWordsPipe } from './as-words.pipe';
import { BillClassPipe } from './bill-class.pipe';
import { ClaimInternalStatusCdPipe } from './claim-internal-status-cd.pipe';
import { CommodityClassCdPipe } from './commodity-class-cd.pipe';
import { ConditionProPipe } from './condition-pro.pipe';
import { CurrencyCdPipe } from './currency-cd.pipe';
import { EmployeeNamePipe } from './employee-name.pipe';
import { ICountryPipe } from './icountry.pipe';
import { InterfaceEmployeePipe } from './interface-employee.pipe';
import { NoteVisibilityCdPipe } from './note-visibility-cd.pipe';
import { PaymentStatusInternalPipe } from './payment-status-internal.pipe';
import { RebuttalReasonInternalPipe } from './rebuttal-reason-cd-internal.pipe';
import { RebuttalStatusInternalPipe } from './rebuttal-status-internal.pipe';
import { SicDatePipe } from './sic-date.pipe';

const pipes = [ClaimEventTypeCdPipe, ClaimNumberTransformerPipe, UnescapeXmlPipe, ArtificialIntelligencePipe, ServiceCenterChargeabilityReasonCdPipe, AsWordsPipe, BillClassPipe, ClaimInternalStatusCdPipe, CommodityClassCdPipe,
  ConditionProPipe, CurrencyCdPipe, EmployeeNamePipe, ICountryPipe, InterfaceEmployeePipe, NoteVisibilityCdPipe, PaymentStatusInternalPipe, RebuttalReasonInternalPipe, RebuttalStatusInternalPipe, SicDatePipe];
@NgModule({
  declarations: pipes,
  exports: pipes,
  providers: pipes,
})
export class PipesModule { }
