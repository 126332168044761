export enum AddressEntryFormNames {
  Name = 'Name',
  Attention = 'Attention',
  Address1 = 'Address1',
  Address2 = 'Address2',
  City = 'City',
  State = 'State',
  Zip = 'Zip',
  Zip2 = 'Zip2',
  Country = 'Country',
  CountryDisplayOnly = 'CountryDisplayOnly',
  ClaimPartyId = 'ClaimPartyId',
}
