<div class="select-commodities-container">
  <div class="select-commodities-container__heading">
      <h2 mat-dialog-title>{{title}}</h2>
  </div>
  <mat-dialog-content class="select-commodities-container__content">
    <table mat-table [dataSource]="dataSource" class="select-commodities-container__table">
      <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="select-commodities-container__xs-column">
            <mat-checkbox #ref (change)="toggleMaster($event, ref)"
              class="select-commodities-container__checkbox select-commodities-container__checkbox-master"
              id="select-commodities-container__checkbox-master"
              [disabled]="hasSingleCommodity()"
              [checked]="selectedCommodities.hasValue() && isAllSelected()"
              [indeterminate]="selectedCommodities.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
        <td mat-cell *matCellDef="let commodity; let i = index">
          <div id="select-commodities-container__checkbox-{{i}}" class="select-commodities-container__checkbox-{{i}} select-commodities-container__xs-column">
            <mat-checkbox (click)="$event.stopPropagation()"
              (change)="toggleCommoditySelected($event, commodity)"
              [checked]="selectedCommodities.isSelected(commodity)">
            </mat-checkbox>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="descriptionTxt">
        <th mat-header-cell *matHeaderCellDef class="select-commodities-container__long-column"> Description </th>
        <td mat-cell *matCellDef="let commodity" class="select-commodities-container__long-column select-commodities-container__description"> {{commodity.descriptionTxt | emptyValue | sentenceCase}} </td>
      </ng-container>

      <ng-container matColumnDef="commodityWeight">
        <th mat-header-cell *matHeaderCellDef class="select-commodities-container__weight-column select-commodities-container__align-right"> Claimed Weight </th>
        <td mat-cell *matCellDef="let commodity" class="select-commodities-container__weight-column select-commodities-container__align-right"> {{commodity.commodityWeight | emptyValue}} </td>
      </ng-container>

      <ng-container matColumnDef="commodityWeightUnitOfMeasure">
        <th mat-header-cell *matHeaderCellDef class="select-commodities-container__short-column select-commodities-container__uom-column"> </th>
        <td mat-cell *matCellDef="let commodity" class="select-commodities-container__short-column select-commodities-container__uom-column"> {{commodity.commodityWeightUnitOfMeasure}} </td>
      </ng-container>

      <ng-container matColumnDef="piecesCount">
        <th mat-header-cell *matHeaderCellDef class="select-commodities-container__align-right select-commodities-container__short-column"> Pieces </th>
        <td mat-cell *matCellDef="let commodity" class="select-commodities-container__align-right select-commodities-container__short-column"> {{commodity.piecesCount | emptyValue}} </td>
      </ng-container>

      <ng-container matColumnDef="nmfcItemNbr">
        <th mat-header-cell *matHeaderCellDef class="select-commodities-container__short-column"> NMFC </th>
        <td mat-cell *matCellDef="let commodity" class="select-commodities-container__short-column"> {{commodity.nmfcItemNbr | emptyValue}} </td>
      </ng-container>

      <ng-container matColumnDef="classCd">
        <th mat-header-cell *matHeaderCellDef class="select-commodities-container__long-column"> Class </th>
        <td mat-cell *matCellDef="let commodity" class="select-commodities-container__long-column"> {{commodity.classCd | commodityClassCd | emptyValue}} </td>
      </ng-container>

      <tr mat-header-row class="select-commodities-container__header" *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row class="select-commodities-container__row" *matRowDef="let row; columns: columnsToDisplay" (click)="selectedCommodities.toggle(row)"></tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions class="select-commodities-container__actionbar">
    <button mat-button color="primary" class="select-commodities-container__cancel-btn" (click)="cancelClicked()">{{leftButtonTitle}}</button>
    <button mat-button
      color="primary"
      class="select-commodities-container__add-btn"
      [disabled]="!this.someCommoditiesSelected()"
      (click)="addSelectedClicked()">{{rightButtonTitle}}</button>
  </mat-dialog-actions>
</div>
