import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';

@Injectable({
  providedIn: 'root',
})
export class SideBarService implements OnInit {
  private readonly defaultRoutes: XpoShellRoute[] = [
    {
      label: 'Claims Lookup',
      path: '/dashboard',
    },
  ];
  private routes = [...this.defaultRoutes];

  private sidebarItemsSubject = new BehaviorSubject<XpoShellRoute[]>(this.routes);
  public sidebarItems$ = this.sidebarItemsSubject.asObservable();

  private sidebarOpenSubject = new BehaviorSubject<boolean>(false);
  public sidebarOpen$ = this.sidebarOpenSubject.asObservable();

  constructor() {}

  ngOnInit(): void {}

  public openSidebar() {
    this.sidebarOpenSubject.next(true);
  }

  public hideSidebar() {
    this.sidebarOpenSubject.next(false);
  }

  public clearRoutes() {
    this.routes = [];
    this.refreshRoutes();
  }

  public resetDefaultRoutes() {
    this.routes = [...this.defaultRoutes];
    this.refreshRoutes();
  }

  public addItem(label: string, path: string, openInNewTab = false, queryParamsHandling = ''): void {
    this.routes.push({ label, path, openInNewTab, queryParamsHandling });
  }

  public refreshRoutes() {
    this.sidebarItemsSubject.next([...this.routes]);
  }
}
