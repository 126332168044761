<div class="emails-detail">
  <div class="emails-detail__actionbar">
    <div class="emails-detail__actionbar-left">
      <button id="reply"
              *ngIf="!draftInd"
              mat-button
              mat-icon-button
              aria-label="reply"
              (click)="replyClicked()">
        <mat-icon>reply</mat-icon>
      </button>
      <button id="reply-all"
              *ngIf="!draftInd"
              mat-button
              mat-icon-button
              aria-label="reply all"
              (click)="replyAllClicked()">
        <mat-icon>reply_all</mat-icon>
      </button>
      <button id="forward"
              *ngIf="!draftInd"
              mat-button
              mat-icon-button
              aria-label="forward"
              (click)="forwardClicked()">
        <mat-icon>forward</mat-icon>
      </button>
      <button *ngIf="!(emailRead$ | async) && getAssignedClaimExaminer()"
              id="mark-as-read"
              mat-button
              color="primary"
              (click)="markAsReadClicked()">MARK AS READ</button>
    </div>
    <div class="emails-detail__actionbar-right">
      <div class="new"
           *ngIf="!(emailRead$ | async)">
        <span>New</span>
        <div class="circle"></div>
      </div>
      <div class="incoming"
           *ngIf="(emailRead$ | async) && email?.directionTypeCd === EmailDirectionTypeCd.INBOUND">
        <span>Customer</span>
        <div class="circle"></div>
      </div>
      <button *ngIf="email?.emailAttachment?.length > 0 && !(isDownloadingAttachments$ | async) && !constants.isReadOnly"
              id="attachments"
              mat-button
              mat-icon-button
              aria-label="attachments"
              (click)="attachmentsClicked()">
        <mat-icon [xpoBadge]="badgeConfig">attach_file</mat-icon>
      </button>
      <span *ngIf="isDownloadingAttachments$ | async"
      class="spinner"></span>
      <button id="expand-collapse"
              mat-button
              mat-icon-button
              aria-label="expand collapse"
              (click)="expandCollapseClicked()">
        <mat-icon *ngIf="isExpanded$ | async">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="!(isExpanded$ | async)">keyboard_arrow_up</mat-icon>
      </button>
    </div>
  </div>
  <div class="emails-detail__subject">
    <h3>{{email?.subjectTxt}}</h3>
    <div>{{email?.auditInfo?.createdTimestamp | date: "HH:mm MM/dd/yyyy"}}</div>
  </div>
  <div class="emails-detail__sender">
    <div>Sender:</div>
    <div>{{email?.senderEmailAddressTxt}}</div>
  </div>
  <div class="emails-detail__recipients">
    <div>Recipients:</div>
    <div>{{email?.recipientEmailAddressTxt}}</div>
  </div>
  <div class="emails-detail__cc" *ngIf="email?.ccEmailAddressTxt">
    <div>CC:</div>
    <div>{{email?.ccEmailAddressTxt}}</div>
  </div>
  <div class="emails-detail__body"
       [ngClass]="{'emails-detail__body-collapsed': (isExpanded$ | async), 'emails-detail__body-expanded': !(isExpanded$ | async)}"
       [innerHTML]="email?.messageTxt | unescapeXml | safeHtml">
      </div>
</div>
