import { CommodityLineItemFormNames } from './../../enums/FormControlNames/commodity-line-item-form-names.enum';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectCommoditiesDataInterface } from '../../interfaces/select-commodities-data';
import { LocalCommodity as Commodity } from '../../classes';
import { SelectionModel } from '@angular/cdk/collections';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';

@Component({
  selector: 'app-select-commodities',
  templateUrl: './select-commodities.component.html',
  styleUrls: ['./select-commodities.component.scss'],
})
export class SelectCommoditiesComponent implements OnDestroy {
  public title: string;
  public leftButtonTitle: string;
  public rightButtonTitle: string;
  public dataSource = new MatTableDataSource<Commodity>();
  public selectedCommodities = new SelectionModel<Commodity>(true, []);

  // columnsToDisplay array determines order, not html. Depending on type of view, array order should be updated.
  public readonly columnsToDisplay = [
    'select',
    CommodityLineItemFormNames.DescriptionTxt,
    CommodityLineItemFormNames.PiecesCount,
    CommodityLineItemFormNames.CommodityWeight,
    CommodityLineItemFormNames.CommodityWeightUnitOfMeasure,
    CommodityLineItemFormNames.NmfcItemNbr,
    CommodityLineItemFormNames.ClassCd,
  ];

  private unsubscriber = new Unsubscriber();

  constructor(@Inject(MAT_DIALOG_DATA) private data: SelectCommoditiesDataInterface, private matDialogRef: MatDialogRef<SelectCommoditiesComponent>) {
    this.title = data.title;
    this.leftButtonTitle = data.leftButtonTitle;
    this.rightButtonTitle = data.rightButtonTitle;
    this.dataSource = new MatTableDataSource<Commodity>(data.commodities);
  }

  public isAllSelected(): boolean {
    const numSelected = this.selectedCommodities.selected.length;
    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  public toggleMaster($event, ref): void {
    if ($event) {
      this.toggleAllCommodities(ref);
    }
  }

  public toggleCommoditySelected($event, commodity): void {
    if ($event) {
      this.selectedCommodities.toggle(commodity);
    }
  }

  public someCommoditiesSelected(): boolean {
    return this.selectedCommodities.selected.length > 0;
  }

  public cancelClicked(): void {
    this.matDialogRef.close();
  }

  public addSelectedClicked(): void {
    this.matDialogRef.close(this.selectedCommodities.selected);
  }

  public hasSingleCommodity(): boolean {
    return this.dataSource.data.length === 1;
  }

  private toggleAllCommodities(ref): void {
    if (this.someCommoditiesSelected()) {
      this.selectedCommodities.clear();
      ref.checked = false;
    } else {
      this.isAllSelected() ? this.selectedCommodities.clear() : this.dataSource.data.forEach(row => this.selectedCommodities.select(row));
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }
}
