export enum ClaimPartyMaxLengths {
  Name = 50,
  Attention = 50,
  OnBehalfOf = 50,
  Address1 = 50,
  Address2 = 50,
  City = 50,
  State = 2,
  Country = 2,
  Postal = 11,
  SameAs = 15,
  ContactFirstName = 20,
  ContactLastName = 20,
  ContactEmail = 100,
  ContactPhone = 20,
  ContactPhoneExt = 20,
  ContactFax = 20,
}
