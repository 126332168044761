import { NgModule } from '@angular/core';
import { NavBarPanelDirective } from './nav-bar-panel.directive';
import { DialogDraggableTitleDirective } from './mat-dialog-draggable-title.directive';
import { XpoBadgeDirective } from './xpo-badge.directive';
import { XpoFocusDirective } from './xpo-focus.directive';
import { TextareaAutoresizeDirective } from './textarea.directive';

const directives = [NavBarPanelDirective, DialogDraggableTitleDirective, XpoBadgeDirective, XpoFocusDirective, TextareaAutoresizeDirective];

@NgModule({
  declarations: directives,
  exports: directives,
})
export class DirectivesModule {}
