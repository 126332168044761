import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { BehaviorSubject, Observable } from 'rxjs';
import { ButtonConfig } from '../../classes';
import { ButtonType, FooterLabels } from '../../enums';
import { FooterButtonGroup } from '../../enums/footer-button-group.enum';
import { FooterButtonTypeCd } from '../../enums/footer-button-type-cd.enum';
import { AppFooterService } from '../app-footer/app-footer.service';

@Injectable({
  providedIn: 'root',
})
export class AppFooterButtonStateService implements OnDestroy {
  private buttonDisableObservables = new Map<FooterButtonTypeCd, BehaviorSubject<boolean>>([
    [FooterButtonTypeCd.BackToWorklist, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.CancelRegistration, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.Save, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.SaveFlat, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.Decline, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.Approve, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.CompleteApproval, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.ConfirmDeclination, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.ProceedToDeclinationLetter, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.DeclineRebuttal, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.ApproveRebuttal, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.Edit, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.SubmitPayment, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.ReviewClaim, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.SubmitClaim, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.Cancel, new BehaviorSubject<boolean>(false)],
    [FooterButtonTypeCd.CancelPendingRebuttal, new BehaviorSubject<boolean>(false)],
  ]);

  private buttonConfigs = new Map<FooterButtonTypeCd, ButtonConfig>([
    [
      FooterButtonTypeCd.BackToWorklist,
      new ButtonConfig(
        FooterLabels.BackToWorklist,
        FooterButtonTypeCd.BackToWorklist,
        false,
        true,
        ButtonType.Primary,
        false,
        this.buttonDisableObservables.get(FooterButtonTypeCd.BackToWorklist).asObservable()
      ),
    ],
    [
      FooterButtonTypeCd.Cancel,
      new ButtonConfig(FooterLabels.Cancel, FooterButtonTypeCd.Cancel, false, true, ButtonType.Primary, false, this.buttonDisableObservables.get(FooterButtonTypeCd.Cancel).asObservable()),
    ],
    [
      FooterButtonTypeCd.CancelRegistration,
      new ButtonConfig(
        FooterLabels.Cancel,
        FooterButtonTypeCd.CancelRegistration,
        false,
        true,
        ButtonType.Primary,
        false,
        this.buttonDisableObservables.get(FooterButtonTypeCd.CancelRegistration).asObservable()
      ),
    ],
    [
      FooterButtonTypeCd.Save,
      new ButtonConfig(FooterLabels.SaveClaim, FooterButtonTypeCd.Save, false, true, ButtonType.Primary, true, this.buttonDisableObservables.get(FooterButtonTypeCd.Save).asObservable()),
    ],
    [
      FooterButtonTypeCd.SaveFlat,
      new ButtonConfig(FooterLabels.SaveClaim, FooterButtonTypeCd.SaveFlat, false, true, ButtonType.Primary, false, this.buttonDisableObservables.get(FooterButtonTypeCd.SaveFlat).asObservable()),
    ],
    [
      FooterButtonTypeCd.Decline,
      new ButtonConfig(FooterLabels.DeclineClaim, FooterButtonTypeCd.Decline, false, true, ButtonType.Warning, false, this.buttonDisableObservables.get(FooterButtonTypeCd.Decline).asObservable()),
    ],
    [
      FooterButtonTypeCd.Approve,
      new ButtonConfig(FooterLabels.ApproveClaim, FooterButtonTypeCd.Approve, false, true, ButtonType.Primary, true, this.buttonDisableObservables.get(FooterButtonTypeCd.Approve).asObservable()),
    ],
    [
      FooterButtonTypeCd.CompleteApproval,
      new ButtonConfig(
        FooterLabels.CompleteApproval,
        FooterButtonTypeCd.CompleteApproval,
        false,
        true,
        ButtonType.Primary,
        true,
        this.buttonDisableObservables.get(FooterButtonTypeCd.CompleteApproval).asObservable()
      ),
    ],
    [
      FooterButtonTypeCd.ProceedToDeclinationLetter,
      new ButtonConfig(
        FooterLabels.ProceedToDeclinationLetter,
        FooterButtonTypeCd.ProceedToDeclinationLetter,
        false,
        true,
        ButtonType.Primary,
        true,
        this.buttonDisableObservables.get(FooterButtonTypeCd.ProceedToDeclinationLetter).asObservable()
      ),
    ],
    [
      FooterButtonTypeCd.ConfirmDeclination,
      new ButtonConfig(
        FooterLabels.ConfirmDeclination,
        FooterButtonTypeCd.ConfirmDeclination,
        false,
        true,
        ButtonType.Primary,
        true,
        this.buttonDisableObservables.get(FooterButtonTypeCd.ConfirmDeclination).asObservable()
      ),
    ],
    [
      FooterButtonTypeCd.DeclineRebuttal,
      new ButtonConfig(
        FooterLabels.DeclineRebuttal,
        FooterButtonTypeCd.DeclineRebuttal,
        false,
        true,
        ButtonType.Warning,
        false,
        this.buttonDisableObservables.get(FooterButtonTypeCd.DeclineRebuttal).asObservable()
      ),
    ],
    [
      FooterButtonTypeCd.ApproveRebuttal,
      new ButtonConfig(
        FooterLabels.ApproveRebuttal,
        FooterButtonTypeCd.ApproveRebuttal,
        false,
        true,
        ButtonType.Primary,
        true,
        this.buttonDisableObservables.get(FooterButtonTypeCd.ApproveRebuttal).asObservable()
      ),
    ],
    [
      FooterButtonTypeCd.CancelPendingRebuttal,
      new ButtonConfig(
        FooterLabels.Cancel,
        FooterButtonTypeCd.CancelPendingRebuttal,
        false,
        true,
        ButtonType.Primary,
        false,
        this.buttonDisableObservables.get(FooterButtonTypeCd.CancelPendingRebuttal).asObservable()
      ),
    ],
    [
      FooterButtonTypeCd.Edit,
      new ButtonConfig(FooterLabels.Edit, FooterButtonTypeCd.Edit, false, true, ButtonType.Primary, true, this.buttonDisableObservables.get(FooterButtonTypeCd.Edit).asObservable()),
    ],
    [
      FooterButtonTypeCd.SubmitPayment,
      new ButtonConfig(
        FooterLabels.SubmitPayment,
        FooterButtonTypeCd.SubmitPayment,
        false,
        true,
        ButtonType.Primary,
        true,
        this.buttonDisableObservables.get(FooterButtonTypeCd.SubmitPayment).asObservable()
      ),
    ],
    [
      FooterButtonTypeCd.ReviewClaim,
      new ButtonConfig(
        FooterLabels.ReviewClaim,
        FooterButtonTypeCd.ReviewClaim,
        false,
        true,
        ButtonType.Primary,
        true,
        this.buttonDisableObservables.get(FooterButtonTypeCd.ReviewClaim).asObservable()
      ),
    ],
    [
      FooterButtonTypeCd.SubmitClaim,
      new ButtonConfig(
        FooterLabels.SubmitClaim,
        FooterButtonTypeCd.SubmitClaim,
        false,
        true,
        ButtonType.Primary,
        true,
        this.buttonDisableObservables.get(FooterButtonTypeCd.SubmitClaim).asObservable()
      ),
    ],
    [
      FooterButtonTypeCd.CancelPendingApproval,
      new ButtonConfig(
        FooterLabels.Cancel,
        FooterButtonTypeCd.CancelPendingApproval,
        false,
        true,
        ButtonType.Primary,
        false,
        this.buttonDisableObservables.get(FooterButtonTypeCd.Cancel).asObservable()
      ),
    ],
  ]);

  private buttonGroups = new Map<FooterButtonGroup, Array<ButtonConfig>>([
    [FooterButtonGroup.Default, []],
    // Claim Details/Review
    [
      FooterButtonGroup.ClaimPendingApproval,
      [
        this.buttonConfigs.get(FooterButtonTypeCd.CancelPendingApproval),
        this.buttonConfigs.get(FooterButtonTypeCd.SaveFlat),
        this.buttonConfigs.get(FooterButtonTypeCd.Decline),
        this.buttonConfigs.get(FooterButtonTypeCd.Approve),
      ],
    ],
    [
      FooterButtonGroup.ManagerDirectorPendingApproval,
      [this.buttonConfigs.get(FooterButtonTypeCd.CancelPendingApproval), this.buttonConfigs.get(FooterButtonTypeCd.Decline), this.buttonConfigs.get(FooterButtonTypeCd.Approve)],
    ],
    [FooterButtonGroup.ClaimCompleteApproval, [this.buttonConfigs.get(FooterButtonTypeCd.CompleteApproval)]],
    [FooterButtonGroup.ClaimDecline, [this.buttonConfigs.get(FooterButtonTypeCd.ProceedToDeclinationLetter)]],
    [FooterButtonGroup.ClaimDeclineAfterEscalation, [this.buttonConfigs.get(FooterButtonTypeCd.ConfirmDeclination)]],
    [FooterButtonGroup.ClaimSubmitPayment, [this.buttonConfigs.get(FooterButtonTypeCd.SubmitPayment)]],

    [FooterButtonGroup.ClaimEdit, [this.buttonConfigs.get(FooterButtonTypeCd.BackToWorklist), this.buttonConfigs.get(FooterButtonTypeCd.Edit)]],
    [FooterButtonGroup.ClaimSave, [this.buttonConfigs.get(FooterButtonTypeCd.Cancel), this.buttonConfigs.get(FooterButtonTypeCd.Save)]],
    [FooterButtonGroup.ClaimReadOnly, [this.buttonConfigs.get(FooterButtonTypeCd.BackToWorklist)]],

    [
      FooterButtonGroup.RebuttalPending,
      [
        this.buttonConfigs.get(FooterButtonTypeCd.CancelPendingRebuttal),
        this.buttonConfigs.get(FooterButtonTypeCd.SaveFlat),
        this.buttonConfigs.get(FooterButtonTypeCd.DeclineRebuttal),
        this.buttonConfigs.get(FooterButtonTypeCd.ApproveRebuttal),
      ],
    ],
    [FooterButtonGroup.RebuttalApprovedNoPreviousPayments, [this.buttonConfigs.get(FooterButtonTypeCd.CompleteApproval)]],
    [FooterButtonGroup.RebuttalApprovedPreviousPayments, [this.buttonConfigs.get(FooterButtonTypeCd.SubmitPayment)]],
    [FooterButtonGroup.RebuttalDeclined, [this.buttonConfigs.get(FooterButtonTypeCd.ProceedToDeclinationLetter)]],

    // Claim Registration
    [FooterButtonGroup.RegisterClaimReview, [this.buttonConfigs.get(FooterButtonTypeCd.CancelRegistration), this.buttonConfigs.get(FooterButtonTypeCd.ReviewClaim)]],
    [FooterButtonGroup.RegisterClaimSubmit, [this.buttonConfigs.get(FooterButtonTypeCd.CancelRegistration), this.buttonConfigs.get(FooterButtonTypeCd.SubmitClaim)]],
  ]);

  private currentButtonGroup: FooterButtonGroup;
  private previousButtonGroup: FooterButtonGroup;

  private unsubscriber = new Unsubscriber();

  constructor(private appFooterService: AppFooterService) {}

  public ngOnDestroy() {
    this.unsubscriber.complete();
  }

  public clearButtonState() {
    this.currentButtonGroup = undefined;
    this.appFooterService.clearLeftButtonConfig();
    this.appFooterService.clearRightButtonConfig();
  }

  public get buttonClicked$(): Observable<ButtonConfig> {
    return this.appFooterService.buttonClicked$;
  }

  public setButtonDisabled(button: FooterButtonTypeCd, disabled: boolean) {
    if (!!this.currentButtonGroup && this.buttonGroups.has(this.currentButtonGroup)) {
      if (this.buttonGroups.get(this.currentButtonGroup).find(buttonConfig => buttonConfig.typeCd === button.toString())) {
        if (this.buttonDisableObservables.has(button)) {
          this.buttonDisableObservables.get(button).next(disabled);
        }
      }
    }
  }

  public enableAllButtons() {
    this.buttonDisableObservables.forEach(button => {
      button.next(false);
    });
  }

  public get ButtonGroup() {
    return this.currentButtonGroup;
  }

  public set ButtonGroup(buttonGroup: FooterButtonGroup) {
    if (this.buttonGroups.has(buttonGroup) && buttonGroup !== this.currentButtonGroup) {
      this.previousButtonGroup = this.currentButtonGroup;
      this.currentButtonGroup = buttonGroup;
      this.appFooterService.setButtonConfig(undefined, this.buttonGroups.get(buttonGroup));
    }
  }

  public get PreviousButtonGroup() {
    return this.previousButtonGroup;
  }
}
