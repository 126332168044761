import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { FormUtils } from '../classes/form-utils.class';
import { RegistrationCommodityErrorWarningType } from '../enums/registration-commodity-error-warning-type.enum';
import { CommodityClassCdHelper } from '@xpo-ltl/sdk-common';

export function commodityClassNmfcCdValidator(nmfcSetClassCdCtrl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control || !nmfcSetClassCdCtrl) {
      return null;
    }

    FormUtils.removeWarning(control, RegistrationCommodityErrorWarningType.ClassNMFCMismatch);

    if (!control.value || !nmfcSetClassCdCtrl.value) {
      return null;
    }

    if (control.value !== nmfcSetClassCdCtrl.value && isValidClassCd(control.value)) {
      FormUtils.setWarning(control, RegistrationCommodityErrorWarningType.ClassNMFCMismatch);
    }

    return null;
  };
}

function isValidClassCd(classCd: string): boolean {
  const transformedClassCd = `Clss${classCd}`;
  return _.indexOf(CommodityClassCdHelper.values(), transformedClassCd) > -1;
}
