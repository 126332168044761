/**
 * States that the Registration/Details panels can be set to.
 */
export enum ClaimEditState {
  Inactive = 'inactive', // panels greyed out and
  ReadOnly = 'readOnly', // no panels are enabled

  // Registration states
  Header = 'header', // header panel is enabled
  Claimant = 'claimant', // header and claimant panels enabled
  Payee = 'payee', // header, claimant, and payee panels enabled
  Additional = 'additional', // all registration panels enabled (form reviewable/submittable)

  // Claim Detail states
  Edit = 'edit', // all panels are enabled
  Rebuttal = 'rebuttal', // only rebuttal panel enabled
  Payment = 'payment', // only payment panel enabled
}
