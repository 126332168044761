import { NgModule } from '@angular/core';
import { XpoLtlPipeModule as XpoPipeModule } from '@xpo-ltl/ngx-ltl';
import { DirectivesModule } from '../../directives/directives.module';
import { EmailsDetailComponent } from './emails/emails-detail/emails-detail.component';
import { EmailsComponent } from './emails/emails.component';
import { NotesEmailsComponent } from './notes-emails.component';
import { NotesDetailComponent } from './notes/notes-detail/notes-detail.component';
import { NotesComponent } from './notes/notes.component';
import { PipesModule } from '../../pipes/pipes.module';
import { CKEditorModule } from 'ckeditor4-angular';
import { MaterialModule } from '../../material.module';
import { AngularSplitModule } from 'angular-split';
import { CoreModule } from '../../core/core.module';
import { NgxFileHelpersModule } from 'ngx-file-helpers';


@NgModule({
  imports: [
    CKEditorModule,
    MaterialModule,
    XpoPipeModule,
    PipesModule,
    AngularSplitModule,
    DirectivesModule,
    CoreModule,
    NgxFileHelpersModule,
  ],
  declarations: [NotesEmailsComponent, NotesComponent, NotesDetailComponent, EmailsComponent, EmailsDetailComponent],
  exports: [NotesEmailsComponent, EmailsComponent, NotesDetailComponent],
})
export class NotesEmailsModule {}
