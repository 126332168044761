<div
  class="status-badge"
  [ngClass]='statusAsClass'
  [class.status-badge__internal]="isInternal"
  [class.status-badge__external]="!isInternal"
  [class.status-badge__header]="isHeaderStatusBadge"
  [class.status-badge__ai-header-no-data]="status === ArtificialIntelligenceStatus.Unknown"
  [class.status-badge__ai-header]="artificialIntelligence"
  >
    <div class="status-badge__internal-box-icon" *ngIf="isInternal||artificialIntelligence">
        <mat-icon>visibility_off</mat-icon>
    </div>
    <div [class.status-badge__internal-box]="isInternal" [class.status-badge__external-box]="!isInternal"  >
        <div class="status-badge__box-text1" *ngIf="isInternal != null">
            <span>{{isInternalStatus}}</span>
        </div>
        <div class="status-badge__box-text2">
            <span>{{statusAsString}}</span>
        </div>
    </div>
</div>
