import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

export function xpoBadCharactersValidator(badChars: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (badChars && control.value) {

      const value: string = control.value;
      const charArray = [];
      const returnValue = {'badchars' : { 'badChars': charArray}};

      for (let i = 0; i < badChars.length; i++) {
        if (value.includes(badChars.charAt(i))) {
          if (!_.some(charArray,badChars.charAt(i))) {
            charArray.push(badChars.charAt(i));
          }
        }
      }
      return (charArray.length > 0) ? returnValue : null;
    }
    // Is valid
    return null;
  };
}

@Directive({
  selector: '[xpoBadCharacters]',
  providers: [{provide: NG_VALIDATORS, useExisting: XpoBadCharactersValidatorDirective, multi: true}]
})
export class XpoBadCharactersValidatorDirective implements Validator {
  @Input() badChars: string;

  validate(control: AbstractControl): { [key: string]: any } {
    return xpoBadCharactersValidator(this.badChars)(control);
  }
}
