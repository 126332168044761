import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ProStatusCd } from '@xpo-ltl/sdk-common';

export function proHasStatusValidator(statusValues: Array<ProStatusCd>, errorKey: string, proStatusFn: Function): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control || !control.value) {
      return null;
    }
    return statusValues.some(status => status === proStatusFn()) ? { [errorKey]: true } : null;
  };
}
