import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Claim, UpsertClaimRqst } from '@xpo-ltl/sdk-claims';
import { DamageTypeCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { FormUtils } from '../../../../classes/form-utils.class';
import { ClaimsRegistrationFormNames, IndicatorDamageFormValues, IndicatorsFormNames } from '../../../../enums';
import { ClaimsRegistrationService } from '../../../../services/claims-registration/claims-registration.service';

export class RegistrationIndicatorsFormBuilder {
  static create(
    formBuilder: UntypedFormBuilder,
    claimsRegistrationService: ClaimsRegistrationService
  ): Observable<AbstractControl> {
    return new Observable((observer) => {
      const claim: Claim = _.get(claimsRegistrationService.claim, 'claim', new Claim());

      const formGroup = formBuilder.group({
        [IndicatorsFormNames.Damage]: [RegistrationIndicatorsFormBuilder.toFormDamageType(claim.damageTypeCd)],
        [IndicatorsFormNames.IndicatorsGroup]: formBuilder.group({
          [IndicatorsFormNames.InvoiceProvided]: !!claim.invoiceProvidedInd,
          [IndicatorsFormNames.NoOpsException]: true,
          [IndicatorsFormNames.PossibleLateFiling]: !!claim.possibleLateFilingInd,
          [IndicatorsFormNames.ExcessiveValue]: true,
          [IndicatorsFormNames.NonRevenueShipment]: false,
          [IndicatorsFormNames.PotentialDRP]: false,
        }),
      });
      observer.next(formGroup);
      observer.complete();
    });
  }

  static setDefaultValues(formGroup: UntypedFormGroup): void {
    if (formGroup) {
      FormUtils.setValues(formGroup, {
        [IndicatorsFormNames.Damage]: IndicatorDamageFormValues.None,
      });
      FormUtils.setValues(formGroup.get(IndicatorsFormNames.IndicatorsGroup), {
        [IndicatorsFormNames.InvoiceProvided]: false,
        [IndicatorsFormNames.NoOpsException]: false,
        [IndicatorsFormNames.PossibleLateFiling]: false,
        [IndicatorsFormNames.ExcessiveValue]: false,
        [IndicatorsFormNames.NonRevenueShipment]: false,
        [IndicatorsFormNames.PotentialDRP]: false,
      });
    }
  }

  static transform(upsertClaimRqst: UpsertClaimRqst, rawFormGroup: any): UpsertClaimRqst {
    if (!upsertClaimRqst.claim) {
      upsertClaimRqst.claim = new Claim();
    }

    upsertClaimRqst.operationsExceptionInd = !_.get(
      rawFormGroup,
      `${ClaimsRegistrationFormNames.Indicators}.${IndicatorsFormNames.IndicatorsGroup}.${
        IndicatorsFormNames.NoOpsException
      }`,
      false
    );
    upsertClaimRqst.claim.invoiceProvidedInd = _.get(
      rawFormGroup,
      `${ClaimsRegistrationFormNames.Indicators}.${IndicatorsFormNames.IndicatorsGroup}.${
        IndicatorsFormNames.InvoiceProvided
      }`,
      false
    );
    upsertClaimRqst.claim.possibleLateFilingInd = _.get(
      rawFormGroup,
      `${ClaimsRegistrationFormNames.Indicators}.${IndicatorsFormNames.IndicatorsGroup}.${
        IndicatorsFormNames.PossibleLateFiling
      }`,
      false
    );
    upsertClaimRqst.nonRevenueProInd = _.get(
      rawFormGroup,
      `${ClaimsRegistrationFormNames.Indicators}.${IndicatorsFormNames.IndicatorsGroup}.${
        IndicatorsFormNames.NonRevenueShipment
      }`,
      false
    );
    upsertClaimRqst.claim.potentialDrpInd = _.get(
      rawFormGroup,
      `${ClaimsRegistrationFormNames.Indicators}.${IndicatorsFormNames.IndicatorsGroup}.${
        IndicatorsFormNames.PotentialDRP
      }`,
      false
    );
    const damageControl = _.get(
      rawFormGroup,
      `${ClaimsRegistrationFormNames.Indicators}.${IndicatorsFormNames.Damage}`,
      undefined
    );
    upsertClaimRqst.claim.damageTypeCd = RegistrationIndicatorsFormBuilder.fromFormDamageType(damageControl);

    return upsertClaimRqst;
  }

  static toFormDamageType(damageTypeCd: DamageTypeCd): IndicatorDamageFormValues {
    switch (damageTypeCd) {
      case DamageTypeCd.CONCEALED:
        return IndicatorDamageFormValues.ConcealedDamage;
      case DamageTypeCd.WRECK_AND_FIRE:
        return IndicatorDamageFormValues.WreckAndFire;
      default:
        return IndicatorDamageFormValues.None;
    }
  }

  static fromFormDamageType(formDamageType: IndicatorDamageFormValues): DamageTypeCd {
    switch (formDamageType) {
      case IndicatorDamageFormValues.ConcealedDamage:
        return DamageTypeCd.CONCEALED;
      case IndicatorDamageFormValues.WreckAndFire:
        return DamageTypeCd.WRECK_AND_FIRE;
      default:
        return undefined;
    }
  }
}
