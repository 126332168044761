import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';

export class ErrorStateMatcher implements ErrorStateMatcher {
    constructor(private validatorFn: Function) {
      this.validatorFn = validatorFn;
    }

    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return !!((control && control.invalid && (control.dirty || control.touched)) || this.validatorFn(form, control));
    }
}
