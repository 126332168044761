import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XpoLtlPipeModule } from '@xpo-ltl/ngx-ltl';
import { TimelineComponent } from './timeline.component';
import { MaterialModule } from '../../material.module';

@NgModule({
  declarations: [TimelineComponent],
  imports: [CommonModule, MaterialModule, XpoLtlPipeModule],
  exports: [TimelineComponent],
})
export class TimelineModule {}
