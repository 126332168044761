import { CommonModule, CurrencyPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { XpoAllowCharactersModule, XpoLtlPipeModule } from '@xpo-ltl/ngx-ltl';
import { CoreModule } from '../core/core.module';
import { DataDisplayGroupModule } from '../components/data-display-group/data-display-group.module';
import { NotesEmailsModule } from '../components/notes-emails/notes-emails.module';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule as LocalPipesModule } from '../pipes/pipes.module';
import { AlertComponent } from './alert/alert.component';
import { ClaimHistoryListComponent } from './claim-history/claim-history-list.component';
import { ConfirmCancelComponent } from './confirm-cancel/confirm-cancel.component';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { DeclinationEmailComponent } from './declination-email/declination-email.component';
import { DialogTemplateInjectorDirective } from './dialog-template-injector.directive';
import { MatchPartiesComponent } from './match-parties/match-parties.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { ReassignClaimsComponent } from './reassign-claims/reassign-claims.component';
import { RecycledProsComponent } from './recycled-pros/recycled-pros.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { SelectCommoditiesComponent } from './select-commodities/select-commodities.component';
import { ServiceCenterChargeabilityComponent } from './service-center-chargeability/service-center-chargeability.component';
import { EscalationEmailComponent } from './escalation-email/escalation-email.component';
import { TimelineModule } from '../components/timeline/timeline.module';
import { PrintClaimComponent } from './print-claim/print-claim.component';
import { NgxPrintModule } from 'ngx-print';
import { RegistrationReassignClaimComponent } from './registration-reassign-claim/registration-reassign-claim.component';
import { MaterialModule } from '../material.module';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { LocationApiService } from '@xpo-ltl/sdk-location';
import { SicDatePipe } from '../pipes/sic-date.pipe';

const dialogs = [
  AlertComponent,
  ClaimHistoryListComponent,
  ConfirmCancelComponent,
  RegistrationSuccessComponent,
  SelectCommoditiesComponent,
  MatchPartiesComponent,
  ContactsListComponent,
  RecycledProsComponent,
  PaymentHistoryComponent,
  ServiceCenterChargeabilityComponent,
  ReassignClaimsComponent,
  DeclinationEmailComponent,
  EscalationEmailComponent,
  PrintClaimComponent,
  RegistrationReassignClaimComponent,
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        XpoLtlPipeModule,
        LocalPipesModule,
        CoreModule,
        DataDisplayGroupModule,
        DirectivesModule,
        NotesEmailsModule,
        TimelineModule,
        NgxPrintModule,
        XpoAllowCharactersModule,
        XpoSnackBarModule,
    ],
    declarations: [
        DialogTemplateInjectorDirective,
        ServiceCenterChargeabilityComponent,
        ...dialogs,
    ],
    providers: [
        LocationApiService,
        CurrencyPipe,
        SicDatePipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DialogsModule {}
