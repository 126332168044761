import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { XpoLtlBaseCacheService } from '@xpo-ltl/ngx-ltl';
import { ClaimsApiService, ListExaminerRebuttalsResp, ListExaminerRebuttalsQuery } from '@xpo-ltl/sdk-claims';
import * as _ from 'lodash';
import { AssignedRebuttalsCacheParams } from '../../interfaces/assigned-rebuttals-cache-params.interface';
import { ApiRequest } from '@xpo-ltl/data-api';

@Injectable()
export class ListExaminerRebuttalsCacheService extends XpoLtlBaseCacheService<AssignedRebuttalsCacheParams, ListExaminerRebuttalsResp> {
  constructor(private claimsApiService: ClaimsApiService) {
    super();
  }

  protected getKeyFromParams(params: AssignedRebuttalsCacheParams): string {
    return `${params.index}`;
  }

  protected requestData(params: AssignedRebuttalsCacheParams): Observable<ListExaminerRebuttalsResp> {
    const request = new ListExaminerRebuttalsQuery();
    _.assign(request, _.pick(params, _.keys(request)));
    request.examinerEmployeeId = params.employeeId;

    return this.claimsApiService.listExaminerRebuttals(request, { ...ApiRequest.defaultDataOptions, toastOnError: false });
  }
}
