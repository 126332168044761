<div class="reassign-claims">
  <div class="reassign-claims-header">
    <h2 md-dialog-title >Reassign Claims</h2>
  </div>
  <mat-dialog-content class="reassign-claims-content">
    <mat-form-field>
      <input id="reassign-claim-to"
             aria-label="Reassign Claim To"
             matInput
             autocomplete="off"
             placeholder="Reassign Claim To"
             maxlength="100"
             [(ngModel)]="employeeName"
             #employeeNameInput="ngModel"
             [matAutocomplete]="auto">
      <mat-autocomplete id="reassign-claims-content-employees"
                        #auto="matAutocomplete">
        <mat-option *ngFor="let employee of (filteredEmployeeList$ | async)"
                    [value]="employee">
          {{employee}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="reassign-claims-actionbar">
    <button mat-button
            color="primary"
            (click)="cancelClicked()">Cancel</button>
    <button mat-button
            color="primary"
            [disabled]="!(isMatched$ | async)"
            (click)="reassignClicked()">Confirm Reassign</button>
  </mat-dialog-actions>
</div>
