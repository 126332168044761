<div class="confirm-cancel-container">
  <h2 md-dialog-title>{{title}}</h2>

  <mat-dialog-content class="confirm-cancel-container-content">
    <div [innerHTML]="content"></div>
  </mat-dialog-content>

  <mat-dialog-actions class="confirm-cancel-container-actionbar">
    <button *ngIf="leftButtonTitle" mat-button color="primary" [mat-dialog-close]="false">{{leftButtonTitle}}</button>
    <button *ngIf="rightButtonTitle" mat-button color="primary" [mat-dialog-close]="true">{{rightButtonTitle}}</button>
  </mat-dialog-actions>
</div>
