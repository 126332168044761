import { AppConstantsService } from '../../services/app-constants.service';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ClaimHistoryDialogInterface } from '../../interfaces/claim-history-dialog.interface';
import { ClaimHistoryListEvent as ListEvent, ClaimHistoryService } from '../../services/claim-history.service';
import { DOCUMENT } from '@angular/common';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { RouterUriComponents } from '../../enums';
import { Subject, BehaviorSubject } from 'rxjs';
import { take, finalize, map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-claim-history-list',
  templateUrl: './claim-history-list.component.html',
  styleUrls: ['./claim-history-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimHistoryListComponent implements OnInit {
  private eventsSubject = new Subject<MatTableDataSource<ListEvent>>();
  public events$ = this.eventsSubject.asObservable();

  public displayedColumns: string[] = ['date', 'typCd', 'employeeName'];
  public events: ListEvent[] = [];
  private showSpinnerSubject = new BehaviorSubject<boolean>(false);
  public showSpinner$ = this.showSpinnerSubject.asObservable();
  public claimId: string;
  public eventsLoaded = false;

  // Used to construct the full path of applicaiton url for new tab events to avoid side effects
  private appUrl = this.document.location.origin;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ClaimHistoryDialogInterface,
    private historyService: ClaimHistoryService,
    public constants: AppConstantsService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  // On angular init lifecycle
  public ngOnInit(): void {
    const migrationDate = this.historyService.getMigrationDate();

    this.showSpinnerSubject.next(true); // Show the spinner when we make the api call
    this.claimId = this.dialogData.claimId; // Set claim id from the dialog
    // If they are opening the dialog, clear the cache to ensure they have most recent events
    this.historyService.clearEventsCache();
    // Call the history service, stop the spinner when the call finishes
    this.historyService
      .getEvents(this.claimId)
      .pipe(
        finalize(() => {
          this.showSpinnerSubject.next(false);
          this.eventsLoaded = true;
        }),
        take(1),
        // if isMigrated claim, keep only events occurred after migration
        map((listEvent) =>
          !this.dialogData.isMigrated
            ? listEvent
            : listEvent.filter((event) => moment(event.date).isAfter(migrationDate))
        )
      )
      .subscribe(
        // Set the events from the service response, they are formatted
        (events: ListEvent[]): ListEvent[] => (this.events = events),
        (err) => console.error(err),
        () => this.populateTable() // Now populate table on completion (happens on migrated data too)
      );
  }

  /**
   * Returns true if there are no events
   */
  public get noEvents(): boolean {
    return _.isEmpty(this.events);
  }

  /**
   * Sets up the data source for the table to consume as well as the sort.
   * Ran after events is set from api.
   */
  private populateTable(): void {
    this.events = this.events.sort((a, b) => {
      return moment(a.date).isAfter(b.date) ? 1 : -1;
    });
    const dataSource = new MatTableDataSource(this.events);
    this.eventsSubject.next(dataSource);
    dataSource.sort = this.sort;
  }

  /**
   * Call back for timeline to notify it is done loading, and disable/enable the spinner here
   *
   * @param flag whether to show the timeline spinner or not
   */
  public showSpinnerTimeline(flag: boolean): void {
    this.showSpinnerSubject.next(flag);
  }

  /**
   * Returns the url for the achor to consume
   *
   * @param event element in the table
   */
  public getElementUrl(event: ListEvent): string {
    return `${this.appUrl}/${RouterUriComponents.CLAIM_HISTORY}/${this.claimId}/${event.eventId}`;
  }
}
