export enum FooterLabels {
  Cancel = 'Cancel',
  ReviewClaim = 'Review Claim',
  SubmitClaim = 'Submit Claim',
  SaveClaim = 'Save',
  DeclineClaim = 'Decline',
  ApproveClaim = 'Approve',
  CompleteApproval = 'Complete Approval',
  ConfirmDeclination = 'Confirm Declination',
  ProceedToDeclinationLetter = 'Proceed To Declination Letter',
  DeclineRebuttal = 'Decline Rebuttal',
  ApproveRebuttal = 'Approve Rebuttal',
  Edit = 'Edit',
  SubmitPayment = 'Submit Payment',
  BackToWorklist = 'Back To Worklist',
}
