<mat-card class="claims-approval">
  <div class="claims-approval__container">
    <div class="claims-approval__header">
      <span class="claims-approval__header-title">Please approve
        or decline.
      </span>
    </div>
    <div class="claims-approval__content">
      <div class="claims-approval__details">
        <div class="claims-approval__details-claim-info">
          <app-data-display-group class="claims-approval__details-claim-item" label="Claim #"
            [value]="claim?.claim?.claimId"></app-data-display-group>
          <app-data-display-group class="claims-approval__details-claim-item" label="PRO #"
            [value]="claim?.claim?.proNbr | proFormatter:10 "></app-data-display-group>
          <app-data-display-group class="claims-approval__details-claim-item" label="Claim Amount"
            [value]="claim?.claim?.claimedAmount | currency | emptyCurrency"></app-data-display-group>
          <app-data-display-group class="claims-approval__details-claim-item" label="Payout Amount"
            [value]="getPaymentRequestedAmount() | currency | emptyCurrency"></app-data-display-group>
        </div>
        <div class="claims-approval__details-claim-parties">
          <ng-container [ngTemplateOutletContext]="{ label: 'Claimant Name', address: claim?.claimant }"
            [ngTemplateOutlet]="addressField"></ng-container>
          <ng-container [ngTemplateOutletContext]="{ label: 'Payee Name', address: claim?.payee }"
            [ngTemplateOutlet]="addressField"></ng-container>
        </div>
      </div>
      <div class="claims-approval__note" [formGroup]="formGroup">
        <mat-form-field class="claims-approval__note-field" floatLabel="auto">
          <textarea #noteTxt matInput placeholder="New Note" [formControlName]="NotesFormNames.NoteTxt"
            matTextareaAutosize matAutosizeMinRows="7" matAutosizeMaxRows="7" md-no-ink-bar matInput
            placeholder="Notes (optional)">
          </textarea>
          <mat-hint align="end">{{noteTxt?.value?.length}}/{{ClaimNoteMaxLengths.BodyCharacters}}</mat-hint>
          <mat-error>
            <div *ngIf="FormUtils.hasError(formGroup, NotesFormNames.NoteTxt, 'maxlength')">Must be less than 500
              characters</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class = 'claims-approval__prevnotes'>
        <div class="claims-approval__prevnotes_lable">Notes</div>
        <div class='claims-approval__prevnotes_content'>
          <div *ngFor='let note of claim?.notes'>
            <app-notes-detail [note]='note' ></app-notes-detail>
          </div>
        </div>
      </div>
    </div>
    <div class="claims-approval__footer">
      <button mat-button (click)="backToDashboard()"
        class="claims-approval__footer-button">Back to Worklist</button>
      <button mat-button [disabled]="(disableButtonGroup$ | async)" (click)="handleDeclineClicked()"
        class="claims-approval__footer-button">Decline</button>
      <button mat-button [disabled]="(disableButtonGroup$ | async)" (click)="handleApproveClicked()"
        class="claims-approval__footer-button">Approve</button>
    </div>
  </div>
</mat-card>
<div class="claims-approval__bottom-space"></div>

<ng-template #addressField let-label="label" let-address="address">
  <div class="claims-approval__address">
    <div class="claims-approval__address-label">{{ label }}</div>
    <div class="claims-approval__address-container">
      <div class="claims-approval__address-data">{{ address?.name1 | emptyValue }}</div>
      <div *ngIf="address?.name2" class="claims-approval__address-data">{{ address?.name2 | emptyValue }}</div>
      <div class="claims-approval__address-data">
        {{ address?.addr1 | emptyValue }}
        <span *ngIf="address?.addr2" class="claims-approval__address-data">
          {{ address?.addr2 | emptyValue }}
        </span>
      </div>
      <div class="claims-approval__address-data">
        {{ address?.cityName | emptyValue }},
        {{ address?.stateCd | emptyValue }}
        {{ address?.postalCd | emptyValue }}
      </div>
      <div class="claims-approval__address-data">{{ address?.countryCd | emptyValue }}</div>
    </div>
  </div>
</ng-template>
