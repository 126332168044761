<div class="notes-detail">
  <div class="notes-detail__subject">
    <div class="notes-detail__subject-text">{{note?.subjectTxt}}</div>
    <div class="notes-detail__subject-date">{{note?.auditInfo?.createdTimestamp | date:'HH:mm, MM/dd/yyyy'}}</div>
  </div>
  <div class="notes-detail__from">
    <div class="notes-detail__from-name">{{authorName$ | async}}</div>
    <div class="notes-detail__from-claim-type">
      <div [ngClass]="{'claim-type-claims-only': note?.visibilityCd === ClaimNoteVisibilityCd.CLAIMS_DEPT, 'claim-type-xpo': note?.visibilityCd === ClaimNoteVisibilityCd.XPO, 'claim-type-external': note?.visibilityCd === ClaimNoteVisibilityCd.EXTERNAL}">{{note?.visibilityCd | noteVisibilityCd}}</div>
      <div class="circle"
           [ngClass]="{'circle-claims-only': note?.visibilityCd === ClaimNoteVisibilityCd.CLAIMS_DEPT, 'circle-xpo-ltl': note?.visibilityCd === ClaimNoteVisibilityCd.XPO, 'circle-external': note?.visibilityCd === ClaimNoteVisibilityCd.EXTERNAL}"></div>
    </div>
  </div>
  <div class="notes-detail__detail">
      <div [innerHTML]="note?.noteTxt | unescapeXml | safeHtml"></div>
    
</div>
