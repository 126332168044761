/** Internal status cd values from xrt search - revisit after knowing all the other possible values*/
export enum ClaimXRTInternalStatusCdEnum {
  APPROVED = 'APPROVED',
  // CEO_PENDING_APPROVAL = "CeoPendingApproval",
  CUSTOMER_CANCELLED = 'CUST_CANCELLED',
  DECLINED = 'DECLINED',
  // DIRECTOR_PENDING_APPROVAL = "DirectorPendingApproval",
  MANAGER_PENDING_APPROVAL = 'MGR_PEND_APRV',
  // PRESIDENT_PENDING_APPROVAL = "PresidentPendingApproval",
  SUBMITTED = 'SUBMITTED',
  // SVP_OF_OPS_PENDING_APPROVAL = "SVPOfOpsPendingApproval",
  UNDER_REVIEW = 'UNDER_REVIEW',
  UNSUBMITTED = 'UNSUBMITTED',
}
