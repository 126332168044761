import { Injectable } from '@angular/core';
import { ClaimHistoryService, ClaimHistoryFields } from '../services/claim-history.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RouterParams, RouterUriComponents } from '../enums';

@Injectable()
export class ClaimHistoryGuard implements CanActivate {
  constructor(private historyService: ClaimHistoryService, private router: Router) {}

  // When we navigate, take the historyService singleton and call the getClaimHistory and populate the history$
  // then when child components are being constructed, they already have data immediately available to them
  // Should anything error out or the historyfields returns a falsy value, we should just stop the load
  // but not the redirect because we typically open history in a new tab rather than in the same tab
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const eventId = route.params[RouterParams.EVENT_ID] as number;
    if (!!eventId) {
      return this.historyService.getClaimHistory(eventId).pipe(
        map((fields: ClaimHistoryFields): boolean => !!fields),
        catchError(() => of(false))
      );
    }
    this.router.navigate([RouterUriComponents.DASHBOARD]);
    return true;
  }
}
