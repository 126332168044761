import { RelatedProFormNames } from './../enums/FormControlNames/related-pro-form-names.enum';
import { ClaimsRegistrationFormNames } from './../enums/FormControlNames/claims-registration-form-names.enum';
import { AbstractControl, ValidatorFn, UntypedFormArray } from '@angular/forms';
import { ClaimsRegistrationService } from '../services/claims-registration/claims-registration.service';
import { RelatedProsFormNames } from '../enums';
import { ConditioningService } from '@xpo-ltl/common-services';

export function uniqueProValidator(claimsRegistrationService: ClaimsRegistrationService, conditioningService: ConditioningService): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control || !control.value) {
      return null;
    }

    const proNbrArray = [];
    let claimProNbr;

    if (claimsRegistrationService.shipment) {
      claimProNbr = claimsRegistrationService.shipment.shipment.proNbr;
    }

    const relatedProCtrls = claimsRegistrationService.claimsRegistrationFormGroup.get(ClaimsRegistrationFormNames.RelatedPros).get(RelatedProsFormNames.RelatedPros) as UntypedFormArray;

    const relatedProNbrs = relatedProCtrls.controls.reduce((acc, relatedProFG) => {
      const proNbr = relatedProFG.get(RelatedProFormNames.ProNbr).value;
      return proNbr ? [...acc, proNbr] : acc;
    }, []);

    proNbrArray.push(...relatedProNbrs, claimProNbr);

    const proNbrCount = proNbrArray.reduce((acc, proNbr) => {
      return control.value === conditioningService.conditionProNumber(proNbr, 9) ||
        control.value === conditioningService.conditionProNumber(proNbr, 10) ||
        control.value === conditioningService.conditionProNumber(proNbr, 11)
        ? acc + 1
        : acc;
    }, 0);

    return proNbrCount > 1 ? { duplicatePro: true } : null;
  };
}
