<ng-container class="address-entry" *ngIf="editMode; then: editView; else: readOnlyView"></ng-container>

<ng-template #readOnlyView>
  <div class="address-entry__view-only--content">
    <div class="address-entry__view-only--container--highlight">
      <div class="address-entry__view-only--element-60">
        <div class="address-entry__view-only--label">Name</div>
        <div class="address-entry__view-only--data">{{ formGroup?.get(AddressEntryFormNames.Name)?.value }}</div>
      </div>
      <div class="address-entry__view-only--element-40">
        <div class="address-entry__view-only--label">Attention</div>
        <div class="address-entry__view-only--data">{{ formGroup?.get(AddressEntryFormNames.Attention)?.value }}</div>
      </div>
    </div>
    <div class="address-entry__view-only--container">
      <div class="address-entry__view-only--element-40 address-entry__view-only--element-md-50">
        <div class="address-entry__view-only--label">Mailing Address 1</div>
        <div class="address-entry__view-only--data">{{ formGroup?.get(AddressEntryFormNames.Address1)?.value }}</div>
      </div>
      <div class="address-entry__view-only--element-40 address-entry__view-only--element-md-50">
        <div class="address-entry__view-only--label">Mailing Address 2</div>
        <div class="address-entry__view-only--data">{{ formGroup?.get(AddressEntryFormNames.Address2)?.value }}</div>
      </div>
    </div>
    <div class="address-entry__view-only--container">
      <div class="address-entry__view-only--element-40 address-entry__view-only--element-md-50 resp-mb20">
        <div class="address-entry__view-only--label">City</div>
        <div class="address-entry__view-only--data">{{ formGroup?.get(AddressEntryFormNames.City)?.value }}</div>
      </div>
      <div class="address-entry__view-only--element-20 address-entry__view-only--element-md-50 resp-mb20">
        <div class="address-entry__view-only--label">Province/State</div>
        <div class="address-entry__view-only--data">{{ formGroup?.get(AddressEntryFormNames.State)?.value }}</div>
      </div>
      <div class="address-entry__view-only--element-20 address-entry__view-only--element-md-50">
        <div class="address-entry__view-only--label">Zip Code</div>
        <div class="address-entry__view-only--data">{{ formGroup?.get(AddressEntryFormNames.Zip)?.value }}</div>
      </div>
      <div class="address-entry__view-only--element-20 address-entry__view-only--element-md-50">
        <div class="address-entry__view-only--label">Country</div>
        <div class="address-entry__view-only--data">{{ formGroup?.get(AddressEntryFormNames.Country)?.value?.cca2 }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editView>
  <div class="address-entry__edit-view--content" [formGroup]="formGroup">
    <mat-form-field class="address-entry__edit-view--name">
      <input matInput [maxlength]="ClaimPartyMaxLengths.Name" type="text" autofocus placeholder="Name"
        autocomplete="off" [formControlName]="AddressEntryFormNames.Name" [xpo-prevent-characters]="preventChars" />
      <mat-error>
        <div [xpoErrors]="AddressEntryFormNames.Name" #nameError="xpoErrors">
          <div xpoError="numericOnly">Only numeric values not allowed</div>
          <div xpoError="required">Required</div>
          <div xpoError="badchars">Invalid Input: {{nameError?.errors?.badchars?.badChars }}</div>
          <div xpoError="maxlength">Input too long</div>
        </div>
      </mat-error>
      <xpo-warnings>
        <div [xpoWarnings]="AddressEntryFormNames.Name">
          <div xpoWarning="short">Name is less than 2 characters</div>
        </div>
      </xpo-warnings>
    </mat-form-field>

    <mat-form-field class="address-entry__edit-view--attention">
      <input matInput [maxlength]="ClaimPartyMaxLengths.Attention" type="text" autofocus placeholder="Attention"
        autocomplete="off" [formControlName]="AddressEntryFormNames.Attention"
        [xpo-prevent-characters]="preventChars" />
      <mat-error>
        <div [xpoErrors]="AddressEntryFormNames.Attention" #attentionError="xpoErrors">
          <div xpoError="badchars">Invalid Input: {{attentionError?.errors?.badchars?.badChars}}</div>
          <div xpoError="maxlength">Input too long</div>
        </div>
      </mat-error>
      <mat-hint align="end">Optional</mat-hint>
    </mat-form-field>

    <mat-form-field class="address-entry__edit-view--address">
      <input matInput [maxlength]="ClaimPartyMaxLengths.Address1" type="text" autofocus
        placeholder="Mailing Address Line 1" autocomplete="off" [formControlName]="AddressEntryFormNames.Address1"
        [xpo-prevent-characters]="preventChars" />
      <mat-error>
        <div [xpoErrors]="AddressEntryFormNames.Address1" #address1Error="xpoErrors">
          <div xpoError="required">Required</div>
          <div xpoError="maxlength">Input too long</div>
          <div xpoError="badchars">Invalid Input: {{ address1Error?.errors?.badchars?.badChars }}</div>
        </div>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="address-entry__edit-view--address">
      <input matInput [maxlength]="ClaimPartyMaxLengths.Address2" type="text" autofocus
        placeholder="Mailing Address Line 2" autocomplete="off" [formControlName]="AddressEntryFormNames.Address2"
        [xpo-prevent-characters]="preventChars"/>
      <mat-error>
        <div [xpoErrors]="AddressEntryFormNames.Address2" #address2Error="xpoErrors">
          <div xpoError="maxlength">Input too long</div>
          <div xpoError="badchars">Invalid Input: {{ address2Error?.errors?.badchars?.badChars }}</div>
        </div>
      </mat-error>
      <mat-hint align="end">Optional</mat-hint>
    </mat-form-field>

    <div class="address-entry__edit-view--city-state-zip">
      <mat-form-field class="address-entry__edit-view--city">
        <input matInput [maxlength]="ClaimPartyMaxLengths.City" type="text" autofocus placeholder="City"
          autocomplete="off" [formControlName]="AddressEntryFormNames.City" [xpo-prevent-characters]="preventChars" />
        <mat-error>
          <div [xpoErrors]="AddressEntryFormNames.City" #cityError="xpoErrors">
            <div xpoError="maxlength">Input too long</div>
            <div xpoError="required">Required</div>
            <div xpoError="badchars">Invalid Input: {{ cityError?.errors?.badchars?.badChars }}</div>
          </div>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="address-entry__edit-view--state">
        <input
          matInput
          type="text"
          autofocus
          placeholder="Province/State"
          autocomplete="off"
          xpo-uppercase
          [xpo-allow-characters]="stateInputAllowedChars"
          [errorStateMatcher]="stateErrorStateMatcher"
          [maxlength]="stateMaxLength"
          [formControlName]="AddressEntryFormNames.State" />
        <mat-error>
          <div [xpoErrors]="AddressEntryFormNames.State" #stateError="xpoErrors">
            <div xpoError="required">Required</div>
            <div xpoError="badchars">Invalid Input: {{ stateError?.errors?.badchars?.badChars }}</div>
            <div *ngIf="formGroup.hasError('maxStateCode')">Input too long</div>
            <div *ngIf="formGroup.hasError('hasSpace')">Space not allowed</div>
          </div>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="address-entry__edit-view--zip">
        <input matInput [maxlength]="ClaimPartyMaxLengths.Postal" type="text" autofocus placeholder="Zip Code"
          autocomplete="off" [formControlName]="AddressEntryFormNames.Zip" />
        <mat-error>
          <div [xpoErrors]="AddressEntryFormNames.Zip" #zipError="xpoErrors">
            <div xpoError="invalidzippostal">{{ zipError?.errors?.invalidzippostal?.message }}</div>
            <div xpoError="required">Required</div>
            <div xpoError="maxlength">Input too long</div>
            <div xpoError="badchars">Invalid Input: {{ zipError?.errors?.badchars?.badChars }}</div>
          </div>
        </mat-error>
      </mat-form-field>
    </div>

    <mat-form-field class="address-entry__edit-view--country">
      <input type="text" autocomplete="off" matInput placeholder="Country" [matAutocomplete]="auto"
        [formControlName]="AddressEntryFormNames.CountryDisplayOnly" (blur)="onCountryBlur()"
        [xpo-prevent-characters]="preventChars" />
      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
        <mat-option *ngFor="let country of (filteredCountryNames$ | async)" [value]="country">{{country}}
        </mat-option>
      </mat-autocomplete>
      <mat-error>
        <div [xpoErrors]="AddressEntryFormNames.Country" #countryError="xpoErrors">
          <div xpoError="required">Required</div>
        </div>
      </mat-error>
    </mat-form-field>
  </div>
</ng-template>
