<div class="contact-dialog">
  <div class="contact-dialog__header">
    <div><h2 mat-dialog-title>Contacts List</h2></div>
  </div>
  <div class="contact-dialog__container">
    <div class="contact-dialog__container-content">
      <!-- Search -->
      <form [formGroup]="formGroup">
        <div class="contact-dialog__search-container">
          <div class="contact-dialog__search-content">
            <mat-form-field class="contact-dialog__search-search" >
              <!-- Search Field -->
              <input
                matInput
                type="text"
                autofocus
                placeholder="Filter First Name, Last Name, Phone, etc."
                autocomplete="off"
                [formControlName]="ContactSearch.Search"
                id="contact-dialog__search-first-name-input" />
            </mat-form-field>
          </div>
        </div>
      </form>

      <!-- Content -->
      <mat-dialog-content>
        <mat-table class="contact-dialog__grid" #table [dataSource]="dataSource$ | async">
          <!-- Select radio button -->
          <ng-container matColumnDef="select">
            <th class="contact-dialog__match-select-radio">
              <mat-header-cell *matHeaderCellDef>Select</mat-header-cell>
            </th>
            <td>
              <mat-cell *matCellDef="let element" (click)="handleSelectContact(element)">
                <mat-radio-button></mat-radio-button>
              </mat-cell>
            </td>
          </ng-container>

          <!-- First Name -->
          <ng-container matColumnDef="firstName">
            <th>
              <mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
            </th>
            <td>
              <mat-cell *matCellDef="let element">{{ element?.firstName }}</mat-cell>
            </td>
          </ng-container>

          <!-- Last Name -->
          <ng-container matColumnDef="lastName">
            <th>
              <mat-header-cell *matHeaderCellDef>Last Name</mat-header-cell>
            </th>
            <td>
              <mat-cell *matCellDef="let element">{{ element?.lastName }}</mat-cell>
            </td>
          </ng-container>

          <!-- Email -->
          <ng-container matColumnDef="email">
            <th>
              <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
            </th>
            <td>
              <mat-cell *matCellDef="let element">{{ element?.email }}</mat-cell>
            </td>
          </ng-container>

          <!-- Phone -->
          <ng-container matColumnDef="phoneNbr">
            <th>
              <mat-header-cell *matHeaderCellDef>Phone</mat-header-cell>
            </th>
            <td>
              <mat-cell *matCellDef="let element">{{ element?.phoneNbr }}</mat-cell>
            </td>
          </ng-container>

          <!-- Phone Ext -->
          <ng-container matColumnDef="phoneExt">
            <th>
              <mat-header-cell *matHeaderCellDef>Ext</mat-header-cell>
            </th>
            <td>
              <mat-cell *matCellDef="let element">{{ element?.phoneExt }}</mat-cell>
            </td>
          </ng-container>

          <!-- Fax -->
          <ng-container matColumnDef="faxNbr">
            <th>
              <mat-header-cell *matHeaderCellDef>Fax</mat-header-cell>
            </th>
            <td>
              <mat-cell *matCellDef="let element">{{ element?.faxNbr }}</mat-cell>
            </td>
          </ng-container>

          <!-- Column Headers -->
          <tr><mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row></tr>
          <tr><mat-row *matRowDef="let row; columns: columnsToDisplay; let i = index;" (click)="handleSelectRow(row, i)" class="contact-dialog__grid_row"></mat-row></tr>
        </mat-table>
        <div *ngIf="!(dataSource$ | async)" class="contact-dialog__no-data">
          No matches found.
        </div>
      </mat-dialog-content>
    </div>
  </div>

	<mat-dialog-actions class="contact-dialog__action-buttons-container">
		<button mat-button color="primary" (click)="handleCancelClicked()">Cancel</button>
		<button mat-raised-button color="primary" [disabled]="!selectedContact" (click)="handleMatchClicked()">Select</button>
	</mat-dialog-actions>
</div>


