import { Pipe, PipeTransform } from '@angular/core';
import { DocumentSearch } from '@xpo-ltl/sdk-documentmanagement';
import * as moment from 'moment-timezone';
import { DocType } from '../classes/doc-type.enum';
import { DocTypePipe } from './doc-type.pipe';
const moment2 = moment;

@Pipe({
  name: 'documentSearchPipe',
})
export class DocumentSearchPipe implements PipeTransform {
  constructor(private docTypePipe: DocTypePipe) {}

  transform(value: DocumentSearch): any {
    if (!value) {
      return undefined;
    }
    return `${this.docTypePipe.transform(<DocType>value.cdt.docClass)} - ${moment2(value.timestampISO).format(
      'MM/DD/YYYY hh:mm'
    )}`;
  }
}
