import { ClaimAdditionalPaymentReasonCd, ClaimDecisionCategoryCd, ClaimDecisionSubCategoryCd, ClaimTypeCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { PaymentState } from '../../../../enums';

export interface DecisionToSubCategories {
  code: ClaimDecisionCategoryCd;
  subCodes: ClaimDecisionSubCategoryCd[];
}

interface ClaimTypeToDecisionMap {
  [key: string]: DecisionToSubCategories[];
}

interface ClaimStateToDecisionMap {
  [key: string]: ClaimTypeToDecisionMap;
}

export class ClaimDecisionReasons {
  private static paymentStateToDecisionMap: ClaimStateToDecisionMap = {
    [PaymentState.Approving]: {
      [ClaimTypeCd.DAMAGE]: [
        {
          code: ClaimDecisionCategoryCd.CONCEALED_DAMAGE,
          subCodes: [ClaimDecisionSubCategoryCd.COMPROMISE_SETTLEMENT],
        },
        {
          code: ClaimDecisionCategoryCd.DAMAGE_DUE_TO,
          subCodes: [
            ClaimDecisionSubCategoryCd.CONTAMINATION,
            ClaimDecisionSubCategoryCd.FORKLIFT,
            ClaimDecisionSubCategoryCd.FREEZING,
            ClaimDecisionSubCategoryCd.HEAT,
            ClaimDecisionSubCategoryCd.WATER,
            ClaimDecisionSubCategoryCd.MISHANDLING,
          ],
        },
        {
          code: ClaimDecisionCategoryCd.LOADING,
          subCodes: [
            ClaimDecisionSubCategoryCd.DIRECTIONAL_MARKINGS_NOT_FOLLOWED,
            ClaimDecisionSubCategoryCd.NO_FORKLIFT_EXTENSION,
            ClaimDecisionSubCategoryCd.NO_STRAPS,
            ClaimDecisionSubCategoryCd.IMPROPER_BLOCKING,
            ClaimDecisionSubCategoryCd.IMPROPER_STACKING,
            ClaimDecisionSubCategoryCd.LOADED_HEAVY_FREIGHT_ON_LIGHT_FREIGHT,
            ClaimDecisionSubCategoryCd.PAPER_BAG_DAMAGE,
            ClaimDecisionSubCategoryCd.SAFE_STACK_IMPROPERLY_INSTALLED,
          ],
        },
        {
          code: ClaimDecisionCategoryCd.PACKAGING,
          subCodes: [ClaimDecisionSubCategoryCd.FREIGHT_UNPROTECTED, ClaimDecisionSubCategoryCd.ORIGIN_PICKUP_FREIGHT_IMPROPERLY_PACKAGED],
        },
        {
          code: ClaimDecisionCategoryCd.POLICY_PAY_DAMAGE,
          subCodes: [ClaimDecisionSubCategoryCd.POLICY_PAY_DAMAGE],
        },
        {
          code: ClaimDecisionCategoryCd.UNDETERMINED,
          subCodes: [ClaimDecisionSubCategoryCd.UNDETERMINED],
        },
        {
          code: ClaimDecisionCategoryCd.WRECK_AND_FIRE,
          subCodes: [ClaimDecisionSubCategoryCd.WRECK_AND_FIRE],
        },
      ],
      [ClaimTypeCd.SHORTAGE]: [
        {
          code: ClaimDecisionCategoryCd.POLICY_PAY_LOSS,
          subCodes: [ClaimDecisionSubCategoryCd.POLICY_PAY_LOSS],
        },
        {
          code: ClaimDecisionCategoryCd.SHORT_AT_DELIVERY,
          subCodes: [ClaimDecisionSubCategoryCd.MISDELIVERY, ClaimDecisionSubCategoryCd.ON_DELIVERY_AT_DESTINATION_SIC, ClaimDecisionSubCategoryCd.OVER_DELIVERY],
        },
        {
          code: ClaimDecisionCategoryCd.SHORT_AT_DESTINATION,
          subCodes: [ClaimDecisionSubCategoryCd.SHORT_OFF_DROP_OR_TRAP_TRAILER, ClaimDecisionSubCategoryCd.SHORT_OFF_DOCK, ClaimDecisionSubCategoryCd.UNDETERMINED],
        },
        {
          code: ClaimDecisionCategoryCd.SHORT_AT_ORIGIN,
          subCodes: [ClaimDecisionSubCategoryCd.SHORT_OFF_PICKUP_AND_DELIVERY_UNIT, ClaimDecisionSubCategoryCd.SHORT_OFF_DOCK],
        },
        {
          code: ClaimDecisionCategoryCd.SHORT_DUE_TO_BILLING_ERROR,
          subCodes: [ClaimDecisionSubCategoryCd.SHORT_DUE_TO_BILLING_ERROR],
        },
        {
          code: ClaimDecisionCategoryCd.SHORT_DUE_TO_MISLOAD,
          subCodes: [ClaimDecisionSubCategoryCd.AT_FAC, ClaimDecisionSubCategoryCd.AT_ORIGIN_SC],
        },
        {
          code: ClaimDecisionCategoryCd.SHORTAGE_FROM_OPEN_OR_DAMAGED_CARTON,
          subCodes: [ClaimDecisionSubCategoryCd.SHORT_OPEN_OR_DAMAGED, ClaimDecisionSubCategoryCd.OPEN_CARTON_AT_DELIVERY_OR_NO_INSPECTION_BY_DSR],
        },
        {
          code: ClaimDecisionCategoryCd.SHORT_AT_ARRIVAL,
          subCodes: [ClaimDecisionSubCategoryCd.AT_DESTINATION_FROM_FAC, ClaimDecisionSubCategoryCd.AT_FAC_FROM_ORIGIN_SIC, ClaimDecisionSubCategoryCd.AT_FAC_FROM_PRIOR_FAC],
        },
        {
          code: ClaimDecisionCategoryCd.SHRINK_WRAP_BROKEN,
          subCodes: [ClaimDecisionSubCategoryCd.AT_FAC, ClaimDecisionSubCategoryCd.AT_ORIGIN_SC, ClaimDecisionSubCategoryCd.ON_DELIVERY_AT_DESTINATION_SIC, ClaimDecisionSubCategoryCd.UNDETERMINED],
        },
        {
          code: ClaimDecisionCategoryCd.SHIPPER_LOAD_AND_COUNT_PROCEDURE_VIOLATION,
          subCodes: [ClaimDecisionSubCategoryCd.FAC, ClaimDecisionSubCategoryCd.AT_ORIGIN_SC],
        },
        {
          code: ClaimDecisionCategoryCd.CONSIGNEE_DROP_TRAILER_PROCEDURE_VIOLATION,
          subCodes: [ClaimDecisionSubCategoryCd.SHIPPER_LOAD_AND_COUNT_DESTINATION_VIOLATION],
        },
        {
          code: ClaimDecisionCategoryCd.THEFT,
          subCodes: [ClaimDecisionSubCategoryCd.AT_DESTINATION_SIC, ClaimDecisionSubCategoryCd.AT_FAC, ClaimDecisionSubCategoryCd.AT_ORIGIN_SC],
        },
        {
          code: ClaimDecisionCategoryCd.UNDETERMINED,
          subCodes: [ClaimDecisionSubCategoryCd.UNDETERMINED],
        },
      ],
    },
    [PaymentState.Declining]: {
      [ClaimTypeCd.DAMAGE]: [
        {
          code: ClaimDecisionCategoryCd.CLEAR_DELIVERY,
          subCodes: [ClaimDecisionSubCategoryCd.CARRIER_LIABILITY_NOT_ESTABLISHED],
        },
        {
          code: ClaimDecisionCategoryCd.DUPLICATE_CLAIM,
          subCodes: [ClaimDecisionSubCategoryCd.DUPLICATE_CLAIM],
        },
        {
          code: ClaimDecisionCategoryCd.FAILED_TO_HOLD_CONTAINER_PACKAGING_OR_CONTENTS,
          subCodes: [ClaimDecisionSubCategoryCd.NOT_HELD_FOR_INSPECTION_OR_SALVAGE],
        },
        {
          code: ClaimDecisionCategoryCd.FILED_LATE,
          subCodes: [
            ClaimDecisionSubCategoryCd.DOMESTIC_FORWARDER_CLAIM_NOT_FILED_WITHIN_90_DAYS,
            ClaimDecisionSubCategoryCd.INTL_FORWARDER_CLAIM_NOT_FILED_WITHIN_120_DAYS,
            ClaimDecisionSubCategoryCd.INTL_FORWARDER_INTENT_NOT_WITHIN_7_DAYS_FROM_DLVY,
            ClaimDecisionSubCategoryCd.OCEAN_AND_NOT_WITHIN_ONE_YR_OF_DLVY_DATE,
            ClaimDecisionSubCategoryCd.ORIGINATED_IN_CANADA_NOT_WITHIN_60_DAYS_OF_DLVY,
            ClaimDecisionSubCategoryCd.ORIGINATED_IN_US_NOT_WITHIN_9_MONTHS_OF_DLVY,
          ],
        },
        {
          code: ClaimDecisionCategoryCd.NOT_HANDLED_BY_XPO,
          subCodes: [ClaimDecisionSubCategoryCd.NO_RECORD_OF_RECEIPT_AND_OR_HANDLING],
        },
        {
          code: ClaimDecisionCategoryCd.INADEQUATE_DOCUMENTS,
          subCodes: [
            ClaimDecisionSubCategoryCd.NO_3_PL_SUPPORT,
            ClaimDecisionSubCategoryCd.REQUESTED_DOCUMENTATION_NOT_PROVIDED,
            ClaimDecisionSubCategoryCd.NO_FORWARDER_SUPPORT,
            ClaimDecisionSubCategoryCd.ORIGINAL_INVOICE_NOT_PROVIDED,
            ClaimDecisionSubCategoryCd.NO_PROOF_OF_LOSS,
            ClaimDecisionSubCategoryCd.NO_REPAIR_INVOICE,
            ClaimDecisionSubCategoryCd.NO_WEIGHT_PROVIDED,
          ],
        },
        {
          code: ClaimDecisionCategoryCd.LIABILITY_NOT_MITIGATED_BY_CLAIMANT,
          subCodes: [ClaimDecisionSubCategoryCd.LIABILITY_NOT_MITIGATED_BY_CLAIMANT],
        },
        {
          code: ClaimDecisionCategoryCd.AGREEMENT,
          subCodes: [
            ClaimDecisionSubCategoryCd.NOCLAIMS_UNDER_SPECIFIC_DOLLAR_THRESHOLD,
            ClaimDecisionSubCategoryCd.FREIGHT_RETURNED_ON_NON_REVENUE_ORDER,
            ClaimDecisionSubCategoryCd.ONLY_THE_SHIPPER_WILL_CLAIM_VS_CUSTOMER,
            ClaimDecisionSubCategoryCd.REFER_TO_SHIPPER_FOR_CREDIT_AND_OR_REPLACEMENT,
          ],
        },
        {
          code: ClaimDecisionCategoryCd.SHIPPER_LOAD_AND_COUNT,
          subCodes: [ClaimDecisionSubCategoryCd.EXCEPTION_NOTED_AT_FIRST_CHECKPOINT],
        },
        {
          code: ClaimDecisionCategoryCd.DAMAGED_AT_PICKUP,
          subCodes: [ClaimDecisionSubCategoryCd.EXCEPTION_NOTED_ON_BILL_OF_LADING],
        },
        {
          code: ClaimDecisionCategoryCd.SPECIAL_DAMAGES_XPO_NOT_LIABLE,
          subCodes: [ClaimDecisionSubCategoryCd.LOST_PROFIT_INCOME_ATTY_FEE_CONSEQUENTIAL_DMG],
        },
        {
          code: ClaimDecisionCategoryCd.SUPERVISED_LOAD_OR_UNLOAD,
          subCodes: [ClaimDecisionSubCategoryCd.DSR_UNDER_SHIPPEROR_CONSIGNEE_SUPERVISION],
        },
        {
          code: ClaimDecisionCategoryCd.VAGUE_DELIVERY_EXCEPTION,
          subCodes: [ClaimDecisionSubCategoryCd.VAGUE_DELIVERY_EXCEPTION],
        },
        {
          code: ClaimDecisionCategoryCd.VEHICULAR_ACCIDENT,
          subCodes: [ClaimDecisionSubCategoryCd.WRECK_AND_FIRE],
        },
        {
          code: ClaimDecisionCategoryCd.CANCELLED_BY_CUSTOMER,
          subCodes: [ClaimDecisionSubCategoryCd.CANCELLED_BY_CUSTOMER],
        },
      ],
      [ClaimTypeCd.SHORTAGE]: [
        {
          code: ClaimDecisionCategoryCd.CLEAR_DELIVERY,
          subCodes: [ClaimDecisionSubCategoryCd.CARRIER_LIABILITY_NOT_ESTABLISHED],
        },
        {
          code: ClaimDecisionCategoryCd.CROSS_OR_OVER_DELIVERY,
          subCodes: [ClaimDecisionSubCategoryCd.FREIGHT_RECOVERED],
        },
        {
          code: ClaimDecisionCategoryCd.DUPLICATE_CLAIM,
          subCodes: [ClaimDecisionSubCategoryCd.DUPLICATE_CLAIM],
        },
        {
          code: ClaimDecisionCategoryCd.FILED_LATE,
          subCodes: [
            ClaimDecisionSubCategoryCd.DOMESTIC_FORWARDER_CLAIM_NOT_FILED_WITHIN_90_DAYS,
            ClaimDecisionSubCategoryCd.INTL_FORWARDER_CLAIM_NOT_FILED_WITHIN_120_DAYS,
            ClaimDecisionSubCategoryCd.INTL_FORWARDER_INTENT_NOT_WITHIN_7_DAYS_FROM_DLVY,
            ClaimDecisionSubCategoryCd.OCEAN_AND_NOT_WITHIN_ONE_YR_OF_DLVY_DATE,
            ClaimDecisionSubCategoryCd.ORIGINATED_IN_CANADA_NOT_WITHIN_60_DAYS_OF_DLVY,
            ClaimDecisionSubCategoryCd.ORIGINATED_IN_US_NOT_WITHIN_9_MONTHS_OF_DLVY,
          ],
        },
        {
          code: ClaimDecisionCategoryCd.NOT_HANDLED_BY_XPO,
          subCodes: [ClaimDecisionSubCategoryCd.NO_RECORD_OF_RECEIPT_AND_OR_HANDLING],
        },
        {
          code: ClaimDecisionCategoryCd.RETURNED_TO_SHIPPER,
          subCodes: [ClaimDecisionSubCategoryCd.RETURNED_TO_SHIPPER],
        },
        {
          code: ClaimDecisionCategoryCd.INADEQUATE_DOCUMENTS,
          subCodes: [
            ClaimDecisionSubCategoryCd.NO_3_PL_SUPPORT,
            ClaimDecisionSubCategoryCd.REQUESTED_DOCUMENTATION_NOT_PROVIDED,
            ClaimDecisionSubCategoryCd.NO_FORWARDER_SUPPORT,
            ClaimDecisionSubCategoryCd.ORIGINAL_INVOICE_NOT_PROVIDED,
            ClaimDecisionSubCategoryCd.NO_PROOF_OF_LOSS,
            ClaimDecisionSubCategoryCd.NO_WEIGHT_PROVIDED,
          ],
        },
        {
          code: ClaimDecisionCategoryCd.SHIPMENT_FULL_DELIVERY,
          subCodes: [ClaimDecisionSubCategoryCd.PART_SHORTAGE_LOCATED_OR_DELIVERED],
        },
        {
          code: ClaimDecisionCategoryCd.SHIPPER_AGREEMENT,
          subCodes: [
            ClaimDecisionSubCategoryCd.NOCLAIMS_UNDER_SPECIFIC_DOLLAR_THRESHOLD,
            ClaimDecisionSubCategoryCd.PER_SHIPPER_DIRECTIONSCLAIMS_TO_BE_DECLINED,
            ClaimDecisionSubCategoryCd.REFER_TO_SHIPPER_FOR_CREDIT_AND_OR_REPLACEMENT,
          ],
        },
        {
          code: ClaimDecisionCategoryCd.SHIPPER_LOAD_AND_COUNT,
          subCodes: [ClaimDecisionSubCategoryCd.EXCEPTION_NOTED_AT_FIRST_CHECKPOINT],
        },
        {
          code: ClaimDecisionCategoryCd.SHORT_AT_PICKUP,
          subCodes: [ClaimDecisionSubCategoryCd.EXCEPTION_NOTED_ON_BILL_OF_LADING],
        },
        {
          code: ClaimDecisionCategoryCd.SHORTAGE_PARTIALLY_CLEARED,
          subCodes: [ClaimDecisionSubCategoryCd.CLAIMANT_REFUSED_TO_AMEND_CLAIM],
        },
        {
          code: ClaimDecisionCategoryCd.SHRINK_WRAP_INTACT,
          subCodes: [ClaimDecisionSubCategoryCd.SHRINK_WRAP_INTACT],
        },
        {
          code: ClaimDecisionCategoryCd.SPECIAL_DAMAGES_XPO_NOT_LIABLE,
          subCodes: [ClaimDecisionSubCategoryCd.LOST_PROFIT_INCOME_ATTY_FEE_CONSEQUENTIAL_DMG],
        },
        {
          code: ClaimDecisionCategoryCd.CANCELLED_BY_CUSTOMER,
          subCodes: [ClaimDecisionSubCategoryCd.CANCELLED_BY_CUSTOMER],
        },
      ],
    },
  };

  public static additionalPaymentReasons = [
    ClaimAdditionalPaymentReasonCd.CUSTOMER_ADDED_ADDITIONAL_ITEMS_OR_FREIGHT,
    ClaimAdditionalPaymentReasonCd.CUSTOMER_PROVIDED_MISSING_INFORMATION,
    ClaimAdditionalPaymentReasonCd.INCORRECT_LIABILITY_LIMIT_USED,
    ClaimAdditionalPaymentReasonCd.LEGAL_DECISION,
    ClaimAdditionalPaymentReasonCd.SHOULD_HAVE_PAID_FREIGHT_CHARGES,
    ClaimAdditionalPaymentReasonCd.OTHER,
  ];

  // override specific codes with custom strings
  public static codeOverrides: { [key: string]: string } = {
    [ClaimDecisionCategoryCd.NOT_HANDLED_BY_XPO]: 'Not Handled By XPO',
    [ClaimDecisionCategoryCd.SPECIAL_DAMAGES_XPO_NOT_LIABLE]: 'Special Damages; XPO Not Liable',

    [ClaimDecisionSubCategoryCd.NO_3_PL_SUPPORT]: 'No 3PL Support',
    [ClaimDecisionSubCategoryCd.NOCLAIMS_UNDER_SPECIFIC_DOLLAR_THRESHOLD]: 'No Claims Under Specific Dollar Threshold',
    [ClaimDecisionSubCategoryCd.DSR_UNDER_SHIPPEROR_CONSIGNEE_SUPERVISION]: 'DSR Under Shipper Or Consignee Supervisor',
    [ClaimDecisionSubCategoryCd.LOST_PROFIT_INCOME_ATTY_FEE_CONSEQUENTIAL_DMG]: 'Lost Profit, Income, Attorney Fees, Consequential Damage',
  };

  /**
   * Returns list of ClaimDecisionCategoryCds for the given claimType
   */
  public static decisionCodesForClaimType(paymentState: PaymentState, claimCd: ClaimTypeCd): ClaimDecisionCategoryCd[] {
    const declinationStates = [PaymentState.Declining, PaymentState.DecliningAfterEscalation, PaymentState.PendingDeclinationFormReview];

    const state = _.some(declinationStates, d => d === paymentState) ? PaymentState.Declining : PaymentState.Approving;

    const decisionMap = _.get(ClaimDecisionReasons.paymentStateToDecisionMap, state, []);
    const decisionToSubCategories: DecisionToSubCategories[] = _.get(decisionMap, claimCd, []);
    return _.map<DecisionToSubCategories[], ClaimDecisionCategoryCd>(decisionToSubCategories, (reason: DecisionToSubCategories) => reason.code);
  }

  /**
   * Returns list of ClaimDecisionSubCategoryCds for the given claimType and ClaimDecisionCategoryCd
   */
  public static decisionSubCodesForDecisionCode(paymentState: PaymentState, claimCd: ClaimTypeCd, decisionCd: ClaimDecisionCategoryCd): ClaimDecisionSubCategoryCd[] {
    const state = paymentState === PaymentState.Declining ? PaymentState.Declining : PaymentState.Approving;

    const decisionMap = _.get(ClaimDecisionReasons.paymentStateToDecisionMap, state, []);
    const decisionToSubCategories: DecisionToSubCategories[] = _.get(decisionMap, claimCd, []);
    return _.get(_.find(decisionToSubCategories, cat => decisionCd === cat.code), 'subCodes', []);
  }
}
