import { Pipe, PipeTransform } from '@angular/core';
import { ConditioningService } from '@xpo-ltl/common-services';

@Pipe({
  name: 'conditionPro'
})
export class ConditionProPipe implements PipeTransform {
  constructor(private conditioningService: ConditioningService) {}

  transform(value: any, args?: any): any {
    return this.conditioningService.conditionProNumber(value);
  }
}
