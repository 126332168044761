export enum DocType {
  ActivityLogHistory = 'ACTL',
  All = 'ALL',
  BillOfLading = 'BL',
  BillOfLadingAttachment = 'BLAT',
  CheckSheet = 'CHKS',
  ClaimForm = 'CLM',
  ClaimNotes = 'CLMN',
  ClaimsAttachment = 'CLAT',
  ClaimsProductionUnknown = 'PXFC',
  ClosedClaimFolder = 'CLMH',
  CrossDockExceptionPhotos = 'DXCF',
  CustomerProvidedDocuments = 'CUPD',
  CustomsDocuments = 'CUST',
  CustomsReleased = 'CUSR',
  DeliveryExceptionPhoto = 'DXFO',
  DeliveryReceipt = 'DR',
  DeliveryReceiptAttachment = 'DRAT',
  DeliveryReturnReceipt = 'DRRR',
  FlySheet = 'FLYS',
  InMotionDimensionerPhoto = 'DMNR',
  InspectionReport = 'IR',
  InspectionReportPhoto = 'IRFO',
  InvoicePdf = 'ARIN',
  LetterOfAuthority = 'LOA',
  MatchMailAcknowledgement = 'MMAK',
  PickupPhotos = 'PUFO',
  NmfcClassInspectionCertificate = 'NCIC',
  OSDPhoto = 'OSDF',
  PoorlyPackagedOnDock = 'DXPP',
  PoorlyPackagedPickUp = 'PPPP',
  TrailerPhotoClose = 'TLFO',
  TrailerPhotoOpen = 'TOFO',
  TrailerPhotoProgressive = 'TPFO',
  UnloadPhoto = 'TUFO',
  WeightAndResearchPhotos = 'WRFO',
  WeightInspectionCertificate = 'WI',
  MobileDimensionerPhoto = 'PDFO',
}
