import { Directive, ElementRef, OnInit, HostListener, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import * as _ from 'lodash';
import { NgControl } from '@angular/forms';
import { EventKeyCode } from '../enums/event-keycode.enum';

/*
Angular implentation ported from https://github.com/plentz/jquery-maskmoney with some additions.

 Options:
  The options that you can set in the currencyInputSettings object that is passed in are:
  prefix: the prefix to be displayed before the value entered by the user(example: "$1234.23"). default: '$'
  suffix: the suffix to be displayed after the value entered by the user(example: "1234.23€"). default: ''
  affixesStay: set if the prefix and suffix will stay in the field's value after the user exits the field. default: true
  thousands: the thousands separator. default: ','
  decimal: the decimal separator. default: '.'
  precision: how many decimal places are allowed. default: 2
  allowNegative: use this setting to prevent users from inputing negative values. default: false
  reverse: by default, maskMoney applies keystrokes from right to left. use this setting to apply keystrokes from left to right.
  allowEmpty: allow empty input values, so that when you delete the number it doesn't reset to 0.00. default: false
  maxLength: The max length allowed in the input box. Default is to not calculate max length.
  includeAllCharsInMaxLength: Whether to include all characters (ie. prefix, suffix, decimal, thousands separator) in calculation for
  max length. Defaults to true.
*/

export class CurrencyInputMask {
  prefix = '$';
  suffix = '';
  affixesStay = true;
  thousands = ',';
  decimal = '.';
  reverse = false;
  precision = 2;
  allowNegative = false;
  doubleClickSelection = true;
  allowEmpty = false;
  bringCaretAtEndOnFocus = false;
  maxLength = -1;
  includeAllCharsInMaxLength: true;
}

export enum PreventKeyValues {
  Apostrophe = '\'',
  M = 'm'
}

export enum KeyboardAction {
  Cut = 'cut',
  Paste = 'paste',
  Copy = 'copy'
}

export interface InputSelection {
  start: number;
  end: number;
}

@Directive({
  selector: '[xpoCurrencyInputMask]',
})
export class XpoCurrencyInputMaskDirective implements OnInit {
  private settings: CurrencyInputMask;
  private _$event;
  private numbersRegex = new RegExp('[^0-9]', 'g');
  private removeZerosRegex = new RegExp('^0*', 'g');
  private thousandsRegex = new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g');
  private onlyZerosRegex = new RegExp('^0*$');

  @Input()
  currencyInputSettings: CurrencyInputMask = new CurrencyInputMask();

  get $event() {
    return this._$event;
  }
  set $event(e) {
    this._$event = e;
  }

  get defaultValue() {
    if (this.currencyInputSettings.allowEmpty) {
      return '';
    }

    let defaultValue = '';
    defaultValue += this.settings.prefix;
    defaultValue += '0';
    if (this.settings.precision > 0) {
      defaultValue += this.settings.decimal;
      defaultValue += new Array(this.settings.precision + 1).join('0');
    }
    defaultValue += this.settings.suffix;
    return defaultValue;
  }

  constructor(public elementRef: ElementRef, private control: NgControl, private _clipboardService: ClipboardService) { }

  ngOnInit(): void {
    this.mapCurrencyInputSettings();
    this.setInitialElementValue();
  }

  @HostListener('keydown', ['$event'])
  onKeydown($event: KeyboardEvent) {
    this.$event = $event;
    this.handleKeydownEvent();
  }

  @HostListener('keypress', ['$event'])
  onKeypress($event: KeyboardEvent) {
    this.$event = $event;
    this.handleKeypressEvent();
  }

  @HostListener('focus', ['$event'])
  onFocus($event: KeyboardEvent) {
    this.$event = $event;
    this.handleFocusEvent();
  }

  @HostListener('paste', ['$event'])
  onPaste($event: KeyboardEvent) {
    this.$event = $event;
    this.preventDefault();
    this.handleCutPasteEvent();
  }

  @HostListener('cut', ['$event'])
  onCut($event: KeyboardEvent) {
    this.$event = $event;
    this.preventDefault();
    this.handleCutPasteEvent();
  }

  @HostListener('click', ['$event'])
  onClick($event: KeyboardEvent) {
    this.$event = $event;
    this.handleClickEvent();
  }

  @HostListener('doubleclick', ['$event'])
  onDoubleClick($event: KeyboardEvent) {
    this.$event = $event;
    this.handleDoubleClickEvent();
  }

  private setInitialElementValue(): void {
    const controlValue = this.control.control.value;
    const input = this.elementRef.nativeElement;
    this.elementRef.nativeElement.value = input.value || this.defaultValue;

    if (this.elementRef.nativeElement.value !== controlValue) {
      this.setInput(input.value);
    }
  }

  private mapCurrencyInputSettings(): void {
    this.settings = _.merge(new CurrencyInputMask(), _.clone(this.currencyInputSettings));
  }

  private handleKeypressEvent(): boolean | void {
    const key = this.$event.which || this.$event.charCode || this.$event.keyCode,
      decimalKeyCode = this.settings.decimal.charCodeAt(0);

    const isAllTextSelected = () => {
      const length = this.elementRef.nativeElement.value.length,
        selection = this.getInputSelection();
      // This should if all text is selected or if the
      // input is empty.
      return selection.start === 0 && selection.end === length;
    };

    const alreadyContainsDecimal = () => {
      return this.elementRef.nativeElement.value.indexOf(this.settings.decimal) > -1;
    };

    const shouldPreventDecimalKey = () => {
      // If all text is selected, we can accept the decimal
      // key because it will replace everything.
      if (isAllTextSelected()) {
        return false;
      }

      return alreadyContainsDecimal();
    };

    // added to handle an IE "special" event
    if (!key) {
      return false;
    }

    if (key === EventKeyCode.V && !!this.$event.ctrlKey) {
      this.preventDefault();
      return false;
    }

    // any key except the numbers 0-9.
    if ((key < EventKeyCode.Zero || key > EventKeyCode.Nine) && key !== decimalKeyCode) {
      return this.handleAllKeysExceptNumericalDigits(key);
    } else if (!this.canInputMoreNumbers()) {
      this.preventDefault();
      return false;
    } else {
      this.preventDefault();

      if (key === decimalKeyCode && shouldPreventDecimalKey()) {
        return false;
      }

      this.applyMask();
    }
  }

  private handleKeydownEvent(): boolean {
    const key = this.$event.which || this.$event.charCode || this.$event.keyCode,
      input = this.elementRef.nativeElement;
    let selection, startPos, endPos, value, lastNumber, decimalPointIndex, isNumber;

    // needed to handle an IE "special" event
    if (!key) {
      return false;
    }

    selection = this.getInputSelection();
    startPos = selection.start;
    endPos = selection.end;

    if (EventKeyCode.V && !!this.$event.ctrlKey) {
      return false;
    }

    if (key === EventKeyCode.Backspace || key === EventKeyCode.Delete || key === EventKeyCode.DeleteSafari) {
      // backspace or delete key (with special case for safari)
      this.preventDefault();

      if (this.elementRef.nativeElement === this.defaultValue) {
        return false;
      }

      value = input.value;
      isNumber = !isNaN(value);
      decimalPointIndex = isNumber ? value.indexOf('.') : value.indexOf(this.settings.decimal);

      // not a selection
      if (startPos === endPos) {
        // backspace
        if (key === EventKeyCode.Backspace && this.canBackspaceDelete()) {
          if (this.settings.suffix === '') {
            startPos -= 1;
          } else {
            // needed to find the position of the last number to be erased
            lastNumber = value
              .split('')
              .reverse()
              .join('')
              .search(/\d/);
            startPos = value.length - lastNumber - 1;
            endPos = startPos + 1;
          }

          if (decimalPointIndex === startPos || value[startPos] === this.settings.thousands) {
            startPos -= 1;
          }

          // delete
        } else {
          endPos += 1;

          if (decimalPointIndex === endPos || value[startPos] === this.settings.thousands || value[startPos] === this.settings.decimal) {
            endPos += 1;
          }
        }
      }

      this.elementRef.nativeElement.value = value.substring(0, startPos) + value.substring(endPos, value.length);
      this.maskAndPosition(startPos);
      return false;
    } else {
      // any other key
      return true;
    }
  }

  private handleFocusEvent(): void {
    const input = this.elementRef.nativeElement;
    let textRange;

    if (input.createTextRange && this.settings.bringCaretAtEndOnFocus) {
      textRange = input.createTextRange();
      textRange.collapse(false); // set the cursor at the end of the input
      textRange.select();
    }
  }

  private handleCutPasteEvent(): void | boolean {
    const currentInputValue = this.elementRef.nativeElement.value,
      selection = this.getInputSelection(),
      startPos = selection.start,
      endPos = selection.end;

    let updatedValue;

    // Need to manually set input value on cut and paste to update value from clipboard and not fire change event
    if (this.$event.type === KeyboardAction.Paste) {
      const clipboardData = this.$event.clipboardData.getData('text');
      updatedValue = currentInputValue.substring(0, startPos) + clipboardData + currentInputValue.substring(endPos);

      if (!this.canPasteMoreNumbers(updatedValue)) {
        return false;
      }
    }

    if (this.$event.type === KeyboardAction.Cut) {
      this._clipboardService.copyFromContent(currentInputValue.substring(startPos, endPos));
      updatedValue = currentInputValue.substring(0, startPos) + currentInputValue.substring(endPos);
    }

    this.mask(updatedValue);
  }

  private setInput(setVal): void {
    this.control.control.setValue(setVal);
  }

  private handleClickEvent(): void {
    const input = this.elementRef.nativeElement;
    let length;
    if (input.setSelectionRange && this.settings.bringCaretAtEndOnFocus) {
      length = input.value.length;
      input.setSelectionRange(length, length);
    }
  }

  private handleDoubleClickEvent(): void {
    const input = this.elementRef.nativeElement;
    let start, length;
    if (input.setSelectionRange && this.settings.bringCaretAtEndOnFocus) {
      length = input.value.length;
      start = this.settings.doubleClickSelection ? 0 : length;
      input.setSelectionRange(start, length);
    } else {
      this.setInput(input.value);
    }
  }

  private applyMask(): void {
    const key = this.$event.which || this.$event.charCode || this.$event.keyCode;

    const isMinusOrNegative = () => key === EventKeyCode.Minus || key === EventKeyCode.Subtract;

    let keyPressedChar = '',
      selection,
      startPos,
      endPos,
      value;

    if (key >= EventKeyCode.Zero && key <= EventKeyCode.Nine || (isMinusOrNegative() && this.currencyInputSettings.allowNegative)) {
      keyPressedChar = String.fromCharCode(key);
    }
    selection = this.getInputSelection();
    startPos = selection.start;
    endPos = selection.end;
    value = this.elementRef.nativeElement.value;
    const newVal = value.substring(0, startPos) + keyPressedChar + value.substring(endPos, value.length);
    this.elementRef.nativeElement.value = newVal;

    this.maskAndPosition(startPos + 1);
  }

  private handleAllKeysExceptNumericalDigits(key): boolean | void {
    // -(minus) key
    if (this.$event.shiftKey || this.$event.key === PreventKeyValues.Apostrophe || this.$event.key === PreventKeyValues.M) {
      // prevents shift key modifiers on numerical keys and apostrophe key which maps to same keys on different browsers
      this.preventDefault();
      return false;
    } else if (key === EventKeyCode.Minus || key === EventKeyCode.Subtract) {
      if (this.currencyInputSettings.allowNegative) {
        this.preventDefault();
        this.applyMask();
        return true;
      }
      this.setInput(this.elementRef.nativeElement.value.replace('-', ''));
      this.preventDefault();
      return false;
      // enter key or tab key
    } else if (key === EventKeyCode.Enter || key === EventKeyCode.Tab) {
      return true;
    } else if (key === EventKeyCode.LeftArrow || key === EventKeyCode.RightArrow) {
      // needed for left arrow key or right arrow key with firefox
      // the charCode part is to avoid allowing "%"(e.charCode 0, e.keyCode 37)
      return true;
    } else {
      // any other key with keycode less than 48 and greater than 57
      this.preventDefault();
      return true;
    }
  }

  private getInputSelection(): InputSelection {
    return {
      start: this.$event.target.selectionStart,
      end: this.$event.target.selectionEnd,
    };
  }

  private canInputMoreNumbers(): boolean {
    const val = this.elementRef.nativeElement.value,
      selection = this.getInputSelection(),
      start = selection.start,
      end = selection.end,
      haveNumberSelected = selection.start !== selection.end && val.substring(start, end).match(/\d/) ? true : false,
      startWithZero = val.substring(0, 1) === '0';

    let haventReachedMaxLength;

    if (this.settings.maxLength > -1) {

      if (this.settings.includeAllCharsInMaxLength) {
        const updatedMaskedValue = this.maskValue(val);
        haventReachedMaxLength = updatedMaskedValue.length < this.settings.maxLength;
      } else {
        const digitsOnly = val.replace(this.numbersRegex, '');
        haventReachedMaxLength = digitsOnly.length < this.settings.maxLength;
      }
    }

    return haventReachedMaxLength || haveNumberSelected || startWithZero;
  }

  private canPasteMoreNumbers(value): boolean {
    let canPaste = true;

    if (this.settings.maxLength > -1) {
      if (this.settings.includeAllCharsInMaxLength) {
        const updatedMaskedValue = this.maskValue(value);
        canPaste = updatedMaskedValue.length <= this.settings.maxLength;
      } else {
        const digitsOnly = value.replace(this.numbersRegex, '');
        canPaste = digitsOnly.length <= this.settings.maxLength;
      }
    }

    return canPaste;
  }

  private canBackspaceDelete(): boolean {
    const selection = this.getInputSelection(),
      start = selection.start,
      end = selection.end,
      hasSuffix = !!this.settings.suffix,
      isAtBeginning = start === end && ((start === 0 && !hasSuffix) || (start === 1 && hasSuffix)),
      isSelected = start !== end;

    return !isAtBeginning || isSelected;
  }

  private setCursorPosition(pos): void {
    const key = this.$event.which || this.$event.charCode || this.$event.keyCode,
      elem = this.elementRef.nativeElement,
      value = this.elementRef.nativeElement.value;

    if (key === EventKeyCode.Backspace && (value[pos - 1] === this.settings.decimal || value[pos - 1] === this.settings.thousands)) {
      pos -= 1;

      if (value[pos] === this.settings.prefix) {
        pos += 1;
      }
    } else if (key === EventKeyCode.Delete || key === EventKeyCode.DeleteSafari) {
      if (value[pos] === this.settings.prefix) {
        pos += 1;
      }
    }

    if (elem.setSelectionRange) {
      elem.focus();
      elem.setSelectionRange(pos, pos);
    } else if (elem.createTextRange) {
      const range = elem.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  }

  private maskAndPosition(startPos): void {
    const value = this.elementRef.nativeElement.value,
      originalLen = value.length;

    let newLen;

    this.setInput(this.maskValue(value));
    newLen = this.elementRef.nativeElement.value.length;

    if (!this.settings.reverse) {
      startPos = startPos - (originalLen - newLen);
    }

    this.setCursorPosition(startPos);
  }

  private mask(updatedVal?): void {
    let value = updatedVal || this.elementRef.nativeElement.value;
    if (this.settings.allowEmpty && value === '') {
      return;
    }

    const isNumber = !isNaN(value);
    const decimalPointIndex = isNumber ? value.indexOf('.') : value.indexOf(this.settings.decimal);
    const decimalPart = value.slice(decimalPointIndex + 1);
    const integerPart = value.slice(0, decimalPointIndex);

    if (this.settings.precision > 0 && decimalPointIndex < 0) {
      // If no decimal point index is found, place one at precision index
      const updatedIntegerPart = value.slice(0, value.length - this.settings.precision);
      const updatedDecimalPart = value.slice(-this.settings.precision);
      value = updatedIntegerPart + this.settings.decimal + updatedDecimalPart;
    } else if (decimalPointIndex > 0 && decimalPart.length < this.settings.precision) {
      // If the following decimal part dosen't have enough length against the precision, it needs to be filled with zeros.
      value = integerPart + this.settings.decimal + decimalPart + new Array(this.settings.precision + 1 - decimalPart.length).join('0');
    } else if (decimalPointIndex > 0 && this.settings.precision === 0) {
      // if the precision is 0, discard the decimal part
      value = value.slice(0, decimalPointIndex);
    }

    this.setInput(this.maskValue(value));
  }

  private setSymbol(value): string {
    let operator = '';
    if (value.indexOf('-') > -1) {
      value = value.replace('-', '');
      operator = '-';
    }
    if (value.indexOf(this.settings.prefix) > -1) {
      value = value.replace(this.settings.prefix, '');
    }
    if (value.indexOf(this.settings.suffix) > -1) {
      value = value.replace(this.settings.suffix, '');
    }
    return operator + this.settings.prefix + value + this.settings.suffix;
  }

  private preventDefault(): boolean | void {
    if (this.$event.preventDefault) {
      // standard browsers
      this.$event.preventDefault();
    } else {
      // old internet explorer
      this.$event.returnValue = false;
    }
  }

  private maskValue(value): string {
    if (this.settings.allowEmpty && value === '') {
      return value;
    }

    return this.maskValueStandard(value);
  }

  private maskValueStandard(value): string {
    const onlyNumbers = value.replace(this.numbersRegex, '');
    const negative = value.indexOf('-') > -1 && this.settings.allowNegative && !this.onlyZerosRegex.test(onlyNumbers) ? '-' : '';
    const integerPart = onlyNumbers.slice(0, onlyNumbers.length - this.settings.precision);

    let newValue, decimalPart, leadingZeros;

    newValue = this.buildIntegerPart(integerPart, negative);

    if (this.settings.precision > 0) {
      decimalPart = onlyNumbers.slice(onlyNumbers.length - this.settings.precision);
      leadingZeros = new Array(this.settings.precision + 1 - decimalPart.length).join('0');
      newValue += this.settings.decimal + leadingZeros + decimalPart;
    }
    return this.setSymbol(newValue);
  }

  private buildIntegerPart(integerPart, negative): string {
    integerPart = integerPart.replace(this.removeZerosRegex, '');

    // put settings.thousands every 3 chars
    integerPart = integerPart.replace(this.thousandsRegex, this.settings.thousands);
    if (integerPart === '') {
      integerPart = '0';
    }
    return negative + integerPart;
  }
}
