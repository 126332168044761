import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RegistrationSuccessDataInterface } from '../../interfaces/registration-success-data-interface';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationService } from '@xpo-ltl/data-api';

@Component({
  selector: 'app-registration-success',
  templateUrl: './registration-success.component.html',
  styleUrls: ['./registration-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationSuccessComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: RegistrationSuccessDataInterface, private clipboardService: ClipboardService, private notificationService: NotificationService) {}

  ngOnInit() {}

  handleCopy(claimId: string) {
    if (this.clipboardService.copyFromContent(claimId)) {
      this.notificationService.showSnackBarMessage('Claim number copied to clipboard', { durationInMillis: 3000, status: 'SUCCESS' });
    }
  }
}
