import { Observable } from 'rxjs';
import { FooterButtonTypeCd } from '../enums/footer-button-type-cd.enum';

export class ButtonConfig {
  constructor(
    public label: string,
    public typeCd: FooterButtonTypeCd,
    public disabled: boolean,
    public visible: boolean,
    public color: string,
    public raised: boolean = false,
    public disabled$?: Observable<boolean>,
    public clickHandler?: Function
  ) {}
}
