import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { ValidationRegexPatterns } from '@xpo-ltl/common-services';

export function xpoPhoneFormatValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const regexTest = new RegExp(ValidationRegexPatterns.phoneNumberPattern, 'g');
    let returnValue = null;
    if ( control.value ) {
      const nakedNumber = control.value.replace(/\D/g, '');
      if (!nakedNumber.replace(/0/g, '') || !regexTest.test(control.value)) {
        returnValue = {'phoneFormat': {value: control.value}};
      }
    }
    return returnValue;
  };
}

@Directive({
  selector: '[xpoPhoneValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: XpoPhoneFormatValidatorDirective, multi: true}]
})
export class XpoPhoneFormatValidatorDirective implements Validator {
  validate(control: AbstractControl): {[key: string]: any} {
    return xpoPhoneFormatValidator()(control);
  }
}
