<div [formGroup]="formGroup"
     class="notes">
  <as-split #splitPanel
            gutterSize="24"
            direction="horizontal"
            class="notes__content">
    <as-split-area class="notes__content-left"
                   size="50">
      <div class="notes__content-container">
        <div class="notes__content-inner">
          <mat-form-field floatLabel="never">
            <input #subject
                   type="text"
                   matInput
                   placeholder="Subject"
                   autocomplete="off"
                   [maxlength]="ClaimNoteMaxLengths.SubjectCharacters"
                   [formControlName]="NoteFormNames.SubjectTxt">
            <mat-hint align="end">{{subject?.value?.length}} / {{ClaimNoteMaxLengths.SubjectCharacters}}</mat-hint>
            <mat-error>
              <div [xpoErrors]="NoteFormNames.SubjectTxt">
                <div xpoError="required"
                     id="note-subject-input-required-error">Required</div>
              </div>
            </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="!isWebNotes">
            <mat-select placeholder="Note Type"
                        [formControlName]="NoteFormNames.TypeCd">
              <mat-option *ngFor="let noteType of noteTypes"
                          [value]="noteType">
                {{noteType}}
              </mat-option>
            </mat-select>
            <mat-error>
              <div [xpoErrors]="NoteFormNames.TypeCd">
                <div xpoError="required"
                     id="note-type-input-required-error">Required</div>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="divider"></div>
        <div class="notes__content-editor">
          <ckeditor formControlName="{{NoteFormNames.NoteTxt}}" [config]="ckConfig" (change)="detectChanges()"></ckeditor>

          <div class="notes__content-length"><span>{{formGroup.get(NoteFormNames.NoteTxt)?.value?.length}} /
            {{ClaimNoteMaxLengths.BodyCharacters}}</span></div>
          <mat-error>
            <div [xpoErrors]="NoteFormNames.NoteTxt">
              <div xpoError="required"
                   class="notes__text-error"
                   *ngIf="formGroup?.get(NoteFormNames.NoteTxt)?.touched"
                   id="note-text-input-required-error">Required</div>
              <div xpoError="maxlength" class="notes__text-error" *ngIf="formGroup?.get(NoteFormNames.NoteTxt)?.touched" id="note-text-input-max-length-error">Cannot exceed {{ClaimNoteMaxLengths.BodyCharacters}} characters</div>
            </div>
          </mat-error>
          <div class="notes__content-actionbuttons">
            <button mat-raised-button
                    color="primary"
                    (click)="addNoteClicked()"
                    [disabled]="constants.isReadOnly ? true : formGroup.invalid">+ ADD NOTE</button>
          </div>
        </div>
      </div>
    </as-split-area>
    <as-split-area size="50"
                   class="notes__detail-inner">
      <div class="notes__detail-inner-container">
        <div class="notes__detail-header">
          <h3>Notes <span *ngIf="(notes$ | async)?.length > 0">({{(notes$ | async)?.length}})</span></h3>
          <div>
            <mat-form-field
              *ngIf="!isWebNotes"
              floatLabel="never" style="margin-right: 16px;">
              <mat-select #visibility
                          [disabled]="constants.isReadOnly"
                          placeholder="All">
                <mat-option *ngFor="let visibility of visibilityTypes"
                            [value]="visibility">
                  {{visibility}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field floatLabel="never">
              <mat-select #age
                          [disabled]="constants.isReadOnly"
                          placeholder="Newest to Oldest">
                <mat-option *ngFor="let age of ageTypes"
                            [value]="age">
                  {{age}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="notes__detail_list">
          <div class="notes__detail-list-item"
               *ngFor="let note of (notes$ | async)">
            <app-notes-detail [note]="note"></app-notes-detail>
          </div>
        </div>
      </div>
    </as-split-area>
  </as-split>
</div>
