import { AbstractControl, ValidatorFn } from '@angular/forms';
import { AddressEntryFormNames } from '../enums/FormControlNames';

export function stateCodeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control || !control.value) {
      return null;
    }

    if (isUsOrCanada(control) && control.get(AddressEntryFormNames.State).value.length > 2) {
      return { maxStateCode: true };
    }

    if (isUsOrCanada(control) && control.get(AddressEntryFormNames.State).value && control.get(AddressEntryFormNames.State).value.indexOf(' ') !== -1) {
      return { hasSpace: true };
    }

    return null;
  };
}

export function isUsOrCanada(partyFormGroup): boolean {
  const country = partyFormGroup && partyFormGroup.get(AddressEntryFormNames.CountryDisplayOnly) ? partyFormGroup.get(AddressEntryFormNames.CountryDisplayOnly) : undefined;
  const countryValue = typeof country.value === 'string' ? country.value.toUpperCase() : '';
  const isUsorCanada =
    !!country && (countryValue === 'UNITED STATES' || countryValue === 'CANADA' || countryValue === 'UNITED STATES MINOR OUTLYING ISLANDS' || countryValue === 'UNITED STATES VIRGIN ISLANDS');

  return isUsorCanada;
}
