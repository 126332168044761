import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { XpoLtlBaseCacheService } from '@xpo-ltl/ngx-ltl';
import { ClaimsApiService, ListExaminerActiveSubrogatedClaimsQuery, ListExaminerActiveSubrogatedClaimsResp } from '@xpo-ltl/sdk-claims';
import * as _ from 'lodash';
import { ApiRequest } from '@xpo-ltl/data-api';
import { AssignedActiveSubrogatedCacheParams } from '../../interfaces/assigned-active-subrogated-cache-params.interface';

@Injectable()
export class ListActiveSubrogatedClaimsCacheService extends XpoLtlBaseCacheService<AssignedActiveSubrogatedCacheParams, ListExaminerActiveSubrogatedClaimsResp> {
  constructor(private claimsApiService: ClaimsApiService) {
    super();
  }

  protected getKeyFromParams(params: AssignedActiveSubrogatedCacheParams): string {
    return `${params.index}`;
  }

  protected requestData(params: AssignedActiveSubrogatedCacheParams): Observable<ListExaminerActiveSubrogatedClaimsResp> {
    const request = new ListExaminerActiveSubrogatedClaimsQuery();
    _.assign(request, _.pick(params, _.keys(request)));
    request.examinerEmployeeId = params.employeeId;
    return this.claimsApiService.listExaminerActiveSubrogatedClaims(request, { ...ApiRequest.defaultDataOptions, toastOnError: false });
  }
}
