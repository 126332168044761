<div class="notes-emails">

  <mat-tab-group #tabGroup
                 class="notes-emails__tabs">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>note</mat-icon>
        Notes ({{ internalNotesTotalCount$ | async }})
        <div [xpoBadge]="internalNotesBadgeConfig"></div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>language</mat-icon>
        Web ({{ webNotesTotalCount$ | async }})
        <div [xpoBadge]="webNotesBadgeConfig"></div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>mail</mat-icon>
        Emails ({{ emailsTotalCount$ | async }})
        <div [xpoBadge]="emailsBadgeConfig"></div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>drafts</mat-icon>
        Drafts ({{ draftsTotalCount$ | async }})
        <div [xpoBadge]="draftsBadgeConfig"></div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <button id="close"
          class="notes-emails__close"
          mat-button
          mat-icon-button
          aria-label="close"
          (click)="closeClicked()">
    <mat-icon>arrow_downward</mat-icon>
  </button>

  <div class="notes-emails__content"
       [hidden]="!(isInternalNotesActive$ | async)">
    <app-notes
      [tabChanged]="isInternalNotesActive$ | async"
      [claim]="claim$ | async"
      [notes]="internalNotes$ | async"></app-notes>
  </div>
  <div class="notes-emails__content"
    [hidden]="!(isWebNotesActive$ | async)">
    <app-notes
      [tabChanged]="isWebNotesActive$ | async"
      [isWebNotes]="true"
      [claim]="claim$ | async"
      [notes]="webNotes$ | async"></app-notes>
  </div>
  <div class="notes-emails__content"
       [hidden]="!(isEmailActive$ | async)">
    <app-emails
      [tabChanged]="isEmailActive$ | async"
      [dmsDocList$]="dmsDocList$"
      [checkClaimsRecordVersionNbrFn]="checkClaimRecordVersionNbrFn"
      [downloadDmsAttachmentFn]="handleDMSDownloadAttachment"
      [claimsRegistrationFormGroup]="claimsRegistrationFormGroup"
      [claim]="claim$ | async"
      [config]="emailConfig$ | async"></app-emails>
  </div>
    <div class="notes-emails__content"
      [hidden]="!(isDraftActive$ | async)">
    <app-emails
      [draftInd]='true'
      [tabChanged]="isDraftActive$ | async"
      [downloadDmsAttachmentFn]="handleDMSDownloadAttachment"
      [claimsRegistrationFormGroup]="claimsRegistrationFormGroup"
      [claim]="claim$ | async"
      [config]="emailConfig$ | async"></app-emails>
  </div>
</div>
