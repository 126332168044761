import { Pipe, PipeTransform } from '@angular/core';
import { ClaimInternalStatusCd } from '@xpo-ltl/sdk-common';
import { ClaimXRTInternalStatusCdEnum } from '../enums/claimXRTInternalStatusCdEnum';

@Pipe({
  name: 'claimInternalStatusCd',
})
export class ClaimInternalStatusCdPipe implements PipeTransform {
  transform(value: ClaimInternalStatusCd | ClaimXRTInternalStatusCdEnum): string {
    switch (value) {
      case ClaimInternalStatusCd.APPROVED:
      case 'APPROVED':
        return 'Approved';

      case ClaimInternalStatusCd.CEO_PENDING_APPROVAL:
        return 'Pending CEO Approval';

      case ClaimInternalStatusCd.CUSTOMER_CANCELLED:
      case ClaimXRTInternalStatusCdEnum.CUSTOMER_CANCELLED:
        return 'Customer Cancelled';

      case ClaimInternalStatusCd.DECLINED:
      case ClaimXRTInternalStatusCdEnum.DECLINED:
        return 'Declined';

      case ClaimInternalStatusCd.DIRECTOR_PENDING_APPROVAL:
        return 'Pending Director Approval';

      case ClaimInternalStatusCd.MANAGER_PENDING_APPROVAL:
      case ClaimXRTInternalStatusCdEnum.MANAGER_PENDING_APPROVAL:
        return 'Pending Manager Approval';

      case ClaimInternalStatusCd.PRESIDENT_PENDING_APPROVAL:
        return 'Pending President Approval';

      case ClaimInternalStatusCd.SUBMITTED:
      case ClaimXRTInternalStatusCdEnum.SUBMITTED:
        return 'Submitted';

      case ClaimInternalStatusCd.SVP_OF_OPS_PENDING_APPROVAL:
        return 'Pending SVP of OPS Approval';

      case ClaimInternalStatusCd.SVP_SALES_PENDING_APPROVAL:
        return 'Pending SVP of Sales Approval';

      case ClaimInternalStatusCd.UNDER_REVIEW:
      case ClaimXRTInternalStatusCdEnum.UNDER_REVIEW:
        return 'Under Review';

      case ClaimInternalStatusCd.UNSUBMITTED:
      case ClaimXRTInternalStatusCdEnum.UNSUBMITTED:
        return 'Unsubmitted';

      case ClaimInternalStatusCd.RVP_PENDING_APPROVAL:
        return 'Pending RVP Approval';

      case ClaimInternalStatusCd.RVP_SALES_PENDING_APPROVAL:
        return 'Pending RVP Sales Approval';

      default:
        return value || 'UNKNOWN';
    }
  }
}
