import { Injectable } from '@angular/core';
import {
  DocumentManagementApiService,
  RetrieveDmsAuthTokenResp,
  SearchDmsDocumentResp,
  SearchDmsDocumentRqst,
  GetDocumentPath,
  GetDocumentQuery,
  GetDocumentResp,
  RetrieveDmsAuthTokenRqst,
  DocumentSearch,
} from '@xpo-ltl/sdk-documentmanagement';
import { Observable, of, forkJoin } from 'rxjs';
import { map, switchMap, tap, retry } from 'rxjs/operators';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import { ApiRequest, DataOptions, HttpOptions } from '@xpo-ltl/data-api';
import { DocType } from '../classes/doc-type.enum';
import DocTypeHelper from '../classes/doc-type-helper';
import * as _ from 'lodash';
import { ImageFormat } from '../classes/image-format.enum';
import { FormatValidationService, ConditioningService } from '@xpo-ltl/common-services';
import { WindowService } from './window.service';
import { DmsDocTypeCd } from '@xpo-ltl/sdk-common';

@Injectable({ providedIn: 'root' })
export class DocViewService {
  private cachedToken: { tokenResponse: RetrieveDmsAuthTokenResp; expires: Date };

  constructor(
    private configManager: ConfigManagerService,
    private documentManagementService: DocumentManagementApiService,
    private conditioningService: ConditioningService,
    private formatService: FormatValidationService,
    private windowService: WindowService
  ) {}

  public listAvailableDocuments(
    searchStrings: string | string[],
    minDate?: Date,
    maxDate?: Date,
    searchTags?: string[]
  ): Observable<SearchDmsDocumentResp> {
    return this.getDmsAuthToken().pipe(
      switchMap((token: RetrieveDmsAuthTokenResp) => {
        const request = new SearchDmsDocumentRqst();
        request.corpCode = this.configManager.getSetting(ConfigManagerProperties.ImageCorpCode);
        request.dmsAuth = this.getProperty<string>(token, 'access_token');
        if (minDate) {
          request.minDateTime = minDate.toISOString();
        }
        if (maxDate) {
          request.maxDateTime = maxDate.toISOString();
        }
        if (searchTags) {
          request.searchTags = searchTags;
        }

        const listDMSDocObservables: Array<Observable<SearchDmsDocumentResp>> = [];

        if (!_.isArray(searchStrings) && _.indexOf(searchStrings, ',') > -1) {
          searchStrings = searchStrings.split(',').map((key) => key.trim());
        }

        if (_.isArray(searchStrings)) {
          _.forEach(searchStrings, (searchString) => {
            const multiRequest = _.cloneDeep(request);
            multiRequest.searchString = this.formatService.isValidProNumber(searchString)
              ? this.conditioningService.conditionProNumber(searchString)
              : searchString;
            listDMSDocObservables.push(
              this.searchDmsDocument(multiRequest, ApiRequest.concealedCall, { headers: { DMSAuth: request.dmsAuth } })
            );
          });
        } else {
          request.searchString = this.formatService.isValidProNumber(searchStrings)
            ? this.conditioningService.conditionProNumber(searchStrings)
            : searchStrings;
          listDMSDocObservables.push(
            this.searchDmsDocument(request, ApiRequest.concealedCall, { headers: { DMSAuth: request.dmsAuth } })
          );
        }

        return forkJoin(listDMSDocObservables).pipe(
          map((response: SearchDmsDocumentResp[]) => {
            const mappedSearchDocumentResp = new SearchDmsDocumentResp();
            mappedSearchDocumentResp.documentInfo = _.chain(response)
              .flatMap((docListItem) => docListItem.documentInfo)
              .uniqBy('cdt.timestamp')
              .value();
            return mappedSearchDocumentResp;
          })
        );
      })
    );
  }

  private searchDmsDocument(
    request: SearchDmsDocumentRqst,
    dataOptions?: DataOptions,
    httpOptions?: HttpOptions
  ): Observable<SearchDmsDocumentResp> {
    return this.documentManagementService.searchDmsDocument(request, dataOptions, httpOptions).pipe(
      map((value) => (value['searchDmsDocumentResp'] ? value['searchDmsDocumentResp'] : value)),
      map((value: SearchDmsDocumentResp) => {
        if (value) {
          value.documentInfo = value.documentInfo.filter((docInfo) => {
            if (docInfo && docInfo.cdt && docInfo.cdt.docClass) {
              return DocTypeHelper.toEnum(docInfo.cdt.docClass) !== undefined;
            }
          });
        }
        return value;
      })
    );
  }

  private getProperty<T>(token: RetrieveDmsAuthTokenResp, propertyName: string): T {
    if (!!token) {
      return token[propertyName] as T;
    }
  }

  public getDocument(
    archiveTimestamp: string,
    docClass: DmsDocTypeCd,
    docFormat: string,
    options?: DataOptions
  ): Observable<GetDocumentResp> {
    return this.getDmsAuthToken().pipe(
      switchMap((token: RetrieveDmsAuthTokenResp) => {
        const pathParams = new GetDocumentPath();
        const queryParams = new GetDocumentQuery();

        pathParams.corpCode = this.configManager.getSetting(ConfigManagerProperties.ImageCorpCode);
        pathParams.docArchiveTimestamp = archiveTimestamp;
        pathParams.docClass = docClass;
        pathParams.docFormat = _.defaultTo(docFormat, ImageFormat.PDF);

        return this.documentManagementService.getDocument(
          pathParams,
          queryParams,
          { ...ApiRequest.defaultDataOptions, ...options, loadingOverlayEnabled: false },
          { headers: { DMSAuth: this.getProperty<string>(token, 'access_token') } }
        );
      })
    );
  }

  private getDmsAuthToken(): Observable<RetrieveDmsAuthTokenResp> {
    return this.cachedToken && this.cachedToken.expires.getMilliseconds() > Date.now()
      ? of(this.cachedToken.tokenResponse)
      : this.documentManagementService
          .retrieveDmsAuthToken(new RetrieveDmsAuthTokenRqst(), { loadingOverlayEnabled: false })
          .pipe(
            tap((resp: RetrieveDmsAuthTokenResp) => {
              this.cachedToken = {
                tokenResponse: resp,
                expires: new Date(Date.now() + this.getProperty<number>(resp, 'expires_in') * 1000),
              };
            })
          );
  }

  public navigateToDocViewApp(
    searchKeys: string,
    referenceNumber: string,
    docType: DocType,
    minDate: string,
    menuUpAllDocs: boolean,
    windowTarget?: string
  ) {
    const context = this.webContextRoot();
    const protocol = this.windowService.getProtocol();
    const host = this.windowService.getHostname();
    const port = this.windowService.getPort();

    const url = `${this.windowService.appendUrlParts(
      `${protocol}//${host}:${port}`,
      context,
      docType,
      searchKeys,
      referenceNumber
    )}?minDate=${minDate}?menuUpAllDocs=${menuUpAllDocs}`;
    this.windowService.openWindow(url, windowTarget);
  }

  private webContextRoot(): string {
    const context = this.configManager.getSetting<string>(ConfigManagerProperties.DocViewWebContext);
    return context || '/appjs/docview/';
  }
}
