import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { ValidationRegexPatterns } from '@xpo-ltl/common-services';
import { get } from 'lodash';

export function conditionalZipValidator(countryControl: AbstractControl): ValidatorFn {
  const usCountry = ['US', 'USA', 'UNITED STATES', 'UNITED STATES OF AMERICA'];
  const canadaCountry = ['CA', 'CAN', 'CANADA'];
  const usMatcher = new RegExp(ValidationRegexPatterns.usZipCode);
  const caMatcher = new RegExp(ValidationRegexPatterns.caPostalCode);
  const key = 'invalidzippostal';
  return (control: AbstractControl): { [key: string]: any } => {
    let errorMessage;
    if (countryControl && countryControl.value) {
      const countryValue: string = get(countryControl, typeof countryControl.value === 'string' ? 'value' : 'value.cca2', '');

      if (usCountry.find(item => item === countryValue.toUpperCase())) {
        errorMessage = !usMatcher.test(control.value) ? { [key]: { message: 'Invalid zip code' } } : undefined;
      } else if (canadaCountry.find(item => item === countryValue.toUpperCase())) {
        errorMessage = !caMatcher.test(control.value) ? { [key]: { message: 'Invalid postal code' } } : undefined;
      }
    }
    return errorMessage;
  };
}

@Directive({
  selector: '[xpoConditionalZipValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: XpoConditionalZipValidatorDirective, multi: true }],
})
export class XpoConditionalZipValidatorDirective implements Validator {
  @Input()
  countryControl: AbstractControl;
  validate(control: AbstractControl): { [key: string]: any } {
    return conditionalZipValidator(this.countryControl)(control);
  }
}
