/**
 * Directive to define and add a focusable tab to the NavigationBar
 */
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { NavigationBarLabelType } from '../enums';
import { NavigationBarService } from '../services/navigation-bar/navigation-bar.service';
import { NavBarPanelConfig } from '../interfaces/nav-bar-panel-config';

@Directive({
  selector: '[appNavBarPanel]',
})
export class NavBarPanelDirective implements AfterViewInit {
  @Input('appNavBarPanel')
  config: NavBarPanelConfig = {
    label: NavigationBarLabelType.Undefined,
    insertAtIndex: -1,
  };

  constructor(private element: ElementRef, private navigationBarService: NavigationBarService) {}

  ngAfterViewInit() {
    // add this panel to the Navigation bar
    this.navigationBarService.addPanel(this.element, this.config);
  }
}
