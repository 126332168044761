<div class="registration-reassign-claim">
  <div class="registration-reassign-claim-header">
    <h2 md-dialog-title>Reassign Claim</h2>
  </div>
  <mat-dialog-content class="registration-reassign-claim__content">
    <p>
      This will only reassign the claim, no other changes in the claim will be saved.
      To save the claim be sure to use the SAVE button in the footer of the page.
    </p>
    <br />
    <!-- Reassign To -->
    <form [formGroup]="formGroup">
      <mat-label>Reassign claim to</mat-label>
      <mat-form-field
          class="registration-reassign-claim__control-employee">
        <input
          matInput
          aria-label="Reassign Claim To"
          placeholder="Reassign Claim To"
          [formControlName]="formNames.Employee"
          [matAutocomplete]="auto">
        <mat-autocomplete
          class="registration-reassign-claim__employee-autocomplete"
          #auto="matAutocomplete"
          [displayWith]="displayEmployee.bind(this)">
          <mat-option
            *ngFor="let employee of (employeeFiltered$ | async)"
            [value]="employee">
            {{ displayEmployee(employee) }}
        </mat-option>
        </mat-autocomplete>
        <mat-error>
          <div [xpoErrors]="formNames.Employee">
            <div
              id="registration-reassign-claim__control-employee-error-required"
              xpoError="required">
              Required
            </div>
          </div>
        </mat-error>
      </mat-form-field>
      <br />
      <!-- Reassign Reason -->
      <mat-label>Reason to Reassign Examiner</mat-label>
      <mat-form-field
        floatLabel="auto" 
        class="registration-reassign-claim__control-reason">
        <textarea
          matInput
          md-no-ink-bar
          [formControlName]="formNames.Reason"
          rows="3"
          [maxlength]="ClaimHeaderMaxLengths.ReassignReasonNumber"
        ></textarea>
        <mat-hint>
          {{ formGroup?.get(formNames.Reason)?.value?.length || 0 }} / {{ ClaimHeaderMaxLengths.ReassignReasonNumber }}
        </mat-hint>
        <mat-error [xpoErrors]="formNames.Reason">
          <div
            id="registration-reassign-claim__control-reason-error-required"
            xpoError="required">
            Required
          </div>
          <div
            id="registration-reassign-claim__control-reason-error-maxlen"
            xpoError="maxLength" >
            Input too long
          </div>
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="registration-reassign-claim__actionbar">
    <button
      mat-button
      color="primary"
      (click)="onCancelClicked()">
      Cancel
    </button>
    <button
      mat-button
      color="primary"
      (click)="onReassignClicked()"
      [disabled]="reassignDisabled$ | async" >
      Confirm Reassign
    </button>
  </mat-dialog-actions>
</div>
