import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ClaimsApprovalComponent } from './components/claims-approval/claims-approval.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { RouterParams, RouterUriComponents } from './enums';
import { ClaimApprovalGuard } from './guards/claim-approval-guard';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { ClaimRouteGuard } from './guards/claim-route.guard';

export const RouterDefinitions: Routes = [
  {
    path: `${RouterUriComponents.CLAIM_LOOKUP}`,
    canActivate: [XpoAuthenticationGuard],
    loadChildren: () => import('./components/registration/registration.module').then((m) => m.RegistrationModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: RouterUriComponents.NOT_AUTHORIZED_PAGE,
    component: NotAuthorizedComponent,
    canActivate: [],
  },
  {
    path: RouterUriComponents.DASHBOARD,
    canActivate: [XpoAuthenticationGuard],
    loadChildren: () => import('./components/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  // {
  //   path: RouterUriComponents.SHIPMENT_DETAILS_PAGE,
  //   component: ShipmentDetailsComponent,
  //   canActivate: [XpoAuthenticationGuard],
  //   runGuardsAndResolvers: 'always',
  // },
  {
    path: `${RouterUriComponents.CLAIMS_DETAILS_PAGE}/:${RouterParams.CLAIM_ID}`,
    loadChildren: () => import('./components/registration/registration.module').then((m) => m.RegistrationModule),
    canActivate: [XpoAuthenticationGuard, ClaimRouteGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: `${RouterUriComponents.CLAIMS_APPROVAL_PAGE}/:${RouterParams.CLAIM_ID}`,
    component: ClaimsApprovalComponent,
    canActivate: [XpoAuthenticationGuard, ClaimApprovalGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: `${RouterUriComponents.CLAIM_HISTORY}/:${RouterParams.CLAIM_ID}/:${RouterParams.EVENT_ID}`,
    loadChildren: () => import('./components/claim-history/claim-history.module').then((m) => m.ClaimHistoryModule),
    canActivate: [XpoAuthenticationGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: `${RouterUriComponents.VIEW_DOCUMENTS}/:${RouterParams.DOC_TYPE}/:${RouterParams.SEARCH_KEY}/:${
      RouterParams.ARCHIVE_TIMESTAMP
    }`,
    loadChildren: () => import('./components/view-documents/view-documents.module').then((m) => m.ViewDocumentsModule),
    canActivate: [XpoAuthenticationGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: `${RouterUriComponents.CLAIMS_REGISTRATION_PAGE}`,
    loadChildren: () => import('./components/registration/registration.module').then((m) => m.RegistrationModule),
    canActivate: [XpoAuthenticationGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    // route anything we don't understand to the dashboard
    path: '**',
    redirectTo: RouterUriComponents.DASHBOARD,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(RouterDefinitions, {
      // enableTracing: true,
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
  providers: [],
  declarations: [],
})
export class AppRoutingModule {}
