export enum FooterButtonTypeCd {
  Save = 'Save',
  SaveFlat = 'SaveFlat',
  Decline = 'Decline',
  Approve = 'Approve',
  Edit = 'Edit',
  Cancel = 'Cancel',
  BackToWorklist = 'BackToWorklist',

  CompleteApproval = 'CompleteApproval',
  ProceedToDeclinationLetter = 'ProceedToDeclinationLetter',
  ConfirmDeclination = 'ConfirmDeclination',
  DeclineRebuttal = 'DeclineRebuttal',
  ApproveRebuttal = 'ApproveRebuttal',
  CancelPendingRebuttal = 'CancelPendingRebuttal',

  SubmitPayment = 'SubmitPayment',
  CancelPendingApproval = 'CancelPendingApproval',

  ReviewClaim = 'ReviewClaim',
  SubmitClaim = 'SubmitClaim',
  CancelRegistration = 'CancelRegistration',
}
