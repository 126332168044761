import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ClaimsRegistrationService } from '../../services/claims-registration/claims-registration.service';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { takeUntil } from 'rxjs/operators';
import { GetOdsShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../enums';

@Component({
  selector: 'claims-shipment-selector',
  templateUrl: './claims-shipment-selector.component.html',
  styleUrls: ['./claims-shipment-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'shipment-selector' },
})
export class ClaimsShipmentSelectorComponent {
  @Input() proNbr;
  protected unsubscriber: Unsubscriber = new Unsubscriber();

  disabled = true;
  private shipmentDetails;
  constructor(
    private claimsRegistrationService: ClaimsRegistrationService,
    private configManager:ConfigManagerService
  ) {}

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.claimsRegistrationService.shipment$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((shpResp: GetOdsShipmentResp) => {
        this.disabled = !shpResp;
        this.shipmentDetails=shpResp;
      });
  }

  openShipmentDetails() {
    const shipmentInstanceId=this.shipmentDetails.shipment.shipmentInstId;
    let baseUrl=`https://shplist.ltl-xpo.com`
    const isProd=this.configManager.getSetting(ConfigManagerProperties.production);
    if(!isProd){
      baseUrl=`https://shplist-s.dev-ltl-xpo.com`
    }
    const url = `${baseUrl}/shipment-details/${shipmentInstanceId}`
    window.open(url);
  }
}
