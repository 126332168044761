import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Rebuttal, UpsertClaimRqst, GetClaimResp } from '@xpo-ltl/sdk-claims';
import { ActionCd, RebuttalInternalStatusCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { FormUtils } from '../../../../classes/form-utils.class';
import { ClaimRebuttalFormNames, ClaimsRegistrationFormNames } from '../../../../enums';
import { CurrencyPipe } from '@angular/common';
import { AppConstantsService } from '../../../../services/app-constants.service';
import { ClaimsRegistrationService } from '../../../../services/claims-registration/claims-registration.service';
import { xpoMaxDateValidator } from '../../../../core/validators/max-date.validators';

export class RegistrationRebuttalsFormBuilder {
  static create(formBuilder: UntypedFormBuilder, currencyPipe: CurrencyPipe): Observable<AbstractControl> {
    return new Observable((observer) => {
      const formGroup = formBuilder.group({
        [ClaimRebuttalFormNames.Amount]: [0, [Validators.min(0.01)]],
        [ClaimRebuttalFormNames.AmountDisplayOnly]: [currencyPipe.transform(0)],
        [ClaimRebuttalFormNames.FilingDate]: [new Date()],
        [ClaimRebuttalFormNames.DecisionDate]: [''],
        [ClaimRebuttalFormNames.NoteTxt]: ['', [Validators.maxLength(4000)]],
        [ClaimRebuttalFormNames.ReasonCd]: ['', [Validators.required]],
        [ClaimRebuttalFormNames.InternalStatusCd]: [undefined],
        [ClaimRebuttalFormNames.ExternalStatusCd]: [undefined],
        [ClaimRebuttalFormNames.FinalApprovedDate]: [undefined],
        [ClaimRebuttalFormNames.FinalDeclinedDate]: [undefined],
        [ClaimRebuttalFormNames.ReceivedDateTime]: [
          new Date(),
          [
            Validators.required,
            xpoMaxDateValidator(
              moment()
                .endOf('day')
                .toDate()
            ),
          ],
        ],
        [ClaimRebuttalFormNames.ClaimEventId]: [undefined],
        [ClaimRebuttalFormNames.SequenceNbr]: [undefined],
        [ClaimRebuttalFormNames.RecordVersionNbr]: [undefined],
        [ClaimRebuttalFormNames.CorrelationId]: [undefined],
        [ClaimRebuttalFormNames.AuditInfo]: [undefined],
        [ClaimRebuttalFormNames.ListActionCd]: [ActionCd.ADD],
      });

      observer.next(formGroup);
      observer.complete();
    });
  }

  static setDefaultValues(formGroup: UntypedFormGroup, currencyPipe: CurrencyPipe) {
    if (formGroup && currencyPipe) {
      FormUtils.setValues(formGroup, {
        [ClaimRebuttalFormNames.Amount]: 0,
        [ClaimRebuttalFormNames.AmountDisplayOnly]: currencyPipe.transform(0),
        [ClaimRebuttalFormNames.FilingDate]: new Date(),
        [ClaimRebuttalFormNames.DecisionDate]: undefined,
        [ClaimRebuttalFormNames.NoteTxt]: '',
        [ClaimRebuttalFormNames.ReasonCd]: '',
        [ClaimRebuttalFormNames.InternalStatusCd]: undefined,
        [ClaimRebuttalFormNames.ExternalStatusCd]: '',
        [ClaimRebuttalFormNames.FinalApprovedDate]: undefined,
        [ClaimRebuttalFormNames.FinalDeclinedDate]: undefined,
        [ClaimRebuttalFormNames.ReceivedDateTime]: new Date(),
        [ClaimRebuttalFormNames.ClaimEventId]: undefined,
        [ClaimRebuttalFormNames.SequenceNbr]: undefined,
        [ClaimRebuttalFormNames.RecordVersionNbr]: undefined,
        [ClaimRebuttalFormNames.CorrelationId]: undefined,
        [ClaimRebuttalFormNames.ListActionCd]: ActionCd.ADD,
      });

      formGroup.markAsUntouched();
      formGroup.updateValueAndValidity({ emitEvent: false });
    }
  }

  static transform(
    upsertClaimRqst: UpsertClaimRqst,
    rawFormGroup: any,
    pristineClaim: GetClaimResp,
    appConstantsService: AppConstantsService,
    claimsRegistrationService: ClaimsRegistrationService
  ): UpsertClaimRqst {
    if (!_.size(pristineClaim.rebuttals)) {
      upsertClaimRqst.rebuttals = [];
    } else {
      upsertClaimRqst.rebuttals = [...pristineClaim.rebuttals];
    }

    const getExistingRebuttal = (sequenceNbr) =>
      _.find(upsertClaimRqst.rebuttals, (rebuttal: Rebuttal) => rebuttal.sequenceNbr === sequenceNbr);
    const formRebuttal = _.get(rawFormGroup, `${ClaimsRegistrationFormNames.Rebuttals}`, undefined);
    const rebuttalSequenceNbr = _.get(formRebuttal, ClaimRebuttalFormNames.SequenceNbr, undefined);
    const isExistingRebuttal = !_.isNull(rebuttalSequenceNbr) && !_.isUndefined(rebuttalSequenceNbr);

    if (!this.isEmptyRebuttal(formRebuttal)) {
      const claimRebuttal = isExistingRebuttal ? getExistingRebuttal(rebuttalSequenceNbr) : new Rebuttal();
      claimRebuttal.internalStatusCd = this.getRebuttalInternalStatusCd(
        formRebuttal,
        claimRebuttal,
        pristineClaim,
        claimsRegistrationService
      );
      claimRebuttal.claimedAmount = _.get(formRebuttal, ClaimRebuttalFormNames.Amount, undefined);
      claimRebuttal.filingDate = moment(_.get(formRebuttal, ClaimRebuttalFormNames.FilingDate)).format('MM/DD/YYYY');
      claimRebuttal.closedDateTimeUtc = formRebuttal.closedDateTimeUtc
        ? appConstantsService.getDateAsUTC(new Date(_.get(formRebuttal, ClaimRebuttalFormNames.DecisionDate)))
        : undefined;
      claimRebuttal.reasonCd = _.get(formRebuttal, ClaimRebuttalFormNames.ReasonCd, undefined);
      claimRebuttal.claimEventId = _.get(formRebuttal, ClaimRebuttalFormNames.ClaimEventId, undefined);
      claimRebuttal.claimId = _.get(formRebuttal, ClaimRebuttalFormNames.ClaimId, undefined);
      claimRebuttal.finalApprovedDate = _.get(formRebuttal, ClaimRebuttalFormNames.FinalApprovedDate, undefined)
        ? moment(_.get(formRebuttal, ClaimRebuttalFormNames.FinalApprovedDate, undefined), 'MM/DD/YYYY')
        : undefined;
      claimRebuttal.finalDeclinedDate = _.get(formRebuttal, ClaimRebuttalFormNames.FinalDeclinedDate, undefined)
        ? moment(_.get(formRebuttal, ClaimRebuttalFormNames.FinalDeclinedDate, undefined)).format('MM/DD/YYYY')
        : undefined;
      claimRebuttal.receivedDate = moment(new Date(_.get(formRebuttal, ClaimRebuttalFormNames.ReceivedDateTime)))
        .endOf('day')
        .format('MM/DD/YYYY');
      claimRebuttal.listActionCd = formRebuttal.sequenceNbr
        ? ActionCd.UPDATE
        : _.get(formRebuttal, ClaimRebuttalFormNames.ListActionCd, undefined);
      claimRebuttal.noteTxt = _.get(formRebuttal, ClaimRebuttalFormNames.NoteTxt, undefined);
      claimRebuttal.recordVersionNbr = _.get(formRebuttal, ClaimRebuttalFormNames.RecordVersionNbr, undefined);
      claimRebuttal.sequenceNbr = rebuttalSequenceNbr;
      claimRebuttal.correlationId = _.get(formRebuttal, ClaimRebuttalFormNames.CorrelationId, undefined);

      if (!isExistingRebuttal) {
        upsertClaimRqst.rebuttals.push(claimRebuttal);
      }
    }

    return upsertClaimRqst;
  }

  private static getRebuttalInternalStatusCd(
    formGroupRebuttal: any,
    upsertRebuttal: Rebuttal,
    pristineClaim: GetClaimResp,
    claimsRegistrationService: ClaimsRegistrationService
  ): RebuttalInternalStatusCd {
    const currentRebuttalStatusCd: RebuttalInternalStatusCd = _.get(
      formGroupRebuttal,
      ClaimRebuttalFormNames.InternalStatusCd,
      undefined
    );

    if (currentRebuttalStatusCd !== RebuttalInternalStatusCd.REBUTTAL_RECEIVED) {
      return currentRebuttalStatusCd;
    }

    const correspondingClaimRebuttal = _.find(pristineClaim.rebuttals, (pristineClaimRebuttal: Rebuttal) => {
      return pristineClaimRebuttal.sequenceNbr === _.get(upsertRebuttal, ClaimRebuttalFormNames.SequenceNbr, undefined);
    });

    return claimsRegistrationService.isAssignedClaimExaminer() &&
      this.hasUpdatedRebuttal(correspondingClaimRebuttal, formGroupRebuttal)
      ? RebuttalInternalStatusCd.UNDER_REVIEW
      : currentRebuttalStatusCd;
  }

  private static hasUpdatedRebuttal(originalRebuttal: Rebuttal, newRebuttal: Rebuttal): boolean {
    return (
      originalRebuttal.claimedAmount !== newRebuttal.claimedAmount ||
      originalRebuttal.filingDate !== newRebuttal.filingDate ||
      originalRebuttal.noteTxt !== newRebuttal.noteTxt ||
      originalRebuttal.reasonCd !== newRebuttal.reasonCd
    );
  }

  static isEmptyRebuttal(rebuttal: Rebuttal): boolean {
    return (
      !_.get(rebuttal, ClaimRebuttalFormNames.Amount, undefined) &&
      !_.get(rebuttal, ClaimRebuttalFormNames.ReasonCd, undefined)
    );
  }
}
