import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { GetClaimResp, UpsertClaimResp, PaymentDetail } from '@xpo-ltl/sdk-claims';
import {
  ActionCd,
  ClaimInternalStatusCd,
  PaymentStatusInternalCd,
  ClaimNoteTypeCd,
  ClaimNoteVisibilityCd,
  RebuttalInternalStatusCd,
} from '@xpo-ltl/sdk-common';

import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { FormUtils } from '../../classes/form-utils.class';
import { NotesFormNames } from '../../enums/FormControlNames/notes-form-names.enum';
import { AppConstantsService } from '../../services/app-constants.service';
import { ClaimsRegistrationService } from '../../services/claims-registration/claims-registration.service';
import { RegistrationNotesFormBuilder } from '../registration/components/registration-notes/registration-notes.form-builder';
import { AppFooterService } from '../../services/app-footer/app-footer.service';
import { ClaimsDialogService } from '../../services/claims-dialog/claims-dialog.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { RouterUriComponents } from '../../enums/router-uri-components.enum';
import { NotificationService } from '@xpo-ltl/data-api';
import { ClaimNoteMaxLengths } from '../../enums/FormMaxLengths/claim-note-max-lengths.enum';
@Component({
  selector: 'app-claims-approval',
  templateUrl: './claims-approval.component.html',
  styleUrls: ['./claims-approval.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimsApprovalComponent implements OnInit {
  public readonly NotesFormNames = NotesFormNames;
  public readonly ClaimNoteMaxLengths = ClaimNoteMaxLengths;
  public readonly FormUtils = FormUtils;
  public formGroup: UntypedFormGroup;
  public claim: GetClaimResp;

  public approvedOrDeclined: string;

  private disableButtonGroupSubject = new BehaviorSubject<boolean>(false);
  public disableButtonGroup$ = this.disableButtonGroupSubject.asObservable();
  isNewTab: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private claimsRegistrationService: ClaimsRegistrationService,
    private constantsService: AppConstantsService,
    private appFooterService: AppFooterService,
    private claimsDialogService: ClaimsDialogService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.appFooterService.clearLeftButtonConfig();
    this.appFooterService.clearRightButtonConfig();
    this.claim = _.get(this.claimsRegistrationService, 'claim', undefined);
    this.initFormGroup();
    this.isNewTab = this.route.snapshot.params['newTab'];
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      [NotesFormNames.AuthorName]: [this.constantsService.user.userId],
      [NotesFormNames.NoteTxt]: ['', [Validators.maxLength(500)]],
      [NotesFormNames.SubjectTxt]: ['Escalation Note'],
      [NotesFormNames.NoteId]: [''],
      [NotesFormNames.TypeCd]: [ClaimNoteTypeCd.EXAMINER_USE],
      [NotesFormNames.VisibilityCd]: [ClaimNoteVisibilityCd.CLAIMS_DEPT],
      [NotesFormNames.ClaimEventId]: [''],
      [NotesFormNames.RecordVersionNbr]: [''],
      [NotesFormNames.CorrelationId]: [''],
      [NotesFormNames.ListActionCd]: [ActionCd.ADD],
    });
  }

  public getPaymentRequestedAmount(): number {
    return _.get(this.claimsRegistrationService.getActivePaymentFromClaim(), 'payment.amount', 0);
  }

  public handleApproveClicked(): void {
    this.claim.claim.internalStatusCd = ClaimInternalStatusCd.APPROVED;

    this.claim.claim.reviewRequiredInd = false;
    this.claim.claim.reviewRequiredDescription = undefined;
    this.checkForAttachedClaimNote();

    this.claim.claim.approvedByEmployeeId = this.constantsService.user.employeeId;

    // if rebuttal, set rebuttal status to approved
    /* if (!!this.claim.claim.rebuttalInd) {
      const activeRebuttal = this.claimsRegistrationService.getActiveRebuttalFromClaim();
      activeRebuttal.internalStatusCd = RebuttalInternalStatusCd.APPROVED;
      activeRebuttal.listActionCd = ActionCd.UPDATE;
    } */

    const requestedPayment: PaymentDetail = this.claimsRegistrationService.getActivePaymentFromClaim();
    if(requestedPayment?.payment){
      requestedPayment.payment.listActionCd = ActionCd.UPDATE;
      requestedPayment.payment.approvedByEmployeeId = this.constantsService.user.employeeId;
    }
    this.claimsRegistrationService.upsertClaimFromClaimsApproval().subscribe((results: UpsertClaimResp) => {
        if (results) {
          this.notificationService.showSnackBarMessage('Claim Approved.', {
            durationInMillis: 3000,
            status: 'SUCCESS',
          });
        } else {
          this.notificationService.showSnackBarMessage('Error approving claim.', {
            durationInMillis: 3000,
            status: 'ERROR',
          });
        }
        this.router.navigate([RouterUriComponents.DASHBOARD]);
     
    });
  }

  public handleDeclineClicked(): void {
    this.claim.claim.internalStatusCd = ClaimInternalStatusCd.DECLINED;
    this.claim.claim.declinedAfterEscalationInd = true;

    this.checkForAttachedClaimNote();

    // if rebuttal, set rebuttal status to declined and api workflow will set status
    if (!!this.claim.claim.rebuttalInd) {
      const activeRebuttal = this.claimsRegistrationService.getActiveRebuttalFromClaim();
       if (activeRebuttal) {
          activeRebuttal.internalStatusCd = RebuttalInternalStatusCd.DECLINED;
          activeRebuttal.listActionCd = ActionCd.UPDATE;
        }
    }

    // decline current payment that is set to payment requested
    const requestedPayment: PaymentDetail = this.claimsRegistrationService.getActivePaymentFromClaim();
    if(requestedPayment?.payment){
      requestedPayment.payment.listActionCd = ActionCd.UPDATE;
      requestedPayment.payment.statusInternalCd = PaymentStatusInternalCd.DECLINED;
      this.claim.claim.approvedAmount -= +requestedPayment.payment.amount;
    }
    this.claimsRegistrationService.upsertClaimFromClaimsApproval().subscribe(
      (results: UpsertClaimResp) => {
          if (results) {
            this.notificationService.showSnackBarMessage('Claim Declined.', {
              durationInMillis: 3000,
              status: 'SUCCESS',
            });
          } else {
            this.notificationService.showSnackBarMessage('Error declining claim.', {
              durationInMillis: 3000,
              status: 'ERROR',
            });
          }
          this.router.navigate([RouterUriComponents.DASHBOARD]);
          
        },
      (error) => {
        if (!!FormUtils.getNestedValue(this.formGroup, NotesFormNames.NoteTxt)) {
          this.claim.notes.pop();
        }
      }
    );
  }


  private checkForAttachedClaimNote(): void {
    if (!!FormUtils.getNestedValue(this.formGroup, NotesFormNames.NoteTxt)) {
      const transformedNote = RegistrationNotesFormBuilder.transformNote(
        this.formGroup.getRawValue(),
        this.constantsService
      );
      this.claim.notes.push(transformedNote);
    }
  }
  
  backToDashboard(){
    this.router.navigate([RouterUriComponents.DASHBOARD]);
  }
}
