import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { MaterialMediaQueries } from '../enums/material-media-queries.enum';
import { AlertWrapperComponent } from '../components/alert-wrapper/alert-wrapper.component';
import { AlertDialogData } from '../interfaces/alert-dialog-data';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';


export interface InjectedDialogComponent {
  dataObservable: Observable<any>;
}

@Injectable({ providedIn: 'root' })
export class XpoDialogManagerService {

  private readonly configDefaults: MatDialogConfig;

  constructor(private matDialog: MatDialog,
              private mediaQueryService: MediaObserver) {
    this.configDefaults = {
      width: '650px',
      panelClass: 'xpo-dialog-panel',
    };

    this.initializeWatchers();
  }

  public alert(alertOptions: AlertDialogData, configOverrides: MatDialogConfig = {}): MatDialogRef<any> {
    const defaultsWithData = _.defaults(configOverrides, _.clone(this.configDefaults));
    defaultsWithData.data = alertOptions;

    return this.matDialog.open(AlertWrapperComponent, defaultsWithData);
  }

  public open(dialogComponent: ComponentType<any> | TemplateRef<any>, configOverrides: MatDialogConfig = {}): MatDialogRef<any> {
    const dialogConfig = _.defaults(configOverrides, _.clone(this.configDefaults));

    return this.matDialog.open(dialogComponent, dialogConfig);
  }

  private initializeWatchers(): void {
    this.mediaQueryService.asObservable()
      .subscribe(mediaChange => {
        this.configDefaults.width = (this.mediaQueryService.isActive(MaterialMediaQueries.GT_MEDIUM)) ? '650px' : '80%';
      });
  }

}
