import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { ErrorWarningValidationType } from '../enums';
/**
 * Execture a lambda function to determine if validation passes, and add either a warning or error with the keyValue
 * to the control
 */
export function lambdaValidator(testFn: Function, keyValue: string, validationType: ErrorWarningValidationType = ErrorWarningValidationType.Error): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control) {
      return null;
    }

    const isValid = testFn();
    switch (validationType) {
      case ErrorWarningValidationType.Warning:
        _.unset(control, `warnings[${keyValue}]`);
        if (isValid) {
          _.set(control, `warnings`, { ...control['warnings'], [keyValue]: true });
        }
        break;

      case ErrorWarningValidationType.Error:
        if (isValid) {
          return { [keyValue]: true };
        }
        break;
    }
    return null;
  };
}
