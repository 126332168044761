export enum RouterUriComponents {
  DASHBOARD = 'dashboard',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  CLAIMS_REGISTRATION_PAGE = 'registration',
  CLAIMS_DETAILS_PAGE = 'claim-details',
  CLAIMS_APPROVAL_PAGE = 'claim-approval',
  SHIPMENT_DETAILS_PAGE = 'shipment-details',
  CLAIM_HISTORY = 'claim-history',
  CLAIM_LOOKUP = 'claim-lookup',
  VIEW_DOCUMENTS = 'view-docs',
}
export enum RouterParams {
  CLAIM_ID = 'claim-id',
  EVENT_ID = 'event-id',
  DOC_TYPE = 'doc-type',
  SEARCH_KEY = 'search-key',
  ARCHIVE_TIMESTAMP = 'archive-timestamp',
}
