<form [formGroup]="formGroup">
  <div class="service-center-chargeability">
    <div class="header__title">
      <h2 md-dialog-title>Service Center Chargeability</h2>
    </div>


    <div class="service-center-chargeability__container">
      <mat-dialog-content class="content">
        <div class="content__orig-dest-load-at">
            <div>Origin, Destination & Load At SICs</div>
            <div class="section-container">
              <div class="section">
                <div class="section-column">{{ (isSameOriginDestSic$ | async) ? 'Origin/Dest Sic' : 'Origin SIC'}}</div>
                <div class="section-column">% Allocation</div>
                <div class="section-column amount">Amount</div>
              </div>
              <div class="section">
                <div class="section-column">
                  <mat-form-field floatLabel="never">
                    <input matInput
                            placeholder="Origin SIC"
                            maxlength="3"
                            [readonly]="isOriginReadonly$ | async"
                            [formControlName]="ServiceCenterChargeabilityFormNames.OriginSic"
                            (blur)="validateOriginSic()">
                    <mat-error>
                      <div *ngIf="FormUtils.hasError(formGroup, ServiceCenterChargeabilityFormNames.OriginSic, 'required')">Required</div>
                      <div *ngIf="FormUtils.hasError(formGroup, ServiceCenterChargeabilityFormNames.OriginSic, 'notUnique')">Duplicate</div>
                      <div *ngIf="FormUtils.hasError(formGroup, ServiceCenterChargeabilityFormNames.OriginSic, 'invalidLocation')">Invalid SIC</div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="section-column">
                  <mat-form-field floatLabel="never">
                    <input matInput="number"
                            placeholder="% Alloc."
                            maxlength="5"
                            [readonly]="isReadonly$ | async"
                            [xpo-allow-characters]="numbersExp | toRegex"
                            [formControlName]="ServiceCenterChargeabilityFormNames.OriginPctAloc"
                            [errorStateMatcher]="totalPercentageErrorStateMatcher"
                            (blur)="validateOriginPctAloc()">
                    <mat-error>
                      <div *ngIf="FormUtils.hasError(formGroup, ServiceCenterChargeabilityFormNames.OriginPctAloc, 'required')">Required</div>
                      <div *ngIf="formGroup.hasError('max')">Exceeds
                        100%</div>
                      <div *ngIf="formGroup.hasError('not100')">Total
                        &lt;
                        100%</div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="section-column amount" style="margin-top: 18px;">{{formGroup.get(ServiceCenterChargeabilityFormNames.OriginAmount).value
                  | currency}}</div>
              </div>

              <div class="load-at-sics" [class.hidden]="loadAtSics.length === 0">
                  <ng-container *ngTemplateOutlet="loadAtSicsForm"></ng-container>
              </div>

              <div *ngIf="!(isSameOriginDestSic$ | async)">
                <div class="section">
                  <div class="section-column">Dest. SIC</div>
                  <div class="section-column">% Allocation</div>
                  <div class="section-column amount">Amount</div>
                </div>
                <div class="section">
                  <div class="section-column">
                    <mat-form-field floatLabel="never">
                      <input matInput
                              placeholder="Dest. SIC"
                              maxlength="3"
                              [readonly]="isDestReadonly$ | async"
                              [formControlName]="ServiceCenterChargeabilityFormNames.DestSic"
                              (blur)="validateDestSic()">
                      <mat-error>
                        <div *ngIf="FormUtils.hasError(formGroup, ServiceCenterChargeabilityFormNames.DestSic, 'required')">Required</div>
                        <div *ngIf="FormUtils.hasError(formGroup, ServiceCenterChargeabilityFormNames.DestSic, 'notUnique')">Duplicate</div>
                        <div *ngIf="FormUtils.hasError(formGroup, ServiceCenterChargeabilityFormNames.DestSic, 'invalidLocation')">Invalid SIC</div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="section-column">
                    <mat-form-field floatLabel="never">
                      <input matInput
                              placeholder="% Alloc."
                              maxlength="5"
                              [readonly]="isReadonly$ | async"
                              [xpo-allow-characters]="numbersExp | toRegex"
                              [formControlName]="ServiceCenterChargeabilityFormNames.DestPctAloc"
                              [errorStateMatcher]="totalPercentageErrorStateMatcher"
                              (blur)="validateDestPctAloc()">
                      <mat-error>
                        <div *ngIf="FormUtils.hasError(formGroup, ServiceCenterChargeabilityFormNames.DestPctAloc, 'required')">Required</div>
                        <div *ngIf="formGroup.hasError('max')">Exceeds
                          100%</div>
                        <div *ngIf="formGroup.hasError('not100')">Total
                          &lt;
                          100%</div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="section-column amount" style="margin-top: 18px;">{{formGroup.get(ServiceCenterChargeabilityFormNames.DestAmount).value |
                    currency}}</div>
                </div>
              </div>
            </div>
            <div class="claim-payout">
              <div class="claim-payout__label">Claim Payout Total:</div>
              <div class="claim-payout__total">{{approvedAmount | currency}}</div>
            </div>
          </div>
          <div class="content__other">
            <div>Other</div>
            <div class="section-container"
                  [formArrayName]="ServiceCenterChargeabilityFormNames.OtherSics">
              <div *ngFor="let otherLoc of otherLocs.controls; let i = index"
                    [formGroupName]="i">
                <div class="section">
                  <div class="section-column">Other SIC</div>
                  <div class="section-column">% Allocation</div>
                  <div class="section-column amount">Amount</div>
                </div>
                <div class="section">
                  <div class="section-column">
                    <mat-form-field floatLabel="never">
                      <input matInput
                              placeholder="Other SIC"
                              maxlength="3"
                              [readonly]="isReadonly$ | async"
                              [formControlName]="ServiceCenterChargeabilityOtherSicsFormNames.Sic"
                              (blur)="validateOtherSic(i)">
                      <mat-error>
                          <div *ngIf="FormUtils.hasError(otherLoc, ServiceCenterChargeabilityOtherSicsFormNames.Sic, 'required')">Required</div>
                          <div *ngIf="FormUtils.hasError(otherLoc, ServiceCenterChargeabilityOtherSicsFormNames.Sic, 'notUnique')">Duplicate</div>
                          <div *ngIf="FormUtils.hasError(otherLoc, ServiceCenterChargeabilityOtherSicsFormNames.Sic, 'invalidLocation')">Invalid SIC</div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="section-column">
                    <mat-form-field floatLabel="never">
                      <input matInput
                              placeholder="% Alloc."
                              maxlength="5"
                              [readonly]="isReadonly$ | async"
                              [xpo-allow-characters]="numbersExp | toRegex"
                              [formControlName]="ServiceCenterChargeabilityOtherSicsFormNames.PctAloc"
                              [errorStateMatcher]="totalPercentageErrorStateMatcher"
                              (blur)="validateOtherPctAloc(i)">
                      <mat-error>
                        <div *ngIf="FormUtils.hasError(otherLoc, ServiceCenterChargeabilityOtherSicsFormNames.PctAloc, 'required')">Required</div>
                        <div *ngIf="formGroup.hasError('max')">Exceeds
                          100%</div>
                        <div *ngIf="formGroup.hasError('not100')">Total
                          &lt;
                          100%</div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="section-column amount" style="margin-top: 18px;">{{otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.Amount).value
                    | currency}}</div>
                </div>
              </div>
              <button mat-button
                      color="primary"
                      [disabled]="isReadonly$ | async"
                      (click)="addAnother()">+Add Another</button>
            </div>
          </div>
          <div class="content__notes">
            <div>Reason</div>
            <mat-form-field >
              <mat-select [formControlName]="ServiceCenterChargeabilityFormNames.ReasonCode" class="reason-code">
                <mat-option *ngFor="let reason of ClaimChargeabilityReasonCd" [value]="reason" class="reason-option"> {{reason | serviceCenterChargeabilityReasonCdPipe}}</mat-option>
              </mat-select>
              <mat-error>
                <div>Required</div>
              </mat-error>
            </mat-form-field>
            <div>Notes (optional)</div>
            <div>
                <mat-form-field  floatLabel="auto">
                <textarea #notes
                          matInput
                          placeholder="Notes about the chargeability change"
                          class="content__notes__text"
                          [maxlength]="MaxNotesCharacters"
                          [formControlName]="ServiceCenterChargeabilityFormNames.Notes">
                </textarea>
              </mat-form-field>
            </div>
          </div>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions class="actionbar">
      <button mat-button
              color="primary"
              (click)="cancelClicked()">Cancel</button>
      <button mat-button
              color="primary"
              class="origin-destination-section"
              [disabled]="!formGroup.valid || !formGroup.dirty || (isReadonly$ | async)"
              (click)="saveClicked()">Save</button>
    </mat-dialog-actions>
  </div>
</form>

<ng-template #loadAtSicsForm >
  <ng-container [formGroup]="formGroup">
    <ng-container [formArrayName]="ServiceCenterChargeabilityFormNames.LoadAtSics">
      <div *ngFor="let loadAtLoc of loadAtLocs.controls; let i = index"
      [formGroupName]="i">
        <div class="section">
          <div class="section-column">Load at SIC</div>
          <div class="section-column">% Allocation</div>
          <div class="section-column amount">Amount</div>
        </div>
        <div class="section">
          <div class="section-column">
            <mat-form-field floatLabel="never">
              <input matInput
                      placeholder="Load at SIC"
                      maxlength="3"
                      [readonly]="true"
                      [formControlName]="ServiceCenterChargeabilityLoadAtSicsFormNames.Sic">
              <mat-error>
                <div *ngIf="FormUtils.hasError(loadAtLoc, ServiceCenterChargeabilityLoadAtSicsFormNames.Sic, 'required')">Required</div>
                  <div *ngIf="FormUtils.hasError(loadAtLoc, ServiceCenterChargeabilityLoadAtSicsFormNames.Sic, 'notUnique')">Duplicate</div>
                  <div *ngIf="FormUtils.hasError(loadAtLoc, ServiceCenterChargeabilityLoadAtSicsFormNames.Sic, 'invalidLocation')">Invalid SIC</div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="section-column">
            <mat-form-field floatLabel="never">
              <input matInput
                      placeholder="% Alloc."
                      maxlength="5"
                      [readonly]="isReadonly$ | async"
                      [xpo-allow-characters]="numbersExp | toRegex"
                      [formControlName]="ServiceCenterChargeabilityLoadAtSicsFormNames.PctAloc"
                      [errorStateMatcher]="totalPercentageErrorStateMatcher"
                      (blur)="validateLoadAtPctAloc(i)">
              <mat-error>
                <div *ngIf="FormUtils.hasError(loadAtLoc, ServiceCenterChargeabilityLoadAtSicsFormNames.PctAloc, 'required')">Required</div>
                <div *ngIf="formGroup.hasError('max')">Exceeds
                  100%</div>
                <div *ngIf="formGroup.hasError('not100')">Total
                  &lt;
                  100%</div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="section-column amount" style="margin-top: 18px;">{{loadAtLoc.get(ServiceCenterChargeabilityLoadAtSicsFormNames.Amount).value
            | currency}}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
