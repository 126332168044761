import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { ButtonConfig } from '../../classes/button-config';
import * as _ from 'lodash';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppFooterService {
  private leftButtonConfigSubject = new BehaviorSubject<ButtonConfig[]>(undefined);
  public leftButtonConfig$ = this.leftButtonConfigSubject.asObservable();

  private rightButtonConfigSubject = new BehaviorSubject<ButtonConfig[]>(undefined);
  public rightButtonConfig$ = this.rightButtonConfigSubject.asObservable();

  private buttonClickedSubject = new Subject<ButtonConfig>();
  public buttonClicked$ = this.buttonClickedSubject.asObservable();

  private hasButtonsSubject = new BehaviorSubject<boolean>(false);
  public hasButtons$ = this.hasButtonsSubject.asObservable().pipe(distinctUntilChanged());

  constructor() {}

  public setButtonConfig(leftButtonConfig: ButtonConfig[], rightButtonConfig: ButtonConfig[]) {
    this.setLeftButtonConfig(leftButtonConfig);
    this.setRightButtonConfig(rightButtonConfig);
  }

  public setLeftButtonConfig(config: ButtonConfig[]) {
    this.leftButtonConfigSubject.next(config);
    this.refreshHasButtonsState();
  }

  public clearLeftButtonConfig() {
    this.leftButtonConfigSubject.next([]);
    this.refreshHasButtonsState();
  }

  public setRightButtonConfig(config: ButtonConfig[]) {
    this.rightButtonConfigSubject.next(config);
    this.refreshHasButtonsState();
  }

  public clearRightButtonConfig() {
    this.rightButtonConfigSubject.next([]);
    this.refreshHasButtonsState();
  }

  public handleButtonClicked(config: ButtonConfig) {
    this.buttonClickedSubject.next(config);
  }

  private refreshHasButtonsState(): void {
    this.hasButtonsSubject.next(this.hasLeftButtonConfig || this.hasRightButtonConfig);
  }

  public get hasLeftButtonConfig(): boolean {
    return _.some(this.leftButtonConfigSubject.value);
  }

  public get hasRightButtonConfig(): boolean {
    return _.some(this.rightButtonConfigSubject.value);
  }
}
