import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

export function xpoMaxDateValidator(date: Date, alias?: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const errorKey: string = alias || 'xpoMaxDate';
    if (control.value && date) {
      return control.value > date ?  {[errorKey]: { 'value': date, 'actualValue': control.value }} : null;
    }
  };
}

export function xpoMaxDateAsyncValidator(date$: BehaviorSubject<any>, alias?: string): AsyncValidatorFn {
  return (control: AbstractControl): Observable<{[key: string]: any}> => {
    return Observable.create((observer) => {
      observer.next(xpoMaxDateValidator(date$.getValue(), alias)(control));
    });
  };
}

@Directive({
  selector: '[xpoMaxDate]',
  providers: [{provide: NG_VALIDATORS, useExisting: XpoMaxDateValidatorDirective, multi: true}]
})
export class XpoMaxDateValidatorDirective implements Validator {
  @Input() date: Date;

  validate(control: AbstractControl): {[key: string]: any} {
    return xpoMaxDateValidator(this.date)(control);
  }
}
