<div class="app-footer-container" fxLayout="row" fxLayoutAlign="space-between center">
  

  <div fxLayout="row" fxLayoutAlign="start center" [ngStyle]="{'margin-left': '14px', 'margin-right': '8px' }">
    <div fxLayoutAlign="start center">
      <span>© {{ year }} XPO. </span>
      <span> &nbsp; Version - {{ buildVersion }}</span>
    </div>

    <div class="app-footer-container_buttons" fxLayout="row" fxLayoutAlign="space-between center">
      <div (click)="feedbackClicked()" class="add-pointer">
        <a>Feedback</a>
      </div>
      <div>
        <a id="releaseNotesClaims">Release Notes</a>
      </div>
      <div>
        <a href="https://xpologistics.sharepoint.com/sites/150/ttdd/LTLservicecenter/SitePages/Edge%20Linehaul.aspx" id="trainingClaims">Training</a>
      </div>
    </div>
  </div>

  <div *ngIf="hasButtons$ | async" fxLayout="row" fxLayoutAlign="end center" fxFlex="56">
    <div *ngIf="(claimsRegistrationService.claim$ | async)" class="app-footer-container__claim-number" flex="30"
      fxLayoutAlign="center center">
      Claim #: {{(claimsRegistrationService.claim$ | async).claim.claimId}}
      <span *ngIf="(claimsRegistrationService.claimEditState$ | async) == ClaimEditState.ReadOnly"
        class="app-footer-container__view-only">(View
        Only)</span>
    </div>
    <div fxLayout="row" class="app-footer-container-row" fxFlex="80" fxlayoutAlign="end center">
      <div fxLayout="row" fxLayoutAlign="start center" class="app-footer-container-row-left">
        <button *ngFor="let button of (footerService.leftButtonConfig$ | async); let i = index"
          id="left-footer-{{i+1}}-button" mat-button [ngClass]="{ 'mat-raised-button': button.raised }"
          [color]="button.color" [disabled]="button.disabled" [ngStyle]="{ display: button.visible ? 'block' : 'none' }"
          (click)="buttonClicked(button)">
          {{ button.label }}
        </button>
      </div>
      <div fxLayout="row" class="app-footer-container-row" fxLayoutAlign="end center">
        <button *ngFor="let button of (footerService.rightButtonConfig$ | async); let i = index"
          id="right-footer-{{i+1}}-button" mat-button [ngClass]="{ 'mat-raised-button': button.raised }"
          [color]="button.color" [disabled]="button.disabled$ ? (button.disabled$ | async) : button.disabled"
          [ngStyle]="{ display: button.visible ? 'block' : 'none', 'margin-left': '8px', 'margin-right': '8px' }" (click)="buttonClicked(button)">
          {{ button.label }}
        </button>
      </div>
    </div>
  </div>

</div>