export enum IndicatorsFormNames {
  Damage = 'damage',

  IndicatorsGroup = 'options',
  InvoiceProvided = 'invoiceProvided',
  NoOpsException = 'noOpsException',
  PossibleLateFiling = 'possibleLateFiling',
  ExcessiveValue = 'excessiveValue',
  NonRevenueShipment = 'nonRevenueShipment',
  PotentialDRP = 'potentialDRP',

  DuplicateClaimsGroup = 'duplicateClaims',
  DuplicateClaimNumbers = 'duplicateClaimNumbers',
}

export enum IndicatorDamageFormValues {
  ConcealedDamage = 'concealedDamage',
  WreckAndFire = 'wreckAndFire',
  None = 'none',
}
