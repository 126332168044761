import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

/**
 * Return a full name built from the passed data with either fullName or firstName and lastName properties
 */
@Pipe({
  name: 'employeeName',
})
export class EmployeeNamePipe implements PipeTransform {
  /**
   * Transforms an employee object to a string
   * @param value employee to transform
   * @param ignoreFullName transforms using firstName and lastName properties
   */
  transform(value: any, ignoreFullName = false): string {
    if (value) {
      if (_.has(value, 'fullName') && !ignoreFullName) {
        return _.get(value, 'fullName');
      } else {
        const fullName = [_.get(value, 'firstName'), _.get(value, 'lastName')].join(' ').trim();
        return _.size(fullName) > 0 ? fullName : null;
      }
    }
    return null;
  }
}
