import { AfterViewInit, Component, ComponentFactoryResolver, Inject, Type, ViewChild, ViewEncapsulation } from '@angular/core';
import { AlertDialogData } from '../../../interfaces/alert-dialog-data.interface';
import { InjectedDialogComponent } from '../../../interfaces/injected-dialog-component.interface';
import { DialogTemplateInjectorDirective } from '../../../dialogs/dialog-template-injector.directive';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-alert-wrapper',
  templateUrl: './alert-wrapper.component.html',
  styleUrls: ['./alert-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertWrapperComponent implements AfterViewInit {
  @ViewChild('injectorRef') messageContent: DialogTemplateInjectorDirective;

  constructor(private dialogRef: MatDialogRef<AlertWrapperComponent>, @Inject(MAT_DIALOG_DATA) public data: AlertDialogData, private componentFactoryResolver: ComponentFactoryResolver) {}

  ngAfterViewInit() {
    if (this.data.contentComponent) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.contentComponent);
      const componentRef = this.messageContent.viewContainerRef.createComponent(componentFactory);
      (<InjectedDialogComponent>componentRef.instance).dataObservable = this.data.injectedData;
    }
  }

  handleCloseClick(): void {
    this.dialogRef.close();
  }
}
