import { Pipe, PipeTransform } from '@angular/core';
import { unescape } from 'lodash';

@Pipe({
  name: 'unescapeXml',
})
export class UnescapeXmlPipe implements PipeTransform {
  transform(value: any = '', args?: any): any {
    return unescape(value);
  }
}
