import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { XpoDialogManagerService } from '../core/services/xpo-dialog-manager.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '@xpo-ltl/sdk-common';
import { Observable, of } from 'rxjs';
import { catchError, map, concatMap, take } from 'rxjs/operators';
import { NotAuthorizedComponent } from '../components/not-authorized/not-authorized.component';
import { RouterUriComponents, UserRole } from '../enums';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import { AppConstantsService } from '../services/app-constants.service';
import * as _ from 'lodash';
import { XpoLtlRoleSwitcherService, XpoLtlRoleSwitcherData, XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';

@Injectable()
export class RoleCheckGuard implements CanActivate {
  constructor(
    private loginService: XpoLtlLoggedInUserService,
    private config: ConfigManagerService,
    private router: Router,
    private appConstantsService: AppConstantsService,
    private dialogManager: XpoDialogManagerService,
    private roleSwitcherService: XpoLtlRoleSwitcherService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.loginService.getLoggedInUser(this.config.getSetting(ConfigManagerProperties.loggedInUserRoot)).pipe(
      concatMap((user: User) => {
        if (!this.appConstantsService.user) {
          this.appConstantsService.user = user;
        }

        const activeClaimsUserRoles = this.appConstantsService.getActiveClaimsRolesFromUser(user.roles);
        this.appConstantsService.activeUserRoles = activeClaimsUserRoles;

        if (activeClaimsUserRoles.length === 1) {
          this.appConstantsService.setActiveUserRole(activeClaimsUserRoles[0]);
        } else if (activeClaimsUserRoles.length > 1) {
          // There is a case where an user can have more than 1 role but we shouldnt trigger the dialog, RVP´s can have readonly role in those cases we
          // need to filter that role.
          const activeUserRoles = activeClaimsUserRoles.filter((role) => {
            const roleName = this.appConstantsService.getUserRoleFromRoleName(role);
            return roleName !== UserRole.ReadOnly;
          });
          const activeUserRoleFromLocalStorage = this.appConstantsService.getActiveUserRoleFromLocalStorage();
          // According to SPGC-991 , the only possible multiple role scenario should be CSR and Examiner
          const transformedRoles = activeUserRoles
            .map((role) => {
              const roleName = this.appConstantsService.getUserRoleFromRoleName(role);
              return roleName.toLowerCase() === UserRole.Csr ? _.toUpper(roleName) : _.upperFirst(roleName);
            })
            .filter((role) => role.toLowerCase() === UserRole.Csr || role.toLowerCase() === UserRole.Examiner);
          if (activeUserRoleFromLocalStorage) {
            this.appConstantsService.setActiveUserRole(activeUserRoleFromLocalStorage);
            this.appConstantsService.displayMultipleRole = transformedRoles.length > 1;
          } else if (transformedRoles.length > 1) {
            this.appConstantsService.displayMultipleRole = true;
            return this.roleSwitcherService
              .showRoleSwitcherDialog({ roles: transformedRoles } as XpoLtlRoleSwitcherData)
              .pipe(
                take(1),
                map((activeRole) => {
                  if (activeRole) {
                    this.appConstantsService.setActiveUserRole(activeRole.toLowerCase());
                  }
                  return user;
                })
              );
          } else {
            this.appConstantsService.setActiveUserRole(activeUserRoles[0]);
          }
        }

        return of(user);
      }),
      map((user: User) => {
        if (this.appConstantsService.isAuthorizedUser(user)) {
          if (route.routeConfig.path.startsWith(RouterUriComponents.CLAIMS_REGISTRATION_PAGE)) {
            // Only the CSR is allowed to the Registration page
            if (!this.appConstantsService.isCSR) {
              this.router.navigate([RouterUriComponents.DASHBOARD]);
              return false;
            }
          }
          return true;
        }
        this.showNotAuthorizedDialog();
        return false;
      }),
      catchError((error) => {
        // this.appConstantsService.user = undefined;
        this.showNotAuthorizedDialog();
        return of(false);
      })
    );
  }

  private showNotAuthorizedDialog() {
    this.dialogManager.alert(
      {
        titleText: 'No Authorization',
        contentComponent: NotAuthorizedComponent,
        injectedData: of({ isSuccess: false }),
      },
      {
        disableClose: true,
        width: '600',
      }
    );
  }
}
