import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoLtlPipeModule } from '@xpo-ltl/ngx-ltl';
import { DataDisplayGroupComponent } from './data-display-group.component';

@NgModule({
  declarations: [DataDisplayGroupComponent],
  imports: [CommonModule, XpoLtlPipeModule],
  exports: [DataDisplayGroupComponent],
})
export class DataDisplayGroupModule {}
