import { RegistrationCommodityErrorWarningType } from '../enums/registration-commodity-error-warning-type.enum';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { ClaimsRegistrationFormNames, CommodityLineItemFormNames, RegistrationHeaderFormNames } from '../enums/FormControlNames';
import { ActionCd } from '@xpo-ltl/sdk-common';
import { GetClaimResp } from '@xpo-ltl/sdk-claims';

export function claimAmountValidator(baseFormGroup: UntypedFormGroup, claim: GetClaimResp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control || !baseFormGroup || !!claim) {
      return null;
    }
    const headerControl = baseFormGroup.get(ClaimsRegistrationFormNames.Header);
    const enteredClaimAmount = +headerControl.get(RegistrationHeaderFormNames.ClaimRequestAmount).value * 100 || 0;
    const lineItemClaimAmount = (control as UntypedFormArray).controls
      .filter(fg => fg.get(CommodityLineItemFormNames.ListActionCd).value !== ActionCd.DELETE)
      .reduce((total, commodity) => +_.get(commodity.get(CommodityLineItemFormNames.ClaimedAmount), 'value', 0) * 100 + total, 0);
    return Math.round(lineItemClaimAmount) !== Math.round(enteredClaimAmount) ? { [RegistrationCommodityErrorWarningType.ClaimAmountMismatch]: true } : null;
  };
}
