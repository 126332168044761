import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { EmailInteraction, Note, GetClaimResp } from '@xpo-ltl/sdk-claims';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConstantsService } from '../../services/app-constants.service';
import { ClaimsRegistrationService } from '../../services/claims-registration/claims-registration.service';
import { EmailsConfig } from './emails/emails-config';
import { ClaimNoteVisibilityCd } from '@xpo-ltl/sdk-common';

@Component({
  selector: 'app-notes-emails',
  templateUrl: './notes-emails.component.html',
  styleUrls: ['./notes-emails.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesEmailsComponent implements OnInit, OnDestroy {
  private internalNotesHiddenBehaviorSubject = new BehaviorSubject<boolean>(true);
  private internalNotesBehaviorSubject = new BehaviorSubject<string>('');

  internalNotesBadgeConfig = {
    xpoBadgeHidden: this.internalNotesHiddenBehaviorSubject,
    xpoBadge: this.internalNotesBehaviorSubject,
    xpoBadgeFontSize: '12px',
    xpoBadgeBackgroundColor: '#c51162',
    xpoBadgeColor: '#ffffff',
  };

  private emailsHiddenBehaviorSubject = new BehaviorSubject<boolean>(true);
  private emailsBehaviorSubject = new BehaviorSubject<string>('');

  emailsBadgeConfig = {
    xpoBadgeHidden: this.emailsHiddenBehaviorSubject,
    xpoBadge: this.emailsBehaviorSubject,
    xpoBadgeFontSize: '12px',
    xpoBadgeBackgroundColor: '#c51162',
    xpoBadgeColor: '#ffffff',
  };

  private draftsHiddenBehaviorSubject = new BehaviorSubject<boolean>(true);
  private draftsBehaviorSubject = new BehaviorSubject<string>('');

  draftsBadgeConfig = {
    xpoBadgeHidden: this.draftsHiddenBehaviorSubject,
    xpoBadge: this.draftsBehaviorSubject,
    xpoBadgeFontSize: '12px',
    xpoBadgeBackgroundColor: '#c51162',
    xpoBadgeColor: '#ffffff',
  };

  private webNotesHiddenBehaviorSubject = new BehaviorSubject<boolean>(true);
  private webNotesBehaviorSubject = new BehaviorSubject<string>('');

  webNotesBadgeConfig = {
    xpoBadgeHidden: this.webNotesHiddenBehaviorSubject,
    xpoBadge: this.webNotesBehaviorSubject,
    xpoBadgeFontSize: '12px',
    xpoBadgeBackgroundColor: '#c51162',
    xpoBadgeColor: '#ffffff',
  };

  @Output()
  public closeClickedHandler = new EventEmitter<void>();

  @ViewChild('tabGroup', { static: true })
  public tabGroup: MatTabGroup;

  public handleDMSDownloadAttachment: Function;

  private isInternalNotesActiveSubject = new BehaviorSubject<boolean>(true);
  public isInternalNotesActive$ = this.isInternalNotesActiveSubject.asObservable();

  private isWebNotesActiveSubject = new BehaviorSubject<boolean>(false);
  public isWebNotesActive$ = this.isWebNotesActiveSubject.asObservable();

  private isEmailActiveSubject = new BehaviorSubject<boolean>(false);
  public isEmailActive$ = this.isEmailActiveSubject.asObservable();

  private isDraftActiveSubject = new BehaviorSubject<boolean>(false);
  public isDraftActive$ = this.isDraftActiveSubject.asObservable();

  private internalNotesTotalCountSubject = new BehaviorSubject<number>(0);
  public internalNotesTotalCount$ = this.internalNotesTotalCountSubject.asObservable();

  private webNotesTotalCountSubject = new BehaviorSubject<number>(0);
  public webNotesTotalCount$ = this.webNotesTotalCountSubject.asObservable();

  private emailsTotalCountSubject = new BehaviorSubject<number>(0);
  public emailsTotalCount$ = this.emailsTotalCountSubject.asObservable();

  private draftsTotalCountSubject = new BehaviorSubject<number>(0);
  public draftsTotalCount$ = this.draftsTotalCountSubject.asObservable();

  private emailConfigSubject = new BehaviorSubject<EmailsConfig>(undefined);
  public emailConfig$ = this.emailConfigSubject.asObservable();

  private unsubscriber = new Unsubscriber();

  public readonly dmsDocList$ = this.claimsRegistrationService.dmsDocList$;
  public readonly claim$ = this.claimsRegistrationService.claim$;
  public claimsRegistrationFormGroup = this.claimsRegistrationService.claimsRegistrationFormGroup;

  private webNotesSubject = new BehaviorSubject<Note[]>([]);
  public webNotes$ = this.webNotesSubject.asObservable();

  private internalNotesSubject = new BehaviorSubject<Note[]>([]);
  public internalNotes$ = this.internalNotesSubject.asObservable();

  public checkClaimRecordVersionNbrFn: Function = () => {};

  public get internalNotes() {
    return _.filter(_.get(this.claimsRegistrationService.claim, 'notes', []), (note: Note) => {
      return this.constants.isReadOnly
        ? note.visibilityCd !== ClaimNoteVisibilityCd.EXTERNAL &&
            note.visibilityCd !== ClaimNoteVisibilityCd.CLAIMS_DEPT
        : note.visibilityCd !== ClaimNoteVisibilityCd.EXTERNAL;
    });
  }

  public get webNotes() {
    return _.filter(
      _.get(this.claimsRegistrationService.claim, 'notes', []),
      (note: Note) => note.visibilityCd === ClaimNoteVisibilityCd.EXTERNAL
    );
  }

  constructor(private claimsRegistrationService: ClaimsRegistrationService, private constants: AppConstantsService) {}

  ngOnInit() {
    this.tabGroup.selectedIndexChange.pipe(takeUntil(this.unsubscriber.done$)).subscribe((selectedIndex) => {
      this.isInternalNotesActiveSubject.next(selectedIndex === 0);
      this.isWebNotesActiveSubject.next(selectedIndex === 1);
      this.isEmailActiveSubject.next(selectedIndex === 2);
      this.isDraftActiveSubject.next(selectedIndex === 3);
    });

    this.claimsRegistrationService.claim$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((claim) => {
      this.updateBadgeCounts();
      this.updateEmailConfig(claim);
    });

    this.claimsRegistrationService.updateNotesEmailsBadgeCounts$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(() => {
        this.updateBadgeCounts();
      });

    this.internalNotesSubject.next(this.internalNotes);
    this.webNotesSubject.next(this.webNotes);
    this.handleDMSDownloadAttachment = this.claimsRegistrationService.downloadDMSAttachment.bind(
      this.claimsRegistrationService
    );
    this.checkClaimRecordVersionNbrFn = this.claimsRegistrationService.checkClaimRecordVersionNbrUpdate.bind(
      this.claimsRegistrationService
    );

    this.claimsRegistrationService.claim$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((claim: GetClaimResp) => {
      this.internalNotesSubject.next(this.internalNotes);
      this.webNotesSubject.next(this.webNotes);
    });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  closeClicked() {
    if (this.constants.isExaminer) {
      this.internalNotesHiddenBehaviorSubject.next(true);
      this.emailsHiddenBehaviorSubject.next(true);
      this.draftsHiddenBehaviorSubject.next(true);
      this.webNotesHiddenBehaviorSubject.next(true);

      this.claimsRegistrationService.claim.claim.examinerViewTmst = new Date();
    }

    this.closeClickedHandler.emit();
  }

  private updateBadgeCounts(): void {
    const newInternalNotes = _.reduce(
      this.internalNotes,
      (total, note: Note) => {
        return note.auditInfo &&
          note.auditInfo.createdTimestamp > this.claimsRegistrationService.claim.claim.examinerViewTmst
          ? total + 1
          : total;
      },
      0
    );

    // External facing notes
    const newWebNotes = _.reduce(
      this.webNotes,
      (total, note: Note) => {
        return note.auditInfo &&
          note.auditInfo.createdTimestamp > this.claimsRegistrationService.claim.claim.examinerViewTmst
          ? total + 1
          : total;
      },
      0
    );

    const allClaimEmails = _.get(this.claimsRegistrationService.claim, 'emails', []);

    const newEmails = _.reduce(
      allClaimEmails,
      (total, email: EmailInteraction) => {
        return email && !email.draftEmailInd && !email.readInd ? total + 1 : total;
      },
      0
    );

    const newDrafts = _.reduce(
      allClaimEmails,
      (total, draftEmail: EmailInteraction) => {
        return draftEmail && draftEmail.draftEmailInd && !draftEmail.readInd ? total + 1 : total;
      },
      0
    );

    this.internalNotesHiddenBehaviorSubject.next(newInternalNotes === 0);
    this.internalNotesBehaviorSubject.next(`${newInternalNotes}`);
    this.internalNotesTotalCountSubject.next(this.internalNotes.length);

    this.webNotesHiddenBehaviorSubject.next(newWebNotes === 0);
    this.webNotesBehaviorSubject.next(`${newWebNotes}`);
    this.webNotesTotalCountSubject.next(this.webNotes.length);

    this.emailsHiddenBehaviorSubject.next(newEmails === 0);
    this.emailsBehaviorSubject.next(`${newEmails}`);
    this.emailsTotalCountSubject.next(_.filter(allClaimEmails, (email) => !email.draftEmailInd).length);

    this.draftsHiddenBehaviorSubject.next(newDrafts === 0);
    this.draftsBehaviorSubject.next(`${newDrafts}`);
    this.draftsTotalCountSubject.next(_.filter(allClaimEmails, (email) => !!email.draftEmailInd).length);
  }

  private updateEmailConfig(claim: GetClaimResp): void {
    const config = {
      subject: `${_.get(claim, 'claim.claimId', '')} - ${_.get(claim, 'claim.claimantRefNbr', '')} `,
      recipient: `${_.get(claim, 'claimantContact.email', '')}`,
    };
    this.emailConfigSubject.next(config);
  }
}
