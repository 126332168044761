import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormatValidationService, ConditioningService } from '@xpo-ltl/common-services';
import { Claim, GetClaimResp, UpsertClaimRqst } from '@xpo-ltl/sdk-claims';
import {
  ClaimCurrencyCd,
  ClaimExternalStatusCd,
  ClaimFilingMethodCd,
  ClaimInternalStatusCd,
  ClaimTypeCd,
  CurrencyCd,
  ProStatusCd,
} from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { ErrorWarningValidationType } from '../../../../enums/error-warning-validation-type.enum';
import { ClaimsRegistrationFormNames, RegistrationHeaderFormNames } from '../../../../enums/FormControlNames';
import { ProValidationErrorWarningType } from '../../../../enums/pro-validation-error-warning-type.enum';
import { ClaimsForShipmentCacheService } from '../../../../services/claims-for-shipments-cache/claims-for-shipment-cache.service';
import { ClaimsRegistrationService } from '../../../../services/claims-registration/claims-registration.service';
import { duplicateClaimValidator, proHasStatusValidator, lambdaValidator } from '../../../../validators';
import { availableProValidator } from '../../../../validators/available-pro-validator.directive';
import { claimValueValidator } from '../../../../validators/claim-value-validator.directive';
import { proValidator } from '../../../../validators/pro-validator.directive';
import { AppConstantsService } from '../../../../services/app-constants.service';
import { xpoMaxDateValidator } from '../../../../core/validators/max-date.validators';
import { xpoMinDateValidator } from '../../../../core/validators/min-date.validators';
import { xpoBadCharactersValidator } from '../../../../core/validators/non-use-characters.validators';
import { ClaimHeaderMaxLengths } from 'projects/internal/src/app/enums/FormMaxLengths/claim-header-max-lengths.enum';

export class RegistrationHeaderFormBuilder {
  static readonly CLAIMANT_REF_BAD_CHARACTERS = `%^'`;
  private static defaultValues = {};

  static create(
    formBuilder: UntypedFormBuilder,
    claimsRegistrationService: ClaimsRegistrationService,
    formatService: FormatValidationService,
    conditioningService: ConditioningService,
    claimsForShipmentCacheService: ClaimsForShipmentCacheService
  ): Observable<AbstractControl> {
    return new Observable((observer) => {
      const formGroup = formBuilder.group({
        [RegistrationHeaderFormNames.ProNumber]: [
          '',
          [
            Validators.required,
            proValidator(formatService, conditioningService),
            proHasStatusValidator([ProStatusCd.VOIDED], ProValidationErrorWarningType.ProHasStatus, () =>
              _.get(claimsRegistrationService.shipment, 'proFrtBillIndexStatCd')
            ),
            availableProValidator(
              ErrorWarningValidationType.Warning,
              () => _.get(claimsRegistrationService.shipment, 'proFrtBillIndexStatCd'),
              () => {
                if (!!claimsRegistrationService.shipment) {
                  const billClassCd = _.get(claimsRegistrationService.shipment, 'shipment.billClassCd');
                  return claimsRegistrationService.isNonRevenueBillClass(billClassCd);
                }
                return false;
              }
            ),
            lambdaValidator(
              () => {
                if (!!claimsRegistrationService.shipment) {
                  const billClassCd = _.get(claimsRegistrationService.shipment, 'shipment.billClassCd');
                  return claimsRegistrationService.isNonRevenueBillClass(billClassCd);
                }
                return false;
              },
              ProValidationErrorWarningType.NonRevPro,
              ErrorWarningValidationType.Warning
            ),
          ],
        ],
        [RegistrationHeaderFormNames.ClaimReceivedDate]: [
          new Date(),
          [
            Validators.required,
            xpoMaxDateValidator(
              moment()
                .endOf('day')
                .toDate()
            ),
            xpoMinDateValidator(
              moment()
                .subtract(5, 'years')
                .toDate()
            ),
          ],
        ],
        [RegistrationHeaderFormNames.ClaimType]: [ClaimTypeCd.DAMAGE, Validators.required],
        [RegistrationHeaderFormNames.CustomerReferenceNumber]: [
          '',
          [
            xpoBadCharactersValidator(RegistrationHeaderFormBuilder.CLAIMANT_REF_BAD_CHARACTERS),
            Validators.maxLength(ClaimHeaderMaxLengths.CustomerReferenceNumber),
          ],
        ],
        [RegistrationHeaderFormNames.DeclinedDate]: [''],
        [RegistrationHeaderFormNames.FilingMethod]: [ClaimFilingMethodCd.EMAIL, Validators.required],
        [RegistrationHeaderFormNames.ClaimRequestAmount]: [
          0,
          [Validators.required, Validators.min(0.01), claimValueValidator(ErrorWarningValidationType.Warning, 50000)],
        ],
        [RegistrationHeaderFormNames.ClaimRequestAmountDisplayOnly]: ['$0.00'],
        [RegistrationHeaderFormNames.Currency]: [ClaimCurrencyCd.US_DOLLARS, Validators.required],

        [RegistrationHeaderFormNames.FilingDate]: [new Date()],

        [RegistrationHeaderFormNames.PendingCustomerResponse]: [false],
        [RegistrationHeaderFormNames.PendingServiceCenterResponse]: [false],
        [RegistrationHeaderFormNames.PendingInspection]: [false],
        [RegistrationHeaderFormNames.Subrogation]: [false],
        [RegistrationHeaderFormNames.PaymentReconciled]: [false],
        [RegistrationHeaderFormNames.InLitigation]: [false],
        [RegistrationHeaderFormNames.Rebuttal]: [false],
        [RegistrationHeaderFormNames.ExaminerReassignReason]: [''],
        [RegistrationHeaderFormNames.ReassignToExaminer]: [undefined],
        [RegistrationHeaderFormNames.Examiner]: [''],

        // ShipmentInstId is a hidden field.
        [RegistrationHeaderFormNames.ShipmentInstId]: [''],
      });

      RegistrationHeaderFormBuilder.defaultValues = formGroup.getRawValue();

      formGroup
        .get(RegistrationHeaderFormNames.ShipmentInstId)
        .setAsyncValidators([
          duplicateClaimValidator(
            claimsForShipmentCacheService,
            formGroup.get(RegistrationHeaderFormNames.ProNumber),
            claimsRegistrationService,
            formatService
          ),
        ]);

      observer.next(formGroup);
      observer.complete();
    });
  }

  static setDefaultValues(formGroup: UntypedFormGroup): void {
    if (formGroup) {
      formGroup.setValue(RegistrationHeaderFormBuilder.defaultValues);
    }
  }

  static transform(
    upsertClaimRqst: UpsertClaimRqst,
    rawFormGroup: any,
    claim: GetClaimResp,
    appConstantsService: AppConstantsService
  ): UpsertClaimRqst {
    if (!upsertClaimRqst.claim) {
      upsertClaimRqst.claim = new Claim();
    }
    // TODO: ADD EXAMINER CHANGE DATA

    if (!claim) {
      upsertClaimRqst.claim.internalStatusCd = ClaimInternalStatusCd.SUBMITTED;
      upsertClaimRqst.claim.externalStatusCd = ClaimExternalStatusCd.SUBMITTED;
    }
    const getValue = (field: string, defaultValue: any) =>
      _.get(rawFormGroup, `${ClaimsRegistrationFormNames.Header}.${field}`, defaultValue);
    upsertClaimRqst.claim.proNbr = getValue(RegistrationHeaderFormNames.ProNumber, undefined);
    upsertClaimRqst.claim.receivedDate = moment(
      getValue(RegistrationHeaderFormNames.ClaimReceivedDate, new Date())
    ).format('MM/DD/YYYY');
    upsertClaimRqst.claim.claimedAmount = getValue(RegistrationHeaderFormNames.ClaimRequestAmount, undefined);
    upsertClaimRqst.claim.typeCd = getValue(RegistrationHeaderFormNames.ClaimType, undefined);
    upsertClaimRqst.claim.currencyCd = getValue(RegistrationHeaderFormNames.Currency, undefined);
    upsertClaimRqst.claim.claimantRefNbr = getValue(RegistrationHeaderFormNames.CustomerReferenceNumber, undefined);
    upsertClaimRqst.claim.filingMethodCd = getValue(RegistrationHeaderFormNames.FilingMethod, undefined);
    upsertClaimRqst.claim.currentAssigneeEmployeeId = _.get(claim, 'claim.currentAssigneeEmployeeId');
    upsertClaimRqst.claim.filingDate = moment(getValue(RegistrationHeaderFormNames.FilingDate, new Date())).format(
      'MM/DD/YYYY'
    );
    upsertClaimRqst.claim.pendingCustomerResponseInd = getValue(
      RegistrationHeaderFormNames.PendingCustomerResponse,
      false
    );
    upsertClaimRqst.claim.pendingSicResponseInd = getValue(
      RegistrationHeaderFormNames.PendingServiceCenterResponse,
      false
    );
    upsertClaimRqst.claim.pendingInspectionInd = getValue(RegistrationHeaderFormNames.PendingInspection, false);
    upsertClaimRqst.claim.subrogationInd = getValue(RegistrationHeaderFormNames.Subrogation, false);
    upsertClaimRqst.claim.subrogationPymtReconInd = getValue(RegistrationHeaderFormNames.PaymentReconciled, false);
    upsertClaimRqst.claim.inLitigationInd = getValue(RegistrationHeaderFormNames.InLitigation, false);
    upsertClaimRqst.claim.rebuttalInd = getValue(RegistrationHeaderFormNames.Rebuttal, false);

    return upsertClaimRqst;
  }
}
