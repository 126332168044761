<div class="navigation-bar" *ngIf="isShown$ | async"
  [ngClass]="{ 'navigation-bar-non-prod': isNonProduction(), 'navigation-bar-elevate': hasScrolled }">
  <div class="navigation-bar__container">
    <nav mat-tab-nav-bar>
      <a mat-tab-link *ngFor="let item of (navigationBarService.panels$ | async)"
        (click)="navigationBarService.showPanel(item)" [active]="navigationBarService.activePanel == item">
        <span [innerHTML]="item.label" [style.border-bottom]="item.style"></span>
      </a>
    </nav>
    <div class="navigation-bar__container-buttons-container">
      <ng-container *ngFor="let button of (navigationBarService.buttons$ | async)">
        <button
          *ngIf="button.icon !== 'link'; else documentSelector"
          #navButtons
          mat-icon-button
          class="navigation-bar__container-buttons-container-button" (click)="button.onClick($event)"
          [matTooltip]="button.tooltip" [disabled]="button.disabled">
          <span *ngIf="button.badgeConfig; then: withBadge else: withoutBadge"></span>
          <ng-template #withBadge>
            <mat-icon [xpoBadge]="button.badgeConfig">{{ button.icon }}</mat-icon>
          </ng-template>
          <ng-template #withoutBadge>
            <mat-icon>{{ button.icon }}</mat-icon>
          </ng-template>
        </button>
        <ng-template #documentSelector>
          <claims-document-selector
            #navButtons
            (selectionChange)="openViewDoc($event)"
            class="navigation-bar__container-buttons-container-button"
            [tooltip]="button.tooltip"
            >
          </claims-document-selector>
        </ng-template>

      </ng-container>
      <claims-shipment-selector [proNbr]="claimsRegistrationService?.claim?.claim?.proNbr"></claims-shipment-selector>
    </div>
    <mat-menu #navMenu="matMenu" xPosition="before">
      <button *ngFor="let menuButton of (navigationBarService.menuButtons$ | async)?.menuButtons"
        (click)="menuButton.onClick($event)" mat-menu-item>{{ menuButton.menuItem }}</button>
    </mat-menu>
    <button #navButtonMore mat-icon-button matTooltip="More (Alt + O)" class="navigation-bar__container-buttons-more" [matMenuTriggerFor]="navMenu"
      *ngIf="(navigationBarService.menuButtons$ | async)?.menuButtons?.length > 0">
      <span
        *ngIf="((navigationBarService.menuButtons$ | async)?.moreButtonConfig?.xpoBadgeHidden | async); then: navMenuWithoutBadge else: navMenuWithBadge">
      </span>
    </button>
  </div>
</div>

<ng-template #navMenuWithBadge>
  <mat-icon [xpoBadge]="(navigationBarService.menuButtons$ | async)?.moreButtonConfig">more_vert</mat-icon>
</ng-template>
<ng-template #navMenuWithoutBadge>
  <mat-icon>more_vert</mat-icon>
</ng-template>
