<div [formGroup]="formGroup"
     class="emails">
  <as-split #splitPanel
            direction="horizontal"
            class="emails__content"
            [ngClass]="{'emails__content-compose-only': config?.composeOnly}"
            gutterSize="24">
    <as-split-area class="emails__content-left"
                   [visible]="!draftInd"
                   size="{{!config?.composeOnly ? 50 : 100}}">
      <div class="emails__content-inner">
        <mat-form-field class="subject">
          <input #subject
                 type="text"
                 matInput
                 placeholder="Subject"
                 autocomplete="off"
                 [maxLength]="ClaimEmailMaxLengths.SubjectCharacters"
                 [formControlName]="EmailFormNames.Subject">
          <mat-hint align="end">{{subject?.value?.length}} / {{ClaimEmailMaxLengths.SubjectCharacters}}</mat-hint>
          <mat-error>
            <div [xpoErrors]="EmailFormNames.Subject">
              <div xpoError="required"
                   id="email-subject-input-required-error">Required</div>
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="recipient" *ngIf="!config?.hideRecipient">
          <input #recipient
                 type="text"
                 matInput
                 placeholder="Recipient"
                 autocomplete="off"
                 [formControlName]="EmailFormNames.Recipient"
                 [maxLength]="ClaimEmailMaxLengths.RecipientCharacters">
          <mat-error>
            <div [xpoErrors]="EmailFormNames.Recipient">
              <div xpoError="required"
                   id="email-recipient-input-required-error">Required</div>
              <div xpoError="email"
                   id="email-recipient-input-invalid-error">Invalid</div>
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="cc">
          <input #cc
                 type="text"
                 matInput
                 placeholder="CC"
                 autocomplete="off"
                 [formControlName]="EmailFormNames.Cc"
                 [maxLength]="ClaimEmailMaxLengths.CCCharacters">
          <mat-error>
            <div [xpoErrors]="EmailFormNames.Cc">
              <div xpoError="required"
                   id="email-cc-input-required-error">Required</div>
              <div xpoError="email"
                   id="email-recipient-input-invalid-error">Invalid</div>
            </div>
          </mat-error>
        </mat-form-field>

        <div class="divider"></div>
        <div class="emails__content-editor">
          <mat-form-field class="emails__content-template"
                          floatLabel="never"
                          [style.display]="config?.hideTemplate ? 'none' : 'block'">
            <mat-select #template
                        [disabled]="constants.isReadOnly"
                        placeholder="Insert Template">
              <mat-option *ngFor="let template of (templates$ | async)"
                          [value]="template">
                {{template | asWords}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ckeditor formControlName="{{EmailFormNames.Body}}" [config]="ckConfig"></ckeditor>
          <div class="emails__content-length"><span>{{bodyControl?.value?.length}} / {{ClaimEmailMaxLengths.BodyCharacters}}</span></div>
          <mat-error class="emails__text-required-error">
            <div [xpoErrors]="EmailFormNames.Body">
              <div xpoError="required"
                   *ngIf="formGroup.get(EmailFormNames.Body)?.touched"
                   class="email-text-input-error">Required</div>
              <div xpoError="maxlength" class="email-text-input-error" *ngIf="formGroup?.get(EmailFormNames.Body)?.touched">Cannot exceed {{ClaimEmailMaxLengths.BodyCharacters}} characters</div>
            </div>
          </mat-error>
        </div>
        <div class="emails__content-actionbuttons">
          <button id="clear"
                  mat-button
                  color="primary"
                  (click)="handleClearClicked()"
                  [disabled]="isEmailBodyEmpty$ | async">CLEAR</button>
          <span *ngIf="uploadingDownloadingFile$ | async"
                class="spinner"></span>
          <span [matTooltip]="(attachments$ | async)">
            <button id="attach-file"
                    mat-button
                    mat-icon-button
                    aria-label="attach file"
                    [matMenuTriggerFor]="uploadOptions"
                    [disabled]="constants.isReadOnly || (uploadingDownloadingFile$ | async)"
                    [ngClass]="{'uploading': uploadingDownloadingFile$ | async}">
              <mat-icon>attach_file</mat-icon>
              <div [xpoBadge]="uploadBadgeConfig"></div>
            </button>
          </span>
          <button id="send"
                  mat-raised-button
                  color="primary"
                  (click)="handleSendClicked()"
                  [disabled]="(formGroup.statusChanges | async) !== FormStatus.Valid">SEND</button>
          <mat-menu #uploadOptions="matMenu">
            <!-- TODO: CHECK ngxFilePicker and multiple properties 
              <button mat-menu-item
                    [ngxFilePicker]="readMode"
                    accept="all/*"
                    (readStart)="onReadStart($event)"
                    (readEnd)="onReadEnd($event)"
                    [multiple]="true"
                    (filePick)="onFilePicked($event)">Attach Files</button> -->
              <button mat-menu-item
                    accept="all/*"
                    (readStart)="onReadStart($event)"
                    (readEnd)="onReadEnd($event)"
                    (filePick)="onFilePicked($event)">Attach Files</button> 
            <button mat-menu-item
                    (click)="dms.open()"
                    *ngIf="(dmsDocumentList$ | async)?.length > 0">DMS Image</button>
            <button mat-menu-item
                    (click)="downloadAttachmentsClicked()"
                    *ngIf="(attachments$ | async)?.length > 0">Download Attachments</button>
            <button mat-menu-item
                    (click)="handleClearAttachments()"
                    *ngIf="(attachments$ | async)?.length > 0">Clear Attachments</button>
          </mat-menu>
          <mat-select multiple
                      #dms
                      [style.display]="dms.panelOpen ? 'block' : 'none'"
                      style="width: 0px;">
            <mat-option *ngFor="let dmsDoc of (dmsDocumentList$ | async)"
                        [value]="dmsDoc">{{dmsDoc | documentSearchPipe}}</mat-option>
          </mat-select>
        </div>
      </div>
    </as-split-area>
    <as-split-area class="emails__detail-inner"
                   size="50"
                   [visible]="!config?.composeOnly">
      <div class="emails__detail-inner-container">
        <div class="emails__detail-header">
          <h3>{{ draftInd ? 'Drafts' : 'Emails'}} <span *ngIf="(emails$ | async)?.length > 0">({{(emails$ | async)?.length}})</span></h3>
          <div class="header-selectors">
            <mat-form-field floatLabel="never" *ngIf="!draftInd">
              <mat-select #direction
                          [disabled]="constants.isReadOnly"
                          placeholder="Incoming and Outgoing">
                <mat-option *ngFor="let direction of directionTypes"
                            [value]="direction">
                  {{direction}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field floatLabel="never">
              <mat-select #age
                          [disabled]="constants.isReadOnly"
                          placeholder="Newest to Oldest">
                <mat-option *ngFor="let age of ageTypes"
                            [value]="age">
                  {{age}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="emails__detail-list"
             *ngFor="let email of (emails$ | async)">
          <app-emails-detail [email]="email"
                             [pro]="claim?.claim?.proNbr"
                             [draftInd]="draftInd"
                             [downloadDmsAttachmentFn]="downloadDmsAttachmentFn"
                             (emailActionClicked)="emailActionHandler($event)"></app-emails-detail>
        </div>
      </div>
    </as-split-area>
  </as-split>
</div>
