import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Contact, UpsertClaimRqst } from '@xpo-ltl/sdk-claims';
import { ActionCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import {
  ClaimantContactFormNames,
  ClaimantPayeeFormNames,
  ClaimsRegistrationFormNames,
  CommonRegex,
} from '../../../../enums';
import { contactPhonePhoneExtValidator } from '../../../../validators';
import { xpoBadCharactersValidator } from '../../../../core/validators/non-use-characters.validators';
import { xpoPhoneFormatValidator } from '../../../../core/validators/phone.validators';
import { ClaimPartyMaxLengths } from 'projects/internal/src/app/enums/FormMaxLengths/claim-party-max-lengths.enum';
import { ClaimPartyMinLengths } from 'projects/internal/src/app/enums/claim-party-min-lengths.enum';

export class RegistrationContactFormBuilder {
  /**
   * Creates the form group and returns it to claimaint parent
   *
   * @param formBuilder used to construct the form
   */
  static create(formBuilder: UntypedFormBuilder): UntypedFormGroup {
    const formGroup = formBuilder.group({
      [ClaimantContactFormNames.ContactFirstName]: [
        undefined,
        [
          Validators.maxLength(ClaimPartyMaxLengths.ContactFirstName),
          xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS),
        ],
      ],
      [ClaimantContactFormNames.ContactLastName]: [
        undefined,
        [
          Validators.maxLength(ClaimPartyMaxLengths.ContactLastName),
          xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS),
        ],
      ],
      [ClaimantContactFormNames.ContactEmail]: [
        undefined,
        [Validators.maxLength(ClaimPartyMaxLengths.ContactEmail), Validators.email],
      ],
      [ClaimantContactFormNames.ContactPhone]: [
        undefined,
        [
          Validators.maxLength(ClaimPartyMaxLengths.ContactPhone),
          Validators.minLength(ClaimPartyMinLengths.ContactPhone),
          xpoPhoneFormatValidator,
        ],
      ],
      [ClaimantContactFormNames.ContactPhoneExt]: [
        undefined,
        [
          Validators.maxLength(ClaimPartyMaxLengths.ContactPhoneExt),
          xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS),
        ],
      ],
      [ClaimantContactFormNames.ContactFax]: [
        undefined,
        [
          Validators.maxLength(ClaimPartyMaxLengths.ContactFax),
          Validators.minLength(ClaimPartyMinLengths.ContactFax),
          xpoPhoneFormatValidator,
        ],
      ],
      [ClaimantContactFormNames.ListActionCd]: [ActionCd.NO_ACTION], // Achtung!! This can lead to duplicates in database, watch status carefully
      [ClaimantContactFormNames.ContactId]: [undefined],
    });

    formGroup.setValidators([contactPhonePhoneExtValidator()]);

    return formGroup;
  }

  /**
   * Transforms contact formGroup into UpsertClaimRqst
   *
   * @param upsertClaimRqst the claim request to add formGroups data to
   * @param rawGroup the raw formGroup
   */
  static transform(upsertClaimRqst: UpsertClaimRqst, rawFormGroup: any): UpsertClaimRqst {
    if (this.hasContactData(rawFormGroup)) {
      if (!upsertClaimRqst.claimantContact) {
        upsertClaimRqst.claimantContact = new Contact();
      }

      const getValue = (field: string, defaultValue: any = undefined) =>
        _.get(
          rawFormGroup,
          `${ClaimsRegistrationFormNames.AsEnteredClaimant}.${ClaimantPayeeFormNames.Contact}.${field}`,
          defaultValue
        );

      upsertClaimRqst.claimantContact.contactId = getValue(ClaimantContactFormNames.ContactId);
      upsertClaimRqst.claimantContact.firstName = getValue(ClaimantContactFormNames.ContactFirstName);
      upsertClaimRqst.claimantContact.lastName = getValue(ClaimantContactFormNames.ContactLastName);
      upsertClaimRqst.claimantContact.email = getValue(ClaimantContactFormNames.ContactEmail);
      upsertClaimRqst.claimantContact.phoneNbr = getValue(ClaimantContactFormNames.ContactPhone);
      upsertClaimRqst.claimantContact.phoneExt = getValue(ClaimantContactFormNames.ContactPhoneExt);
      upsertClaimRqst.claimantContact.faxNbr = getValue(ClaimantContactFormNames.ContactFax);
      upsertClaimRqst.claimantContact.listActionCd = getValue(ClaimantContactFormNames.ListActionCd);
    }
    return upsertClaimRqst;
  }

  /**
   * Checker to see if contact formGroup has any data set
   *
   * @param rawGroup Flag on whether the formGroup has data set
   */
  static hasContactData(rawFormGroup: any): boolean {
    const getValue = (field: string, defaultValue: any = undefined) => {
      return _.get(
        rawFormGroup,
        `${ClaimsRegistrationFormNames.AsEnteredClaimant}.${ClaimantPayeeFormNames.Contact}.${field}`,
        defaultValue
      );
    };

    let hasData = false;
    if (!!rawFormGroup) {
      hasData = _.some(
        [
          !!getValue(ClaimantContactFormNames.ContactFirstName),
          !!getValue(ClaimantContactFormNames.ContactLastName),
          !!getValue(ClaimantContactFormNames.ContactEmail),
          !!getValue(ClaimantContactFormNames.ContactPhone),
          !!getValue(ClaimantContactFormNames.ContactPhoneExt),
          !!getValue(ClaimantContactFormNames.ContactFax),
        ],
        Boolean
      );
    }
    return hasData;
  }
}
