import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'transformClaimNumber',
})
export class ClaimNumberTransformerPipe implements PipeTransform {
  transform(claimNbr: string): string {
    return this.isValidClaimNumber(claimNbr) ? _.split(claimNbr, '-')[0] : claimNbr;
  }

  public isValidClaimNumber(claimNbr: string): boolean {
    const standardizedClaimNbr = _.split(claimNbr, '-')[0];
    return standardizedClaimNbr.slice(0, 2).toUpperCase() === 'CC' && standardizedClaimNbr.slice(2).length === 7 && typeof _.toNumber(standardizedClaimNbr.slice(2)) === 'number';
  }
}
