import { Injectable } from '@angular/core';
import { XpoLtlBaseCacheService } from '@xpo-ltl/ngx-ltl';
import { GetEmployeeDetailsByEmpIdPath, GetEmployeeDetailsByEmpIdQuery, GetEmployeeDetailsByEmpIdResp, HumanResourceApiService } from '@xpo-ltl/sdk-humanresource';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

export interface EmployeeDetailsCacheParams {
  employeeId: string;
}

@Injectable()
export class EmployeeDetailsCacheService extends XpoLtlBaseCacheService<EmployeeDetailsCacheParams, GetEmployeeDetailsByEmpIdResp> {
  constructor(private hrService: HumanResourceApiService) {
    super();
  }

  protected getKeyFromParams(params: EmployeeDetailsCacheParams): string {
    return `${_.defaultTo(params.employeeId, '').toUpperCase()}`;
  }
  protected requestData(params: EmployeeDetailsCacheParams): Observable<GetEmployeeDetailsByEmpIdResp> {
    const pathParams = new GetEmployeeDetailsByEmpIdPath();
    pathParams.employeeId = params.employeeId;

    const queryParams = new GetEmployeeDetailsByEmpIdQuery();

    return this.hrService.getEmployeeDetailsByEmpId(pathParams, queryParams, { toastOnError: false });
  }
}
