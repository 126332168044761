import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation, HostListener, AfterViewInit } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from './enums/config-manager-properties.enum';
import { NavigationBarService } from './services/navigation-bar/navigation-bar.service';
import { ScrollbarService } from './services/scrollbar/scrollbar.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { AppConstantsService } from './services/app-constants.service';
import { RouterUriComponents, UserRole } from './enums';
import { Router, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';
import { AppFooterService } from './services/app-footer/app-footer.service';
import { of, Observable } from 'rxjs';
import { detect } from 'detect-browser';
import { SideBarService } from './services/side-bar/side-bar.service';
import { XpoLtlRoleSwitcherService, XpoLtlRoleSwitcherData, XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { XpoShell, XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';
import {
  XpoAccountPopoverConfig,
} from '@xpo-ltl/ngx-ltl-core/account-popover';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { GlobalSearchDetailCd } from '@xpo-ltl/sdk-common';
import { GlobalSearchOptions } from '@xpo-ltl/ngx-ltl-global-search';
import { Log } from '@xpo-ltl/sdk-logging';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('navBarPanelContainer')
  navBarPanelContainer: ElementRef;

  @ViewChild(XpoShell)
  xpoShell: XpoShell;

  private removeListener: Function;
  title: string;
  build: string;
  isProduction: boolean;
  hasFooter$: Observable<boolean>;
  userRoleEnum = UserRole;
  accountPopoverProfileConfig: XpoAccountPopoverConfig;
  @HostListener('document:keydown.insert', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    event.preventDefault();
  }

  globalSearchOptions: GlobalSearchOptions = {
    globalSearchResultTypes: [
      { detailCd: GlobalSearchDetailCd.CLAIM_RESULT },
      { detailCd: GlobalSearchDetailCd.DISPUTE_RESULT },
      { detailCd: GlobalSearchDetailCd.INVOICE_RESULT },
    ],
  };

  constructor(
    private configManagerService: ConfigManagerService,
    private scrollbarService: ScrollbarService,
    public navigationBarService: NavigationBarService,
    private eventManager: EventManager,
    public constants: AppConstantsService,
    private roleSwitcherService: XpoLtlRoleSwitcherService,
    private appConstantsService: AppConstantsService,
    private router: Router,
    private footerService: AppFooterService,
    private elementRef: ElementRef,
    private sidebarService: SideBarService,
    private authService: XpoLtlAuthenticationService,
    private loginService: XpoLtlLoggedInUserService
  ) {
    this.title = configManagerService.getSetting<string>(ConfigManagerProperties.appName);
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.isProduction = this.configManagerService.getSetting<boolean>('production');
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
    });

    this.hasFooter$ = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap((event: NavigationEnd) => {
        const inClaimHistory = event.url.startsWith(`/${RouterUriComponents.CLAIM_HISTORY}`);
        if (inClaimHistory) {
          return of(true);
        } else {
          return this.footerService.hasButtons$;
        }
      })
    );

    this.updateVerticalHeightCssVariable();
    window.addEventListener('resize', () => this.updateVerticalHeightCssVariable());
    this.preventWindowScrollIfMobile();
  }

  handleRoleSwitchClicked(): void {
    const activeClaimsUserRoles = this.appConstantsService.getActiveClaimsRolesFromUser(this.appConstantsService.user.roles);
    // According to SPGC-991 , the only possible multiple role scenario should be CSR and Examiner

    let transformRoles = activeClaimsUserRoles
      .map(role => {
        const roleName = this.appConstantsService.getUserRoleFromRoleName(role);
        return roleName.toLowerCase() === UserRole.Csr ? _.toUpper(roleName) : _.upperFirst(roleName);
      })
    const transformedRoles = [...new Set(transformRoles)];

    this.roleSwitcherService
      .showRoleSwitcherDialog({ roles: transformedRoles, closeable: true } as XpoLtlRoleSwitcherData)
      .pipe(take(1))
      .subscribe((activeRole: UserRole) => {
        if (activeRole && this.appConstantsService.userRole !== activeRole.toLowerCase()) {
          this.appConstantsService.isUpdatingRole = true;
          this.appConstantsService.setActiveUserRole(activeRole.toLowerCase());
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.navigate([this.router.url]).then(success => {
            if (success) {
              this.appConstantsService.isUpdatingRole = false;
            }
          });
        }
      });
  }

  get sidebarItems$(): Observable<XpoShellRoute[]> {
    return this.sidebarService.sidebarItems$;
  }

  ngOnInit() {
    this.loginService.getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot)).subscribe((userInfo) => {
      if (!this.appConstantsService.user) {
        this.appConstantsService.user = userInfo;
      }
      const activeClaimsUserRoles = this.appConstantsService.getActiveClaimsRolesFromUser(userInfo.roles);
      this.appConstantsService.activeUserRoles = activeClaimsUserRoles;
      if (activeClaimsUserRoles.length === 1) {
        this.appConstantsService.setActiveUserRole(activeClaimsUserRoles[0]);
      } else if (activeClaimsUserRoles.length > 1) {
        // There is a case where an user can have more than 1 role but we shouldnt trigger the dialog, RVP´s can have readonly role in those cases we
        // need to filter that role.
        const activeUserRoles = activeClaimsUserRoles.filter(role => {
          const roleName = this.appConstantsService.getUserRoleFromRoleName(role);
          return roleName !== UserRole.ReadOnly;
        });
        const activeUserRoleFromLocalStorage = this.appConstantsService.getActiveUserRoleFromLocalStorage();
        // According to SPGC-991 , the only possible multiple role scenario should be CSR and Examiner
        const transformedRoles = activeUserRoles
          .map(role => {
            const roleName = this.appConstantsService.getUserRoleFromRoleName(role);
            return roleName.toLowerCase() === UserRole.Csr ? _.toUpper(roleName) : _.upperFirst(roleName);
          })
          .filter(role => role.toLowerCase() === UserRole.Csr || role.toLowerCase() === UserRole.Examiner);
        if (activeUserRoleFromLocalStorage) {
          this.appConstantsService.setActiveUserRole(activeUserRoleFromLocalStorage);
          this.appConstantsService.displayMultipleRole = transformedRoles.length > 1;
        } else {
          this.appConstantsService.setActiveUserRole(activeUserRoles[0]);
        }
      }
      this.accountPopoverProfileConfig = {
        name: userInfo.displayName,
        onSignOutCallback: this.signOut.bind(this),
        email: userInfo.emailAddress,
        links: [],
      };
    });
  }

  ngOnDestroy() {
    this.scrollbarService.stopWatcher();
    if (this.removeListener) {
      this.removeListener();
      this.removeListener = undefined;
    }
  }

  ngAfterViewInit(): void {
    this.sidebarService.sidebarOpen$.subscribe(open => (this.xpoShell.isDrawerOpen = open));
    this.scrollbarService.startWatcher();
    this.navigationBarService.clear();
    this.removeListener = this.eventManager.addEventListener(this.navBarPanelContainer?.nativeElement, 'scroll', event => {
      this.navigationBarService.handleScrollEvent(event);
    });
  }

  signOut(): void {
    this.authService.logout();
    window.location.reload();
  }

  private updateVerticalHeightCssVariable() {
    const vh = window.innerHeight * 0.01;
    this.elementRef.nativeElement.style.setProperty('--vh', `${vh}px`);
  }

  private preventWindowScrollIfMobile() {
    const browserInfo = detect();
    const isMobile = browserInfo && browserInfo.os && (browserInfo.os === 'iOS' || browserInfo.os === 'Android OS');
    if (isMobile) {
      window.onscroll = function () {
        window.scrollTo(0, 0);
      };
    }
  }
}
