<div class="registration-success">
  <div class="registration-success__success">Success!</div>
  <div class="registration-success__confirm">Claim Submission Confirmed</div>
  <div class="registration-success__claim-id-title">Your claim number is</div>
  <div class="registration-success__claim-id-container">
    <div class="registration-success__claim-id">{{ data.claimId }}</div>
      <div class="registration-success__copy" id="copy-to-clipboard" (click)="handleCopy(data.claimId)">Copy #</div>
  </div>
  <mat-dialog-actions class="registration-success__actionbar">
    <button mat-button color="primary" [mat-dialog-close]="false">Claims List</button>
    <button mat-button color="primary" [mat-dialog-close]="true">New Claim</button>
  </mat-dialog-actions>
</div>
