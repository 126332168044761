import { Pipe, PipeTransform } from '@angular/core';
import { BillClassCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'billClass',
})
export class BillClassPipe implements PipeTransform {
  transform(value: BillClassCd): any {
    switch (value) {
      case BillClassCd.ACCESSORIAL_ONLY:
        return 'Accessorial Only';
      case BillClassCd.ASTRAY_FRT_SEGMENT:
        return 'Astray Freight Segment';
      case BillClassCd.CLAIMS_OVRG_RPTG_BILL:
        return 'Claims Overage Reporting Bill';
      case BillClassCd.CO_BUS_SHPMT:
        return 'Company Business Shipment';
      case BillClassCd.EXPEDITE:
        return 'Expedite';
      case BillClassCd.GENERAL_CLAIMS_BUS_SHPMT:
        return 'General Claims Business Shipment';
      case BillClassCd.MASTER_SEGMENT:
        return 'Master Segment';
      case BillClassCd.MASTER_SHPMT:
        return 'Master Shipment';
      case BillClassCd.NORMAL_MVMT:
        return 'Normal Movement';
      case BillClassCd.PARTIAL_SEGMENT:
        return 'Partial Segment';
      case BillClassCd.SPLIT_SEGMENT:
        return 'Split Segment';
      default:
        return 'UNKNOWN';
    }
  }
}
