<div class="recycled-pros">
  <h2 mat-dialog-title>Recycled PROs</h2>

  <mat-dialog-content class="recycled-pros__content">
    <div class="recycled-pros__content-label">Multiple instances of PRO found. Please select the correct one.</div>
    <div class="recycled-pros__content-table">
      <mat-radio-group [(ngModel)]="selectedShipment">
        <table mat-table [dataSource]="dataSource">
          <ng-container *ngFor="let column  of columns" [matColumnDef]="column.id">
            <th mat-header-cell *matHeaderCellDef>{{column.label}}</th>
            <td mat-cell *matCellDef="let row">
              <mat-radio-button *ngIf="column.isRadioButton" [value]="row"></mat-radio-button>
              <span *ngIf="!column.isRadioButton">{{column.renderer ? column.renderer(row) : row[column.id]}}</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
      </mat-radio-group>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="recycled-pros__action-buttons-container">
    <button mat-button
            color="primary"
            (click)="cancelClicked()">Cancel</button>
    <button mat-button
            color="primary"
            [disabled]="!selectedShipment"
            (click)="selectedProClicked()">Use Selected PRO</button>
  </mat-dialog-actions>
</div>
