export enum RelatedProFormNames {
  ClaimId = 'claimId',
  ShipmentInstId = 'shipmentInstId',
  ProNbr = 'proNbr',
  PickupDate = 'pickupDate',
  RelatedShipmenTypeCd = 'relatedShipmenTypeCd',
  ListActionCd = 'listActionCd',
  AuditInfo = 'auditInfo',
  ClaimEventId = 'claimEventId',
  RecordVersionNbr = 'recordVersionNbr',
  CorrelationId = 'correlationId',

  OriginalProNumber = 'originalProNumber',
  ParentRelatedShipmentId = 'parentRelatedShipmentId',
  ShipmentStatus = 'shipmentStatus',
}
