import { Pipe, PipeTransform } from '@angular/core';
import { ClaimNoteVisibilityCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'noteVisibilityCd',
})
export class NoteVisibilityCdPipe implements PipeTransform {
  transform(value: ClaimNoteVisibilityCd): any {
    if (value === ClaimNoteVisibilityCd.CLAIMS_DEPT) {
      return 'Claims Only';
    }
    if (value === ClaimNoteVisibilityCd.EXTERNAL) {
      return 'External';
    }
    if (value === ClaimNoteVisibilityCd.XPO) {
      return 'XPO LTL';
    }

    return undefined;
  }

  transformText(text: string): ClaimNoteVisibilityCd {
    if (text === 'Claims Only') {
      return ClaimNoteVisibilityCd.CLAIMS_DEPT;
    } else if (text === 'External') {
      return ClaimNoteVisibilityCd.EXTERNAL;
    } else if (text === 'XPO LTL') {
      return ClaimNoteVisibilityCd.XPO;
    } else {
      return undefined;
    }
  }
}
