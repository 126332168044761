export enum ClaimantPayeeFormNames {
  SameAs = 'SameAs',
  OnBehalfOfSelection = 'OnBehalfOfSelector',
  OnBehalf = 'OnBehalf',
  OnBehalfOfParty = 'OnBehalfOfParty',
  ExistingContacts = 'ExistingContacts',
  Contact = 'Contact',
  ListActionCd = 'ListActionCd',
  MatchedClaimPartyId = 'MatchedClaimPartyId',
  Address = 'Address',
  AsEnteredAddress = 'AsEnteredAddress',
}
