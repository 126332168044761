import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { take, takeUntil } from 'rxjs/operators';
import { ButtonConfig } from '../../classes';
import { ClaimEditState } from '../../enums';
import { AppFooterService } from '../../services/app-footer/app-footer.service';
import { ClaimsRegistrationService } from '../../services/claims-registration/claims-registration.service';
import { ScrollbarService } from '../../services/scrollbar/scrollbar.service';
import { FeedbackService } from '../../services/feedback.service';
import { XpoFeedback } from '@xpo-ltl/ngx-ltl-core/feedback';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFooterComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  private actionButtonsElement: Element;

  public readonly ClaimEditState = ClaimEditState;
  buildVersion: string;
  year: string;


  constructor(
    private dialog: MatDialog,
    public feedbackService: FeedbackService,
    private configManagerService: ConfigManagerService,
    public footerService: AppFooterService,
    private scrollbarService: ScrollbarService,
    public claimsRegistrationService: ClaimsRegistrationService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.buildVersion = this.configManagerService.getSetting('buildVersion');
    this.year = new Date().getFullYear().toString();
    this.actionButtonsElement = this.document.getElementsByClassName('app-footer-container-row')[0];

    if (!!this.actionButtonsElement && this.scrollbarService.scrollbarStatus$) {
      this.scrollbarService.scrollbarStatus$.pipe(takeUntil(this.unsubscriber.done)).subscribe(hasScrollbars => {
        if (hasScrollbars) {
          this.actionButtonsElement.setAttribute('class', 'app-footer-container-row app-footer-container-row-with-scroll');
        } else {
          this.actionButtonsElement.setAttribute('class', 'app-footer-container-row');
        }
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  buttonClicked(buttonConfig: ButtonConfig) {
    if (!!buttonConfig.clickHandler) {
      buttonConfig.clickHandler();
    }
    this.footerService.handleButtonClicked(buttonConfig);
  }

  public get hasButtons$() {
    return this.footerService.hasButtons$;
  }

  feedbackClicked() {
    this.feedbackService.getFeedbackConfig$().pipe(take(1)).subscribe((config) => {
      this.dialog.open(XpoFeedback, { data: config });
    });
  }
}
