import { Directive, Input } from '@angular/core';
import { AbstractControl, UntypedFormArray, NG_VALIDATORS, Validator, ValidatorFn, UntypedFormGroup } from '@angular/forms';
import { FormUtils } from '../classes/form-utils.class';
import { CommodityLineItemFormNames } from '../enums';
import { ClaimTypeCd, CommodityClaimTypeCd, ActionCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';

export function requireDamageOrShortageValidator(): ValidatorFn {
  return (control: UntypedFormArray): { [key: string]: any } => {
    if (!control || _.isUndefined(control.value) || _.isNull(control.value)) {
      return null;
    }
    return _.every(
      _.filter(control.controls, (commodityFG: UntypedFormGroup) => FormUtils.getNestedValue(commodityFG, CommodityLineItemFormNames.ListActionCd) !== ActionCd.DELETE),
      (commodityFG: UntypedFormGroup) => {
        const getCommodityClaimType = FormUtils.getNestedValue(commodityFG, CommodityLineItemFormNames.ClaimTypeCd);

        return (
          getCommodityClaimType === CommodityClaimTypeCd.FREIGHT ||
          getCommodityClaimType === CommodityClaimTypeCd.CREDIT ||
          getCommodityClaimType === CommodityClaimTypeCd.MISCELLANEOUS ||
          !getCommodityClaimType
        );
      }
    ) && _.some(control.controls, commodityFG => commodityFG.touched)
      ? { requireDamageOrShortageValidator: true }
      : null;
  };
}
