export enum ConfigManagerProperties {
  buildVersion = 'buildVersion',
  production = 'production',
  apiUrl = 'apiUrl',
  scopeOptions = 'scopeOptions',
  secretToken = 'secretToken',
  appName = 'appName',
  region = 'region',
  loggedInUserRoot = 'loggedInUserRoot',
  userContextPath = 'userContextPath',
  appMetadataEndpoint = 'appMetadataEndpoint',
  bolApiEndpoint = 'bolApiEndpoint',
  customerLocationApiEndpoint = 'customerLocationApiEndpoint',
  elasticSearchApiEndpoint = 'elasticSearchApiEndpoint',
  freightFlowApiEndpoint = 'freightFlowApiEndpoint',
  infrastructureApiEndpoint = 'infrastructureApiEndpoint',
  invoiceApiEndpoint = 'invoiceApiEndpoint',
  locationApiEndpoint = 'locationApiEndpoint',
  loggingApiEndpoint = 'loggingApiEndpoint',
  membershipApiEndpoint = 'membershipApiEndpoint',
  pricingApiEndpoint = 'pricingApiEndpoint',
  shipmentApiEndpoint = 'shipmentApiEndpoint',
  errorToastDuration = 'errorToastDuration',
  successToastDuration = 'successToastDuration',
  feedbackToAddress = 'feedbackToAddress',
  feedbackSubject = 'feedbackSubject',
  filterByPickupDateAgeInMonths = 'filterByPickupDateAgeInMonths',
  webContext = 'webContext',
  assignerQueueName = 'assignerQueueName',
  assignerActivityName = 'assignerActivityName',
  assignerFlowName = 'assignerFlowName',
  registryQueueName = 'registryQueueName',
  registryActivityName = 'registryActivityName',
  registryFlowName = 'registryFlowName',
  svpEmpID = 'svpEmpId',
  presidentEmpID = 'presidentEmpID',
  ceoEmpID = 'ceoEmpId',
  imageCorpCode = 'imageCorpCode',
}
