import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Unsubscriber, XpoLtlDocType, XpoLtlDocumentSelectorComponent, XpoLtlDocumentService } from '@xpo-ltl/ngx-ltl';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { takeUntil } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../enums';
import { AppConstantsService } from '../../services/app-constants.service';
import { NavigationBarService } from '../../services/navigation-bar/navigation-bar.service';
import { ClaimsRegistrationService } from '../../services/claims-registration/claims-registration.service';

/**
 * Manage the list of Page navigation buttons.  Clicking a button will jump to that section
 * in the page.
 *
 * Buttons will also highlight depending on what page is on screen, updating as the user scrolls.
 *
 */
@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  private unsubscriber: Unsubscriber;
  private isProd = false;
  hasScrolled = false;
  isShown$ = this.navigationBarService.isShown$;
  proNbr;
  claim;
  @ViewChildren('navButtons', { read: ElementRef }) navButtons: QueryList<ElementRef>;
  @ViewChild('navButtonMore', { static: true, read: ElementRef }) navButtonMore: ElementRef;

  @HostListener('window:keydown', ['$event'])
  handleKeyDownEvent(event: KeyboardEvent) {
    const menuButtonShorcutFound = this.navigationBarService.handleMenuButtonKeyShorcut(
      event,
      this.navButtons.toArray(),
      this.navButtonMore
    );
    if (!menuButtonShorcutFound) {
      this.navigationBarService.handleKeyDownEvent(event);
    }
  }

  constructor(
    public configManager: ConfigManagerService,
    public navigationBarService: NavigationBarService,
    public appConstantsService: AppConstantsService,
    public router: Router,
    private claimsRegistrationService: ClaimsRegistrationService,
    private documentService: XpoLtlDocumentService
  ) {
    this.unsubscriber = new Unsubscriber();
    this.isProd = this.configManager.getSetting(ConfigManagerProperties.production);
  }

  ngOnInit() {
    this.navigationBarService.scrolled$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((event) => {
      this.hasScrolled = event.currentTarget['scrollTop'] > 10;
    });


  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  isNonProduction(): boolean {
    return !this.isProd;
  }

  openViewDoc(value): void {
    localStorage.setItem('doc_metadata', JSON.stringify(value));

    if (value.length > 1) {
      this.documentService.navigateToDocViewApp(
        this.proNbr,
        this.claimsRegistrationService.claim.claim.claimId,
        XpoLtlDocType.All,
        this.claimsRegistrationService.claim.claim.pickupDate,
        true
      );
    } else {
      this.documentService.navigateToDocViewApp(
        value[0].cdt.timestamp,
        this.claimsRegistrationService.claim.claim.claimId,
        value[0].cdt.docClass,
        this.claimsRegistrationService.claim.claim.pickupDate,
        value.length > 0
      );
    }
  }
}
