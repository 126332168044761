import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ConditioningService } from '@xpo-ltl/common-services';
import { OdsShipment } from '@xpo-ltl/sdk-shipmentods';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

export interface RecycledProColumn {
  pro: string;
  shipmentPickup: string;
  shipmentDelivery: string;
  shipper: string;
  consignee: string;
  shipmentInstId: number;
}

@Component({
  selector: 'app-recycled-pros',
  templateUrl: './recycled-pros.component.html',
  styleUrls: ['./recycled-pros.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecycledProsComponent implements OnInit {
  public columns = [
    { id: 'selected', label: '', isRadioButton: true },
    { id: 'pro', label: 'PRO #' },
    { id: 'shipmentPickup', label: 'Shipment Pickup' },
    { id: 'shipmentDelivery', label: 'Shipment Delivery' },
    { id: 'shipper', label: 'Shipper' },
    { id: 'consignee', label: 'Consignee' },
  ];
  public columnsToDisplay = _.map(this.columns, c => c.id);
  public dataSource = new MatTableDataSource<RecycledProColumn>([]);
  public selectedShipment: RecycledProColumn;

  constructor(@Inject(MAT_DIALOG_DATA) private data: OdsShipment[], private matDialogRef: MatDialogRef<RecycledProsComponent>, private conditioningService: ConditioningService) {}

  ngOnInit() {
    const recycledShipments = _.map(this.data, shipment => {
      return {
        pro: this.conditioningService.conditionProNumber(shipment.shipment.proNbr, 10),
        shipmentPickup: moment(shipment.shipment.pickupDate).format('MM/DD/YYYY'),
        shipmentDelivery: moment(shipment.shipment.estimatedDeliveryDate).format('MM/DD/YYYY'),
        shipper: shipment.shipperName,
        consignee: shipment.consigneeName,
        shipmentInstId: shipment.shipment.shipmentInstId,
      } as RecycledProColumn;
    });
    this.dataSource = new MatTableDataSource<RecycledProColumn>(recycledShipments);
  }

  public cancelClicked(): void {
    this.matDialogRef.close();
  }

  public selectedProClicked(): void {
    this.matDialogRef.close(this.selectedShipment.shipmentInstId);
  }
}
