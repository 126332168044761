import { FormatValidationService, ConditioningService } from '@xpo-ltl/common-services';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function proValidator(formatValidationService: FormatValidationService, conditioningService: ConditioningService): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control || !control.value) {
      return null;
    }

    // 000-00000 is a valid pro number but needs to be prevented via validation
    if (control.value.length < 9 || !formatValidationService.isValidProNumber(control.value) || conditioningService.conditionProNumber(control.value, 10) === '000-000000') {
      return { invalidProFormat: true };
    }
    return null;
  };
}
