import { Pipe, PipeTransform } from '@angular/core';
import { RebuttalInternalStatusCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'rebuttalStatusInternal',
})
export class RebuttalStatusInternalPipe implements PipeTransform {
  transform(value: RebuttalInternalStatusCd): any {
    if (!value) {
      return '-';
    }
    switch (value) {
      case RebuttalInternalStatusCd.APPROVED:
        return 'Approved';
      case RebuttalInternalStatusCd.CEO_PENDING_APPROVAL:
        return 'Pending CEO Approval';
      case RebuttalInternalStatusCd.CUSTOMER_CANCELLED:
        return 'Cancelled By Customer';
      case RebuttalInternalStatusCd.DECLINED:
        return 'Declined';
      case RebuttalInternalStatusCd.DIRECTOR_PENDING_APPROVAL:
        return 'Pending Director Approval';
      case RebuttalInternalStatusCd.EXAMINER_PENDING_APPROVAL:
        return 'Pending Examiner Approval';
      case RebuttalInternalStatusCd.MANAGER_PENDING_APPROVAL:
        return 'Pending Manager Approval';
      case RebuttalInternalStatusCd.PRESIDENT_PENDING_APPROVAL:
        return 'Pending President Approval';
      case RebuttalInternalStatusCd.REBUTTAL_RECEIVED:
        return 'Rebuttal Received';
      case RebuttalInternalStatusCd.SVP_OF_OPS_PENDING_APPROVAL:
        return 'Pending SVP of Operations Approval';
      case RebuttalInternalStatusCd.RVP_PENDING_APPROVAL:
        return 'Pending RVP Approval';
      case RebuttalInternalStatusCd.SVP_SALES_PENDING_APPROVAL:
        return 'Pending SVP of Sales Approval';
      case RebuttalInternalStatusCd.RVP_SALES_PENDING_APPROVAL:
        return 'Pending RVP Sales Approval';
      case RebuttalInternalStatusCd.UNDER_REVIEW:
        return 'Under Review';
      default:
        return 'UNKNOWN';
    }
  }
}
