export enum CommodityLineItemFormNames {
  ClaimTypeCd = 'claimTypeCd',
  ConditionTypeCd = 'conditionTypeCd',
  DescriptionTxt = 'descriptionTxt',
  ClaimedAmount = 'claimedAmount',
  ClaimedAmountDisplayOnly = 'claimedAmountDisplayOnly',
  ModelOrPartNbr = 'modelOrPartNbr',
  CommodityWeightUnitOfMeasure = 'commodityWeightUnitOfMeasure',
  PiecesCount = 'piecesCount',
  CommodityWeight = 'commodityWeight',
  ClassCd = 'classCd',
  FilteredCommodityClassListOptions = 'filteredCommodityClassListOptions',
  NmfcItemNbr = 'nmfcItemNbr',
  NmfcItemNbrSetClass = 'nmfcItemNbrSetClass',
  NmfcItemNbrListOptions = 'nmfcItemNbrListOptions',
  SequenceNbr = 'sequenceNbr',
  ListActionCd = 'listActionCd',
  FakClass = 'fakClass',
  FakInd = 'fakInd',
  LiabilityLimit = 'liabilityLimit',
  PrcingAgreementDollarPerLbAmount = 'prcingAgreementDollarPerLbAmount',
  PrcingAgreementDollarPerLbAmountDisplayOnly = 'prcingAgreementDollarPerLbAmountDisplayOnly',
  PricingAgreementDollarPerLbNoneInd = 'pricingAgreementDollarPerLbNoneInd',
}
