/**
 * Logged in User Role
 */
export enum UserRole {
  Csr = 'csr',
  Examiner = 'examiner',
  Manager = 'manager',
  Director = 'director',
  President = 'president',
  RVPWest = 'rvpwest',
  RVPEast = 'rvpeast',
  RVPMidwest = 'rvpmidwest',
  RVPSalesWest = 'rvpsaleswest',
  RVPSalesEast = 'rvpsaleseast',
  RVPSalesMidwest = 'rvpsalesmidwest',
  SVPSales = 'svpsales',
  SVP = 'svp',
  CEO = 'ceo',
  ReadOnly = 'readonly',
}
