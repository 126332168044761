import { Injectable } from '@angular/core';
import { GetLocReferenceDetailsBySicResp, GetLocReferenceDetailsBySicRqst, LocationApiService } from '@xpo-ltl/sdk-location';
import { Observable } from 'rxjs';
import { XpoLtlBaseCacheService } from '@xpo-ltl/ngx-ltl';

export interface LocationDetailsCacheParams {
  sicCd: string;
}

@Injectable({ providedIn: 'root' })
export class LocationDetailsCacheService extends XpoLtlBaseCacheService<LocationDetailsCacheParams, GetLocReferenceDetailsBySicResp> {
  constructor(private locationService: LocationApiService) {
    super();
  }

  protected getKeyFromParams(params: LocationDetailsCacheParams): string {
    return params.sicCd.toUpperCase();
  }

  protected requestData(params: LocationDetailsCacheParams): Observable<GetLocReferenceDetailsBySicResp> {
    const request = new GetLocReferenceDetailsBySicRqst();
    request.sicCd = params.sicCd.toUpperCase();
    return this.locationService.getLocReferenceDetailsBySic(request, { toastOnError: false });
  }
}
