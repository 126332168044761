import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

export function minLengthWarningValidator(lengthCheck: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control || !control.value) {
      return null;
    }

    _.unset(control, `warnings['short']`);

    if (control.value.length < lengthCheck) {
      _.set(control, `warnings`, { ...control['warnings'], short: true });
    }

    return null;
  };
}
