 <div class="payment-history">


  <div class="payment-history__header">
    <div class="payment-history__header-title">Payment History</div>
    <button class="payment-history__header-close" mat-icon-button [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div><mat-divider></mat-divider></div>

  <mat-dialog-content class="payment-history__content">

    <ng-container *ngIf="!!payments && payments.length > 0; else noPayments">
      <div class="payment-history__payment" *ngFor="let paymentDetail of payments; let i = index;">
        <ng-container
          [ngTemplateOutlet]='paymentDetails'
          [ngTemplateOutletContext]="{paymentDetail: paymentDetail, index: i, hideApprovals: true}">
        </ng-container>
        <div><mat-divider></mat-divider></div>
      </div>
  </ng-container>
</mat-dialog-content>

<ng-template #noPayments>
  <div class="payment-history__no-payments">No Payments For This Claim</div>
</ng-template>

<ng-template #paymentDetails let-paymentDetail="paymentDetail" let-index="index" >
  <div class="payment-history__payment-details">
    <div class="payment-history__payment-row payment-history__payment-details-header">
      <div class="payment-history__payment-details-title">Payment {{index + 1}}</div>
      <button class="payment-history__payment-details-toggle" mat-icon-button (click)="hideApproval()">
          <mat-icon *ngIf="!!hideApprovals">expand_more</mat-icon>
          <mat-icon *ngIf="!hideApprovals">expand_less</mat-icon>
      </button>
    </div>
    <div class="payment-history__payment-row">
      <app-data-display-group label="Payment Request" [value]="paymentDetail?.payment?.sentToOfspDateTimeUtc" asDate="true"></app-data-display-group>
      <app-data-display-group label="Payment Reason" [value]="paymentDetail?.payment?.additionalPaymentReasonCd | asWords"></app-data-display-group>
      <app-data-display-group label="Payment Status" [value]="paymentDetail?.payment?.statusInternalCd | paymentStatusInternal"></app-data-display-group>
      <app-data-display-group label="Payment Date" [value]="paymentDetail?.payment?.checkDate" asDate="true"></app-data-display-group>
      <app-data-display-group label="Paid Amount" [value]="paidAmount(paymentDetail?.payment)"></app-data-display-group>
    </div>
    <div class="payment-history__payment-row">
      <app-data-display-group label="Awaiting Approval From" [value]="paymentDetail?.payment?.approvalPendingEmployeeDtl | employeeName"></app-data-display-group>
      <app-data-display-group label="Final Approval From" [value]="paymentDetail?.payment?.approvedByEmployeeDtl | employeeName"></app-data-display-group>
      <app-data-display-group label="Check #" [value]="paymentDetail?.payment?.checkNbr"></app-data-display-group>
      <app-data-display-group *ngIf="paymentDetail?.payment?.checkCancelDate" label="Check Voided" [value]="paymentDetail?.payment?.checkCancelDate" asDate="true"></app-data-display-group>
      <app-data-display-group *ngIf="!paymentDetail?.payment?.checkCancelDate" label="Check Cashed" [value]="paymentDetail?.payment?.checkCashedDate" asDate="true"></app-data-display-group>
      <app-data-display-group label="OFS Invoice #" [value]="paymentDetail?.payment?.ofspInvoiceNbr"></app-data-display-group>
    </div>
  </div>
  <mat-expansion-panel class="payment-history__payment-approvals mat-elevation-z0" [expanded]="!hideApprovals">
    <table mat-table [dataSource]="paymentDetail.approvals" class="mat-elevation-z0" *ngIf="!hideApprovals">
        <ng-container matColumnDef="approver">
          <th mat-header-cell *matHeaderCellDef>Approver</th>
          <td mat-cell *matCellDef="let element">{{element?.approvedByEmployeeDtl | employeeName}}</td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Approved On</th>
          <td mat-cell *matCellDef="let element">{{element?.approvalDate | date}}</td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Max Approval Amount</th>
          <td mat-cell *matCellDef="let element">{{element?.employeeApprovalLimitAmt | currency}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['approver', 'date', 'amount']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['approver', 'date', 'amount'];"></tr>
      </table>
  </mat-expansion-panel>
</ng-template>
</div>
