import { Directive } from '@angular/core';
import { AbstractControl, Validator, ValidatorFn } from '@angular/forms';
import { FormatValidationService } from '@xpo-ltl/common-services';

export function xpoEmailValidator(formatValidator: FormatValidationService): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    if (control.value) {
      return formatValidator.isValidEmails(control.value) ? null : { 'email': control.value };
    }
    return null;
  };
}

@Directive({
  selector: '[xpoEmailValidator]'
})
export class XpoEmailValidatorDirective implements Validator {
  constructor(private formatValidator: FormatValidationService) {}
  validate(control: AbstractControl): {[key: string]: any} {
    return xpoEmailValidator(this.formatValidator)(control);
  }
}
