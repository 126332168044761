import { Pipe, PipeTransform } from '@angular/core';
import { ClaimCurrencyCd, InvoiceCurrencyCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'currencyCd',
})
export class CurrencyCdPipe implements PipeTransform {
  transform(value: InvoiceCurrencyCd | ClaimCurrencyCd): any {
    switch (value) {
      case InvoiceCurrencyCd.CANADIAN_DOLLAR:
      case ClaimCurrencyCd.CANADIAN_DOLLARS:
        return 'CAD';
      case InvoiceCurrencyCd.US_DOLLAR:
      case ClaimCurrencyCd.US_DOLLARS:
        return 'USD';
      default:
        return '';
    }
  }
}
