<div class="timeline">
  <div class="timeline-header">
    <h3 class="timeline-header-title">Claim Timeline <span *ngIf="events?.length">- {{events?.length}}</span>
      Events</h3>
    <button
      class="timeline-header-button"
      mat-raised-button
      (click)="onHelpClick()"
      [class.selected]="displayHelp">
      <mat-icon [class.selected]="displayHelp">info</mat-icon>
      Keys
    </button>

    <mat-card *ngIf="displayHelp" class="timeline-header-event-container">
      <ul>
        <li class="gray"><span>Claim Status Related</span> </li>
        <li class="orange"><span>Contact/Claimant/Payee Related</span></li>
        <li class="purple"><span>Claims Fields Updated Only</span></li>
        <li class="black"><span>DMS Related</span></li>
        <li class="lightblue"><span>Note/Email Related</span></li>
        <li class="green"><span>Payment Related</span></li>
        <li class="red"><span>Rebuttal Related</span></li>
      </ul>
    </mat-card>
  </div>

  <div id="timeline" #visjsTimeline></div>
  <div class="timeline-action-buttons">
    <div class="icon-container" (click)="onMoveToTheFirstEventClicked()">
      <mat-icon>first_page</mat-icon>
    </div>
    <div class="icon-container" (click)="onMoveLeftClicked()">
      <mat-icon>chevron_left</mat-icon>
    </div>
    <div class="icon-container" (click)="onMoveRightClicked()">
      <mat-icon>chevron_right</mat-icon>
    </div>
    <div class="icon-container" (click)="onMoveToTheLastEventClicked()">
      <mat-icon>last_page</mat-icon>
    </div>
    <div class="zoom-icons">
      <div class="icon-container zoom-icon" (click)="onZoomOutClicked()">
        <mat-icon>zoom_out</mat-icon>
      </div>
      <div class="icon-container zoom-icon" (click)="onZoomInClicked()">
        <mat-icon>zoom_in</mat-icon>
      </div>
    </div>
  </div>
</div>
