import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { XpoDialogManagerService } from '../core/services/xpo-dialog-manager.service';
import { forkJoin, Observable, ObservableInput, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { NotAuthorizedComponent } from '../components/not-authorized/not-authorized.component';
import { AppConstantsService } from '../services/app-constants.service';
import { ClaimsRegistrationService } from '../services/claims-registration/claims-registration.service';
import { RoleCheckGuard } from './role-check-guard';
import { ClaimRouteGuard } from './claim-route.guard';
import { UserRole } from '../enums';

@Injectable()
export class ClaimApprovalGuard implements CanActivate {
  constructor(
    private roleCheckGuard: RoleCheckGuard,
    private claimRouteGuard: ClaimRouteGuard,
    private dialogManager: XpoDialogManagerService,
    private claimsRegistrationService: ClaimsRegistrationService,
    private appConstantsService: AppConstantsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return forkJoin(this.roleCheckGuard.canActivate(route, state) as ObservableInput<boolean>, this.claimRouteGuard.canActivate(route, state) as ObservableInput<boolean>).pipe(
      take(1),
      map(() => {
        if (this.checkExecutive()) {
          return true;
        } else {
          this.showNotAuthorizedDialog();
          return false;
        }
      }),
      catchError(error => {
        this.showNotAuthorizedDialog();
        return of(false);
      })
    );
  }

  private showNotAuthorizedDialog() {
    this.dialogManager.alert(
      {
        titleText: 'No Authorization',
        contentComponent: NotAuthorizedComponent,
        injectedData: of({ isSuccess: false }),
      },
      {
        disableClose: true,
        width: '600',
      }
    );
  }

  private checkExecutive(): boolean {
    return (
      this.claimsRegistrationService.canApproveClaim() &&
      this.claimsRegistrationService.isClaimInExecutiveApprovalState() &&
      (this.appConstantsService.isExecutiveRole() || this.appConstantsService.isUserRole(UserRole.Director))
    );
  }
}
