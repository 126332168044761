import { Component } from '@angular/core';

@Component({
  selector: 'xpo-warnings',
  templateUrl: './xpo-warnings.component.html',
  styleUrls: ['./xpo-warnings.component.scss'],
})
export class XpoWarningsComponent {
  constructor() {}
}
