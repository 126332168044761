import { Pipe, PipeTransform } from '@angular/core';
import { PaymentStatusInternalCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'paymentStatusInternal',
})
export class PaymentStatusInternalPipe implements PipeTransform {
  transform(value: PaymentStatusInternalCd): any {
    switch (value) {
      case PaymentStatusInternalCd.CHECK_CASHED:
        return 'Check Cashed';
      case PaymentStatusInternalCd.CHECK_VOIDED:
        return 'Check Voided';
      case PaymentStatusInternalCd.PAYMENT_CANCELLED:
        return 'Payment Cancelled';
      case PaymentStatusInternalCd.PAYMENT_ISSUED:
        return 'Payment Issued';
      case PaymentStatusInternalCd.PAYMENT_ON_HOLD:
        return 'Payment On Hold';
      case PaymentStatusInternalCd.PAYMENT_REQUESTED:
        return 'Payment Requested';
      case PaymentStatusInternalCd.PENDING_TRANSMISSION:
        return 'Pending Transmission';
      case PaymentStatusInternalCd.SENT_TO_ORACLE:
        return 'Sent To Oracle';
      case PaymentStatusInternalCd.DECLINED:
        return 'Declined';
      default:
        return 'None Created';
    }
  }
}
